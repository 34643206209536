<template>
  <g ref="icon" />
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Logo0Icon",
  data: () => ({
    logoswidth: "3px",
    logopacity: 1,
  }),
  mounted() {
    const logo0 = d3.select(this.$refs["icon"]);

    logo0
      .append("circle")
      .attr("r", "33.29")
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("stroke-width", this.logoswidth);

    logo0
      .style("stroke-width", "20")
      .style("stroke", "red")
      .style("fill", "white")
      .style("stroke-opacity", 1)
      .attr("opacity", 0.6);
  },
};
</script>