<template>
  <div
    @mouseover="handleHover"
    @mouseleave="handleLeave"
  >
    <h4
      class="text_score_header"
      @click="handleClick"
    >
      <div>
        <div
          :class="{
            'text_score_no_dot': !display,
            'text_score_dot': display && status,
            'text_score_empty-dot': display && !status,
          }"
        />
      </div>
      {{ name }}
    </h4>
    <ul class="text_score_list">
      <li
        v-if="items.length > 0 && !displayEvents"
        class="text_score_items"
      >
        <span
          class="cursor-pointer"
          @click="changeDisplayDetails"
        >
          {{ `${items.length} correspondance${items.length > 1 ? 's' : ''}` }}
        </span>
      </li>
      <li
        v-for="(item, index) in items"
        v-else
        :key="index"
        class="text_score_items"
      >
        <span
          class="cursor-pointer"
          @click="changeDisplayDetails"
        >
          {{ item.defaultLabel }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import * as mutationTypes from "../store/mutations-types.js";
import EventSelectionTypes from "@/enums/event_selection_types.js";
import EventManager from "@/libraries/EventManager";
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import { mapActions, mapGetters } from 'vuex'
import utils from "@/libraries/utils.js"

export default {
  name: "ScoreTooltipName",
  props: {
    /**
     * Il s'agit des événements matchant avec le score
     * @type {Object}
     */
    items: {
      type: Object,
      required: true,
    },
    /**
     * Il s'agit du libellé du test du score
     * @type {String}
     */
    name: {
      type: String,
      required: true,
    },
    /**
     * Définit si le test est validé par des événements patient ou s'il a été cliqué par l'utilisateur
     * @type {Boolean}
     */
    display: {
      type: Boolean,
      required: true,
    },
    /**
     * Définit le type de validation: à true il s'agit d'une validation par des événements patient, à false il s'agit d'une valdiation par l'utilisateur
     * @type {Boolean}
     */
    status: {
      type: Boolean,
      required: true,
    },
    /**
     * Il s'agit du code du test du score
     * @type {String}
     */
    test: {
      type: String,
      required: true,
    },
    /**
     * Détermine si le détail des événements matchant le test doit être affiché ou non
     * @type {Boolean}
     */
    displayDetails: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    displayEvents: false
  }),
  computed: {
    ...mapGetters({
      eyediagMode: 'eyediagMode'
    })
  },
  watch: {
    displayDetails() {
      this.displayEvents = this.displayDetails
    }
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction est appelée lorsque l'utilisateur clique sur un test pour faire comme s'il était validé par des événements du dossier patient
     * @method
     * @public
     * @param {Event} event Evénement fournit par le listener
     */
    handleClick(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`refCircle/score/${mutationTypes.CHANGE_DISPLAY_VALUE}`, this.test);
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur survole un test. 
     * @method
     * @public
     * @param {Event} event Evénement fournit par le listener
     */
    handleHover(event) {
      if (!this.items.length) return;
      this.sendEvent({event: event })

      const toSumbit = [];
      
      this.items.forEach((elt) => {
        let tmp = null
        if (this.eyediagMode.type === eyediagMode.mode.PATIENT) {
          tmp = EventManager.events.find(e => e.id === elt.id)
        } else {
          tmp = utils.findEventFromRanks(EventManager.events, (e => e.id === elt.id))
        }
        if (tmp) {
          toSumbit.push(tmp)
        }
      });
      
      const hoveredEvent = EventManager.prepareFilteringSelection(
        toSumbit,
        EventSelectionTypes.SCOREMATCH
      );
      this.$store.commit(`refCircle/score/${mutationTypes.UPDATE_EVENT_HOVERED}`, hoveredEvent);
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur arrête de suvoler un test
     * @method
     * @public
     * @param {Event} event Evénement fournit par le listener
     */
    handleLeave(event) {
      if (!this.items.length) return;
      this.sendEvent({ event: event })
      this.$store.commit(`refCircle/score/${mutationTypes.UPDATE_EVENT_HOVERED}`, null);
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction permet de modifier le niveau de détail afficher par chacun des tests du score. Cette fonction est appelé lorsque l'utilisateur clique sur le nom du score
     * @param {Event} event Il s'agit de l'événement fournit par le listener
     * @method
     * @public
     */
    changeDisplayDetails(event) {
      this.sendEvent({ event })
      this.displayEvents = !this.displayEvents
      this.collaborativeEventTreated()
    }
  },
};
</script>

<style>
.text_score_header {
  color: var(--color-text);
  font-size: 1.4rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  font-family: Helvetica, Arial, sans-serif;
  cursor: pointer;
  user-select: none;
}

.text_score_items {
  color: var(--color-text);
  font-size: 1.2rem;
  user-select: none;
}

.text_score_dot {
  background: var(--c-pure-red);
  height: 7px;
  width: 7px;
  border-radius: 5px;
  margin-right: 6px;
}

.text_score_no_dot {
  background: none;
  height: 7px;
  width: 7px;
  border-radius: 5px;
  margin-right: 6px;
}

.text_score_empty-dot {
  background: rgba(0, 0, 0, 0);
  border: var(--c-pure-red) 1px solid;
  height: 6px;
  width: 6px;
  border-radius: 5px;
  margin-right: 5px;
}

.text_score_list {
  padding-left: 3rem;
}
</style>