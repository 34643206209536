<template>
  <svg
    id="svg-bio-root"
    :viewBox="viewBox"
  >
    <g :style="`transform: translate(${centerX}px, ${centerY}px) scale(${scale})`">
      <circle
        cx="0"
        cy="0"
        :r="referenceRadius"
        fill="none"
        stroke="green"
        stroke-width="2px"
      />
      <g
        id="bio-container"
        ref="bio-container"
      >
        <path
          v-for="(path, index) in paths"
          ref="bio1"
          :key="index"
          :d="path.path"
          class="bio-circle"
        />
      </g>
      <g>
        <g
          v-for="(point, index) in points"
          :key="index"
        >
          <circle
            class="point-ctrl"
            :cx="point.x"
            :cy="point.y"
            r="3"
          />
        </g>
      </g>
      <g>
        <g
          v-for="(point, index) in pointsTrace"
          :key="index"
        >
          <circle
            :class="point.peak === true
              ? 'point-debug-event'
              : (index === 0 || point.idSection !== pointsTrace[index - 1].idSection)
                ? 'point-debug-section'
                : 'point-debug'"
            :cx="point.x"
            :cy="point.y"
            r="3"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

import { mapGetters } from 'vuex'
import * as d3 from 'd3'
import CircleUtility from '@/libraries/CircleUtility.js'
import EyeCardinalClosedDebug from '@/libraries/EyeCardinalClosedDebug.js'
import EyeCardinalClosedCustomCtrlPoint from '@/libraries/EyeCardinalClosedCustomCtrlPoint.js'
import * as mutationTypes from "../store/mutations-types.js";

export default {
  name: 'BioTest',
  data: () => ({
    viewBox: '0 0 0 0',
    points: [],
    pointsTrace: [],
    paths: []
  }),
  computed: {
    ...mapGetters({
      circles: 'circle/circles',
      referenceRadius: 'layout/radius',
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      scale: "layout/scale",
    })
  },
  watch: {
    points: {
      handler() {
        console.log('points', this.points)
        const dragHandler = d3.drag()
          .on("drag", (event, d) => {
            d.x = event.x
            d.y = event.y
            d3.select(event.currentTarget)
              .attr("cx", event.x)
              .attr("cy", event.y);
          })
          .on('end', () => {
            this.redrawCurve()
          })

        d3.selectAll(".point-ctrl").data(this.points)

        dragHandler(d3.selectAll(".point-ctrl"));
      },
      flush: 'post'
    },
  },
  created() {
    this.viewBox = `0 0 ${window.innerWidth} ${window.innerHeight}`
  },
  mounted() {
    const zoom = d3.zoom()
      .scaleExtent([1, 4])
      .on("zoom", ({transform}) => {
        transform = transform.translate(window.innerWidth / 2, window.innerHeight / 2)
        const { x, y, k } = transform;
        this.$store.commit(`layout/${mutationTypes.UPDATE_SCALE}`, k, { root: true });
        this.$store.commit(`layout/${mutationTypes.UPDATE_CENTERX}`, x, { root: true });
        this.$store.commit(`layout/${mutationTypes.UPDATE_CENTERY}`, y, { root: true });
      })

    d3.select("#svg-bio-root")
      .call(zoom)
      .on("dbclick.zoom",null)

    this.drawCircleArea()
  },
  methods: {
    drawCircleArea() {
      const mainCircle = this.circles.find(circle => circle.active)  
      const tabDebug = []

      const radiusCircle = CircleUtility.getRadiusCircle(
        this.referenceRadius,
        mainCircle.configIndex
      )

      d3.lineRadial()
        .angle((d) => d.angle)
        .curve(
          EyeCardinalClosedDebug
            .circleData(mainCircle)
            .radiusCircle(radiusCircle)
            .paths(this.paths)
            .configIndexBounded(CircleUtility.getIndexConfig(mainCircle.configIndex))
            .ctrlPoint(tabDebug)
        )
        .radius((d) => CircleUtility.getRadiusAtPoint(this.referenceRadius, d.severity))
        (mainCircle.points)

      this.points = tabDebug
      this.pointsTrace = mainCircle.points.map(point => {
        console.log('point', point)
        const data = d3.pointRadial(point.angle, CircleUtility.getRadiusAtPoint(this.referenceRadius, point.severity))
        return { x: data[0], y: data[1], peak: point.peak, idSection: point.idSection }
      })
    },

    redrawCurve() {
      this.paths = []
      const mainCircle = this.circles.find(circle => circle.active)
      const radiusCircle = CircleUtility.getRadiusCircle(
        this.referenceRadius,
        mainCircle.configIndex
      )

      d3.lineRadial()
        .angle((d) => d.angle)
        .curve(
          EyeCardinalClosedCustomCtrlPoint
            .circleData(mainCircle)
            .radiusCircle(radiusCircle)
            .paths(this.paths)
            .configIndexBounded(CircleUtility.getIndexConfig(mainCircle.configIndex))
            .ctrlPoint(this.points)
        )
        .radius((d) => CircleUtility.getRadiusAtPoint(this.referenceRadius, d.severity))
        (mainCircle.points)
    }
  }
}

</script>

<style>

.bio-circle {
  fill: none;
  stroke: var(--color-text);
  stroke-width: 2px;
}

.point-debug {
  fill: orange
}

.point-debug-event {
  fill: red
}

.point-debug-section {
  fill: magenta
}

.point-ctrl {
  fill: turquoise
}

</style>