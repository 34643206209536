import * as d3 from 'd3'
import _ from 'lodash'
import CircleUtility from '@/libraries/CircleUtility.js'
import ColorDoubleLine from '@/shared/enums/colorDoubleLine.js'
//Dérivé d'une cardinal normal closed

/**
 * Il s'agit d'une duplication de la courbe Cardinal d3js, mais permettant en plus d'obtenir les points de contrôle utilisé pour le tracé de la courbe de Béziers. La courbe sert à générer les tracés des cercles d'Eyediag
 * https://github.com/d3/d3-shape/blob/v3.2.0/README.md#custom-curves
 */
class EyeCardinalClosed {
  constructor(context, tension, options) {
    this._paths = options.paths
    this._circleData = options.circleData
    this._context = context;
    this._k = (1 - tension) / 6;
    this._idxPoints = 0
    this._configIndexBounded = options.configIndexBounded
    this._radiusCircle = options.radiusCircle

    this._tmpPath = []
  }

  areaStart() {
    this._line = 0
  }

  areaEnd() {
    this._line = NaN
  }

  lineStart() {
    this._x0 = this._x1 = this._x2 = this._x3 = this._x4 = this._x5 =
    this._y0 = this._y1 = this._y2 = this._y3 = this._y4 = this._y5 = NaN;
    this._point = 0;
  }

  lineEnd() {
    switch (this._point) {
    case 1: {
      this._context.moveTo(this._x3, this._y3);
      this._context.closePath();
      break;
    }
    case 2: {
      this._context.lineTo(this._x3, this._y3);
      this._context.closePath();
      break;
    }
    case 3: {
      this.point(this._x3, this._y3);
      this.point(this._x4, this._y4);
      this.point(this._x5, this._y5);
      break;
    }
    }
  }

  point(x, y) {
    x = +x, y = +y;

    switch (this._point) {
    case 0: this._point = 1; this._x3 = x, this._y3 = y; break;
    case 1: this._point = 2; this._context.moveTo(this._x4 = x, this._y4 = y); break;
    case 2: this._point = 3; this._x5 = x, this._y5 = y; break;
    default: this.bezier(x, y); break;
    }

    //Check si l'on va changer de catégorie ou si l'on arrive à la fin du tableau pour faire le découpage des événements
    //Mauvaise coupure au début si _point ne possède pas encore 3 points
    if (this._point === 3 && (this._idxPoints - 1 >= this._circleData.points.length || this._circleData.points[this._idxPoints - 2].idSection !== this._circleData.points[this._idxPoints - 1].idSection)) {
      this.cutPath((this._circleData.points[this._idxPoints - 2] || this._circleData.points[0]).idSection)
    }

    this._idxPoints++
    this._x0 = this._x1, this._x1 = this._x2, this._x2 = x;
    this._y0 = this._y1, this._y1 = this._y2, this._y2 = y;
  }

  bezier(x, y) {
    this._context.bezierCurveTo(
      this._x1 + this._k * (this._x2 - this._x0), //cpx1
      this._y1 + this._k * (this._y2 - this._y0), //cpy1
      this._x2 + this._k * (this._x1 - x), //cpx2
      this._y2 + this._k * (this._y1 - y), //cpy2
      this._x2, //x
      this._y2 //y
    )

    this.manageRedLineBio(x, y)
  }

  cutPath(idSection) {
    this.pushPath(this._context, idSection, null)
    this._context = new d3.path()
    this._context.moveTo(this._x2, this._y2)
  }

  pushPath(path, idSection, colorDoubleLine) {
    this._paths.push({
      path: path.toString(),
      colorDoubleLine: colorDoubleLine,
      circleData: this._circleData,
      configIndexBounded: this._configIndexBounded,
      idSection: idSection
    })
  }

  manageRedLineBio(x, y) {
    const firstPointToTreat = this._idxPoints === this._circleData.points.length + 2 && this._circleData.points[1].expectedSeverity

    if (firstPointToTreat || this._circleData.points[this._idxPoints - 1] && this._circleData.points[this._idxPoints - 1].expectedSeverity) {
      const expectedSeverityPointIdx = firstPointToTreat ? 1 : this._idxPoints - 1
      const expectedSeverityPoint = this._circleData.points[expectedSeverityPointIdx]
      const path = d3.path()
      path.moveTo(this._x1, this._y1)

      const r = CircleUtility.getRadiusAtPoint(
        this._radiusCircle,
        expectedSeverityPoint.expectedSeverity
      )
      const tmp = d3.pointRadial(expectedSeverityPoint.angle, r)
      path.bezierCurveTo(
        this._x1 + this._k * (this._x2 - this._x0),
        this._y1 + this._k * (this._y2 - this._y0),
        tmp[0] + this._k * (this._x1 - x),
        tmp[1] + this._k * (this._y1 - y),
        tmp[0],
        tmp[1]
      )
      if (firstPointToTreat) {
        this.pushPath(path, expectedSeverityPoint.idSection, ColorDoubleLine[expectedSeverityPoint.colorDoubleLine])
      } else {
        this._tmpPath.unshift(path)
      }
    }
    if (this._circleData.points[this._idxPoints - 2] && this._circleData.points[this._idxPoints - 2].expectedSeverity) {
      const expectedSeverityPoint = this._circleData.points[this._idxPoints - 2]
      let path = null
      const r = CircleUtility.getRadiusAtPoint(
        this._radiusCircle,
        expectedSeverityPoint.expectedSeverity
      )
      const tmp = d3.pointRadial(expectedSeverityPoint.angle, r)

      //Gére le cas, où une double ligne doit être présente sur le 2eme point de la figure (première déformation)
      if (this._tmpPath.length === 0) {
        path = d3.path()
        path.moveTo(tmp[0], tmp[1])
      } else {
        path = this._tmpPath[0]
      }

      path.bezierCurveTo(
        tmp[0] + this._k * (this._x2 - this._x0),
        tmp[1] + this._k * (this._y2 - this._y0),
        this._x2 + this._k * (this._x1 - x),
        this._y2 + this._k * (this._y1 - y),
        this._x2,
        this._y2
      )
      this.pushPath(path, expectedSeverityPoint.idSection, ColorDoubleLine[expectedSeverityPoint.colorDoubleLine])
      this._tmpPath.pop()
    }
  }
}

export default (function custom(tension, options) {
  const cardinal = (context) => new EyeCardinalClosed(context, tension, options)
  cardinal.tension = (tension) => custom(+tension, options)
  cardinal.circleData = (circleData) => custom(+tension, {...options, ...{circleData: circleData}})
  cardinal.paths = (paths) => custom(+tension, {...options, ...{paths: paths}})
  cardinal.configIndexBounded = (configIndexBounded) => custom(+tension, {...options, ...{configIndexBounded: configIndexBounded}})
  cardinal.radiusCircle = (radiusCircle) => custom(+tension, {...options, ...{radiusCircle: radiusCircle}})

  return cardinal
})(0, {
  circleData: {},
  paths: [],
  configIndexBounded: 0,
  radiusCircle: 0
})