<template>
  <g ref="condition-group">
    <LinesReport ref="lines-report" />
    <Filtering ref="filtering" />
    <Symbols />
    <ScoreMatch />
    <g
      ref="events"
    />
    <g
      id="arc-clone-container"
    />
  </g>
</template>

<script>
import * as d3 from "d3";
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "@/store/mutations-types.js";
import _ from "lodash";

import LinesReport from "@/components/event/LinesReport.vue";
import Filtering from "@/components/event/Filtering.vue";
import ScoreMatch from "@/components/event/ScoreMatch.vue";
import Symbols from '@/components/event/Symbols.vue'

import * as EyeColor from '@/assets/color.js'

import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import D3Animation from '@/config/D3Animation.js'

import EventManager from '@/libraries/EventManager.js'
import selectionTypes from '@/enums/event_selection_types.js'

import CircleUtility from '@/libraries/CircleUtility.js'
import utils from "@/libraries/utils"
import Event from "@/libraries/Event.js"

export default {
  name: "ConditionView",
  components: {
    LinesReport,
    Filtering,
    ScoreMatch,
    Symbols
  },
  data: () => ({
    /**
     * Id de l'événement survolé par l'utilisateur
     * @type {Number}
     */
    idCircleHover: null,
  }),
  computed: {
    ...mapGetters({
      events: "event/common/events",
      referenceRadius: "layout/radius",
      labelToDisplay: "event/common/labelToDisplay",
      hoveredEvent: "event/common/hoveredEvent",
      theme: "circle/theme",
      filteredEvents: "event/common/filteredEvents",
      selectedEvents: "event/common/selectedEvents",
      isDisplayedMemorizedEvents: "event/common/isDisplayedMemorizedEvents",
      eyediagMode: "eyediagMode",
      symbols: 'event/common/symbols',
      isDisplayedLines: 'event/common/isDisplayedLines',
      isLockedDataEntry: 'formEntry/isLocked',
      lockedEventDateEntry: 'formEntry/lockedEvent',
      isInPatient: 'isInPatient',
      isInNurseEntry: 'isInNurseEntry'
    }),
    /**
     * Indique si les zones d'intéractions des événements doivent être affichées
     * @type {Boolean}
     */
    isDisplayEvents() {
      return this.$store.state.event.common.displayEvents;
    },
    /**
     * Détermine s'il est possible d'avoir des événements similaires en fonction du mode dans lequel se trouve l'application
     * @type {Boolean}
     */
    canHaveSimilarEvent() {
      return [eyediagMode.mode.PATIENT, eyediagMode.mode.UNIVERSAL].includes(this.eyediagMode.type) || (this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && this.eyediagMode.options.mergedBy === eyediagMode.populationalOptions.mergedBy.CODE)
    }
  },
  watch: {
    events() {
      if (this.isDisplayEvents) {
        this.linkDomDataD3(this.events);
        this.showEvents(D3Animation.EVENT_APPEAR_CIRCLE);
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, this.events.filter((event) => event.displayLabel));
      }
    },
    isDisplayEvents() {
      if (this.isDisplayEvents) {
        this.linkDomDataD3(this.events);
        this.showEvents(D3Animation.EVENT_APPEAR_CIRCLE);
      } else {
        this.hideEvents();
      }
    },
    hoveredEvent(newHoveredEvent, oldHoveredEvent) {
      //évenement de survol sur les évements des cercles directement
      if (newHoveredEvent !== null){
        this.$store.commit(`circle/${mutationTypes.SET_HOVERED_CIRCLE}`, newHoveredEvent.circleParent, {
          root: true,
        });
      } else {
        this.$store.commit(`circle/${mutationTypes.SET_HOVERED_CIRCLE}`, null, {
          root: true,
        });
      }

      //évenement du survol des évenements sélectionner dans notre menu
      if (
        oldHoveredEvent !== null &&
        this.events.find(
          (event) =>
            event.id === oldHoveredEvent.id
        )
      ) {
        this.mouseLeaveEvent(null, oldHoveredEvent);
      }

      if (this.idCircleHover !== null) {
        return
      }

      if (
        this.hoveredEvent !== null &&
        this.events.find(
          (event) =>
            event.id === this.hoveredEvent.id
        )
      ) {
        this.mouseOverEvent(null, this.hoveredEvent);
      }
    },
    lockedEventDateEntry(newLockedEvent, oldLockedEvent) {
      if (oldLockedEvent !== null) {
        this.mouseLeaveEvent(null, oldLockedEvent)
      }
    }
  },
  mounted() {
    this.linkDomDataD3(this.events);
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Retourne la valeur d'opacité que l'événement doit avoir
     * @method
     * @param {EyeEvent} event
     * @return Number opacité devant être appliqué à l'événement
     */
    getEventStrokeOpacity(event) {
      if (event.dataEntry === true) {
        let courbe_transparence = (x, index_ppl) => {
          let y = 0

          if (x >= 0 && x < index_ppl) {
            y = x / index_ppl
          } else if (x > index_ppl) {
            y = 2 - x / index_ppl
          } else if (x === index_ppl) {
            return 1
          }
          return 0.05 + (y**3) / 2
        }
        return courbe_transparence(event.circleParent.configIndex, 9)
        // return event.circleParent.active ? 1 : 0.4
        // return event.circleParent.active ? 1 : +`0.${event.circleParent.configIndex - 1}`
      }
      return (event.selected || (this.hoveredEvent !== null && this.hoveredEvent.id === event.id))
        ? 1
        : 0
    },
    getOpacityFillColor(event) {
      if (event.dataEntry === true) {
        return 0.8
      }
      return (event.selected || (this.hoveredEvent !== null && this.hoveredEvent.id === event.id))
        ? 0.8
        : 0
    },
    /**
     * Cette fonction permet de réactualiser l'affichage des événements sur la représentation.
     * @method
     * @public
     * @param {EyeEvent[]} events Il s'agit des événements affichés sur les cercles
     */
    linkDomDataD3(events) {      
      const eventsToRead = events.filter(d => d.dataEntry !== true)
      const eventsDataEntry = events.filter(d => d.dataEntry === true)

      d3.select(this.$refs["events"])
        .selectAll("circle")
        .data(eventsToRead)
        .join("circle")
        .attr("class", (d) => `${utils.getEventResourceTypeColor(d, this.$store)} event`)
        .attr("r", (d) => d.r)
        .attr("cx", (d) => d.cx)
        .attr("cy", (d) => d.cy)
        .style('stroke-opacity', d => this.getEventStrokeOpacity(d))
        .style('fill', d => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
        .style('pointer-events', d => d.disabled ? 'none' : 'auto')
        .on("mouseover", (event, d) => {
          if (this.isLockedDataEntry === false) {
            this.idCircleHover = d.id
            this.mouseOverEvent(event, d)
          }
        })
        .on("mouseleave", (event, d) => {
          if (this.isLockedDataEntry === false) {
            this.mouseLeaveEvent(event, d)
            this.idCircleHover = null
          }
        })
        .on("contextmenu", this.contextMenuEventCircle)
        .on("click", this.clickEventCircle)

      d3.select(this.$refs["events"])
        .selectAll(".event-data-entry")
        .data(eventsDataEntry)
        .join("path")
        .attr("class", (d) => `event event-data-entry ${utils.getEventResourceTypeColor(d, this.$store)}`)
        .attr("d", d3.symbol().type(d3.symbolTriangle2)())
        .style('stroke-opacity', (d) => this.getEventStrokeOpacity(d))
        .style('fill', d => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
        .attr("transform", (d) => {
          let transform = `translate(${d.cx}, ${d.cy}) rotate(${180 + d.angle * (180/Math.PI)})`
          
          if (this.lockedEventDateEntry !== null && d.id === this.lockedEventDateEntry.id) {
            transform = `${transform} scale(1.5)`
          }

          return transform
        })
        // .attr("transform", (d) => `translate(${d.cx}, ${d.cy}) rotate(${180 + d.angle * (180/Math.PI)}) scale(0.${d.circleParent.configIndex})`)
        // .attr("transform", (d) => `translate(${d.cx}, ${d.cy}) rotate(${180 + d.angle * (180/Math.PI)}) scale(0.${3* Math.round(Math.sqrt(d.circleParent.configIndex))})`)
        .on("mouseover", (event, d) => {
          if (this.isLockedDataEntry === false) {
            this.idCircleHover = d.id
            this.mouseOverEvent(event, d)
          }
        })
        .on("mouseleave", (event, d) => {
          if (this.isLockedDataEntry === false) {
            this.mouseLeaveEvent(event, d)
            this.idCircleHover = null
          }
        })
        .on("mousedown", (event) => {
          this.sendEvent({event: event})
          event.preventDefault() //Permet de ne pas perdre le focus sur les inputs du formulaire lorsque l'utilisateur clique sur le triangle
          this.collaborativeEventTreated()
        })
        .on("click", this.clickEventDataEntry)
    },
    mouseOverEvent(event, d) {
      if (event) {
        this.sendEvent({ event: event})
      }

      if (event) {
        this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, d, {
          root: true,
        })
      }
      
      this.mouseOverEventCircle(event, d)

      if (d.dataEntry) {
        this.mouseOverEventDataEntry(event, d)
      }

      if (event) {
        this.collaborativeEventTreated()
      }
    },
    mouseOverEventDataEntry(event, d) {
      const element = d3.select(this.$refs["events"])
        .selectAll(".event-data-entry")
        .filter((event) => event.id === d.id)

      try {
        let baseTransform = element.attr('transform')
        baseTransform = baseTransform.substring(0, baseTransform.indexOf(")", baseTransform.indexOf(")") + 1) + 1)
  
        element
          .transition()
          .duration(200)
          .attr('transform', `${baseTransform} scale(1.5)`)
      } catch {}
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur positionne son pointeur sur une zone de survol. La fonction va engendrer les appels des fonctions suivantes:
     * drawCircleSimilarEvent
     * showFilters
     * drawLineReport
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     * @param {EyeEvent} d Il s'agit de l'événement sur lequel se trouve la souris
     */
    mouseOverEventCircle(event, d) {
      // if (event) {
      //   this.sendEvent({ event: event });
      //   this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, d, {
      //     root: true,
      //   });
      // }
      // rétrécir le cercle des évenements similaire
      //Dans ce mode tout les events avec le même code ont forcément la même sévérité donc il sera possible plus tard de réassigner la bonne sévérité sans l'écraser et la perdre à tout jamais
      if (this.canHaveSimilarEvent) {
        this.events.forEach(event => {
          if (event.code === d.code && event.id !== d.id) {
            event.r = 4;
          }
        })
      }

      this.drawCircleSimilarEvent(event, d);
      this.$refs["filtering"].showFilters(this.filteredEvents);

      if (d.report && this.isDisplayedLines && this.isDisplayEvents && d.cx) {// d.cx => ecriture pas top mais permet de savoir si l'événement est présent sur la représentation (cas de survol de l'événement depuis la tooltip)
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
          src: d,
          dest: this.events.filter(e => e.report === d.report && utils.differentEventOrManyLocation(d, e))
        })
      }
      this.addLabelHoveredEvent(d);
      // if (event) {
      //   this.collaborativeEventTreated()
      // }
    },
    mouseLeaveEvent(event, d) {
      if (event) {
        this.sendEvent({ event: event })
        this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, {
          root: true,
        })
      }
      
      this.mouseLeaveEventCircle(event, d)

      if (d.dataEntry) {
        this.mouseLeaveEventDataEntry(event, d)
      }

      if (event) {
        this.collaborativeEventTreated()
      }
    },
    mouseLeaveEventDataEntry(event, d) {
      const element = d3.select(this.$refs["events"])
        .selectAll(".event-data-entry")
        .filter((event) => event.id === d.id)
      let baseTransform = element.attr('transform')
      baseTransform = baseTransform.substring(0, baseTransform.indexOf(")", baseTransform.indexOf(")") + 1) + 1)

      element
        .transition()
        .duration(200)
        .attr('transform', baseTransform)
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur quitte une zone de survol.
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     * @param {EyeEvent} d Il s'agit de l'événement qui était précédemment survolé
     */
    async mouseLeaveEventCircle(event, d) {
      // if (event) {
      //   this.sendEvent({
      //     event: event,
      //   });
      //   this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, {
      //     root: true,
      //   });
      // }

      this.removeLabelHoveredEvent(d);


      if (d.report) {
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
          src: {},
          dest: []
        })
      }

      const selection = d3
        .select(this.$refs["condition-group"])
        .selectAll("circle")
        .filter(
          (event) =>
            event.code === d.code ||
            (event.report > 0 && event.report === d.report)
        );

      //Dans ce mode tout les events avec le même code ont forcément la même sévérité
      if (this.canHaveSimilarEvent) {
        this.events.forEach((event) => {
          if (event.code === d.code) {
            event.r = d.r
          }
        })
      }

      selection
        .filter((event) => event.selected !== true)
        .transition()
        .duration(D3Animation.EVENT_HIDE_CIRCLE)
        .style('stroke-opacity', 0)
        .style('fill', EyeColor.getFillColorEventCircle(0))

      Event.deleteParenthesisOtherLocations(null, d)

      //supprimer les symbols lié à l'événement qui était en cours de survol
      const symbols = this.symbols.filter(symbol => symbol.selectionTypes !== selectionTypes.OVER_EVENTS)
      this.$store.commit(`event/common/${mutationTypes.SET_SYMBOLS}`, symbols)

      this.$refs['filtering'].showFilters(this.filteredEvents)
      this.showEvents(0, true, (e) => (e.code === d.code || (e.report && e.report === d.report)))
      // if (event) {
      //   this.collaborativeEventTreated()
      // }
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur effectue un clique droit sur une zone de survol. La fonction émet un événement de type opentooltip au composant parent en fournissant en paramètre les données de l'événement patient liées à la zone de survol.
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     * @param {EyeEvent} d Il s'agit de l'événement sur lequel le click droit à été effectué
     */
    contextMenuEventCircle(event, d) {
      this.sendEvent({ event: event })
      event.preventDefault();
      event.stopPropagation();

      this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d);
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur effectue un clique gauche sur une zone de survol. La fonction permet d'activer ou désactiver la surbrillance de la zone de survol cliquée quelque soit la position du pointeur.
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     * @param {EyeEvent} d Il s'agit de l'événement sur lequel le click gauche à été effectué
     */
    clickEventCircle(event, d) {
      if (this.isDisplayedMemorizedEvents) {
        this.sendEvent({ event: event })
        this.$store.commit(
          `event/common/${mutationTypes.CHANGE_STATE_MEMORIZED_EVENT}`,
          d
        )
        this.collaborativeEventTreated()
      }
    },
    clickEventDataEntry(event, d) {
      this.sendEvent({ event: event })

      if (this.lockedEventDateEntry === null && this.isLockedDataEntry) {
        this.collaborativeEventTreated()
        return
      }

      if (this.lockedEventDateEntry !== null && this.lockedEventDateEntry.id === d.id) {
        this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_EVENT}`, null)
      } else {
        //@TO-DO Petit bug graphique double label à voir comment corriger
        this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, d)
        this.mouseOverEvent(null, d)

        this.$store.commit(`formEntry/${mutationTypes.SET_LOCKED_EVENT}`, d)
      }

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction permet de mettre en surbrillance les événements patient de même nature que celui fournis en second paramètre.
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     * @param {EyeEvent} d Il s'agit de l'événement sur lequel se trouve la souris
     */
    drawCircleSimilarEvent(event, d) {
      const selectionEvent = d3
        .select(this.$refs["condition-group"])
        .selectAll("circle")

      const similarEvent = selectionEvent
        .filter((event) => event.code === d.code);

      let similarEventData = similarEvent.data()
      const indexSelectedEvent = similarEventData.findIndex(event => event.id === d.id && event.parentSection === d.parentSection)

      const selectionSame = similarEvent.filter(
        (elem, index) => index === indexSelectedEvent
      )

      this.overCalcSymbol(d)

      const selectionSameTransition = selectionSame
        .attr("r", (d) => d.r)
        .transition()
        .duration(D3Animation.EVENT_APPEAR_CIRCLE)
        .style('stroke-opacity', 1)
        .style('fill', EyeColor.getFillColorEventCircle(0.8))

      if (event === null) {
        selectionSameTransition
          .transition()
          .duration(D3Animation.EVENT_GROW_CIRCLE)
          .attr("r", (d) => d.r + 6)
          .transition()
          .duration(D3Animation.EVENT_SHRINK_CIRCLE)
          .attr("r", (d) => d.r);
      }

      //Pas de sens en mode merge par section
      if (this.canHaveSimilarEvent) {
        const selectionAfter = similarEvent.filter(
          (elem, index) => index > indexSelectedEvent
        )
        const selectionBefore = similarEvent.filter(
          (elem, index) => index < indexSelectedEvent
        )

        selectionBefore._groups[0] = selectionBefore._groups[0].reverse();
        selectionBefore
          .attr("r", (d) => d.r)
          .transition()
          .delay((d, i) => 200 + D3Animation.EVENT_APPEAR_CIRCLE * i)
          .duration(D3Animation.EVENT_APPEAR_CIRCLE)
          .style('stroke-opacity', 0.6)
          .style('fill', EyeColor.getFillColorEventCircle(0.8))
  
        selectionAfter
          .attr("r", (d) => d.r)
          .transition()
          .delay((d, i) => 200 + D3Animation.EVENT_APPEAR_CIRCLE * i)
          .duration(D3Animation.EVENT_APPEAR_CIRCLE)
          .style('stroke-opacity', 0.6)
          .style('fill', EyeColor.getFillColorEventCircle(0.8))
      }

      // Affichage des parenthéses pour double localisation (ex Greffes)
      Event.displayOtherLocationsEvent(selectionEvent, d)
    },
    /**
     * Cette fonction permet de masquer les événements et les filtres de la représentation.
     * @method
     * @public
     */
    hideEvents() {
      const selection = d3
        .select(this.$refs["condition-group"])
        .selectAll(".event");

      d3.select(this.$refs["events"])
        .selectAll(".event")
        .on("mouseover", null)
        .on("mouseleave", null)
        .on("contextmenu", null)
        .on("click", null);

      selection.interrupt();
      selection
        .transition()
        .duration(D3Animation.EVENT_HIDE_CIRCLE)
        .style('stroke-opacity', 0)
        .style('fill', d => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
        .on("end", () => {
          this.linkDomDataD3([]);
        });
    },
    /**
     * Cette fonction permet de mettre en surbrillance les événements qui étaient séléctionné par l'utilisateur ainsi que les filtres. Cette fonction est appelé lorsque les événements changent comme lors d'un déplacement de cercle par exemple. La fonction prend en paramètre la durée de l'animation permettant l'affichage des événements et des filtres.
     * @method
     * @public
     * @param {Number} animationTime
     * @param {(EyeEvent) => Boolean} selectionFilters Fonction permettant de filtrer les événements devant être affichés 
     */
    showEvents(animationTime, changeRadius = false, selectionFilters = null) {
      let selection = d3
        .select(this.$refs["condition-group"])
        .selectAll(".event");

      if (this.hoveredEvent !== null) {
        selection
          .filter((event) => event.id === this.hoveredEvent.id)
          .style('stroke-opacity', (d) => this.getEventStrokeOpacity(d))
          .style('fill', d => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
        //selection = selection.filter(event => event.code !== this.hoveredEvent.code)
        // Les événement hover ne sont pas "selected", la ligne du dessous va les éteindre
      }

      if (selectionFilters) {
        selection = selection.filter(selectionFilters)
      }

      if (animationTime === 0) {
        //Evite lors d'un changement rapide d'événement que l'animation EVENT_GROW_CIRCLE / EVENT_SHRINK_CIRCLE reste bloquée sur un rayon précis du cercle d'événement
        selection.interrupt()
        if (changeRadius) {
          selection.attr("r", d => d.r)
        }
  
        selection
          .style('stroke-opacity', d => this.getEventStrokeOpacity(d))
          .style('fill', (d) => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
      } else {
        if (changeRadius) {
          selection.attr("r", d => d.r)
        }
  
        const transition = selection
          .transition()
          .duration(animationTime)
          .style('stroke-opacity', (d) => this.getEventStrokeOpacity(d))
          .style('fill', (d) => EyeColor.getFillColorEventCircle(this.getOpacityFillColor(d)))
          
        if (changeRadius) {
          transition.attr("r", d => d.r) // Evite que en cas de repetition survol puis arret du survol rapide evenement memorise, double animation qui bloque le rayon à une grande valeur
        }
      }
    },
    /**
     * Cette fonction permet d'ajouter le libellé de l'événement actuellement survolé aux tableaux des libellés devant être affichés présent dans le store
     * @method
     * @public
     * @param {EyeEvent} d Données de l'événement actuellement survolé par l'utilisateur
     */
    addLabelHoveredEvent(d) {
      // vérification que l'event est actuellement visible
      const selectedEvent = this.events.find((e) => e.id === d.id && e.parentSection === d.parentSection)

      if (!this.selectedEvents.find((e) => e.id === d.id)) {
        // on donne toujours l'element retourné par find pour eviter les mauvaise positione en cas d'une copie de l'objet (ex tooltip)
        this.$store.commit(`event/common/${mutationTypes.PUSH_LABEL_TO_DISPLAY}`, selectedEvent)
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`,[...this.labelToDisplay]);
      }
    },
    /**
     * Cette fonction permet de retirer le libellé de l'événement précédemment survolé du tableau des libellés devant être affichés présent dans le store
     * @method
     * @public
     * @param {EyeEvent} d Données de l'événement précédement survolé par l'utilisateur
     */
    removeLabelHoveredEvent(d) {
      if (!this.selectedEvents.find((e) => e.id === d.id)) {
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, this.labelToDisplay.filter((e) => e.id !== d.id));
      }
    },
    /**
     * Cette fonction permet de déterminer si des événements similaires (avec le même code) sont présent sur des cercles non visible à l'écran par l'utilisateur, si c'est le cas un symbole + est rajouté au tableau des symboles devant être affichés présent dans le store
     * @method
     * @public
     * @param {EyeEvent} d Données de l'événement actuellement survolé par l'utilisateur
     */
    overCalcSymbol(d) {
      const symbols = [...this.symbols]
      let events = EventManager.allEvents.filter(event => event.code === d.code)

      events = _.groupBy(events, (event) => event.parentSection)

      const addSymbol = (indexCircle) => {
        let radius = CircleUtility.getRadiusCircle(this.referenceRadius, indexCircle)
        if (indexCircle > 1) {
          radius = CircleUtility.getRadiusAtPoint(radius, d.severity)
        }
        const point = d3.pointRadial(d.angle, radius)
        symbols.push({
          "path": d3.symbol().type(d3.symbolPlus).size(50)(),
          "x": point[0],
          "y": point[1],
          "resourceType": d.resourceType,
          "selectionTypes": selectionTypes.OVER_EVENTS,
          "resourceTypeClass": utils.getEventResourceTypeColor(d, this.$store)
        })
      }

      _.forEach(events, (sectionEvents) => {
        const first = _.first(sectionEvents)
        const last = _.last(sectionEvents)

        if (!CircleUtility.isVisibleCircle(first.circleParent.configIndex)) {
          addSymbol(1)
        }
        if (!CircleUtility.isVisibleCircle(last.circleParent.configIndex)) {
          addSymbol(this.theme.length - 1)
        }
      })

      this.$store.commit(`event/common/${mutationTypes.SET_SYMBOLS}`, symbols)
    }
  },
};
</script>

<style>
.event {
  fill: rgba(255, 255, 255, 0);
  stroke-width: 1.5px;
  stroke-opacity: 0;
  background-color: transparent;
}
</style>