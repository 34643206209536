<template>
  <div class="wrapper">
    <div class="container">
      <UniversalSteps
        :import-file="true"
        :default-step-button="true"
      />
    </div>
  </div>
</template>

<script>

import UniversalSteps from '@/components/universal/Steps.vue'

export default {
  name: 'UniversalImportView',
  components: {
    UniversalSteps
  }
}
</script>

<style scoped>
.wrapper {
  height: 100vh;
  width: 100vw;
  display: grid;
  place-items: center;
  overflow: scroll;
  font-size: 1.27rem;
}
.container {
  display: flex;
  justify-content: center;
  border: 2px solid var(--c-circle-1-1);
  opacity: 0.8;
  width: 50vw;
  min-height: 25vh;
  min-width: 50vh;
  max-width: 800px;
  border-radius: 5px;
  padding: 5px;
}
</style>