import { onMounted, computed, watch } from "vue"
import { useStore } from "vuex"
import UserRole from '@/shared/enums/userRole.js'

export function welcoopSpecificities() {
  const store = useStore()

  let loadedAtMounted = false
  let loadingRepresentationUnwatch = null
  let scoreCheckerUnwatch = null

  const typePatientContext = computed(() => store.getters['user/typePatientContext'])
  const scoreChecker = computed(() => store.getters['refCircle/score/scoreChecker'])
  const isLoadingRepresentation = computed(() => store.getters['isLoadingRepresentation'])

  onMounted(() => {
    if (typePatientContext.value === UserRole.PHARMACIEN) {
      setLoadingRepresentationWatcher()
    }
  })

  function setLoadingRepresentationWatcher() {
    loadingRepresentationUnwatch = watch(isLoadingRepresentation, () => {
      if (isLoadingRepresentation.value === false) {
        loadedAtMounted = true
        setScoreCheckerWatcher()
        store.dispatch('refCircle/score/baseEnableScore', 9)
        if (loadingRepresentationUnwatch !== null) {
          loadingRepresentationUnwatch()
          loadingRepresentationUnwatch = null
        }
      }
    })
  }

  function setScoreCheckerWatcher() {
    scoreCheckerUnwatch = watch(scoreChecker, () => {
      if (loadedAtMounted === true) {
        for (const test of Object.values(scoreChecker.value)) {
          if (test.display === true) {
            loadedAtMounted = false
            if (scoreCheckerUnwatch !== null) {
              scoreCheckerUnwatch()
              scoreCheckerUnwatch = null
            }
            return
          }
        }
        store.dispatch('refCircle/score/disableScore')
        if (scoreCheckerUnwatch !== null) {
          scoreCheckerUnwatch()
          scoreCheckerUnwatch = null
        }
        loadedAtMounted = false
      }
    })
  }
}