<template>
  <div style="font-size: 2rem; color: white;">
    <p>Il y a 4 différents scores disponibles: </p>
    <p>- Fact.Aggr.COVID</p>
    <p>- Euroscore2.2</p>
    <p>- CHA₂DS₂-VASc</p>
    <p>- HasBled</p>
  </div>
</template>

<script>
export default {
  name: "Component2Tuto",
}
</script>

<style scoped>
p {
    padding-bottom: 10px;
}
</style>