import store from '@/store/index.js'
import menuTypes from '@/enums/menu_types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import periodUnits from '@/shared/enums/period_units.js'
import * as mutationTypes from '../store/mutations-types.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'

import AppContextChecker from '@/libraries/AppContextChecker.js'

const menuStruct = [
  { labelId: 'Sev3Ant3Opti', selected: false },
  { labelId: 'Sev2', selected: false },
  { labelId: 'Cardio/Euroscore2', selected: false, conditions: AppContextChecker.otherThanPharmacienTypePatient },
  { labelId: '1anCercle', selected: false },
  { labelId: 'MesMemos', selected: false }
]

/**
 * Tableau de configuration des fonctionnalités présentent dans le menu contextuel des suggestions
 * @type {EyeContextMenuItem[]}
 */
const suggestionsItems = [
  {
    id: 'Sev3Ant3Opti',
    label: "Sév ≥ 3/ Ant ≥ 3/ Tps Optimal",
    type: menuTypes.TEXT,
    selected: false,
    click: async () => {
      store.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`)
      store.commit(`refCircle/score/${mutationTypes.UPDATE_SCORE_CIRCLE}`, null, { root: true })
      store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
        severity: { min: 3, max: 4 },
        antecedent: { min: 2 }
      })
      await store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: store.getters['circle/optimalYearPerCircle'],
        periodUnit: periodUnits.YEAR,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })
      store.dispatch('event/common/generateEvents')
    }
  },
  {
    id: 'Sev2',
    label: "Sév ≥ 2",
    type: menuTypes.TEXT,
    selected: false,
    click: () => {
      store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
        severity: { min: 2, max: 4 },
      })
      store.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`)
      store.commit(`refCircle/score/${mutationTypes.UPDATE_SCORE_CIRCLE}`, null, { root: true })
      store.dispatch('event/common/generateEvents')
    }
  },
  {
    id: 'Cardio/Euroscore2',
    label: "Cardiologie/ Euroscore2",
    type: menuTypes.TEXT,
    selected: false,
    click: async () => {
      store.commit(`refCircle/associatedEvents/${mutationTypes.DISABLE_ASSOCIATED_EVENT_CIRCLE}`)
      store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {})

      const baseHistorySection = store.state.historySections.splice(0, store.state.indexHistorySection + 1)
      store.commit(mutationTypes.SET_HISTORY_SECTIONS, [
        ...baseHistorySection,
        ...[{
          asSection: null,
          sections: [{
            "id": 4,
            "name": "Cardio / Circul",
            "content": [
              {
                "hierarchy": [
                  {
                    "type": "CatYD",
                    "value": "Cardio / Circul"
                  }
                ]
              }
            ],
            "selectedByPie": true,
            "asSection": "CatYD"
          }],
          hierarchy: {
            type: hierarchy.type.SYSTEM,
            id: hierarchy.hierarchy.DEFAULT
          }
        }]
      ])
      store.commit(
        mutationTypes.SET_INDEX_HISTORY_SECTIONS,
        store.state.indexHistorySection + 1
      )

      await store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: store.state.circle.unitPerCircle,
        periodUnit: store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION,
      })
      store.commit(`refCircle/score/${mutationTypes.SET_ID_SCORE}`, 4)
      await store.dispatch('refCircle/score/getScoreCircle')
      store.dispatch('event/common/generateEvents')
    }
  },
  {
    id: '1anCercle',
    label: "Retour à 1an/cercle",
    type: menuTypes.TEXT,
    selected: false,
    click: () => {
      store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {})
      store.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`)
      store.commit(`refCircle/score/${mutationTypes.UPDATE_SCORE_CIRCLE}`, null, { root: true })
      store.commit(mutationTypes.SET_HISTORY_SECTIONS, [{asSection: null, sections: [], hierarchy: {
        type: hierarchy.type.SYSTEM,
        id: hierarchy.hierarchy.DEFAULT
      }}])
      store.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, 0)
      store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: 1,
        periodUnit: periodUnits.YEAR,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })
    }
  },
  {
    id: 'MesMemos',
    label: "Mes mémos",
    type: menuTypes.TEXT,
    selected: false,
    click: () => {
      store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {}, { root: true })
      store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "Diags mémorisés")
      store.commit(`event/common/${mutationTypes.SET_MEMORIZE_EVENTS}`, true)
      store.commit(`event/common/${mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT}`, false)
      store.dispatch('event/common/generateEvents')
    }
  },
]

export function fillMenuSuggestions(store) {
  let index = 0
  const result = []

  for (const item of menuStruct) {
    if (item.conditions && item.conditions(store) === false) {
      continue
    }

    const itemConfig = suggestionsItems.find(suggestion => suggestion.id === item.labelId)

    if (itemConfig !== undefined) {
      result.push({
        ...itemConfig,
        ...{id: index}
      })
      index++
    }
  }

  return result
}