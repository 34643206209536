<template>
  <MovingItem
    ref="scoreTooltipContainer"
    container-class="score-tooltip-generic-container"
    :ref-slot="refScoreTooltip"
    :z-index="1"
  >
    <div
      ref="tooltip_score_wrapper"
      class="tooltip_score_wrapper"
    >
      <div
        ref="tooltip_score_header"
        class="tooltip_score_header"
      >
        <p
          class="tooltip_score_title"
        >
          <strong
            class="cursor-pointer"
            @click="changeDisplayDetails"
          >
            {{ title }}
          </strong>
        </p>
        <p class="tooltip-score-result">
          {{ result }}
        </p>
        <p class="tooltip_score_comment">
          {{ comment }}
        </p>
      </div>

      <div
        ref="tooltip_score_body"
        class="tooltip_score_body"
      >
        <div class="cross-score-tooltip">
          <CrossButton 
            @close="exitTooltip"
          />
        </div>
        <ul>
          <ScoreTooltipText
            v-for="(item, test, index) in scoreChecker"
            :key="index"
            :name="item.LibelleTest"
            :items="item.events"
            :display="item.display"
            :status="item.status"
            :test="test"
            :display-details="displayDetails"
          />
        </ul>
      </div>
    </div>
  </MovingItem>
</template>

<script>
import ScoreTooltipText from "./ScoreTooltipText.vue";
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../store/mutations-types";
import { calculate } from "../score/calculate.js";
import * as d3 from "d3";
import utils from "@/libraries/utils.js";
import eventManager from "@/libraries/EventManager.js";
import EventSelectionTypes from "@/enums/event_selection_types.js";
import _ from "lodash";
import D3Animation from "@/config/D3Animation.js";
import MovingItem from "./MovingItem.vue";
import CrossButton from "./Button.vue";
import * as eyediagMode from '@/shared/enums/eyediagMode.js'

export default {
  name: "ScoreTooltip",
  components: {
    MovingItem,
    ScoreTooltipText,
    CrossButton,
  },
  data: () => ({
    /**
     * Il s'agit du résultat du score
     * @type {Number}
     */
    result: 0,
    /**
     * Il s'agit du titre du score
     * @type {String}
     */
    title: "",
    /**
     * Il s'agit d'un commentaire en fonction du résultat du score
     * @type {String}
     */
    comment: "",
    /**
     * Détermine si le détail des événements matchant le test doit être affichés ou non pour l'ensemble des tests du score
     * @type {Boolean}
     */
    displayDetails: false,
    /**
     * Référence vers la div englobant le composant scoreTooltip
     */
    refScoreTooltip: null
  }),
  computed: {
    ...mapGetters({
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      windowSize: "layout/windowSize",
      referenceRadius: "layout/radius",
      scoreChecker: "refCircle/score/scoreChecker",
      hoveredEvent: "event/common/hoveredEvent",
      idScore: "refCircle/score/idScore",
      patientData: "patient/patientData",
      eyediagMode: 'eyediagMode'
    }),
  },
  watch: {
    scoreChecker: {
      handler: function () {
        this.scoreValue();
      },
      deep: true,
    },
  },
  mounted() {
    this.popUp();
    this.moveTooltip();
    this.scoreValue();
    this.initMatchingAllEvents();
    this.initScrollEmitter()
    this.refScoreTooltip = this.$refs.tooltip_score_body
  },
  beforeUnmount() {
    this.$refs.scoreTooltipContainer.freeContainer()
  },
  updated() {
    this.scoreValue();
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Permet d'initialiser les intéractions possible avec le conteneur du score
     * @method
     * @public
     */
    initMatchingAllEvents() {
      d3.select(this.$refs["tooltip_score_header"])
        .on("mouseover", (event) => {
          this.sendEvent({ event: event })
          let scoreFiltering = [];
          _.forEach(this.scoreChecker, (test) => {
            test.events.forEach(elt => {
              let tmp = null
              if (this.eyediagMode.type === eyediagMode.mode.PATIENT) {
                tmp = eventManager.events.find(e => e.id === elt.id)
              } else {
                tmp = utils.findEventFromRanks(eventManager.events, (e => e.id === elt.id))
              }

              if (tmp) {
                scoreFiltering.push(tmp)
              }
            })
          });
          const hoveredEvents = eventManager.prepareFilteringSelection(
            scoreFiltering,
            EventSelectionTypes.SCOREMATCH
          );
          this.$store.commit(
            `refCircle/score/${mutationTypes.UPDATE_EVENT_HOVERED}`,
            hoveredEvents
          );
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event) => {
          this.sendEvent({ event: event })
          this.$store.commit(
            `refCircle/score/${mutationTypes.UPDATE_EVENT_HOVERED}`,
            null
          );
          this.collaborativeEventTreated()
        });
    },
    /**
     * Cette fonction gère l'animation d'entrée du conteneur du score
     * @method
     * @public
     */
    popUp() {
      d3.select(this.$refs["tooltip_score_header"])
        .style("opacity", 0)
        .transition()
        .duration(D3Animation.SCORE_TOOLTIP_HEADER_APPEAR)
        .style("opacity", 1);
      d3.select(this.$refs["tooltip_score_body"])
        .style("margin-top", `${5.0}rem`)
        .style("opacity", 0)
        .transition()
        .duration(D3Animation.SCORE_TOOLTIP_BODY_APPEAR)
        .style("opacity", 1)
        .style("margin-top", `${1.2}rem`);
    },

    /**
     * Cette fonction est appelée lorsque l'utilisateur clique sur le bouton pour fermer la tooltip de score
     * @method
     * @public
     * @param {Event} event Evenement fournit par le listener
     */
    exitTooltip(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`refCircle/score/${mutationTypes.HIDE_SCORE_TOOLTIP}`);
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée pour initialiser les fonctionnalité de déplacement de la tooltip de score
     * @method
     * @public
     */
    moveTooltip() {
      const DEFAULT_XCOORDINATE = 0.05;
      const DEFAULT_YCOORDINATE = 0.2;
      const posX = window.innerWidth * DEFAULT_XCOORDINATE;
      const posY = window.innerHeight * DEFAULT_YCOORDINATE;

      //cause this.posY will be in px, and 1 px === 0.1 rem
      d3.select(this.$refs.tooltip_score_wrapper)
        .style("top", `${posY * 0.1}rem`)
        .style("left", `${posX * 0.1}rem`);
      this.$refs.scoreTooltipContainer.initialCoordinate(posX, posY);
      this.$refs.scoreTooltipContainer.moveItem();
      this.$refs.scoreTooltipContainer.setOptions({
        share: true,
        resize: true,
      });
    },
    /**
     * Cette fonction permet de calculer le score par rapport aux données du patient
     * @method
     * @public
     */
    scoreValue() {
      if (this.patientData === null) {
        return
      }

      const values = calculate(
        this.scoreChecker,
        {
          age: utils.calculateAge(new Date(this.patientData.birthDate)),
          sexe: this.patientData.gender,
        },
        this.idScore,
        this.$store
      );
      this.title = values.scoreTitle;
      this.result = values.scoreAlert;
      this.comment = values.scoreRecom;
    },
    /**
     * Cette fonction permet de modifier le niveau de détail afficher par chacun des tests du score. Cette fonction est appelé lorsque l'utilisateur clique sur le nom du score
     * @param {Event} event Il s'agit de l'événement fournit par le listener
     * @method
     * @public
     */
    changeDisplayDetails(event) {
      this.sendEvent({ event })
      this.displayDetails = !this.displayDetails
      this.collaborativeEventTreated()
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur du conteneur de la tooltip des scores puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['tooltip_score_body'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    }
  },
};
</script>

<style>
.tooltip_score_wrapper {
  min-width: 30rem;
  width: 30rem;
  cursor: grab;
}

.tooltip_score_body {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--color-bg-1);
  border-radius: 0.5rem;
  border: solid 0.1rem var(--color-border);
  max-height: 70vh;
  overflow: scroll;
  min-height: 150px;
  min-width: 30rem;
  -ms-overflow-style: none; /* Hide scrollbar IE and Edge */
  scrollbar-width: none; /* Hide scrollbar Firefox */
  resize: both;
  width: fit-content;
}
.tooltip_score_result {
  margin-right: 3rem;
}
.cross-score-tooltip {
  position: absolute;
  right: 10px;
  top: 10px
}
.tooltip_score_header {
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
  user-select: none;
  width: fit-content;
}
.tooltip_score_title {
  color: var(--color-text);
  font-weight: 900;
  font-size: 1.8rem;
  user-select: none;
}
ul {
  padding: 0.5rem 2rem;
  margin: 0rem;
}
p {
  margin: 0;
  padding: 0;
}
.tooltip_score_body > ul {
  padding-top: 1.5rem;
}
.tooltip_score_comment,
.tooltip-score-result {
  color: var(--color-text);
}
</style>

<style scoped>
.tooltip_score_body ul {
  overflow: scroll;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.tooltip_score_body ul::-webkit-scrollbar {
  display: none;
}
</style>