<template>
  <ListBase
    id="list-choose-events-content"
    ref="list-base"
  >
    <template #content>
      <div
        id="choose-events-types-list-content"
        class="min-h-0 overflow-scroll"
      >
        <div
          v-if="isInPopulational"
          class="border-between-section border-0 border-dashed"
        >
          <h3 class="m-b-20 m-0">
            Types de contenu
          </h3>
          <div
            v-for="(typeContent, index) in typeContents"
            :key="index"
          >
            <div class="content-types-choice">
              <ToggleSwitch
                v-model:status="typeContent.enable"
                :text="typeContent.label"
                @on-off="switchContentChangeState(typeContent)"
              />
            </div>
          </div>
        </div>
        <div
          v-if="!isGHMTypeOfContent"
          class="border-between-section border-0 border-dashed"
        >
          <h3 class="m-b-20">
            Types d'événement
          </h3>
          <div
            v-for="(typeEvent, index) in typeEvents"
            :key="index"
          >
            <div class="event-types-choice">
              <ToggleSwitch
                v-model:status="typeEvent.enable"
                :text="typeEvent.label"
              />
            </div>
            <div>
              <div v-if="typeEvent.enable">
                <div
                  v-for="(subTypeEvent, subIndex) in typeEvent.children"
                  :key="subIndex"
                  class="event-types-choice m-l-20"
                >
                  <ToggleSwitch
                    v-model:status="subTypeEvent.enable"
                    :text="subTypeEvent.label"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="isInPopulational">
          <h3 class="m-b-20">
            Filtrages
          </h3>
          <FiltersPopulationalPatient
            ref="filtersPopPatient"
          />
          <FiltersPopulationalCode
            ref="filtersPopCode"
            class="mt-3"
          />
          <FiltersPopulationalUm
            ref="filtersPopUm"
            class="mt-3"
          />
        </div>
      </div>
      <div class="validate-type-events">
        <button
          e2e="listChooseTypeEventsDisplayValidate"
          @click="validateForm"
        >
          Valider
        </button>
      </div>
    </template>
  </ListBase>
</template>

<script>
import * as eventsTypes from '@/shared/enums/eventsTypes.js'
import _ from 'lodash'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import * as mutationTypes from '@/store/mutations-types.js'
import { mapActions, mapGetters } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import utils from '@/libraries/utils.js'
import * as d3 from 'd3'
import ToggleSwitch from "../../ToggleSwitch.vue";
import FiltersPopulationalCode from '@/components/borderLists/filters/populational/Code.vue'
import FiltersPopulationalUm from '@/components/borderLists/filters/populational/Um.vue'
import FiltersPopulationalPatient from '@/components/borderLists/filters/populational/Patient.vue'

let timeoutIntervalSearch = null

export default {
  components: {
    ToggleSwitch,
    ListBase,
    FiltersPopulationalCode,
    FiltersPopulationalUm,
    FiltersPopulationalPatient
  },
  data: () => ({
    afficheEvent: false,
    /**
     * Ce tableau contient la hiérarchie devant être affiché avec les switch pour permettre à l'utilisateur d'afficher / ne pas afficher certains types d'événement
     * @type {Object[]}
     */
    typeEvents: [
      {
        label: 'Diagnostiques',
        type: 'condition',
        enable: true,
        children: []
      },
      {
        label: 'Actes',
        type: 'procedure',
        enable: true,
        children: [
          {
            label: 'Diagnostiques',
            enable: true,
            type: eventsTypes.procedure.DIAGNOSIS
          },
          {
            label: 'Thérapeutiques',
            enable: true,
            type: eventsTypes.procedure.THERAPEUTIC
          }
        ]
      },
      {
        label: 'Vaccins',
        type: 'immunization',
        enable: true,
        children: []
      },
      {
        label: 'Allergies',
        type: 'allergy',
        enable: true,
        children: []
      }
    ],
    typeContents: [],
    search: '',
    codes: [],
    testCodeCohort: '',
    testCodeCohortPpl: ''
  }),
  computed: {
    ...mapGetters({
      eyediagMode: 'eyediagMode',
      eventFilters: 'event/common/eventFilters',
      isInPopulational: 'isInPopulational'
    }),
    isGHMTypeOfContent() {
      let ghmOption = null
      if (this.typeContents) {
        ghmOption = this.typeContents.find(d => d.type === eyediagMode.populationalOptions.content.GHM)
      }
      if (!ghmOption) {
        ghmOption = {}
      }
      return ghmOption.enable === true
    }
  },
  watch: {
    eyediagMode: {
      handler() {
        this.generateTypeContentsList()
      },
      deep: true //J'ai mis en deep sinon lorsqu'un spectateur reçois une session collaborative son état n'est pas à jour
    }
  },
  mounted() {
    this.initEvents()
    this.generateTypeContentsList()
  },
  methods: {
    ...mapActions({
      'sendEvent': 'ws/sendEvent',
      'searchEvents': 'search/searchEvents',
      'collaborativeEventTreated': 'ws/collaborativeEventTreated',
      'sendProperties': "ws/sendProperties",
    }),
    /**
     * Permet la gestion de l'ouverture / fermeture de la liste
     * @param  {...any} params Paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    initEvents() {
      d3.select(this.$refs['list-base'].$elem)
        .on('scroll', () => this.onScroll(this.$refs['list-base']))
    },
    onScroll(domElem) {
      this.sendProperties({
        target: domElem,
        params: {
          scrollTop: domElem.scrollTop
        }
      })
    },
    async validateForm(event) {
      this.sendEvent({ event })
      if (this.isInPopulational) {
        this.validateSelectionContentTypes()
      }

      if (!this.isGHMTypeOfContent) {
        this.validateSelectionEventTypes()
      }

      let pmsi = {}

      if (this.$refs.filtersPopPatient) {
        Object.assign(pmsi, this.$refs.filtersPopPatient.getFilters())
      }
      if (this.$refs.filtersPopCode) {
        Object.assign(pmsi, this.$refs.filtersPopCode.getFilters())
      }
      if (this.$refs.filtersPopUm) {
        Object.assign(pmsi, this.$refs.filtersPopUm.getFilters())
      }

      this.$store.commit(`circle/${mutationTypes.UPDATE_CIRCLE_FILTERS}`, {
        ...this.$store.state.circle.filters,
        ...{
          events: {
            text: this.search,
            codes: this.codes,
            pmsi: pmsi
          }
        }
      })

      await this.$store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })

      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    validateSelectionContentTypes() {
      const enabledContent = this.typeContents.filter(d => d.enable)
      const mode = this.eyediagMode

      mode.options.content = enabledContent.map(d => d.type)

      this.$store.commit(mutationTypes.SET_EYEDIAG_MODE, mode)
    },
    /**
     * Cette fonction permet de récupérer la selection des types d'événement voulu par l'utilisateur puis de mettre à jour cette selection pour les prochaines requêtes faites à l'api
     * @method
     * @public
     */
    validateSelectionEventTypes() {
      const displayedEventsTypes = {}
      _.forEach(this.typeEvents, (value) => {
        if (!value.enable) {
          return
        }

        let subTypes = []

        if (value.children.length === 0) {
          subTypes = [{
            type: eventsTypes[value.type].ALL
          }]
        } else {
          const enableChildren = value.children.filter(child => child.enable)

          if (enableChildren.length === 0) {
            return
          }

          subTypes = enableChildren.map(child => ({
            type: child.type
          }))
        }
        displayedEventsTypes[value.type] = { types: subTypes }
      })

      this.$store.commit(mutationTypes.SET_TYPES_EVENTS_TO_DISPLAY, displayedEventsTypes)
    },
    generateTypeContentsList() {
      this.typeContents = [
        {
          label: 'Evénements associés',
          type: eyediagMode.populationalOptions.content.ASSOCIATED_DIAGNOSIS,
          enable: this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.ASSOCIATED_DIAGNOSIS),
          children: [],
          linkFamily: 1
        },
        {
          label: 'DP',
          type: eyediagMode.populationalOptions.content.MAIN_DIAGNOSIS,
          enable: this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.MAIN_DIAGNOSIS),
          children: [],
          linkFamily: 1
        },
        {
          label: 'GHM',
          type: eyediagMode.populationalOptions.content.GHM,
          enable: this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && this.eyediagMode.options.content.includes(eyediagMode.populationalOptions.content.GHM),
          children: [],
          linkFamily: 2
        },
      ]
    },
    switchContentChangeState(typeContent) {
      for (let i = 0; i < this.typeContents.length; i++) {
        if (this.typeContents[i].linkFamily !== typeContent.linkFamily) {
          this.typeContents[i].enable = false
        }
      }
    },
    onSearch(event) {
      const lenText = this.search.length
      if (event.isTrusted) {
        if (timeoutIntervalSearch !== null) {
          clearTimeout(timeoutIntervalSearch)
        }
  
        if (lenText < 2) {
          timeoutIntervalSearch = setTimeout(() => {
            this.sendEvent({
              event: event,
              params: {
                text: ''
              }
            })
            this.deleteSearchFilter()
            timeoutIntervalSearch = null
          }, 500)
          return
        }
  
        if (this.search[lenText - 1] === ' ' && this.search[lenText - 2] !== ' ') {
          this.sendEvent({
            event: event,
            params: {
              text: this.search
            }
          })
          this.makeSearchRequest()
        } else {
          timeoutIntervalSearch = setTimeout(() => {
            this.sendEvent({
              event: event,
              params: {
                text: this.search
              }
            })
            timeoutIntervalSearch = null
            this.makeSearchRequest()
          }, 500)
        }
      } else if (lenText >= 2) {
        this.sendEvent({ event: event })
        this.makeSearchRequest()
        this.collaborativeEventTreated()
      } else {
        this.sendEvent({ event: event })
        this.deleteSearchFilter()
        this.collaborativeEventTreated()
      }
    },
    async makeSearchRequest() {
      this.codes = await this.searchEvents({
        search: this.search
      })
      this.refreshFilters()
      this.$store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "Recherche")
    },
    deleteSearchFilter() {
      this.search = ''
      this.codes = []
      this.$store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "")
      this.refreshFilters()
    },
    async onChangeHighLightEvent(event) {
      this.sendEvent({event: event})
      this.afficheEvent = !this.afficheEvent

      await this.refreshFilters()

      this.collaborativeEventTreated()
    },
    /* Possibilité d'amélioration pour ne pas avoir de duplicate de stockage des filtres dans le store
      - stocker dans le store la variable this.afficheEvent
      - Lors des filtrages de mise en surbrillance utiliser les données de filters du module circle du store
    */
    async refreshFilters() {
      this.$store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
        ...this.eventFilters,
        ...{
          text: this.afficheEvent ? this.search : '',
          codes: this.afficheEvent ? this.codes : [],
          pmsi: {
            discipline: this.afficheEvent ? this.selectedDiscipline : [],
            activite: this.afficheEvent ? this.selectedActivite : []
          }
        }
      })
      await this.$store.dispatch('event/common/generateEvents')
    }
  }
}
</script>

<style scoped>
#choose-events-types-list-content {
  margin-inline: 5px;
}

.content-types-choice, .event-types-choice {
  display: flex;
  align-content: center;
  margin-top: 10px;
}

h3 {
  display: flex;
  justify-content: center;
}

.validate-type-events {
  position: sticky;
  padding-top: 10px;
  background-color: var(--color-bg-0);
  bottom: 0px;
  display: flex;
  flex-direction: column;
  margin-inline: 5px;
}
</style>