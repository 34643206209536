<template>
  <div>
    <p>Il n'est pas nécessaire de re importer tous les fichiers, seulement ceux qui ont changés</p>
    <br>
    <div>
      <label for="avatar">Fichier de correspondance</label>
      <input 
        id="import-test-corres" 
        ref="import-test-corres"
        type="file" 
        name="import-test-corres" 
        accept=".tsv"
      >   
    </div>
    <br>
    <div>
      <label for="import-test-form">Fichier des formulaire</label>
      <input
        id="import-test-form"
        ref="import-test-form"
        type="file"
        name="import-test-form"
        accept=".tsv"
      >
    </div>
    <br>
    <div>
      <button @click="uploadFiles">
        Valider
      </button>
    </div>
  </div>
</template>

<script>
import EyeFetch from '@/libraries/EyeFetch.js'

export default {
  name: 'EyeTest',
  methods: {
    async uploadFiles() {
      console.log(
        'Hi',
        this.$refs['import-test-form'].files,
        this.$refs['import-test-corres'].files,
      )

      const data = new FormData()
      
      if (this.$refs['import-test-form'].files.length > 0) {
        data.append('form', this.$refs['import-test-form'].files[0])
      }
      
      if (this.$refs['import-test-corres'].files.length > 0) {
        data.append('corres', this.$refs['import-test-corres'].files[0])
      }

      try {
        await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/test/config-files`, {
          method: 'POST',
          body: data
        })
        this.$router.push({ name: "EyeApp" });
      } catch (err) {
        console.error(err)
      }
    }
  }
}

</script>