<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./3.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Il est possible de naviguer à travers les temporalités en cliquant sur les cercles.</p>
    <p>Essayez de changer d'année.</p>
  </div>
</template>

<script>
export default {
  name: "Component3Tuto",
}
</script>

<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>