<template>
  <MovingItem
    v-if="myParticipantData"
    ref="visio"
    container-class="visio-generic-container"
    :z-index="501"
  >
    <div class="visio-container">
      <div class="cross-visio">
        <CrossButton
          color="d3_white"
          @close="hideVisioModal"
        />
      </div>
      <div class="user-visio-container">
        <div
          class="video-wrapper"
          width="200"
          height="150"
        >
          <video
            :ref="`video-${myParticipantData.id}`"
            class="visio-video"
            poster="@/assets/doctor_placeholder.png"
            disablePictureInPicture
            playsinline
            autoplay
            muted
            width="200"
            height="150"
          />
          <img
            :class="camera ? 'cover-img hidden' : 'cover-img'"
            src="@/assets/doctor_placeholder.png"
          >
        </div>
        <p align="center">
          {{ `${myParticipantData.name} ${myParticipantData.familyName}` }}
        </p>
        <div align="center">
          <button
            :class="
              micro
                ? 'button-visio-options button-enabled'
                : 'button-visio-options button-disabled'
            "
            @click="onChangeMicroState"
          >
            <icon-mic />
          </button>
          <button
            :class="
              camera
                ? 'button-visio-options button-enabled'
                : 'button-visio-options button-disabled'
            "
            @click="onChangeCameraState"
          >
            <icon-camera />
          </button>
        </div>
      </div>
      <div
        v-for="participant in otherParticipantsData"
        :key="participant.id"
      >
        <div
          class="video-wrapper"
          width="200"
          height="150"
        >
          <video
            :ref="`video-${participant.id}`"
            class="visio-video"
            poster="@/assets/doctor_placeholder.png"
            disablePictureInPicture
            playsinline
            autoplay
            width="200"
            height="150"
          />
          <img
            :class="participant.camera ? 'cover-img hidden' : 'cover-img'"
            src="@/assets/doctor_placeholder.png"
          >
        </div>
        <p align="center">
          {{ `${participant.name} ${participant.familyName}` }}
        </p>
      </div>
    </div>
    <div
      :class="{ show: !isOpen }"
      class="wrapper_visio-button"
      @click="visiobuttonclickhandler"
    >
      <p class="visio-button">
        Ouvrir
      </p>
    </div>
  </MovingItem>
</template>

<script>
import { mapGetters } from "vuex"
import _ from "lodash"
import MovingItem from "./MovingItem.vue"
import CrossButton from "./Button.vue"
import IconMic from "@/assets/icons/mic.vue"
import IconCamera from "@/assets/icons/camera.vue"

/**
 * Configuration des serveurs stun et turn utilisés pour la connexion webRTC de la visio
 * @type {Object}
 */
const configuration = {
  iceServers: [
    {
      urls: process.env.VUE_APP_TURNSERVER_HOST,
      username: process.env.VUE_APP_TURNSERVER_USER,
      credential: process.env.VUE_APP_TURNSERVER_PASSWORD,
    },
  ],
}

/**
 * Stream de l'utilisateur 
 * @type {MediaStream}
 */
let mediaStream = null

/**
 * Il s'agit de la position x de base du conteneur contenant les videos de visio
 * @type {Number}
 */
let posX = 0

/**
 * Il s'agit de la position y de base du conteneur contenant les videos de visio
 * @type {Number}
 */
let posY = window.innerHeight * 0.2

export default {
  name: "MyVisio",
  components: {
    MovingItem,
    CrossButton,
    IconMic,
    IconCamera
  },
  data: () => ({
    /**
     * Tableau contenant la liste des participants à la visio avec leur infos webRTC
     * @type {EyeCollabParticipantOffer[]}
     */
    participantsOffers: [],
    /**
     * Détermine si la camera de l'utilisateur est allumé
     * @type {Boolean}
     */
    camera: true,
    /**
     * Détermine si le micro de l'utilisateur est allumé
     * @type {Boolean}
     */
    micro: true,
    /**
     * Détermine si l'utilisateur à ouvert la fenêtre de visio avec l'aide de la languette sur le coté
     * @type {Boolean}
     */
    isOpen: true,
  }),
  computed: {
    ...mapGetters({
      roomId: "ws/roomId",
      wsSocket: "ws/wsSocket",
      isInCollaboration: "ws/isInCollaboration",
      userData: "user/userData",
      visioConfig: "ws/visioConfig",
    }),
    /**
     * Il s'agit des données de l'utilisateur actuellement présent sur l'application et dans la visio
     * @type {EyeCollabParticipantOffer}
     */
    myParticipantData() {
      return this.participantsOffers.find(
        (participant) => participant.id === this.userData.sub
      )
    },
    /**
     * Il s'agit des données des autres utilisateurs présent dans la visio
     * @type {EyeCollabParticipantOffer[]}
     */
    otherParticipantsData() {
      return this.participantsOffers.filter(
        (participant) => participant.id !== this.userData.sub
      )
    },
  },
  watch: {
    isInCollaboration() {
      if (this.isInCollaboration === false) {
        this.endVisio()
      }
    },
    myParticipantData: {
      handler(value) {
        if (value) {
          this.$refs["visio"].initialCoordinate(posX, posY)
        }
      },
      flush: "post",
    },
    wsSocket() {
      this.setupWsEvent()
    }
  },
  async mounted() {
    this.setupWsEvent()
    //TEMPORAIRE POUR LES TESTS AVEC CLIENT
    
  },
  methods: {
    /**
     * Cette fonction permet à l'initiateur d'une session de collaboration en visio d'initialiser les composants de visio de son côté
     * @method
     * @public
     */
    async joinVisio() {
      mediaStream = await this.openMediaDevices()
      this.participantsOffers.forEach(async (participant) => {
        participant.peerConnection = new RTCPeerConnection(configuration)
        participant.peerConnection.addEventListener("track", (event) =>
          this.onNewRemoteTrack(participant, event)
        )
        participant.peerConnection.addEventListener("icecandidate", (e) =>
          this.manageLocalIceCandidate(participant.idSocket, e)
        )
        participant.peerConnection.addEventListener(
          "connectionstatechange",
          () => this.connexionStateChanged(participant.peerConnection)
        )
        mediaStream.getTracks().forEach((track) => {
          participant.peerConnection.addTrack(track, mediaStream)
        })
        participant.camera = true
        const offer = await participant.peerConnection.createOffer()
        await participant.peerConnection.setLocalDescription(offer)
        this.wsSocket.emit("collaborative:visio:offer", {
          offer: offer,
          participant: { idSocket: participant.idSocket },
        })
      })
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur recoit une offre d'un autre participant
     * @method
     * @public
     * @param {Object} data Il s'agit du détail de l'offre transmise par un participant
     */
    async onRemoteOffer(data) {
      const participant = this.participantsOffers.find(
        (participant) => participant.idSocket === data.participant.idSocket
      )
      const peerConnection = new RTCPeerConnection(configuration)
      participant.peerConnection = peerConnection
      peerConnection.addEventListener("icecandidate", (e) =>
        this.manageLocalIceCandidate(data.participant.idSocket, e)
      )
      peerConnection.addEventListener("track", (event) =>
        this.onNewRemoteTrack(participant, event)
      )
      peerConnection.setRemoteDescription(
        new RTCSessionDescription(data.offer)
      )

      if (!mediaStream) {
        mediaStream = await this.openMediaDevices()
      }

      mediaStream.getTracks().forEach((track) => {
        peerConnection.addTrack(track, mediaStream)
      })
      const answerOffer = await peerConnection.createAnswer()
      await peerConnection.setLocalDescription(answerOffer)
      this.wsSocket.emit("collaborative:visio:answerOffer", {
        answerOffer,
        participant: { idSocket: participant.idSocket },
      })
      participant.camera = true
      participant.peerConnection.addEventListener("connectionstatechange", () =>
        this.connexionStateChanged(participant.peerConnection)
      )
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur recoit une réponse à son offre de la part d'un autre participant
     * @method
     * @public
     * @param {Object} data Il s'agit du détail de la réponse de l'offre transmise par un participant
     */
    async onRemoteAnswerOffer(data) {
      const remoteDesc = new RTCSessionDescription(data.answerOffer)
      const participant = this.participantsOffers.find(
        (participant) => participant.idSocket === data.participant.idSocket
      )
      await participant.peerConnection.setRemoteDescription(remoteDesc)
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur recoit un nouvel candidat ice d'un autre participant de la session collaborative
     * @method
     * @public
     * @param {Object} data Il s'agit du détail du candidat ice transmit par un participant
     */
    async onRemoteNewIceCandidate(data) {
      try {
        const participant = this.participantsOffers.find(
          (participant) => participant.idSocket === data.participant.idSocket
        )
        await participant.peerConnection.addIceCandidate(data.candidate)
      } catch (e) {
        console.error("Error adding received ice candidate", e)
      }
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur clique sur la languette pour afficher / masquer le conteneur de visioconférence
     * @method
     * @public
     */
    visiobuttonclickhandler() {
      if (this.isOpen) {
        this.hideVisioModal()
      } else {
        this.showVisioModal()
      }
    },
    /**
     * Cette fonction est appelée lorsqu'un participant ajoute une nouvelle piste à son flux
     * @method
     * @public
     * @param {EyeCollabParticipantOffer} participant Objet contenant les informations du participant concerné
     * @param {event} event Il s'agit de l'événement fournit au listener
     */
    onNewRemoteTrack(participant, event) {
      const [remoteStream] = event.streams
      const domRef = `video-${participant.id}`
      const refs = this.$refs[domRef]

      if (refs.length > 0) {
        refs[0].srcObject = remoteStream
      }
    },
    /**
     * Permet la fermeture de la modal de visio
     * @method
     * @public
     */
    hideVisioModal() {
      this.$refs["visio"].updateCoordinate(-210, window.innerHeight * 0.2, 300)
      this.isOpen = false
    },
    /**
     * Permet l'ouvertue de la modal de visio
     * @method
     * @public
     */
    showVisioModal() {
      this.$refs["visio"].updateCoordinate(0, window.innerHeight * 0.2, 300)
      this.isOpen = true
    },
    /**
     * Cette fonction est appelée lorqu'un caméra allumer ou coupe sa caméra
     * @method
     * @public
     * @param {Object} data Objet contenant les informations d'état de la caméra du participant
     */
    onRemoteCameraChangeState(data) {
      const participant = this.participantsOffers.find(
        (participant) => participant.idSocket === data.idSocket
      )
      if (participant) {
        participant.camera = data.state
      }
    },
    /**
     * Cette fonction permet l'envoi de candidat ice aux partiicpants lorsqu'un nouveau candidats est disponible
     * @method
     * @public
     * @param {String} idSocketParticipant Identifiant de la socket à qui le candidat ice doit être tansmit
     * @param {event} event Evénement transmit au listener
     */
    manageLocalIceCandidate(idSocketParticipant, event) {
      if (event.candidate) {
        this.wsSocket.emit("collaborative:visio:newIceCandidate", {
          candidate: event.candidate,
          participant: { idSocket: idSocketParticipant },
        })
      }
    },
    /**
     * Cette fonction est appelée lorsque la connexion avec le peer distant change d'état
     * @method
     * @public
     * @param {RTCPeerConnection} peerConnection Identifiant de la socket à qui le candidat ice doit être tansmit
     */
    connexionStateChanged(peerConnection) {
      console.log("connectionState", peerConnection.connectionState)
    },
    /**
     * Cette fonction permet la récupération du stream de l'utilisateur en fonction des périphériques audio / video qu'il a séléctionné précédemment
     * @method
     * @public
     */
    async openMediaDevices() {
      const constraints = {
        audio: {
          echoCancellation: true,
          deviceId: this.visioConfig.devices.audio.deviceId,
        },
        video: {
          deviceId: this.visioConfig.devices.video.deviceId,
        },
      }
      const myMedia = await navigator.mediaDevices.getUserMedia(constraints)
      const domRef = `video-${this.userData.sub}`
      this.$refs[domRef].srcObject = myMedia

      this.camera = true
      this.micro = true

      return myMedia
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur souhaite activer / couper sa caméra
     * @method
     * @public
     */
    onChangeCameraState() {
      this.camera = !this.camera

      const videoTrack = mediaStream
        .getTracks()
        .find((track) => track.kind === "video")
      videoTrack.enabled = this.camera

      this.wsSocket.emit("collaborative:visio:cameraChangeState", {
        roomId: this.roomId,
        state: this.camera,
      })
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur souhaite activer / couper sa micro
     * @method
     * @public
     */
    onChangeMicroState() {
      this.micro = !this.micro

      const videoTrack = mediaStream
        .getTracks()
        .find((track) => track.kind === "audio")
      videoTrack.enabled = this.micro
    },
    /**
     * Cette fonction permet de mettre fin à la visio pour l'utilisateur et de quitter les connexions webRTC avec les participants
     * @method
     * @public
     */
    endVisio() {
      this.participantsOffers.forEach((participant) => {
        if (participant.peerConnection) {
          participant.peerConnection.close()
        }
      })

      if (mediaStream) {
        mediaStream.getTracks().forEach((track) => {
          track.stop()
          mediaStream.removeTrack(track)
        })
        mediaStream = null
        const refString = `video-${this.myParticipantData.id}`
        this.$refs[refString].srcObject = null
      }

      this.participantsOffers = []
    },
    setupWsEvent() {
      if (this.wsSocket === null) {
        return
      }

      this.wsSocket.on("collaborative:stopVisio", () => {
        this.endVisio()
      })
      //TEMPORAIRE

      this.wsSocket.on("collaborative:updateRoomListParticipant", (data) => {
        if (!this.visioConfig.enable) {
          return
        }

        const amIInitiater =
          this.participantsOffers.length === 0 && data.participants.length >= 2
        if (data.participants.length > this.participantsOffers.length) {
          const diffParticipants = _.differenceBy(
            data.participants,
            this.participantsOffers,
            "id"
          )
          diffParticipants.forEach((participant) => {
            this.participantsOffers.push(participant)
          })
        } else {
          const diffParticipants = _.differenceBy(
            this.participantsOffers,
            data.participants,
            "id"
          )
          this.participantsOffers = this.participantsOffers.filter(
            (participant) => {
              if (diffParticipants.find((d) => d.id === participant.id)) {
                if (participant.peerConnection) {
                  participant.peerConnection.close()
                }
                return false
              }
              return true
            }
          )
        }
        if (amIInitiater) {
          this.joinVisio()
        }
      })

      this.wsSocket.on("collaborative:visio:offer", this.onRemoteOffer)
      this.wsSocket.on(
        "collaborative:visio:answerOffer",
        this.onRemoteAnswerOffer
      )
      this.wsSocket.on(
        "collaborative:visio:newIceCandidate",
        this.onRemoteNewIceCandidate
      )
      this.wsSocket.on(
        "collaborative:visio:cameraChangeState",
        this.onRemoteCameraChangeState
      )
    }
  },
}
</script>

<style scoped>
.user-visio-container {
  margin-bottom: 10px;
}

.wrapper {
  display: flex;
  position: absolute;
}

.cross-visio {
  z-index: 1;
  position: absolute;
  right: 10px;
  top: 5px;
}

.visio-container_show {
  transform: translateX(89%);
}
.visio-button {
  transform: rotate(-90deg) translateX(25%);
  margin-top: 20px;
}
.wrapper_visio-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: red;
  z-index: -1;
  opacity: 0;
  transform: translateX(-100%);
}
.show {
  transform: translateX(0%);
  transition: 0.4s;
  transition-delay: 1000ms;
  background: var(--c-gray-2);
  opacity: 1;
}

.visio-container {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: var(--c-gray-2);
  padding-inline: 5px;
  padding-bottom: 10px;
  max-height: 80vh;
  overflow: scroll;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
}

.visio-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.visio-video {
  margin-bottom: 5px;
  margin-top: 5px;
  border-radius: 5px;
}

.button-visio-options {
  border: none;
  margin: 5px;
  padding: 5px;
  font-size: 15px;
  border-radius: 25px;
  padding-inline: 7px;
}

.button-enabled {
  background-color: green;
}

.button-disabled {
  background-color: red;
}

.video-wrapper {
  position: relative;
}

.cover-img {
  position: absolute;
  left: 0;
  pointer-events: none;
  margin-top: 5px;
  width: 100%;
  height: 93%;
  border-radius: 5px;
}

.cover-img.hidden {
  display: none;
}
</style>