import { computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import UserRole from '@/shared/enums/userRole.js'

export function useCreateAccount() {
  const store = useStore()
  const router = useRouter()

  const userData = computed(() => store.getters['user/userData'])

  async function createAccount(event, data) {
    const accountObj = {
      surname: data.lastName,
      name: data.firstName,
      email: data.email,
    }
  
    if (userData.value.Role.includes(UserRole.CREATE_ACCOUNT)) {
      accountObj.permissions = data.permissions
      accountObj.enableExpireDate = data.enableExpireDate
      accountObj.minutesExpireDate = data.minutesExpireDate
      accountObj.sendToCreatorAddress = data.sendToCreatorAddress
      accountObj.sendToRecipientAddress = data.sendToRecipientAddress
    }

    if (event.isTrusted) {
      await store.dispatch('user/createAccount', accountObj)
      await store.dispatch('user/getUserData')
    }
  
    store.dispatch('ws/sendEvent', {event: event})
    router.push({ name: 'Home' })
    store.dispatch('ws/collaborativeEventTreated')
  }

  return { createAccount }
}