<template>
  <g>
    <path
      :id="idRefCircle"
      ref="ref-circle"
    />
    <path
      :id="idInvisibleRefCircle"
      ref="invisible-ref-circle"
    />
    <g :id="idEventsContainer" />
  </g>
</template>

<script>

import { mapGetters, mapActions } from 'vuex'
import MixinRefCircle from '@/components/refCircle/MixinRefCircle.js'
import * as d3 from 'd3'
import * as mutationTypes from '@/store/mutations-types.js'
import eventManager from '@/libraries/EventManager.js'

import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'

export default {
  mixins: [MixinRefCircle],
  data: () => ({
    /**
     * Il s'agit de l'identifiant donné à l'element du dom du cercle des antécédents familiaux
     * @type {String}
     */
    idRefCircle: 'family-history-circle',
    /**
     * Il s'agit de l'identifiant donnée à l'lement du dom du cercle des antécédents familiaux invisible permettant d'augmenter la zone de captation des événements de la souris
     * @type {String}
     */
    idInvisibleRefCircle: 'invisible-family-history-circle',
    /**
     * Il s'agit de l'identifiant donnée au group contenant les cercles des événements du cercle des antécédents familiaux
     * @type {String}
     */
    idEventsContainer: 'family-history-circle-event-container',
    /**
     * Il s'agit de l'identifiant donnée à chacun des cercles des événements du cercle des antécédents familiaux
     * @type {String}
     */
    classRefEvents: 'family-history-circle-events'
  }),
  computed: {
    ...mapGetters({
      isDisplayedFamilyHistoryCircle: 'refCircle/familyHistory/isDisplayedFamilyHistoryCircle',
      familyHistoryCircle: 'refCircle/familyHistory/familyHistoryCircle',
      labelToDisplay: 'event/common/labelToDisplay'
    })
  },
  watch: {
    isDisplayedFamilyHistoryCircle() {
      if (this.isDisplayedFamilyHistoryCircle) {
        this.points = [...this.familyHistoryCircle.points]
        this.events = eventManager.extractEventsFromCircle([this.familyHistoryCircle])
        this.draw()
        this.calcEventCircle()
        this.initEventInvisibleRefCircle()
      } else {
        d3.select(`#${this.idRefCircle}`).attr("d", "");
        d3.select(`#${this.idInvisibleRefCircle}`).attr("d", "");
        this.points = []
        this.events = []
        this.disableScoreEvents()
      }
    },
    familyHistoryCircle() {
      if (this.isDisplayedFamilyHistoryCircle) {
        this.points = [...this.familyHistoryCircle.points]
        this.events = eventManager.extractEventsFromCircle([this.familyHistoryCircle])
        this.draw()
        this.calcEventCircle()
        this.initEventInvisibleRefCircle()
      }
    }
  },
  mounted() {
    this.initRefCircleEvents()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction permet l'initialisation des événements d'écoute lors du survol / arret de survol des événements du cercle des antécédents familiaux
     */
    initRefCircleEventsEvents() {
      d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)
        .data(this.events)
        .on("mouseover", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.PUSH_LABEL_TO_DISPLAY}`, d)
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`,[...this.labelToDisplay])
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.FAMILY_HISTORY_CIRCLE_APPEAR)
            .style('stroke-opacity', 1)
            .style('fill', EyeColor.getFillColorEventCircle(0.8))

          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, this.labelToDisplay.filter((e) => e.id !== d.id))
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.FAMILY_HISTORY_CIRCLE_DISAPPEAR)
            .style('stroke-opacity', 0)
            .style('fill', EyeColor.getFillColorEventCircle(0))
          this.collaborativeEventTreated()
        })
        .on('contextmenu', (event, d) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d)
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction permet l'initialisation d'événements d'écoute sur le cercle des antécédents familiaux
     */
    initRefCircleEvents() {
      d3.select(this.$refs['invisible-ref-circle'])
        .on('contextmenu', (event) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.$store.commit(`refCircle/familyHistory/${mutationTypes.SET_DISPLAY_FAMILY_HISTORY_CIRCLE}`, false)
          this.collaborativeEventTreated()
        })
    }
  }
}

</script>

<style>
#family-history-circle {
  fill: none;
  stroke: var(--c-orange);
  stroke-width: 2px;
}

#invisible-family-history-circle {
  fill: none;
  stroke: transparent;
  stroke-width: 20px;
}
</style>