<template>
  <div class="mt1-1">
    <p
      v-for="(extension, index) in extendedSections"
      :key="index"
      class="underline underline-offset-2 cursor-pointer mt-2"
      @click="e => onClickExtension(e, extension)"
    >
      {{ extension.label }}
    </p>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, computed } from 'vue'
import * as HierarchyEntries from '@/libraries/hierarchy/hierarchyEntries.js'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()
const extensions = [
  { parentId: 8, label: 'Suivi Diabétologie Traitement', onClick: (store) => HierarchyEntries.diabNurseEntryTrt(store) },
  { parentId: 8, label: 'Suivi Diabétologie', onClick: (store) => HierarchyEntries.diabNurseEntry(store) },
  { parentId: 8, label: 'Diabétologie', onClick: (store) => HierarchyEntries.diabGlobal(store) }
]

const props = defineProps({
  selectedSection: {
    type: Object,
    required: true
  }
})

const extendedSections = ref([])
const isInPatient = computed(() => store.getters['isInPatient'])
const isInNurseEntry = computed(() => store.getters['isInNurseEntry'])

if (isInPatient.value === true && isInNurseEntry.value === false) {
  extendedSections.value = extensions.filter(extension => extension.parentId === props.selectedSection.id)
}

function onClickExtension(event, extension) {
  store.dispatch('ws/sendEvent', {event: event})
  store.commit(`pie/${mutationTypes.RESET_HOVERED_SECTION}`)
  store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
  extension.onClick(store)
  store.dispatch('ws/collaborativeEventTreated')
}
</script>