<template>
  <div style="font-size: 2rem; color: white;">
    <p>Euroscore2.2: </p>
    <p>...</p>
  </div>
</template>

<script>
export default {
  name: "Component3Tuto",
}
</script>

<style scoped>
p {
    padding-bottom: 10px;
}
</style>