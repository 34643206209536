/**
 * Définit les types de contenu possibles dans le panel à droite de l'application
 * @type {Object}
 */
export default {
  "LIST_CHOOSE_TYPE_EVENTS_DISPLAY": "LIST_CHOOSE_TYPE_EVENTS_DISPLAY",
  "LIST_MEMORIZED_EVENTS": "LIST_MEMORIZED_EVENTS",
  "LIST_TIME_FILTER": "LIST_TIME_FILTER",
  "LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY": "LIST_CHOOSE_TYPE_ELEMENTS_ASSEMBLY",
  "LIST_UNCLASSIFIED": "LIST_UNCLASSIFIED",
  "LIST_EVENTS_TO_ADD": "LIST_EVENTS_TO_ADD",
  "LIST_TOP_10": "LIST_TOP_10",
  "LIST_ENTER_HIERARCHY": "LIST_ENTER_HIERARCHY",
  "LIST_NURSE_ENTRY": "LIST_NURSE_ENTRY",
  "LIST_FORCE_JOIN_COLLAB": "LIST_FORCE_JOIN_COLLAB",
  "LIST_PATIENT_NOTES": "LIST_PATIENT_NOTES",
  "LIST_LEGEND": "LIST_LEGEND",
  "LIST_PATIENT_VIEWS": "LIST_PATIENT_VIEWS"
}