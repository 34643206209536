import EyeFetch from "@/libraries/EyeFetch.js"

/**
 * class Universal
 */

class Universal {
  async sendConfig(store, config, options) {
    if (options.makeRequest === true) {
      try {
        await EyeFetch(store,
          `${process.env.VUE_APP_SERVER_BASE_URL}/universal/config`,
          {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(config)
          }
        )
      } catch (e) {
        console.error(e)
      }
    }
  }
}

export default new Universal()