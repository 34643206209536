<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./12.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Un menu contextuel est accessible via un clic droit dans un espace de vide de l'interface.</p>
    <p>Il est constitué de 5 ensembles.</p>
  </div>
</template>
      
<script>
export default {
  name: "Component12Tuto",
}
</script>
      
<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>