import colorThemes from '@/enums/color_themes.js'
import fontSize from '@/enums/fontSize.js'
import utils from '@/libraries/utils.js'

export default [
  { labelId: 'AfficherMasquerCatLabel', selectedChecker: (store) => store.getters['displayLabelSections'] === true },
  // { labelId: 'MasquerCatLabel', selectedChecker: () => false },
  {
    labelId: 'TailleMenu',
    selectedChecker: () => false,
    children: [
      { labelId: 'InterfaceTaille1', selectedChecker: () => !localStorage.getItem('ratioRemPx') || localStorage.getItem('ratioRemPx') == fontSize.SIZE1 },
      { labelId: 'InterfaceTaille2', selectedChecker: () => localStorage.getItem('ratioRemPx') == fontSize.SIZE2 },
      { labelId: 'InterfaceTaille3', selectedChecker: () => localStorage.getItem('ratioRemPx') == fontSize.SIZE3 },
    ]
  },
  // {
  //   labelId: 'CouleurRepCercle',
  //   selectedChecker: () => false,
  //   children: [
  //     { labelId: 'CouleurRepCercleOriginal', selectedChecker: () => !localStorage.getItem('repCircleColor') || localStorage.getItem('repCircleColor') === '#95CD41' },
  //     { labelId: 'CouleurRepCercle#A8FF98', selectedChecker: () => localStorage.getItem('repCircleColor') === '#A8FF98' },
  //     { labelId: 'CouleurRepCercleLime', selectedChecker: () => localStorage.getItem('repCircleColor') === '#00FF00' },
  //     { labelId: 'CouleurRepCercleChartreuse', selectedChecker: () => localStorage.getItem('repCircleColor') === '#7FFF00' },
  //     { labelId: 'CouleurRepCercleSpringGreen', selectedChecker: () => localStorage.getItem('repCircleColor') === 'springGreen' },
  //     { labelId: 'CouleurRepCercle#74FF00', selectedChecker: () => localStorage.getItem('repCircleColor') === '#74FF00' },
  //     { labelId: 'CouleurRepCercle#3AFF00', selectedChecker: () => localStorage.getItem('repCircleColor') === '#3AFF00' },
  //     { labelId: 'CouleurRepCercle#00FF23', selectedChecker: () => localStorage.getItem('repCircleColor') === '#00FF23' },
  //     { labelId: 'CouleurRepCercle#7BFF57', selectedChecker: () => localStorage.getItem('repCircleColor') === '#7BFF57' },
  //     { labelId: 'CouleurRepCercle#8EFF70', selectedChecker: () => localStorage.getItem('repCircleColor') === '#8EFF70' },
  //     { labelId: 'CouleurRepCercleBlue', selectedChecker: () => localStorage.getItem('repCircleColor') === '#6495ED' }
  //   ]
  // },
  {
    labelId: 'Theme',
    selectedChecker: () => false,
    children: [
      { labelId: 'ThemeOriginal', selectedChecker: () => !localStorage.getItem('theme') || localStorage.getItem('theme') === colorThemes['ORIGINAL'] },
      // { labelId: 'ThemeVert+', selectedChecker: () => localStorage.getItem('theme') === colorThemes['GREEN+'] },
      // { labelId: 'ThemeBleu', selectedChecker: () => localStorage.getItem('theme') === colorThemes['BLUE'] },
      { labelId: 'ThemeBleu+', selectedChecker: () => localStorage.getItem('theme') === colorThemes['BLUE+'] },
    ]
  },
  {
    labelId: 'ThemeGeneral',
    selectedChecker: () => false,
    children: [
      { labelId: 'ThemeGeneralClair', selectedChecker: () => !utils.isInDarkMode() },
      { labelId: 'ThemeGeneralSombre', selectedChecker: () => utils.isInDarkMode() },
    ]
  }
]
