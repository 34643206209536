import * as mutations from '@/store/mutations-types.js'

const getDefaultState = () => {
  return {
    routeName: null
  }
}
  
export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {},
  mutations: {
    [mutations.SET_APP_ROUTE_NAME] (state, payload) {
      state.routeName = payload
    }
  },
  actions: {}
}