import record from '@/store/modules/record/record.js'
import replay from '@/store/modules/record/replay.js'

export default {
  namespaced: true,
  modules: {
    record: record,
    replay: replay
  },
  getters: {},
  mutations: {},
  actions: {}
}