/**
 * Définit les types de selections / mise en surbrillance possibles sur les événements
 */
export default {
  "MEMORIZED": "MEMORIZED",
  "FILTERING": "FILTERING",
  "SCOREMATCH": "SCOREMATCH",
  "REPORT": "REPORT",
  "MAIN_CIRCLE": "MAIN_CIRCLE",
  "OVER_EVENTS": "OVER_EVENTS",
  "LEGEND": "LEGEND"
}