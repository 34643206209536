class ContextMenuConfigHelpers {
  static isThisScoreEnabled(store, idScore) {
    return store.getters['refCircle/score/idScore'] === idScore
  }

  static isThisTemporalityEnabled(store, periodUnit, unitPerCircle) {
    return store.getters['circle/periodUnit'] === periodUnit
      && store.getters['circle/unitPerCircle'] === unitPerCircle
  }

  static isThisScoreCircleCoefRadius(store, coefRadius) {
    return store.state.refCircle.score.coefRadiusScore === coefRadius
  }

  static isThisPatientFilters(store, patientFilters) {
    return store.state.circle.filters.sectionsEventsOnly === patientFilters.sectionsEventsOnly
      && store.state.circle.filters.matchOnly === patientFilters.matchOnly
  }

  static isThisHierarchyEnabled(store, hierarchy) {
    return store.getters['hierarchy'].type === hierarchy.type
      && store.getters['hierarchy'].id === hierarchy.id
  }

  static isThisBorderListEnabled(store, borderList) {
    return store.getters['borderList/isDisplayedList'] === true
      && store.getters['borderList/currentDisplayedBorderList'] === borderList
  }

  static isThisPopulationalMergingEnabled(store, configMerging) {
    return store.getters['eyediagMode'].options.typeMergingName === configMerging.typeMergingName
  }

  static isThisTypeOfScalePopulational(store, scale) {
    return store.getters['eyediagMode'].options.typeScale === scale
  }
}

export default ContextMenuConfigHelpers