<template>
  <div
    ref="temporality-menu"
    class="temporality-menu"
  >
    <ul ref="temporality-list">
      <li
        v-for="(temporality, index) in temporalities"
        ref="temporality-elem"
        :key="index"
        :class="{
          'menu-temporality-option': true,
          'menu-temporality-current-option': indexCurrentTemporalityOption === index,
          'menu-temporality-other-option': indexCurrentTemporalityOption !== index,
        }"
      >
        {{ temporality.label }}
      </li>
    </ul>
  </div>
</template>

<script>

import * as d3 from 'd3'
import store from '../store/index.js'
import periodUnits from '@/shared/enums/period_units'
import * as mutationTypes from '../store/mutations-types.js'
import {mapActions, mapGetters} from 'vuex'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import CircleUtility from '@/libraries/CircleUtility.js'
import D3Animation from '@/config/D3Animation.js'
import utils from '@/libraries/utils'

/**
 * Coordonnées de la souris lors du précédent mouvement
 * @type {EyeCoordinate|null}
 */
let previousMousePosition = null

export default {
  name: 'TemporalityMenu',
  data: () => ({
    /**
     * Tableau de configuration contenant les temporalités qu'il est possible de séléctionner
     * @type {EyeTemporality[]}
     */
    temporalities: [
      {
        label: '',
        unitPerCircle: store.getters['circle/optimalYearPerCircle'],
        periodUnit: periodUnits.YEAR
      }, {
        label: 'Opti',
        unitPerCircle: store.getters['circle/optimalYearPerCircle'],
        periodUnit: periodUnits.YEAR
      }, {
        label: 'Trim',
        unitPerCircle: 1,
        periodUnit: periodUnits.QUARTER
      }, {
        label: 'Seme',
        unitPerCircle: 1,
        periodUnit: periodUnits.SEMESTER
      }, {
        label: '1an ',
        unitPerCircle: 1,
        periodUnit: periodUnits.YEAR
      }, {
        label: '2ans',
        unitPerCircle: 2,
        periodUnit: periodUnits.YEAR
      }, {
        label: '3ans',
        unitPerCircle: 3,
        periodUnit: periodUnits.YEAR
      }, {
        label: '4ans',
        unitPerCircle: 4,
        periodUnit: periodUnits.YEAR
      }, {
        label: '',
        unitPerCircle: 4,
        periodUnit: periodUnits.YEAR
      }
    ],
    indexCurrentTemporalityOption: 1
  }),
  computed: {
    ...mapGetters({
      circles: 'circle/circles',
      centerX: 'layout/centerX',
      centerY: 'layout/centerY',
      referenceRadius: 'layout/radius',
    }),
    menuPosition() {
      return this.$store.state.layout.temporalityMenuPos
    }
  },
  watch: {
    menuPosition() {
      d3.select(this.$refs['temporality-menu'])
        .style('top', `${this.menuPosition.y}px`)
        .style('left', `${this.menuPosition.x}px`)
    }
  },
  mounted() {
    this.drawMenu()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * d3 tween permettant l'interpolation  de l'animation du scroll progressif vers la droite
     * @method
     * @public
     * @param {Number} scrollLeft Il s'agit de la valeur de scroll devant être appliqué à l'élément
     * @return (Number) => void Cette fonction sera invoqué pour chaque frame de la transition du scroll horizontal
     */
    scrollLeftTween(scrollLeft) { 
      return function() { 
        var i = d3.interpolateNumber(this.scrollLeft, scrollLeft); 
        return function(t) { this.scrollLeft = i(t); }; 
      }
    },
    /**
     * Cette fonction a pour de placer le menu à la bonne position par rapport à 'menuPosition' et d'initier l'écoute des événements
     * @method
     * @public
     */
    drawMenu() {
      d3.select(this.$refs['temporality-menu'])
        .style('top', `${this.menuPosition.y}px`)
        .style('left', `${this.menuPosition.x}px`)

      d3.select('#root-app').on('mouseup', async (event) => {
        this.sendEvent({ event: event })
        d3.select('#root-app').on('mousemove', null)
        d3.select('#root-app').on('mouseup', null)
        d3.select('#root-app').on('eyeTemporalityMove', null)
        await this.$store.dispatch('circle/getDataRepresentation', {
          unitPerCircle: this.temporalities[this.indexCurrentTemporalityOption].unitPerCircle,
          periodUnit: this.temporalities[this.indexCurrentTemporalityOption].periodUnit,
          reloadDataTypes: reloadDataTypes.TEMPORALITY_CIRCLE
        })
        this.$store.commit(`layout/${mutationTypes.SET_DISPLAYED_TEMPORALITY_MENU}`, false)
        utils.onGlobalAnimationEnd(this.$store, () => {
          this.collaborativeEventTreated()
        })
      })
      d3.select('#root-app').on('mousemove', (event) => {
        if (previousMousePosition === null) {
          previousMousePosition = {
            x: event.clientX,
            y: event.clientY 
          }
        }
        const differenceBetweenLastMove = event.clientX - previousMousePosition.x
        if (Math.abs(differenceBetweenLastMove) > this.referenceRadius * 0.05) {
          if (differenceBetweenLastMove < 0) {
            this.indexCurrentTemporalityOption--
          } else {
            this.indexCurrentTemporalityOption++
          }
          const maxScroll = this.$refs['temporality-list'].scrollWidth - this.$refs['temporality-list'].clientWidth
          let newScroll = 80 * (this.indexCurrentTemporalityOption - 1)

          if (newScroll < 0) {
            newScroll = 0
            this.indexCurrentTemporalityOption = 1
          } else if (newScroll > maxScroll) {
            newScroll = maxScroll
            this.indexCurrentTemporalityOption = this.temporalities.length - 2
          }
          this.sendEvent({
            event: {
              type: 'custom',
              customType: 'eyeTemporalityMove',
            },
            params: {
              indexCurrentTemporalityOption: this.indexCurrentTemporalityOption,
              newScroll: newScroll
            },
            currentTarget: document.getElementById('root-app'),
          })

          previousMousePosition = {
            x: event.clientX,
            y: event.clientY 
          }
  
          d3.select(this.$refs['temporality-list'])
            .transition()
            .duration(D3Animation.TEMPORALITY_MENU_MOVE_SELECTION)
            .tween('test', this.scrollLeftTween(newScroll))
        }
      })
      
      d3.select('#root-app').on('eyeTemporalityMove', event => {
        if (event.isTrusted === false) {
          this.sendEvent({event: event})
        }

        this.indexCurrentTemporalityOption = event.detail.indexCurrentTemporalityOption
        d3.select(this.$refs['temporality-list'])
          .transition()
          .duration(D3Animation.TEMPORALITY_MENU_MOVE_SELECTION)
          .tween('test', this.scrollLeftTween(event.detail.newScroll))
        this.collaborativeEventTreated()
      })
    }
  }
}
</script>

<style scoped>
.temporality-menu {
  color: var(--color-text);
  position: absolute;
  top: 50px;
  left: 50px;
  z-index: 1;
}

ul {
  background-color: var(--color-bg-1);
  width: 240px;
  display: block;
  overflow-x: hidden;
  overflow-y: hidden;
  white-space: nowrap;
  border-radius: 5px;
  border: none;
  cursor: grab;
  margin: 0px;
  padding: 0px;
  box-shadow: 0 0 30px 4px var(--color-shadow);
}

ul::-webkit-scrollbar {
  display: none;
}

.menu-temporality-option {
  width: 80px;
  display: inline-block;
  text-align: center;
  padding: 0px;
  user-select: none;
  font-size: 1.3rem;
  margin-top: 5px;
  margin-bottom: 5px;
}

.menu-temporality-current-option {
  border-inline: 1px dotted var(--c-gray-2);
  color: var(--color-text);
  opacity: 1;
  width: 78px;
}

.menu-temporality-other-option {
  border-inline: 0px;
  color: var(--color-text);
  opacity: 0.5;
}

</style>