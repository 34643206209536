<template>
  <div class="wrapper flex items-center">
    <label class="switch">
      <input
        :checked="status"
        type="checkbox"
        @click="(e)=>toggle(e)"
      >
      <span class="slider round" />
    </label>
    <span class="toggle-switch-label ml-2">{{ text }}</span>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: "ToggleSwitch",
  props: {
    text: {
      type: String,
      required: true,
    },
    status: {
      type:Boolean,
      required: false,
      default: false
    }
  },
  emits: ["onOff", "update:status"],
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
    }),
    toggle(event) {
      if(event!=null){
        this.sendEvent({event: event}) 
      }
      this.$emit("update:status", !this.status)
      this.$emit("onOff")
      if(event!=null){
        this.collaborativeEventTreated()
      }
    },
  },
};
</script>

<style scoped>
.wrapper {
  display: flex;
  align-items: center;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
  margin: 3px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 8.5px;
}

.slider.round:before {
  border-radius: 50%;
}

.toggle-switch-label {
  font-size: 12px;
}
</style>