<template>
  <div
    v-if="displayOption"
    class="font-manager-div"
  >
    <input
      v-model="value"
      type="range"
      min="10"
      max="200"
      step="0.5"
      @change="onChangeFontSize"
    >
    <span>{{ value }}</span>
  </div>
</template>

<script>
import * as mutationTypes from "@/store/mutations-types.js";
import { mapGetters } from "vuex";

/**
 * Ce composant est un composant de test permettant d'ajuster la taille de tout les textes de l'application
 * @displayName Composant fontManager
 */
export default {
  name: "FontManager",
  props: {
    /**
     * Détermine si le slider permettant la modification de la taille des textes doit-être affiché
     * @type {Boolean}
     */
    displayOption: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    value: localStorage.getItem("ratioRemPx") ?localStorage.getItem('ratioRemPx'): 62.5,
  }),
  computed: {
    ...mapGetters({
      ratioRemPx: "ratioRemPx",
    }),
  },
  watch: {
    value() {
      this.setRoot();
    },
    ratioRemPx(newValue){
      this.value = newValue
    }
  },
  mounted() {
    this.setRoot();
    this.onChangeFontSize();
  },
  methods: {
    /**
     * Permet la modification du ratio rem / px dans le style de la page
     * @method
     * @public
     */
    setRoot() {
      this.root = document.documentElement;
      this.root.style.fontSize = `${this.value}%`;
      getComputedStyle(this.root);
    },
    /**
     * Permet de sauvegarder la taille de police selectionnée dans le store
     * @method
     * @public
     */
    onChangeFontSize() {
      this.$store.commit(mutationTypes.SET_RATIO_REM_PX, this.value);
    },
  },
};
</script>

<style>
.font-manager-div {
  font-size: 16px;
  align-content: center;
  position: absolute;
  top: 0;
  left: 0;
}
</style>