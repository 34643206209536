<template>
  <div>
    <div
      class="notifications-container"
    >
      <TransitionGroup
        name="notification"
        tag="div"
      >
        <div
          v-for="notification in notifications"
          :key="notification.id"
          class="notification"
          :class="notification.type"
        >
          {{ notification.message }}
          <button
            @click="removeNotification(notification.id)"
          >
            X
          </button>
        </div>
      </TransitionGroup>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EyeNotification'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const notifications = computed(() => store.getters['notification/notifications'])

const removeNotification = (id) => {
  store.commit(`notification/${mutationTypes.REMOVE_NOTIFICATION}`, id)
}
</script>

<style scoped>
.notifications-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.notification {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: opacity 0.3s, transform 0.3s;
  width: 300px;
}

.notification.success {
  background-color: #4caf50;
  color: white;
}

.notification.error {
  background-color: #f44336;
  color: white;
}

.notification.info {
  background-color: #2196f3;
  color: white;
}

.notification button {
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

/* Transition classes */
.notification-move
.notification-enter-active,
.notification-leave-active {
  position: fixed;
  transition: all 0.5s ease;
}

.notification-enter-from,
.notification-leave-to {
  opacity: 0;
  transform: translateX(100px);
}
</style>