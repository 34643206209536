import store from '@/store/index.js'
import CircleUtility from '@/libraries/CircleUtility.js'
import circlesConfig from '@/config/circle.js'
import utils from './utils.js'
import * as d3 from 'd3'
import * as mutationTypes from '@/store/mutations-types.js'
import _ from 'lodash'

class Circles {
  constructor() {
    this.currentTransition = null
  }

  setDefaultStyleCircles(selection, isTransition) {
    selection = selection.attr("d", (d) => d.path)
      .style("fill", "none")

    if (isTransition) {
      selection = selection
        .tween("style.stroke", (d, i, nodes) => {
          let base = nodes[i].style.stroke
          let next = (d.colorDoubleLine) ? d.colorDoubleLine.color : circlesConfig[d.configIndexBounded].color
          const interpolator = utils.getRGBInterpolatorCssVar(base, next)

          return (t) => {
            nodes[i].style.stroke = (t === 1)
              ? d.colorDoubleLine
                ? d.colorDoubleLine.color
                : circlesConfig[d.configIndexBounded].color //remettre dans le style, directement la variable css et non pas juste la valeur hex ou rgb pour que le changement de couleur continue de se faire dynamiquement lorsque l'utilisateur change de theme (sombre / clair)
              : interpolator(t);
          }
        })
    }

    selection = selection
      .style("stroke-width", (d) => circlesConfig[d.configIndexBounded].stroke)
      .style("opacity", (d) => (d.colorDoubleLine) ? d.colorDoubleLine.opacity : circlesConfig[d.configIndexBounded].opacity)
  }

  /**
   * Cette fonction permet l'affichage / rafraichissement des cercles du dossier patient à l'écran
   * @param {Object[]} paths Tableau d'objet contenant les path svg de chaque portion des cercles
   * @param {Boolean} interactions Détermine si les intéractions sont actives sur les cercles
   * @param {Number} animationTime Durée de l'animation 
   * @param {D3Transition} transition Optionel, transition custom pouvant être fournis en paramètre pour le rafraichissement des cercles
   */
  async displayCircles({paths, interactions, animationTime, transition}) {
    const animationGiven = transition !== undefined
    if (!transition) {
      transition = (selection, animationTime) => {
        this.currentTransition = selection
          .transition()
          .duration(animationTime)
          .call(this.setDefaultStyleCircles, true)
      }
    }

    const groupedPaths = _.groupBy(paths, d => d.idSection)

    d3.select('#displayCircle')
      .selectAll('g')
      .data(Object.keys(groupedPaths))
      .join('g')
      .attr('id', (d) => `section-container-${d}`)

    for (const [key, value] of Object.entries(groupedPaths)) {
      let group = d3.select(`#section-container-${key}`)

      group
        .selectAll('.circle')
        .data(value)
        .join('path')
        .attr('class', 'circle')
        .attr('stroke-dasharray', (d) => d.circleData.futur ? '10,10' : '')
    }

    const circleTransitions = d3.select("#displayCircle")
      .selectAll(".circle")
      .attr("class", "circle")

    if (animationGiven === false && animationTime === 0) {
      circleTransitions.call(this.setDefaultStyleCircles, false)
    } else {
      circleTransitions.call(transition, animationTime)
    }

    d3.select('#invisible-circles-container')
      .selectAll(".invisible-custom-circle")
      .data((interactions) ? paths : [])
      .join('path')
      .attr("class", "invisible-custom-circle")
      .on("click", null)
      .on("mouseover", null)
      .on("mouseleave", null)
      .on("mousedown", null)
      .attr("d", (d) => d.path)
      .style("stroke-width", (d) => [0,1].includes(d.configIndexBounded) ? '0px' : '8px' )
      //Stroke-width à 0 pour empecher que les cercles trop petit avec une opacité de 0 bloque l'animation mouseover du cercle bleu de la pie

    //Atribution des actions sur les cercles invisibles des cercles visibles à l'écran
    d3.select('#invisible-circles-container')
      .selectAll(".invisible-custom-circle")
      .filter((d) => CircleUtility.isVisibleCircle(d.circleData.configIndex))
      .on("click", this.onClick)
      .on("mouseover", this.onMouseOver)
      .on("mouseleave", this.onMouseLeave)
      .on("mousedown", this.onMouseDown)

    if (this.currentTransition !== null) {
      try {
        await this.currentTransition.end()
      } catch {}
      this.currentTransition = null
      return
    } else {
      return
    }
  }

  /**
   * Cette fonction est appelée lorsque l'utilisateur effectue un clique sur l'un des cercles. Elle a pour rôle d'initier un changement de cercle principal. Le cercle cliqué devient le cercle principal.
   * @method
   * @public
   * @property {event} event Il s'agit de l'événement fournis au listener
   * @property {EyeCircle} d Il s'agit des données associées au cercle
   */
  async onClick(event, d) {
    store.dispatch('ws/sendEvent', { event: event })
    await store.dispatch('circle/changeMainCircle', { selectedCircleData: d.circleData })
    utils.onCircleMoveAnimationEnd(store, () => {
      store.dispatch('ws/collaborativeEventTreated')
    })
  }

  /**
   * Cette fonction est appelé lorsque l'utilisateur survol un cercle avec son pointeur
   * @method
   * @public
   * @property {event} event Il s'agit de l'événement fournis au listener
   * @property {EyeCircle} d Il s'agit des données associées au cercle
   */
  onMouseOver(event, d) {
    store.dispatch('ws/sendEvent', { event: event})
    store.commit(`circle/${mutationTypes.SET_HOVERED_CIRCLE}`, d.circleData)
    d3.select("#displayCircle")
      .selectAll(".circle")
      .filter((path) => path.circleData.id === d.circleData.id)
      .style("stroke-width", "3px")
      .style("opacity", 1)

    store.dispatch('ws/collaborativeEventTreated')
  }

  /**
   * Cette fonction est appelé lorsque l'utilisateur arrête de survoler un cercle avec son pointeur
   * @method
   * @public
   * @property {event} event Il s'agit de l'événement fournis au listener
   * @property {EyeCircle} d Il s'agit des données associées au cercle
   */
  onMouseLeave(event, d) {
    store.dispatch('ws/sendEvent', { event: event })
    store.commit(`circle/${mutationTypes.SET_HOVERED_CIRCLE}`, null)
    d3.select("#displayCircle")
      .selectAll(".circle")
      .filter((path) => path.circleData.id === d.circleData.id)
      .style("stroke-width", circlesConfig[d.configIndexBounded].stroke)
      .style("opacity", (d) => d.colorDoubleLine ? d.colorDoubleLine.opacity : circlesConfig[d.configIndexBounded].opacity)

    store.dispatch('ws/collaborativeEventTreated')
  }

  /**
   * Lorsque l'utilisateur laisse son clique gauche enfoncé pendant une petite durée puis fait un mouvement de souris, le menu de changement de temporalité depuis ce cercle s'ouvre
   * @method
   * @public
   * @property {event} event Il s'agit de l'événement fournis au listener
   * @property {EyeCircle} d Il s'agit des données associées au cercle
   */
  onMouseDown(event, d) {
    store.dispatch('ws/sendEvent', {
      event: event,
      params: CircleUtility.eyePointRadial(event.clientX, event.clientY, store.getters['layout/centerX'], store.getters['layout/centerY'], store.getters['layout/radius'])
    })
    const timer = setTimeout(
      (ev) => {
        // Désactiver l'événement click pour ne pas que le listener se déclenche dans le même que le mouseup
        d3.select("#invisible-circles-container")
          .selectAll(".invisible-custom-circle")
          .on("click", null)

        d3.select('#root-app').on("mousemove", (e) => {
          store.dispatch('ws/sendEvent', { event: e })
          d3.select('#root-app').on("mousemove", null);
          store.commit(
            `layout/${mutationTypes.SET_DISPLAYED_TEMPORALITY_MENU}`,
            true
          );
          store.commit(
            `layout/${mutationTypes.UPDATE_TEMPORALITY_MENU_POS}`,
            {
              x: ev.clientX - 120,
              y: ev.clientY - 70,
            }
          );
          store.commit(
            `circle/${mutationTypes.SET_TEMPORALITY_SELECTED_CIRCLE}`,
            d.circleData
          );
          store.dispatch('ws/collaborativeEventTreated')
        });
      },
      200,
      event
    );

    d3.select(window).on("mouseup", (e) => {
      store.dispatch('ws/sendEvent', {
        event: e,
        window: true
      })
      clearTimeout(timer)
      d3.select('#root-app').on("mousemove", null)
      store.dispatch('ws/collaborativeEventTreated')
    });
    store.dispatch('ws/collaborativeEventTreated')
  }

  interruptAnimation() {
    this.currentTransition = null
    d3.select("#displayCircle")
      .selectAll(".circle")
      .interrupt()
  }
}

export default new Circles()