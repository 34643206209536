import * as d3 from 'd3'
import { computed, watch } from 'vue'
import { useStore } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'

export function useE2e() {
  const store = useStore()
  const userData = computed(() => store.getters['user/userData'])
  const wsSocket = computed(() => store.getters['ws/wsSocket'])
  const testName = computed(() => store.getters['e2eTesting/testName'])
  const idxScreenshot = computed(() => store.getters['e2eTesting/idxScreenshot'])

  watch(userData, () => {
    setListener()
  })

  setListener()

  function setListener() {
    if (userData.value.Role !== undefined && userData.value.Role.includes(6)) {
      d3.select(document.body)
        .on('keyup.e2e', onKeyUpe2e)
    }
  }

  async function onKeyUpe2e(event) {
    if (event.key === 'Control') {
      store.dispatch('ws/sendEvent', {
        event: event,
        params: {
          key: event.key
        }
      })
      if (event.isTrusted === true) {
        wsSocket.value.emit('collaborative:robot:screenshot', {
          data: null,
          roomId: 'Test',
          idxScreenshot: idxScreenshot.value,
          testName: testName.value
        })
      } else {
        try {
          const stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
              displaySurface: "browser",
            },
            preferCurrentTab: true
          })
          const track = stream.getVideoTracks()[0]
          let imageCapture = new ImageCapture(track)
          const bitmap = await imageCapture.grabFrame()
          track.stop()

          const canvas = document.createElement('canvas')
          canvas.width = bitmap.width
          canvas.height = bitmap.height
          const context = canvas.getContext('2d')
          context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height)
          const image = canvas.toDataURL('image/png')
          canvas.remove()
          wsSocket.value.emit('collaborative:robot:screenshot', {
            data: image,
            roomId: 'Test',
            idxScreenshot: idxScreenshot.value,
            testName: testName.value
          })
          store.commit(`e2eTesting/${mutationTypes.E2E_SET_IDX_SCREENSHOT}`, idxScreenshot.value + 1)
        } catch (err) {
          console.error(err)
        }
      }
      store.dispatch('ws/collaborativeEventTreated')
    }
  }

  return {}
}