<template>
  <g
    id="group-lines"
    ref="group-lines"
  />
</template>

<script>
import MixinLines from '@/components/event/MixinLines.js'
import { mapGetters } from 'vuex'

export default {
  name: 'EventFiltering',
  mixins: [MixinLines],
  data: () => ({
    /**
     * Il s'agit de l'id qui sera utilisé pour le groupe contenant les lignes de filtrages
     * @type {String}
     */
    idGroupLines: 'group-lines'
  }),
  computed: {
    ...mapGetters({
      filteredEvents: 'event/common/filteredEvents',
    }),
    /**
     * Détermine si les événements doivent être affichés
     * @type {Boolean}
     */
    isDisplayEvents() {
      return this.$store.state.event.common.displayEvents;
    },
  },
  watch: {
    isDisplayEvents() {
      if (this.isDisplayEvents) {
        this.showFilters(this.filteredEvents)
      } else {
        this.removeFiltering()
      }
    },
    filteredEvents() {
      if (this.isDisplayEvents) {
        this.showFilters(this.filteredEvents)
      }
    }
  }
}
</script>

<style>

.trace-filtering {
  stroke-width: 1.5px;
  fill: none;
  opacity: 1;
  pointer-events: none;
}

</style>