<template>
  <ListBase
    ref="list-base"
    title="Vues"
  >
    <template #content>
      <ContextMenu
        v-if="menuConfig.show"
        :x="menuConfig.x"
        :y="menuConfig.y"
        :items="menuConfig.items"
        :z-index="5"
        @close="onCloseContextMenu"
      />
      <ul
        id="item-list"
        :class="{'list-borderList': !onDeleteMode, 'list-borderList list-deleteUL': onDeleteMode}"
      >
        <li
          v-for="(view) in patientViews"
          :key="view.Id"
          :class="{ selected: view.selected, 'list-normal': !onDeleteMode, 'list-delete': onDeleteMode }"
          data-selected="false"
          @contextmenu.prevent="(e) => handleRightClick(e, view)"
          @click="(e)=>toggleSelection(e,view)"
        >
          <span
            v-if="onDeleteMode" 
            :class="bulletClass" 
          />
          <div class="viewInfo">
            <div v-if="!view.isEditing">
              <p
                class="cursor-pointer" 
                @click="(e) => onShowView(e, view.Id)" 
              >
                {{ `Vue : ${view.Name}` }}
              </p>
            </div>
            <div 
              v-if="view.isEditing" 
              class="viewRename"
            >
              <span>Vue : </span>
              <textarea
                ref="editableDiv"
                v-model="view.Name"
                class="divtext" 
                type="text"
                maxlength="50"
                style="overflow:hidden"
                rows="1"
                @keydown="(e) => handleKeyDown(e)" 
                @keyup.enter="(e) => onPutViewName(e, view)" 
                @input="(e)=>onInput(e, view.Name)"
                @focus="(e)=>autoResize(e)"
                @blur="(e) => onBlur(e)"
              />
            </div>
            <p>
              {{ view.CreationDate.slice(0, 10) }}
            </p>
          </div>
        </li>
      </ul>
    </template>
  </ListBase>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import ContextMenu from "@/components/ContextMenu.vue"
import menuTypes from '@/enums/menu_types.js'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  name: 'ListPatientViews',
  components: {
    ListBase,
    ContextMenu
  },
  data: () => ({
    /**
     * Configuration du menu contextuel s'affichant lors d'un clique droit sur le bouton
     * @type EyeContextMenu
     */
    menuConfig: {
      show: false,
      x: 0,
      y: 0,
      items: []
    },
    item: {},
    selectedView: {},
    editingViewId: null
  }),
  computed: {
    ...mapGetters({
      patientViews: 'view/patientViews',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      onDeleteMode: "view/onDeleteMode",
      isDisplayedList: 'borderList/isDisplayedList'
    }),
    bulletClass() {
      return {
        bullet: true,
        circle: this.onDeleteMode
      };
    }
  },
  watch: {
    async currentDisplayedBorderList() {
      if (this.currentDisplayedBorderList === borderListTypes.LIST_PATIENT_VIEWS){
        await this.getAllViews()
      }
    },
    async onDeleteMode(){
      for (const id of Object.keys(this.patientViews)) {
        this.patientViews[id].selected = false
      }
    },
    async isDisplayedList(){
      if(this.isDisplayedList == false){
        this.$store.commit(`view/${mutationTypes.SET_DELETE_MODE}`, false)
      }
    }
  },
  mounted() {
    this.menuConfig.items = [
      {
        id: 0,
        label: "Renommer",
        labelId: "PUT",
        type: menuTypes.TEXT,
        selected: false,
        click: () => {
          if(this.editingViewId != null && this.patientViews[this.editingViewId] != undefined && this.patientViews[this.editingViewId].isEditing == true){
            this.patientViews[this.editingViewId].isEditing = false
          }

          this.item.isEditing = true
          this.editingViewId = this.item.Id
        }
      },
      {
        id: 1,
        label: "Supprimer",
        labelId: "DELETE",
        type: menuTypes.TEXT,
        selected: false,
        click: async () => {
          await this.deleteViews([this.item.Id])
          await this.getAllViews()
        }
      }
    ]
  },
  unmounted() {
    this.$store.commit(`view/${mutationTypes.SET_DELETE_MODE}`, false)
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getAllViews: 'view/getAllViews',
      showView: 'view/showView',
      deleteViews: 'view/deleteViews',
      putViewName: 'view/putViewName',
    }),
    onCloseContextMenu() {
      this.menuConfig.show = false
    },
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    async onShowView(event, indexView) {
      if(this.onDeleteMode == true){
        return
      }else{
        this.sendEvent({ event: event })
        this.showView(indexView)
        this.collaborativeEventTreated()
      }
    },
    handleRightClick(event, item) {
      if(this.onDeleteMode == true){
        return
      }
      this.sendEvent({ event: event })
      event.stopPropagation()
      event.preventDefault()

      this.menuConfig.x = event.clientX
      this.menuConfig.y = event.clientY
      this.item = item

      this.menuConfig.show = !this.menuConfig.show
      //Faire un nextTick et getBoundingRect sur le composant du menuContextuel ou voir avec une ref si moyen de choper la taille
      this.collaborativeEventTreated()
    },
    onPutViewName(event, view){
      this.sendEvent({ event: event, params: {key:'enter'}})
      event.stopPropagation()
      event.preventDefault()
      this.item.isEditing = false
      this.putViewName(view)
      this.collaborativeEventTreated()
    },
    toggleSelection(event,view) {
      this.sendEvent({event:event})
      view.selected = !view.selected
      this.collaborativeEventTreated()
    },
    handleKeyDown(event) {
      this.sendEvent({
        event: event,
        params: {
          key: event.key
        }
      })
      if (event.key === 'Enter') {
        event.preventDefault()
      }else{
        this.autoResize(null)
      }
      this.collaborativeEventTreated()
    },
    autoResize(event) {
      if(event!= null){
        this.sendEvent({event:event})
      }
      const textarea = this.$refs.editableDiv[0]
      textarea.style.height = "auto"
      textarea.style.height = textarea.scrollHeight + 'px'
      if(event!= null){
        this.collaborativeEventTreated()
      }
    },
    onBlur(event){
      const textarea = this.$refs.editableDiv[0]
      if (textarea === undefined) {
        return
      }

      textarea.style.height = 'auto'

      if (event.target.value != '') {
        textarea.style.height = textarea.scrollHeight - 7 + 'px' 
      }
    },
    onInput(event, viewName){
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: viewName
          }
        })
      } else {
        this.sendEvent({event: event})
      }
      if(viewName == ''){
        this.onBlur(event)
      }
      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>

.list-deleteUL {
  padding-left: 0px;
}

#item-list li {
  cursor: pointer;
}

#item-list li.list-normal {
  list-style-type: disc;
  margin-bottom: 10px;
}
#item-list li.list-delete {
  list-style-type: none;
  display: flex;
  margin-bottom: 10px;
  padding-right: 20px;
}

.bullet {
  display: inline-block;
  top: 0; 
  width: 8px;
  height: 8px;
  border: 1.5px solid var(--color-text);
  margin-right: 4px;
}

.circle {
  border-radius: 50%;
  background-color: transparent;
}

#item-list li.selected .bullet {
  background-color: var(--color-text); 
}

.viewRename {
  display: flex;
  flex-direction: column;
}

.viewInfo {
  overflow: hidden; 
  white-space: pre-wrap;
  word-wrap: break-word;
}

.divtext {
    border: ridge 2px;
    padding: 5px;
    margin: 5px;
    max-width: 250px;
    min-height: 15px;
    height: auto;
    background: transparent;
    color: var(--color-text);
}

/* C'est pas bien de modifier une classe déjà utilisée par tailwind */
.cursor-pointer{
  overflow: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-bottom: 2px;
}
</style>