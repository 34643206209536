import { computed } from 'vue'

export function useTodayDate() {
  const todayDate = computed(() => {
    return new Date().toISOString().split('T')[0]
  })

  return {
    todayDate
  }
}