export default {
  "COVID": 1,
  "HASBLED": 2,
  "CHADSVASC": 3,
  "EUROSCORE2": 4,
  "IMC": 5,
  "DESIR": 6,
  "FINDRISC": 7,
  "WELCOOP_ALERTE": 9,
  "QOR0": 10,
  "QOR1": 11,
  "QOR2": 12,
  "QOR3": 13,
  "QOR4": 14
}