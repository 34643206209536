import EyeFetch from "@/libraries/EyeFetch"
import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

const getDefaultState = () => ({
  record: process.env.VUE_APP_RECORD_SESSION === "true",
  queueRecordEvents: [],
  recordRoomId: "",
  recordChunkInterval: null,
  isRecording: false,
  secondsElapsed: 0,
  startRecordDate: null,
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    record: state => state.record,
    isRecording: state => state.isRecording,
    recordRoomId: state => state.recordRoomId
  },
  mutations: {
    [mutationTypes.SET_RECORD_STATUS] (state, payload) {
      state.record = payload
    },
    [mutationTypes.SET_RECORD_SECONDS_ELAPSED] (state, payload) {
      state.secondsElapsed = payload
    },
    [mutationTypes.SET_START_RECORD_DATE] (state, payload) {
      state.startRecordDate = payload
    },
    [mutationTypes.SET_QUEUE_RECORD_EVENTS] (state, payload) {
      state.queueRecordEvents = payload
    },
    [mutationTypes.ADD_ELEM_QUEUE_RECORD_EVENTS] (state, payload) {
      state.queueRecordEvents.push(payload)
    },
    [mutationTypes.SET_IS_RECORDING] (state, payload) {
      state.isRecording = payload
    },
    [mutationTypes.SET_RECORD_CHUNK_INTERVAL] (state, payload) {
      state.recordChunkInterval = payload
    },
    [mutationTypes.SET_RECORD_ROOM_ID] (state, payload) {
      state.recordRoomId = payload
    }
  },
  actions: {
    async startRecording(context) {
      if (context.state.record === true && context.state.recordRoomId === "" && context.rootState.user.userData.Role.includes(8)) {
        context.commit(mutationTypes.SET_RECORD_SECONDS_ELAPSED, 0)
        context.commit(mutationTypes.SET_IS_RECORDING, true)
        context.commit(mutationTypes.SET_START_RECORD_DATE, performance.now())
        context.dispatch('ws/createRoom', { isRecordEvent: true }, {root: true})
        const interval = setInterval(() => {
          context.commit(mutationTypes.SET_RECORD_SECONDS_ELAPSED, context.state.secondsElapsed + 10)
          context.dispatch('sendRecordChunk')
          context.dispatch('ws/sendPresenterState', { idSocket: null, reload: reloadDataTypes.TEMPORALITY, isRecordEvent: true }, {root: true})
        }, 10000)
        context.commit(mutationTypes.SET_RECORD_CHUNK_INTERVAL, interval)
      }
    },
    stopRecording(context) {
      if (context.getters.isRecording === true) {
        clearInterval(context.state.recordChunkInterval)
        context.commit(mutationTypes.SET_RECORD_CHUNK_INTERVAL, null)
        context.dispatch('sendRecordChunk')
        context.rootState.ws.socket.emit('recording:stop', { idRecordRoom: context.getters.recordRoomId })
        context.commit(mutationTypes.SET_RECORD_ROOM_ID, '')
        context.commit(mutationTypes.SET_IS_RECORDING, false)
      }
    },
    //Lorsque l'utilisateur quitte le navigateur, cette fonction n'est pas utilisé, cela ne fonctionne pas car elle est asynchrone. A la place, la route api est appelé avec sendBeacon
    async sendRecordChunk(context) {
      const body = {
        recordEvents: context.state.queueRecordEvents
      }
      context.commit(mutationTypes.SET_QUEUE_RECORD_EVENTS, [])

      try {
        await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/user/record/chunk/${context.state.recordRoomId}`,
          {
            method: "POST",
            credentials: 'include',
            body: JSON.stringify(body),
            keepalive: true
          }
        )
      } catch (err) {
        console.log(err)
      }
    },
    getElapsedMs(context) {
      if (context.state.startRecordDate !== null) {
        return performance.now() - context.state.startRecordDate
      } else {
        return 0
      }
    },
  }
}