<template>
  <div id="record-replay-container">
    <ProgressBar />
    <div class="flex justify-center">
      <button
        v-if="isPlayingReplay"
        @click="changeStatePlaying"
      >
        Pause
      </button>
      <button
        v-else
        @click="changeStatePlaying"
      >
        Play
      </button>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import ProgressBar from '@/components/record/ProgressBar.vue'
import {computed} from 'vue'

const store = useStore()
const isPlayingReplay = computed(() => store.getters['record/replay/isPlayingReplay'])

function changeStatePlaying() {
  if (isPlayingReplay.value === true) {
    store.dispatch('record/replay/pauseReplay')
  } else {
    store.dispatch('record/replay/playReplay')
  }
}
</script>

<script>
export default {
  name: 'RecordReplay'
}
</script>

<style scoped>
#record-replay-container {
  position: absolute;
  width: calc(100vw - 250px);
  left: 125px;
  right: 125px;
  bottom: 20px;
  background-color: var(--color-bg-1);
  z-index: 501;
}
</style>