<template>
  <div>
    <div v-if="isInPopulational">
      <h1 class="patient-info_name">
        Mode Populationnel
      </h1>
    </div>
    <div v-else-if="isAssociatedPatient">
      <p class="patient-birthdate">
        {{ sex }}, {{ birthdate }}, {{ age }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { userParserPatientData } from '@/components/hud/main/patientParser.js'
import { computed } from 'vue'
import { useStore } from 'vuex'

import * as eyediagMode from '@/shared/enums/eyediagMode.js'

const store = useStore()
const {
  sex,
  birthdate,
  age
} = userParserPatientData()

const selectedEyediagMode = computed(() => store.getters.eyediagMode)
const isInPopulational = computed(() => selectedEyediagMode.value.type === eyediagMode.mode.POPULATIONAL)
const isAssociatedPatient = computed(() => selectedEyediagMode.value.type === eyediagMode.mode.PATIENT && selectedEyediagMode.value.options.cohortBase)

</script>

<script>
export default {
  name: 'MainHudPopulational',
}
</script>