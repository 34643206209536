<template>
  <GenericModal
    :id="id"
    :title="title"
    :buttons="buttons"
    :description="description"
    theme="success"
    @close="onCancel"
  >
    <div class="wrapper">
      <ul class="flex flex-wrap list-none">
        <li
          v-for="(column, index) in columnsObj"
          :key="index"
          :class="`${column.isClicked ? 'selected' : ''}`"
          @click="(e) => selectColumn(e, index)"
        >
          {{ column.name }}
        </li>
      </ul>
      <ToggleSwitch
        v-model:status="keepEmptiesSections"
        class="m-8"
        text="Inclure les sections vides"
      />
    </div>
  </GenericModal>
</template>

<script>
import GenericModal from "../modal/GenericModal.vue";
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "@/store/mutations-types.js";
import reloadDataTypes from "@/shared/enums/reload_data_types.js";
import ToggleSwitch from "../ToggleSwitch.vue";

export default {
  name: "ModalChoseSection",
  components: {
    GenericModal,
    ToggleSwitch,
  },
  data() {
    return {
      id: "modal-chose-section",
      buttons: [],
      description: "Choisir la colonne pour les sous catégories",
      isColumnSelected: false,
      columnsObj: null,
      keepEmptiesSections : false
    };
  },
  computed: {
    ...mapGetters({
      isChoseSectionModalOpen: "isChoseSectionModalOpen",
      columns: "columnsFile",
      mode: "eyediagMode",
    }),
    title() {
      return "Sous catégories";
    },
  },
  watch: {
    isChoseSectionModalOpen(newVal) {
      if (newVal) {
        const elem = document.getElementById(this.id);
        elem.show();
      }
    },
  },
  mounted() {
    this.setButtons();

    if (this.columns) {
      this.columnsObj = this.columns.map((elt) => {
        const obj = {};
        obj.name = elt;
        obj.isClicked = false;
        return obj;
      });
    }
  },
  methods: {
    ...mapActions({
      getDataRepresentation: "circle/getDataRepresentation",
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    resetColumns(){
      this.columnsObj.forEach(elt => {
        elt.isClicked = false
      });
    },
    setButtons() {
      this.buttons = [
        {
          label: "Valider",
          backgroundColorTheme: "success",
          color: "white",
          onClick: this.onConfirm,
        },
        {
          label: "Annuler",
          backgroundColorTheme: "error",
          color: "white",
          onClick: this.onCancel,
        },
      ];
    },
    onConfirm(event) {
      event.preventDefault();
      if (!this.isColumnSelected) return;
      this.sendEvent({event: event})

      const historySections = this.$store.getters["historySections"];
      historySections[historySections.length - 1].sections.forEach(s => {
        s.asSection = this.column
      })
      historySections[historySections.length - 1].keepEmptiesSections = this.keepEmptiesSections;
      const elem = document.getElementById(this.id);
      this.resetColumns()
      elem.close();

      this.$store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false);
      this.$store.commit(mutationTypes.SET_HISTORY_SECTIONS, historySections);
      this.getDataRepresentation({
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION,
      });
      this.$store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false);
      this.collaborativeEventTreated()
    },
    onCancel(event) {
      this.sendEvent({event: event})
      const elem = document.getElementById(this.id);
      this.resetColumns()
      elem.close();
      this.$store.commit(`${mutationTypes.OPEN_COLUMNS_MODAL}`, false);
      this.collaborativeEventTreated()
    },
    selectColumn(event, index) {
      this.sendEvent({event: event})
      this.isColumnSelected = true;

      //On s'assure qu'il n'on puisse pas selection deux case en même temps.
      let toFalse = this.columnsObj.findIndex((elt) => elt.isClicked === true);
      this.column = event.target.innerText;
      this.columnsObj[index].isClicked = true;

      if (toFalse !== -1) this.columnsObj[toFalse].isClicked = false;
      this.$store.commit(`${mutationTypes.SET_AS_SECTION}`, this.column);
      this.collaborativeEventTreated()
    },
  },
};
</script>
<style scoped>
.wrapper {
  margin-bottom: 15px;
}
li {
  padding: 5px;
  margin: 5px;
  list-style-type: none;
  background: var(--c-gray-soft-1);
}
ul {
  columns: 3;
}
.selected {
  background: rgba(128, 128, 128, 0.8);
}
</style>
