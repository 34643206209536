<template>
  <svg
    id="Layer_1"
    ref="padLock"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20px"
    height="20px"
    viewBox="0 0 50 60"
    xml:space="preserve"
  >
    <g
      ref="bottomPadLock"
      class="bottomPadLock"
    >
      <path
        :style="`fill:none;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;`"
        :stroke="color"
        d="M9,49c-1.1,0-2-0.9-2-2V23c0-1.1,0.9-2,2-2h32c1.1,0,2,0.9,2,2v24c0,1.1-0.9,2-2,2H9z"
      />
      <path
        :fill="color"
        d="M28,33c0-1.7-1.3-3-3-3s-3,1.3-3,3c0,0.9,0.4,1.7,1,2.2V38c0,1.1,0.9,2,2,2s2-0.9,2-2v-2.8C27.6,34.7,28,33.9,28,33z"
      />
    </g>
    <path
      :style="`fill:none;stroke-width:3;stroke-linecap:round;stroke-miterlimit:10;`"
      :stroke="color"
      d="M36,21c0,0,0-4.9,0-6c0-6.1-4.9-11-11-11S14,8.9,14,15c0,1.1,0,6,0,6"
    />
  </svg>
</template>

<script>
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import { mapActions } from 'vuex'

export default {
  name: 'PadLockIcon',
  props: {
    color: {
      type: String,
      default: 'var(--color-text)'
    }
  },
  mounted() {
    this.initAnimation()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    initAnimation() {
      d3.select(this.$refs.padLock)
        .on('mouseover', (event) => {
          this.sendEvent({ event: event })
          d3.select(this.$refs.bottomPadLock)
            .transition()
            .duration(D3Animation.ANIMATION_PADLOCK_ICON)
            .style('transform', 'rotate(10deg)')
          this.collaborativeEventTreated()
        })
        .on('mouseleave', (event) => {
          this.sendEvent({ event: event })
          d3.select(this.$refs.bottomPadLock)
            .transition()
            .duration(D3Animation.ANIMATION_PADLOCK_ICON)
            .style('transform', 'rotate(0deg)')
          this.collaborativeEventTreated()
        })
    }
  }
}
</script>

<style>

.bottomPadLock {
  transform-origin: top left;
}
</style>