import * as eyediagMode from '@/shared/enums/eyediagMode.js'

export default {
  'SECTION': {
    typeMergingName: 'SECTION',
    mergedBy: eyediagMode.populationalOptions.mergedBy.SECTION,
    severity: eyediagMode.populationalOptions.severity.STATS
  },
  'CODE_SEV_DIAG': {
    typeMergingName: 'CODE_SEV_DIAG',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.CODE,
  },
  'CODE_SEV_STATS': {
    typeMergingName: 'CODE_SEV_STATS',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.STATS,
  },
  'CODE_SEV_DIAG_PODIUM': {
    typeMergingName: 'CODE_SEV_DIAG_PODIUM',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.CODE,
    podiumBy: eyediagMode.populationalOptions.podiumBy.ALL_CIRCLES,
  },
  'CODE_SEV_STATS_PODIUM': {
    typeMergingName: 'CODE_SEV_STATS_PODIUM',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.STATS,
    podiumBy: eyediagMode.populationalOptions.podiumBy.ALL_CIRCLES,
  },
  'CODE_SEV_DIAG_PODIUM_PER_CIRCLE': {
    typeMergingName: 'CODE_SEV_DIAG_PODIUM_PER_CIRCLE',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.CODE,
    podiumBy: eyediagMode.populationalOptions.podiumBy.CIRCLE,
  },
  'CODE_SEV_STATS_PODIUM_PER_CIRCLE': {
    typeMergingName: 'CODE_SEV_STATS_PODIUM_PER_CIRCLE',
    mergedBy: eyediagMode.populationalOptions.mergedBy.CODE,
    severity: eyediagMode.populationalOptions.severity.STATS,
    podiumBy: eyediagMode.populationalOptions.podiumBy.CIRCLE,
  }
}