<template>
  <div>
    <div
      ref="tooltip-event-note-container"
      class="tooltip-event-note-container"
    >
      <TooltipForm
        :id-section="tooltipData.Section"
        :tooltip-data="tooltipData"
        :hovered-id-groupe="tooltipData.IdGroupe"
        :hovered-section="null"
      />
    </div>
  </div>
</template>

<script>
import TooltipForm from '../diabetologie/nurseForm/TooltipForm.vue'
import utils from '@/libraries/utils.js'
import * as d3 from 'd3'

export default {
  name: 'TooltipContentNurseEntry',
  components: {
    TooltipForm
  },
  props: {
    /**
     * Contient les informations de l'événement dont le détail doit être affiché en tooltip
     * @type EyeEvent
     */
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  mounted() {
    this.initScrollEmitter()
  },
  methods: {
    /**
     * Permet de contrôler la position de la souris à l'intérieur de la tooltip puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['tooltip-event-note-container'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    },
  }
}
</script>