/**
 * function
 * @param {*} patientData 
 * @returns {Object}
 */

function calcDESIR(patientData) {
  let points = 0

  if (patientData['age50'].display) {
    points += 1
  }

  if (patientData['imc25-29.9'].display) {
    points += 1
  }
  if (patientData['imc30'].display) {
    points += 2
  }

  if (patientData['tourTaille94-102'].display) {
    points += 1
  }
  if (patientData['tourTaille102'].display) {
    points += 2
  }

  if (patientData['hypertension'].display) {
    points += 1
  }

  if (patientData['diabeteGestationnel'].display) {
    points += 1
  }

  if (patientData['glucoseAJeun5.6-6.9'].display) {
    points += 1
  }
  if (patientData['glucoseAJeun7'].display) {
    points += 2
  }

  return {
    scoreAlert: `Desir: ${points} `,
    scoreTitle: 'Desir',
    scoreRes: points,
    //scoreRecom: recom ? `Conseil: ${recom}`: ''
  }
}

function calculFindrisc(patientData) {
  let points = 0

  if (patientData['age45-54'].display) {
    points += 2
  }
  if (patientData['age55-64'].display) {
    points += 3
  }
  if (patientData['age65'].display) {
    points += 4
  }

  if (patientData['imc25-29.9'].display) {
    points += 1
  }
  if (patientData['imc30'].display) {
    points += 2
  }

  if (patientData['tourTaille94-101.9'].display) {
    points += 2
  }
  if (patientData['tourTaille102'].display) {
    points += 3
  }

  if (patientData['exercise30'].display) {
    points += 2
  }

  if (patientData['familyHistory'].display) {
    points += 2
  }

  if (patientData['hypertension'].display) {
    points += 2
  }

  if (patientData['tauxSucreEleve'].display) {
    points += 2
  }

  if (patientData['diabeteGestationnel'].display) {
    points += 2
  }

  return {
    scoreAlert: `Findrisc: ${points} `,
    scoreTitle: 'Findrisc',
    scoreRes: points,
    //scoreRecom: recom ? `Conseil: ${recom}`: ''
  }
}

export {
  calcDESIR,
  calculFindrisc
}