import * as mutationTypes from '@/store/mutations-types.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import EyeFetch from '@/libraries/EyeFetch.js'
import ErrorManager from '@/libraries/ErrorManager.js'

const getDefaultState = () => {
  return {
    /**
     * Tableau comprenant les différents états du store qui ont été sauvegardé
     * @type {EyeState[]}
     */
    states: [],
    patientViews: [],
    onDeleteMode: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    states: state => state.states,
    patientViews: state => state.patientViews,
    onDeleteMode: state => state.onDeleteMode
  },
  mutations: {
    /**
     * Met à jour la liste contenant les identifiants des différents assemblages de filtres créé par l'utilisateur
     * @param {State} state state du module
     * @param {Object[]} payload liste d'objets contenant les identifiants des assemblages créé par l'utilisateur
     */
    [mutationTypes.ADD_VIEW](state, payload) {
      state.states.push(payload)
    },
    [mutationTypes.SET_PATIENT_VIEWS](state, payload) {
      state.patientViews = payload
    },
    [mutationTypes.SET_DELETE_MODE](state,payload){
      state.onDeleteMode = payload
    }
  },
  actions: {
    async showView(context, indexView) {
      try {
        await context.dispatch('resetState', null, {root: true})

        let response = await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/view/${indexView}`,
          {
            method: 'GET',
            credentials: 'include'
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'view/showView', params: indexView})
          return 
        }

        response = await response.json()
  
        await context.dispatch('stateInitCollab', {
          store: JSON.parse(response[0].State),
          reload: reloadDataTypes.TEMPORALITY
        }, { root: true})
  
        let eventsALLTrier = context.rootGetters['event/common/allEvents'].filter((event) => context.rootState.event.common.memorizedEvents.includes(event.id))
  
        context.commit(
          `event/common/${mutationTypes.SET_MEMORIZED_EVENTS}`,
          eventsALLTrier,
          {root : true}
        )
  
        context.dispatch('event/common/generateEvents',null, { root: true})
      } catch (err) {
        ErrorManager.fetchError(err)
      }
    },
    async saveView(context, nameView) {
      try {
        const state = await context.dispatch("getState",null, {root:true})
        state.event.common.memorizedEvents = state.event.common.memorizedEvents.map(objet => objet.id)
        state.borderList.isDisplayedList = true // true pour éviter un bug quan dborlist est enregistrer à false dans les vues : le bug n'est pas gérer par le code aujourd'hui dans le cas ou c'est true pas de problème voir gestion IsDiplayedList
        const response = await EyeFetch(this, 
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/view`,
          {
            method: "POST",
            body: JSON.stringify({
              name: nameView,
              idPatient: context.rootState.patient.patient.id,
              state: JSON.stringify(state),
            }),
            credentials: 'include'
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'view/saveView', params: nameView})
          return 
        }

      } catch (err) {
        ErrorManager.fetchError(err)
      }
    },
    async putViewName(context, view){
      try {
        const response = await EyeFetch(this, 
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/view/update/newname/`,
          {
            method: "PUT",
            body: JSON.stringify({
              idView: view.Id,
              newName: view.Name,
            }),
            credentials: 'include',
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'view/putViewName', params: view})
          return 
        }
      } catch (err) {
        ErrorManager.fetchError(err)
      }
    },
    async getAllViews(context) {
      try {
        const params = new URLSearchParams()
        params.append('idPatient', context.rootState.patient.idPatient)

        let response = await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/view/all?${params}`,
          {
            method: 'GET',
            credentials: 'include'
          }
        )
 
        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'view/getAllViews', params: null})
          return 
        }

        response = await response.json()

        response = Object.keys(response).reduce((acc, key) => {
          const newKey = response[key].Id; // Récupère l'ID pour  comme nouvelle clé
          acc[newKey] = response[key]; // Assigne l'objet à la nouvelle clé
          return acc;
        }, {})

        
        context.commit(mutationTypes.SET_PATIENT_VIEWS, response)
      } catch (err) {
        ErrorManager.fetchError(err)
      }
      
    },
    async setDeleteMode(context, mode){
      context.commit(mutation.SET_DELETE_MODE, mode)
    },
    async deleteViews(context, views){
      try {
        const response = await EyeFetch(this,
          `${process.env.VUE_APP_SERVER_BASE_URL}/patient/view/deleteView`,
          {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify({
              arrayIds : views
            })
          }
        )

        if (!response.ok) {
          ErrorManager.requestError(response, {origin: 'view/deleteViews', params: views})
          return 
        }
      } catch (err) {
        ErrorManager.fetchError(err)
      }
    }
  }
}