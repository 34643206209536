<template>
  <div class="overflow-scroll">
    <table>
      <thead>
        <tr>
          <th
            v-for="(column, index) in columns"
            :key="index"
          >
            {{ column.label }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(periods, code) in sortedEvents"
          :key="code"
          @mouseover="(e) => mouseOverColumn(e, periods)"
          @mouseleave="(e) => mouseLeaveColumn(e, periods)"
        >
          <td
            v-for="(column, idxColumn) in columns"
            :key="idxColumn"
            class="text-left"
          >
            {{ column.f(periods, column.label) }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import _ from 'lodash'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  name: 'TooltipTable',
  props: {
    /**
     * Contient les informations de l'événement dont le détail doit être affiché en tooltip
     * @type {EyeEvent}
     */
    tooltipData: {
      type: Object,
      default: null
    }
  },
  data: () => ({
    columns: [],
    sortedEvents: {}
  }),
  computed: {
    ...mapGetters({
      'events': 'event/common/events',
      'displayedCircles': 'circle/displayedCircles'
    })
  },
  watch: {
    events() {
      this.sortEvents()
      this.generateColumns()
    }
  },
  mounted() {
    this.sortEvents()
    this.generateColumns()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: "ws/collaborativeEventTreated",
    }),
    sortEvents() {
      let eventsSrc = []
      if (this.tooltipData === null) {
        eventsSrc = this.events
      } else {
        eventsSrc = this.events.filter(e => e.code === this.tooltipData.code)
      }

      const sortedEvents = _.groupBy(eventsSrc, d => d.code)
      for (const [code, events] of Object.entries(sortedEvents)) {
        sortedEvents[code] = _.groupBy(events, event => event.circleParent.period.label)
      }
      this.sortedEvents = sortedEvents
    },
    generateColumns() {
      const periods = this.displayedCircles.map(circle => circle.period).reverse()
      if (this.events.length > 0) {
        this.columns = [
          {
            label: 'Evénement',
            f: this.getEventLabel
          }
        ]
        for (const period of periods) {
          this.columns.push({
            label: period.label,
            f: this.getNumberInPeriods
          })
        }
      }
    },
    mouseOverColumn(event, periods) {
      event.stopPropagation()
      this.sendEvent({event: event})
      const years = Object.keys(periods)
      this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, periods[years[years.length - 1]][0], { root: true })
      this.collaborativeEventTreated()
    },
    mouseLeaveColumn(event) {
      event.stopPropagation()
      this.sendEvent({event: event})
      this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, { root: true })
      this.collaborativeEventTreated()
    },
    getEventLabel(periods) {
      const keys = Object.keys(periods)
      //Aucune vérification si periods > 0 et si periods[keys] > 0 car tout est créé avec groupBy. Si rien n'existe groupBy ne peut pas avoir créé ces clés
      const event = periods[keys[0]][0]
      return `${event.code} - ${event.defaultLabel}`
    },
    getNumberInPeriods(periods, period) {
      return periods[period] !== undefined
        ? `${periods[period][0].ranks[0].size}`
        : '0'
    }
  }
}
</script>

<style scoped>
table {
  border: 1px solid var(--color-text);
  border-collapse: collapse;
}
th, td {
  border-right: 1px solid var(--color-text);
}
tr > th:last-child, td:last-child {
  border-right: 0px;
}
tr {
  border-bottom: 1px solid var(--color-text);
}
tbody > tr:last-child {
  border-bottom: 0px;
}
</style>