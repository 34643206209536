import * as mutationTypes from '../../mutations-types.js'
import eventManager from '@/libraries/EventManager.js'
import CircleUtility from '@/libraries/CircleUtility.js'
import EventSelectionTypes from '@/enums/event_selection_types.js'
import MemorizedEventsSortTypes from '@/enums/memorized_events_sort_types.js'
import utils from '@/libraries/utils.js'
import borderListTypes from '@/enums/borderList/borderListTypes.js'

/**
 * Events are the diagrams peaks.
 * It's can be a condition or an acte 
 * and this module just take care or all the interaction about event (like all the little red circle)
 */

const getDefaultState = () => {
  return {
    /**
     * Liste des événements actuellement visible sur la représentation
     * @type {EyeEvent[]}
     */
    events: [],
    /**
     * Liste des symboles (+) devant s'afficher sur la représentation
     * @type {EyeSymbol[]}
     */
    symbols: [],
    /**
     * Filtres appliquées sur les événements visualisés à l'aide d'un filtrage
     * @type {EyeEventFilterOptions}
     */
    eventFilters: {},
    /**
     * Il s'agit des événements repondant aux filtrages sur les événements
     * @type {EyeEventFiltering}
     */
    filteredEvents: {},
    linkedEvents: {
      src: {},
      dest: []
    },
    /**
     * Classement du code des événements. Le classement se fait par rapport au nombre d'occurence de l'événement dans le dossier patient
     * @type {Map<String, Number>}
     */
    antecedentRankEvent: null,
    /**
     * Tableau contenant les événements séléctionné par l'utilisateur
     * @type {EyeEvent[]}
     */
    memorizedEvents: [],
    /**
     * Tableau contenant les événements séléctionné par des fonctionnalité de l'application (par exemple les événements qui sont en bout de filtrage (au bout du trait) ont leur cercle rouge allumé)
     * @type {EyeEvent[]}
     */
    selectedEvents: [],
    /**
     * Evénement survolé par l'utilisateur
     * @type {EyeEvent}
     */
    hoveredEvent: null,
    /**
     * Evénement présent sur le cercle de score et survolé par l'utilsateur
     * @type {EyeEvent}
     */
    hoveredRefEvent: null,
    /**
     * Indique si les zones d'intéractions des événements doivent être affichées
     * @type {Boolean}
     */
    displayEvents: false,
    /**
     * Permet de déterminer si les événements doivent être mémorisé ou non
     * @type {Boolean}
     */
    memorizeEvents: true,
    /**
     * Permet de déterminer si tous les événements du cercle actif doivent être affichés
     * @type {Boolean}
     */
    displayAllActiveCircleEvent: false,
    /**
     * Ce tableau contient la liste des événements dont leur libellé doit être affichés
     * @type {EyeEvent[]}
     */
    labelToDisplay:[],
    /**
     * Ce tableau contient la liste des événements dont leur tooltip doit être affichés
     * @type {EyeEvent[]}
     */
    tooltips: [],
    /**
     * Définit le trie appliqué sur la liste des événements mémorisés
     * @type {Object}
     */
    listMemorizedEventsSort: {
      type: MemorizedEventsSortTypes.SELECTION,
      asc: true,
    },
    /**
     * Définit si les lignes des événements liés par un compte rendu doivent s'afficher
     * @type {Boolean}
     */
    displayedLines: false,
    /**
     * Permet de sauvegarder les tooltips que l'utilisateur à ouverte lorsque celui-ci décide de les masquer. Le tableau sert à ensuite à récupérer ces événements lorsqu'il souhaite réafficher les tooltips
     * @type {EyeEvent[]}
     */
    tooltipsBackUp: [],
    /**
     * Définit si les tooltips doivent être affichés ou non
     * @type {Boolean}
     */
    isTooltipsShown: true,
    /**
     * Cette variable permet seulement d'enclencher un watcher dans le composant tooltip pour réorganiser l'affichage des tooltips
     * @type {Boolean}
     */
    orderTooltips: false
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    tooltips: state => state.tooltips,
    allEvents: (state, getters, rootState) => {
      return eventManager.extractEventsFromCircle(rootState.circle.circles)
    },
    events: state => state.events,
    symbols: state => state.symbols,
    eventFilters: state => state.eventFilters,
    filteredEvents: state => state.filteredEvents,
    memorizedEvents: state => state.memorizedEvents,
    selectedEvents: state => state.selectedEvents,
    hoveredEvent: state => state.hoveredEvent,
    hoveredRefEvent: state => state.hoveredRefEvent,
    displayAllActiveCircleEvent: state => state.displayAllActiveCircleEvent,
    isDisplayedLines: state => state.displayedLines,
    isDisplayedMemorizedEvents: state => state.memorizeEvents,
    listMemorizedEventsSort: state => state.listMemorizedEventsSort,
    tooltips: state => state.tooltips,
    orderTooltips: state => state.orderTooltips,
    labelToDisplay: state => state.labelToDisplay,
    linkedEvents: state => state.linkedEvents
  },
  mutations: {
    /**
     * Permet de définir la liste des événements présent sur les cercles actuellement visibles sur la représentation
     * @param {EyeEvent[]} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_DISPLAYED_EVENTS](state, payload) {
      state.events = [...payload]
    },
    /**
     * Permet de définir la liste des symbols devant être affichée sur la représentation
     * @param {EyeSymbol[]} payload
     * @method
     * @public
     */
    [mutationTypes.SET_SYMBOLS] (state, payload) {
      state.symbols = payload
    },
    /**
     * Permet de définir la liste des événements répondant aux filtrages
     * @param {EyeEventFiltering} payload
     * @method
     * @public
     */
    [mutationTypes.SET_FILTERED_EVENTS](state, payload) {
      state.filteredEvents = payload
    },
    /**
     * Permet de modifier l'état de mémorisation d'un événement
     * @param {EyeEvent} payload
     * @method
     * @public
     */
    [mutationTypes.CHANGE_STATE_MEMORIZED_EVENT](state, payload) {
      const memorizedEvent = state.memorizedEvents.find(event => event.id === payload.id && event.parentSection === payload.parentSection)
      const selectedEvent = state.selectedEvents.find(event => event.id === payload.id && event.parentSection === payload.parentSection)

      if (memorizedEvent) {
        state.memorizedEvents = state.memorizedEvents.filter(event => utils.differentEventOrManyLocation(payload, event))
        if (!selectedEvent) {
          payload.selected = false
        }
      } else if (!memorizedEvent) {
        payload.selected = true
        state.memorizedEvents.push(payload)
      }
    },
    /**
     * 
     * @param {*} state 
     * @param {*} payload les des évéenemtns mémorisé
     */
    [mutationTypes.SET_MEMORIZED_EVENTS](state, payload) {
      state.memorizedEvents = payload
    },
    /**
     * Permet d'ajouter un type de selection sur un événement
     * @param { Object } event: EyeEvent Evenement concerné par la selection
     *  selectionType: EyeEnumSelectionType Raison pour laquelle l'événement est séléctionné
     * @method
     * @public
     */
    [mutationTypes.ADD_SELECTED_EVENT](state, { event, selectionType }) {
      const selectedEvent = state.selectedEvents.find(e => utils.uniqEvent(event, e))

      if (selectedEvent) {
        if (!selectedEvent.selectionTypes.includes(selectionType)) {
          selectedEvent.selectionTypes.push(selectionType)
        }
      } else {
        event.selectionTypes.push(selectionType)
        event.selected = true
        event.displayLabel = true
        state.selectedEvents.push(event)
      }
    },
    /**
     * Permet de supprimer un type de selection sur un événement
     * @param { Object } event: EyeEvent Evenemment concerné par la déselection
     *  selectionType: EyeEnumSelectionType Raison pour laquelle l'événement est déselectionné
     * @method
     * @public
     */
    [mutationTypes.REMOVE_SELECTED_EVENT](state, { event, selectionType }) {
      const selectedEvent = state.selectedEvents.find(e => utils.uniqEvent(event, e))

      if (selectedEvent) {
        selectedEvent.selectionTypes = selectedEvent.selectionTypes.filter(type => type !== selectionType)
        event.selectionTypes = selectedEvent.selectionTypes
      }
      if (event.selectionTypes.length === 0) {
        state.selectedEvents = state.selectedEvents.filter(e => utils.differentEventOrManyLocation(event, e))
        // Si l'element n'est plus selectionné via des outils eyeDiag et que l'utilisateur ne l'a pas mémorisé ou qu'il n'est pas en mode affichage diag mémorisé, il n'a plus a être en surbrillance 
        if (!state.memorizeEvents || !state.memorizedEvents.find(memorizedEvent => utils.uniqEvent(event, memorizedEvent))) {
          event.selected = false
        }
        // Un element non selectionné par des outils eyediag, qu'il soit mémorisé ou non sont libellé n'est pas affiché sans survol sur celui-ci
        event.displayLabel = false
      }
    },
    /**
     * Permet de définir l'événement que l'utilisateur est en train de survoler
     * @param {EyeEvent} payload
     * @method
     * @public
    */
    [mutationTypes.SET_HOVERED_EVENT](state, payload) {
      state.hoveredEvent = payload
    },
    /**
     * Permet de définir l'événement que l'utilisateur est en train de survoler sur le cercle des scores
     * @param {EyeEvent} payload
     * @method
     * @public
     */
    [mutationTypes.SET_HOVERED_REF_EVENT](state, payload) {
      state.hoveredRefEvent = payload
    },
    /**
     * Permet de définir si les zones d'intéraction des événements doivent être affichées
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAY_EVENTS](state, payload) {
      state.displayEvents = payload
    },
    /**
     * Permet de définir un filtrage qui sera appliqué sur les événements devant être affiché à l'utilisateur
     * @param {EyeEventFilterOptions} payload
     * @method
     * @public
     */
    [mutationTypes.SET_EVENT_FILTERS](state, payload) {
      state.eventFilters = payload
      
      //suppression selection ancien element filtrés
      const eventsToUnselect = state.events.filter(event => event.selectionTypes.includes(EventSelectionTypes.FILTERING))
      eventsToUnselect.forEach(event => {
        this.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
          event: event,
          selectionType: EventSelectionTypes.FILTERING
        })
      })
    },
    /**
     * Permet de mettre à jour la variable indiquant si les événements doivent être mémorisé ou non
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.SET_MEMORIZE_EVENTS](state, payload) {
      state.memorizeEvents = payload
    },
    /**
     * Permet de mettre à jour la variable indiquant si tous les événements du cercle actif doivent être affiché ou non
     * @param {Boolean} payload
     * @method
     * @public
     */
    [mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT](state, payload) {
      state.displayAllActiveCircleEvent = payload

      if (payload === false) {
        const eventsActiveCircle = state.events.filter(event => event.selectionTypes.includes(EventSelectionTypes.MAIN_CIRCLE))
        eventsActiveCircle.forEach(event => {
          this.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
            event,
            selectionType: EventSelectionTypes.MAIN_CIRCLE
          })
        })
      }
    },
    /**
     * Permet d'afficher la tooltip de l'événement fournit en paramètre. Si la tooltip de cet événement est déjà ouverte, la tooltip est fermée
     * @param {EyeEvent} payload
     * @method
     * @public
     */
    [mutationTypes.TOOLTIPS_ADD_EVENTS](state, payload) {
      //TO-DO revoir et détailler pourquoi on fait une copie de l'event ici. Cela à un impact lors des survols de la tooltip, il faut donner à la mutation SET_HOVERED_EVENT l'object source l'événement eyediag
      let copy = { ...payload };
      if (state.tooltips.find(tooltip => tooltip.id === copy.id)) {
        this.commit(`event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`, copy.id)
      } else {
        const x = copy.cx + this.state.layout.centerX + 10
        const y = copy.cy + this.state.layout.centerY + 10
        const eyePolar = CircleUtility.eyePointRadial(
          x,
          y,
          this.state.layout.centerX,
          this.state.layout.centerY,
          this.state.layout.radius
        )
        copy.tooltipRadius = eyePolar.r
        copy.tooltipAngle = eyePolar.t
        state.tooltips.push(copy)
      }
    },
    /**
     * Permet de supprimer une ou toutes les tooltips ouvertes par l'utilisateur
     * @param {Number} payload Si l'identifiant d'un événement est fournis en paramètre, la tooltip de cet événement sera supprimée. Si aucun élément n'est fournis en paramètre toutes les tooltips seront supprimées
     * @method
     * @public
     */
    [mutationTypes.TOOLTIPS_REMOVE_EVENTS](state, payload) {
      // REMOVE_ALL_EVENTS if payload is not defined (id of event)
      if (!payload) {
        state.tooltips = []
        state.tooltipsBackUp = []
      } else {
        state.tooltips = state.tooltips.filter((tooltip) => tooltip.id !== payload)
      }
    },
    /**
     * Permet de définir si les éléments liés par un compte rendu doivent être affichés au survol d'événement
     * @param {Boolean} payload Si un événement est fournis en paramètre, la tooltip de cet événement sera supprimée. Si aucun élément n'est fournis en paramètre toutes les tooltips seront supprimées
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAYED_LINES](state, payload) {
      state.displayedLines = payload
    },
    /**
     * Permet de définir le tri appliqué à la liste des événements mémorisés
     * @param {Boolean} payload Si un événement est fournis en paramètre, la tooltip de cet événement sera supprimée. Si aucun élément n'est fournis en paramètre toutes les tooltips seront supprimées
     * @method
     * @public
     */
    [mutationTypes.SET_LIST_MEMORIZED_EVENTS_SORT](state, payload) {
      state.listMemorizedEventsSort = payload
    },
    /**
     * Permet de mettre à jour les coordonées polaire d'une tooltip lors d'un déplacement de celle-ci
     * @param {Boolean} payload 
     * @method
     * @public
     */
    [mutationTypes.UPDATE_TOOLTIP_POLAR_COORDINATE](state, payload) {
      const tooltip = state.tooltips.find(tooltip => tooltip.id === payload.id)
      
      if (tooltip) {
        tooltip.tooltipRadius = payload.r
        tooltip.tooltipAngle = payload.t
      }
    },
    /**
     * Permet la réorganisation des tooltips. La fonction calcule la nouvelle position de chacune des tooltips
     * @method
     * @public
     */
    [mutationTypes.ORDER_TOOLTIPS](state) {
      state.tooltips.forEach((e, index) => {
        e.cx = 100
        e.cy = 100 + index * 57;
        const {r, t} = CircleUtility.eyePointRadial(
          e.cx,
          e.cy,
          this.getters['layout/centerX'],
          this.getters['layout/centerY'],
          this.getters['layout/radius']
        )
        e.tooltipRadius = r
        e.tooltipAngle = t
      })
      state.tooltips = [...state.tooltips]
      state.orderTooltips = !state.orderTooltips
    },
    /**
     * Permet d'afficher / masquer les tooltips
     * @method
     * @public
     */
    [mutationTypes.HIDE_AND_SHOW_TOOLTIPS](state) {
      state.isTooltipsShown = !state.isTooltipsShown
      if (state.isTooltipsShown) {
        state.tooltips.push(...state.tooltipsBackUp)
        state.tooltipsBackUp = []
      } else {
        state.tooltipsBackUp.push(...state.tooltips)
        state.tooltips = []
      }
    },
    /**
     * Permet de mettre à jour le tableau contenant les événements dont le libellés doit être affichés sur la représentation
     * @method
     * @public
     */
    [mutationTypes.UPDATE_LABEL_TO_DISPLAY](state, payload){
      state.labelToDisplay = payload
    },
    /**
     * Permet d'ajouter un événement au tableau contenant les événements dont le libellé doit être affichés sur la représentation
     * @method
     * @public
     */
    [mutationTypes.PUSH_LABEL_TO_DISPLAY](state, payload){
      state.labelToDisplay.push(payload)
    },
    [mutationTypes.UPDATE_LINKED_EVENTS](state, payload) {
      state.linkedEvents = payload
    }
  },
  actions: {
    /**
     * Permet de remettre à la valeur par défaut le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    /**
     * Permet d'initier la génération des événements du patient
     * @method
     * @public
     */
    generateEvents(context) {
      const eventsData = eventManager.updateEvents(this)
      context.commit(mutationTypes.UPDATE_DISPLAYED_EVENTS, eventsData.events)
      context.commit(mutationTypes.SET_FILTERED_EVENTS, eventsData.filteredEvents)
    },
    /**
     * topTen fonction permet la gestion des événement du cercle via le menu e-contextuel et la borderList
     * @method
     * @public
     */
    async controlEvent(context, {isEnabled, typeEventslist, time}){
      if(isEnabled){
        if(typeEventslist === borderListTypes.LIST_MEMORIZED_EVENTS){
          this.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {}, { root: true })
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_MEMORIZED_EVENTS)
          await this.dispatch('event/common/baseEnableFiltering',{label: "Diags mémorisés",isEventsMemorized: true})
        }else if(typeEventslist === borderListTypes.LIST_TOP_10){
          this.commit(`event/common/${mutationTypes.SET_MEMORIZE_EVENTS}`, false)
          this.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "Top 10")
          this.commit(`event/common/${mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT}`, false)
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_TOP_10)
        }else if(typeEventslist === borderListTypes.LIST_TIME_FILTER){
          this.commit(`event/common/${mutationTypes.SET_MEMORIZE_EVENTS}`, false)
          this.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "List_Time_Filter")
          this.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
            time: { unit: time }
          }, { root: true })
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_TIME_FILTER)
        }else{
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, typeEventslist)
        }
      }else{
        if (typeEventslist === borderListTypes.LIST_MEMORIZED_EVENTS) {
          this.commit(`event/common/${mutationTypes.SET_MEMORIZE_EVENTS}`, false)
          this.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "")
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
          await this.dispatch('event/common/generateEvents')
        } else if (typeEventslist === borderListTypes.LIST_TOP_10) {
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
          await this.dispatch('event/common/disableFiltering')
        } else if (typeEventslist === borderListTypes.LIST_TIME_FILTER) {
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
          await this.dispatch('event/common/disableFiltering')
        } else {
          this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
        }
      }
    },
    async baseEnableFiltering(context, {label, isEventsMemorized}) {
      const borderListToCheck = [borderListTypes.LIST_TIME_FILTER, borderListTypes.LIST_TOP_10]
    
      this.commit(`event/common/${mutationTypes.SET_MEMORIZE_EVENTS}`, isEventsMemorized)
      this.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, label)
      this.commit(`event/common/${mutationTypes.DISPLAY_ALL_ACTIVE_CIRCLE_EVENT}`, false)
    
      if (borderListToCheck.includes(this.getters['borderList/currentDisplayedBorderList'])) {
        this.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      }
    
      await this.dispatch('event/common/generateEvents')
    },
    async disableFiltering() {
      this.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {})
      this.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, "")
      await this.dispatch('event/common/generateEvents')
    }
  }
}