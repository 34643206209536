import * as d3 from 'd3'
import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'

export function useScoreChecker(idEventsContainer, classRefEvents) {
  function onHoveredEventChange(hoveredEvent, events) {
    if (hoveredEvent === null) {
      hideRefCircleEventsEvents()
    } else {
      const idEventsToShow = []
      for (const element of events) {
        let condition1 = hoveredEvent != null && hoveredEvent.code === element.code
        let condition2 = hoveredEvent != null && typeof element.code === "object" && element.code.includes(hoveredEvent.code)
        if (condition1 || condition2) {
          idEventsToShow.push(element.id)
        }
      }
      displayRefCircleEventsEvents(idEventsToShow)
    }
  }

  function onMatchScoreHoveredEventChange(matchScoreHoveredEvent, events) {
    if (matchScoreHoveredEvent === null) {
      hideRefCircleEventsEvents()
    } else {
      const idEventsToShow = []
      for (const code of Object.keys(matchScoreHoveredEvent)) {
        for (const element of events) {
          let condition1 = code === element.code
          let condition2 = typeof element.code === "object" && element.code.includes(code)
          if (condition1 || condition2) {
            idEventsToShow.push(element.id)
          }
        }
      }
      displayRefCircleEventsEvents(idEventsToShow)
    }
  }

  function hideRefCircleEventsEvents() {
    d3.select(`#${idEventsContainer}`)
      .selectAll(`.${classRefEvents}`)
      .transition()
      .duration(D3Animation.SCORE_CIRCLE_APPEAR)
      .style('stroke-opacity', 0)
      .style('fill', EyeColor.getFillColorEventCircle(0))
  }

  /**
   * Cette fonction permet de faire apparaittre ou disparaitre les cercle de score correspondant lorsque que nous survolons un évenement
   */
  function displayRefCircleEventsEvents(idElements) {
    d3.select(`#${idEventsContainer}`)
      .selectAll(`.${classRefEvents}`)
      .filter(d => idElements.includes(d.id))
      .transition()
      .duration(D3Animation.SCORE_CIRCLE_APPEAR)
      .style('stroke-opacity', 1)
      .style('fill', EyeColor.getFillColorEventCircle(0.8))
  }

  return {
    onHoveredEventChange,
    onMatchScoreHoveredEventChange
  }
}