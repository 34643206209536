<template>
  <form @submit.prevent="validateForm">
    <CommonForm
      ref="commonForm"
    />
    <div class="field-block">
      <label>
        <span>Droits</span>
      </label>
      <div
        v-for="(role, index) in allowedUserRoles"
        :key="index"
      >
        <ToggleSwitch
          v-model:status="role.selected"
          :text="role.Label"
        />
      </div>
      <p
        v-for="(error, index) in errors.permissions"
        :key="index"
        class="error-modal"
      >
        {{ error }}
      </p>
    </div>
    <div class="field-block">
      <ToggleSwitch
        v-model:status="enableExpireDate"
        text="Temps limite d'utilisation"
      />
      <div
        v-if="enableExpireDate"
        class="field-block mt-4"
      >
        <label>Préciser pendant combien de minutes le compte sera utilisable à partir de la première connexion de l'utilisateur. Au delà de ce temps, le compte sera désactivé et inaccessible.</label>
        <input
          v-model="minutesExpireDate"
          type="number"
          class="text-input mt-2"
          min="0"
          @input="(e) => onInput(e, minutesExpireDate, false)"
        >
        <p
          v-for="(error, index) in errors.minutesExpireDate"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </div>
    </div>
    <div class="field-block">
      <label>
        <span>
          Envoi des identifiants par e-mail
        </span>
      </label>
      <ToggleSwitch
        v-model:status="sendToCreatorAddress"
        text="A mon adresse"
      />
      <ToggleSwitch
        v-model:status="sendToRecipientAddress"
        text="Au destinataire du compte"
      />
    </div>
    <div>
      <DesignButton
        id="create-patient-validate"
        text="Valider"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: 'AccountCreationForm'
}
</script>

<script setup>
import CommonForm from '@/views/AccountCreation/Common.vue'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import EyeFetch from '@/libraries/EyeFetch'
import DesignButton from '@/components/DesignButton.vue'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'
import { useCreateAccount } from '@/views/AccountCreation/useCreateAccount.js'
import { ref, onMounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const errors = ref({})
const commonForm = ref(null)
const allowedUserRoles = ref([])
const enableExpireDate = ref(false)
const minutesExpireDate = ref(0)
const sendToCreatorAddress = ref(false)
const sendToRecipientAddress = ref(false)

const { createAccount } = useCreateAccount()

onMounted(() => {
  getAllowedUserRole()
})

function checkForm() {
  errors.value = commonForm.value.checkForm()
  errors.value.permissions = []
  errors.value.minutesExpireDate = []

  const nbSelectedUserRole = allowedUserRoles.value.filter(role => role.selected === true).length

  if (nbSelectedUserRole === 0) {
    errors.value.permissions.push('Vous n\'avez pas sélectionné de droits pour ce compte')
  }
  if (enableExpireDate.value === true && minutesExpireDate.value <= 0) {
    errors.value.minutesExpireDate.push('Le temps saisi n\'est pas valide')
  }
}

async function validateForm(event) {
  checkForm()

  for (const err of Object.values(errors.value)) {
    if (err.length > 0) {
      return
    }
  }

  const permissions = []
  for (const role of allowedUserRoles.value) {
    if (role.selected === true) {
      permissions.push(role.Id)
    }
  }

  const data = commonForm.value.getDataForm()

  data.permissions = permissions
  data.enableExpireDate = enableExpireDate.value
  data.minutesExpireDate = minutesExpireDate.value
  data.sendToCreatorAddress = sendToCreatorAddress.value
  data.sendToRecipientAddress = sendToRecipientAddress.value

  createAccount(event, data)
}

async function getAllowedUserRole() {
  try {
    let response = await EyeFetch(store,
      `${process.env.VUE_APP_SERVER_BASE_URL}/config/role/creation-account`,
      {
        method: "GET",
        credentials: 'include'
      }
    )
    response = await response.json()
    for (const role of response.roles) {
      role.selected = false
    }
    allowedUserRoles.value = response.roles
  } catch (err) {
    console.error(err)
    allowedUserRoles.value = []
  }
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

</script>