<template>
  <ListBase
    ref="list-base"
    title="Composer un filtrage"
  >
    <template #content>
      <div id="choose-assembly-elements-list-content">
        <div class="assembly-title-container">
          <input
            v-model="title"
            placeholder="Titre"
            @input="onTitleInput"
          >
        </div>
        <div
          v-for="(typeElement, index) in typeElements"
          :key="index"
        >
          <div class="element-assembly-choice">
            <ToggleSwitch
              v-model:status="typeElement.enable"
              :text="typeElement.label"
            />
          </div>
        </div>
        <div class="m-t-20 validate-assembly-elements">
          <button @click="validateSelectionElementAssembly">
            Valider
          </button>
        </div>
      </div>
    </template>
  </ListBase>
</template>
  
<script>
import * as mutationTypes from '@/store/mutations-types.js'
import _ from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import ToggleSwitch from "../../ToggleSwitch.vue";

export default {
  components: {
    ToggleSwitch,
    ListBase
  },
  data: () => ({
    /**
     * Ce tableau contient la liste des éléments pouvant faire parti du filtrage, chaque objet du tableau contient le libellé, une booléen indiquant si la sauvegarde de cet élément est activé ainsi qu'une clé et une fonction permettant de construire l'objet final contenant tout les éléments présent dans le filtrage
     * @type {Object[]}
     */
    typeElements: [],
    title: ''
  }),
  computed: {
    ...mapGetters({
      oldDisplayedBorderList: 'borderList/oldDisplayedBorderList'
    })
  },
  mounted() {
    this.typeElements = [
      {
        enable: true,
        label: 'Filtres d\'événement',
        key: ['eventFilters'],
        value: [() => this.$store.getters['event/common/eventFilters']]
      },
      {
        enable: true,
        label: 'Filtres de cercles',
        key: ['circleFilters'],
        value: [() => this.$store.state.circle.filters]
      },
      {
        enable: true,
        label: 'Types d\'événement',
        key: ['displayedEventsTypes'],
        value: [() => this.$store.state.displayedEventsTypes]
      },
      {
        enable: true,
        label: 'Score',
        key: ['idScore'],
        value: [() => this.$store.getters['refCircle/score/idScore']]
      },
      {
        enable: true,
        label: 'Antécédents familiaux',
        key: ['displayedFamilyHistoryCircle'],
        value: [() => this.$store.getters['refCircle/familyHistory/isDisplayedFamilyHistoryCircle']]
      },
      {
        enable: true,
        label: 'Mode de l\'application',
        key: ['mode'],
        value: [() => this.$store.getters['eyediagMode']]
      },
      {
        enable: true,
        label: 'Temporalité',
        key: ['periodUnit', 'unitPerCircle'],
        value: [
          () => this.$store.state.circle.periodUnit,
          () => this.$store.getters['circle/unitPerCircle']
        ]
      },
      {
        enable: true,
        label: 'Sections',
        key: ['sections', 'hierarchy'],
        value: [
          () => this.$store.state.historySections[this.$store.state.indexHistorySection],
          () => this.$store.state.hierarchy
        ]
      },
    ]
  },
  methods: {
    ...mapActions({
      'sendEvent': 'ws/sendEvent',
      'collaborativeEventTreated': 'ws/collaborativeEventTreated'
    }),
    /**
     * Permet la gestion de l'ouverture / fermeture de la liste
     * @param  {...any} params Paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    /**
     * Cette fonction permet de créer l'objet représentant l'assemblage avec l'ensemble des filtres / paramètres puis d'initier la sauvegarder de cet objet auprès du serveur
     * @param {Event} event Evenement fournit par le listener
     */
    async validateSelectionElementAssembly(event) {
      this.sendEvent({ event })
      const assembly = {}

      for (const typeElement of this.typeElements) {
        if (typeElement.enable) {
          for (let i = 0; i <  typeElement.key.length; i++) {
            assembly[typeElement.key[i]] = typeElement.value[i]()
          }
        }
      }
      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, this.oldDisplayedBorderList)
      await this.$store.dispatch('assembly/addUserAssembly', {
        assembly: assembly,
        title: this.title
      })
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur saisi du texte dans l'input de titre lors de la création d'un assemblage. La fonction s'occupe de transmettre cet événement aux spectateurs lors de session collaborative
     * @param {Event} event Evénement fournit par le listener
     */
    onTitleInput(event) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: this.title
          }
        })
      } else {
        this.sendEvent({event: event})
        this.collaborativeEventTreated()
      }
    }
  }
}
</script>
  
<style scoped>
#choose-assembly-elements-list-content {
  margin-inline: 5px;
}

.element-assembly-choice {
  display: flex;
  align-content: center;
  margin-top: 10px;
}

h3 {
  display: flex;
  justify-content: center;
}

.validate-assembly-elements {
  display: flex;
  flex-direction: column;
}

.assembly-title-container {
  display: flex;
  justify-items: center;
  margin-top: 10px;
}

.assembly-title-container > input {
  flex-grow: 1;
  margin-bottom: 10px;
  width: 100%;
}
</style>