import colorThemes from '../enums/color_themes.js'

/**
 * Tableau de configuration pour l'affichage des cercles. Les cercles situés aux extrémités et non visible (soit plus dans le passé, soit plus dans le futur) utilise les elements de configuration aux extrémité. Exemple tout les cercles du passé non affiché, auront la première config du tableau
 * @type {Object[]}
 */
export default [
  {
    id: 1,
    active: false,
    coefRadius: 0.06,
    //stroke: '0.18px',
    stroke: '0px',
    color: 'var(--c-circle-1-1)',
    opacity: 0
  },
  {
    id: 2,
    active: false,
    coefRadius: 0.08,
    //stroke: '0.22px',
    stroke: '0px',
    color: 'var(--c-circle-1-2)',
    opacity: 0.0
  },
  {
    id: 3,
    active: false,
    coefRadius: 0.12,
    stroke: '0.6px',
    color: 'var(--c-circle-1-3)',
    opacity: 0.35
  },
  {
    id: 4,
    active: false,
    coefRadius: 0.19,
    stroke: '0.7px',
    color: 'var(--c-circle-1-4)',
    opacity: 0.4
  },
  {
    id: 5,
    active: false,
    coefRadius: 0.27,
    stroke: '0.85px',
    color: 'var(--c-circle-1-5)',
    opacity: 0.45
  },
  {
    id: 6,
    active: false,
    coefRadius: 0.37,
    stroke: '1px',
    color: 'var(--c-circle-1-6)',
    opacity: 0.6
  },
  {
    id: 7,
    active: false,
    coefRadius: 0.49,
    stroke: '1.2px',
    color: 'var(--c-circle-1-7)',
    opacity: 0.8
  },
  {
    id: 8,
    active: false,
    coefRadius: 0.63,
    stroke: '1.4px',
    color: 'var(--c-circle-1-8)',
    opacity: 1
  },
  {
    id: 9,
    active: false,
    coefRadius: 0.8,
    stroke: '1.6px',
    color: 'var(--c-circle-1-9)',
    opacity: 1
  },
  {
    id: 10,
    active: true,
    coefRadius: 1,
    stroke: '1.8px',
    color: 'var(--c-circle-1-10)',
    opacity: 1
  },
  {
    id: 11,
    active: false,
    coefRadius: 1.18,
    stroke: '1.9px',
    color: 'var(--c-circle-1-11)',
    opacity: 0.52
  },
  {
    id: 12,
    active: false,
    coefRadius: 1.36,
    stroke: '1.8px',
    color: 'var(--c-circle-1-12)',
    opacity: 0.38
  },
  {
    id: 13,
    active: false,
    coefRadius: 1.54,
    stroke: '1.7px',
    color: 'var(--c-circle-1-13)',
    opacity: 0.27
  },
  {
    id: 14,
    active: false,
    coefRadius: 1.72,
    stroke: '1.6px',
    color: 'var(--c-circle-1-14)',
    opacity: 0.22
  },
  {
    id: 15,
    active: false,
    coefRadius: 1.90,
    stroke: '1.5px',
    color: 'var(--c-circle-1-15)',
    opacity: 0
  }
]

/**
 * Cet objet contient des configurations de couleurs supplémentaires pour les cercles. Le thème de couleur s'appliquant aux cercles peut être selectionné depuis le menu contextuel
 * @type {Object}
 */
export const themeConfig = {
  [colorThemes.ORIGINAL]: ['var(--c-circle-1-1)', 'var(--c-circle-1-2)', 'var(--c-circle-1-3)', 'var(--c-circle-1-4)', 'var(--c-circle-1-5)', 'var(--c-circle-1-6)', 'var(--c-circle-1-7)', 'var(--c-circle-1-8)', 'var(--c-circle-1-9)', 'var(--c-circle-1-10)', 'var(--c-circle-1-11)', 'var(--c-circle-1-12)', 'var(--c-circle-1-13)', 'var(--c-circle-1-14)', 'var(--c-circle-1-15)'],
  [colorThemes.BLUE]: ['var(--c-circle-2-1)', 'var(--c-circle-2-2)', 'var(--c-circle-2-3)', 'var(--c-circle-2-4)', 'var(--c-circle-2-5)', 'var(--c-circle-2-6)', 'var(--c-circle-2-7)', 'var(--c-circle-2-8)', 'var(--c-circle-2-9)', 'var(--c-circle-2-10)', 'var(--c-circle-2-11)', 'var(--c-circle-2-12)', 'var(--c-circle-2-13)', 'var(--c-circle-2-14)', 'var(--c-circle-2-15)'],
  [colorThemes['GREEN+']]: ['var(--c-circle-3-1)', 'var(--c-circle-3-2)', 'var(--c-circle-3-3)', 'var(--c-circle-3-4)', 'var(--c-circle-3-5)', 'var(--c-circle-3-6)', 'var(--c-circle-3-7)', 'var(--c-circle-3-8)', 'var(--c-circle-3-9)', 'var(--c-circle-3-10)', 'var(--c-circle-3-11)', 'var(--c-circle-3-12)', 'var(--c-circle-3-13)', 'var(--c-circle-3-14)', 'var(--c-circle-3-15)'],
  [colorThemes['BLUE+']]: ['var(--c-circle-4-1)', 'var(--c-circle-4-2)', 'var(--c-circle-4-3)', 'var(--c-circle-4-4)', 'var(--c-circle-4-5)', 'var(--c-circle-4-6)', 'var(--c-circle-4-7)', 'var(--c-circle-4-8)', 'var(--c-circle-4-9)', 'var(--c-circle-4-10)', 'var(--c-circle-4-11)', 'var(--c-circle-4-12)', 'var(--c-circle-4-13)', 'var(--c-circle-4-14)', 'var(--c-circle-4-15)']
}