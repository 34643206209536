<template>
  <div class="choose-devices-container">
    <div class="choose-devices-video-container">
      <video
        ref="video-preview"
        class="visio-video"
        poster="@/assets/doctor_placeholder.png"
        disablePictureInPicture
        playsinline
        autoplay
        muted
        width="200"
        height="150"
      />
    </div>
    <div class="m-t-10">
      <div class="visio-device-options-container">
        <p>Périphérique Vidéo</p>
        <select
          v-model="selectedVideoDevice"
          class="m-t-5"
          @change="onChangeCamera"
        >
          <option :value="null">
            Aucun
          </option>
          <option
            v-for="(device, index) in devices.video"
            :key="index"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </select>
      </div>
      <div class="visio-device-options-container m-t-10">
        <p>Périphérique Audio</p>
        <select
          v-model="selectedAudioDevice"
          class="m-t-5"
        >
          <option :value="null">
            Aucun
          </option>
          <option
            v-for="(device, index) in devices.audio"
            :key="index"
            :value="device.deviceId"
          >
            {{ device.label }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import * as mutationTypes from '@/store/mutations-types.js'

let userMedia = null

export default {
  name: 'ChooseDevices',
  data: () => ({
    devices: {
      /**
       * Liste des périphérique audio disponible pour la visioconférence
       * @type {MediaDeviceInfo[]}
       */
      audio: [],
      /**
       * Liste des périphérique video disponible pour la visioconférence
       * @type {MediaDeviceInfo[]}
       */
      video: []
    },
    /**
     * Identifiant du périphérique audio selectionné par l'utilisateur
     * @type {String}
     */
    selectedAudioDevice: null,
    /**
     * Identifiant du périphérique video selectionné par l'utilisateur
     * @type {String}
     */
    selectedVideoDevice: null,
  }),
  async mounted() {
    await this.getConnectedDevices()
  },
  methods: {
    /**
     * Cette fonction permet d'obtenir les listes des périphériques audio et video disponibles pour la visioconférence
     */
    async getConnectedDevices() {
      await navigator.mediaDevices.getUserMedia({audio: true, video: true})
      const devices = await navigator.mediaDevices.enumerateDevices()

      this.devices.audio = devices.filter(device => device.kind === 'audioinput')
      this.devices.video = devices.filter(device => device.kind === 'videoinput')
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur choisi un autre périphérique audio. La fonction permet de modifier la prévisualisation pour afficher le stream du nouveau périphérique audio choisi
     */
    async onChangeCamera() {
      if (this.selectedVideoDevice === null) {
        userMedia = null
        this.$refs['video-preview'].srcObject = null
        return
      }

      userMedia = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          deviceId: this.selectedVideoDevice
        }
      })
      this.$refs['video-preview'].srcObject = userMedia
    },
    /**
     * Cette fonction permet de mettre fin à la prévisualisation du périphérique video choisi par l'utilisateur.
     */
    endPreview() {
      if (userMedia) {
        userMedia.getTracks().forEach((track) => {
          track.stop();
          userMedia.removeTrack(track)
        });
        userMedia = null;
        this.$refs['video-preview'].srcObject = null
      }
    },
    /**
     * Cette fonction permet de sauvegarder les périphériques audio / video choisis par l'utilisateur dans le store de l'application
     */
    saveDevices() {
      const audioDevice = this.selectedAudioDevice === null
        ? null
        : this.devices.audio.find(device => device.deviceId === this.selectedAudioDevice)
      
      const videoDevice = this.selectedVideoDevice === null
        ? null
        : this.devices.video.find(device => device.deviceId === this.selectedVideoDevice)
      
      this.$store.commit(`ws/${mutationTypes.SET_VISIO_AUDIO_DEVICE}`, audioDevice)
      this.$store.commit(`ws/${mutationTypes.SET_VISIO_VIDEO_DEVICE}`, videoDevice)
      this.$store.commit(`ws/${mutationTypes.SET_VISIO_STATE}`, true)
    }
  }
}

</script>

<style scoped>
.choose-devices-container {
  display: flex;
  flex-direction: column;
  font-size: 11px;
}

.choose-devices-video-container {
  display: flex;
  justify-content: center;
  align-content: center;
}

.visio-device-options-container {
  display: flex;
  flex-direction: column;
}

</style>