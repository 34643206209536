<template>
  <div id="root-account-creation">
    <div>
      <h2
        class="pb-3"
        align="center"
      >
        Créer un compte
      </h2>
      <div v-if="isNotRestrictedAccountCreation">
        <AccountCreationForm />
      </div>
      <div v-else>
        <div class="mb-4">
          <p>Crédits disponibles: {{ userData.Credit }}</p>
        </div>
        <AccountCreationFormCredit
          v-if="userData.Credit >= 1"
        />
        <CreditsRenew
          v-else
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import AccountCreationFormCredit from '@/views/AccountCreation/FormWithCredit.vue'
import AccountCreationForm from '@/views/AccountCreation/Form.vue'
import CreditsRenew from '@/components/credits/renew.vue'
import UserRole from '@/shared/enums/userRole.js'

import { useStore } from 'vuex'
import { computed } from 'vue'

const store = useStore()

const userData = computed(() => store.getters['user/userData'])
const isNotRestrictedAccountCreation = computed(() => userData.value.Role.includes(UserRole.CREATE_ACCOUNT))
</script>

<style>
#root-account-creation {
  width: 100%;
  height: 100%;
  display: flex;
  overflow: scroll;
}

#root-account-creation > div {
  border: 1px solid var(--color-shadow);
  padding: 15px;
  border-radius: 10px;
  margin: auto;
}

#root-account-creation form {
  width: 350px;
}

#root-account-creation #create-patient-validate {
  margin: unset;
  width: 100%;
}

#root-account-creation .text-input {
  @apply py-1 px-1;
  width: 100%;
  height: 2.5rem;
  box-sizing: border-box;
}

#root-account-creation label > span {
  @apply mb-1;
  font-size: 1.1rem;
}

#root-account-creation label {
  font-size: 1.2rem;
}

#root-account-creation .field-block {
  @apply mb-6;
}
</style>