import * as mutationTypes from '../mutations-types.js'
import proportion from '@/libraries/Proportion.js'

const getDefaultState = () => {
  return {
    /**
     * Il s'agit du rayon de référence utilisé au travers de l'application pour déterminer les autres rayon qui sont un pourcentage de ce rayon
     * @type {Number}
     */
    radius: proportion.getRadiusSize(),
    /**
     * Coordonée x du centre des cercles
     * @type {Number}
     */
    centerX: window.innerWidth / 2,
    /**
     * Coordonée y du centre des cercles
     * @type {Number}
     */
    centerY: window.innerHeight / 2,
    /**
     * Coefficient de taille pour le menu contextuel
     * @type {Number}
     */
    contextMenuSize: 1,
    /**
     * Coefficient d'echelle pour la taille de la représentation
     * @type {Number}
     */
    scale: 1,
    /**
     * Indique si le menu de changement de temporalité depuis un cercle précis doit être affiché
     * @type {Boolean}
     */
    displayedTemporalityMenu: false,
    /**
     * Coordonnées du coin supérieur gauche du menu de changement de temporalité depuis un cercle précis (Object)
     * @type {EyeCoordinates}
     */
    temporalityMenuPos: {x: 0, y: 0},
    /**
     * Objet contenant les proportions de la fenêtre
     * @type { Object } width: Number,
     *  height: Number
     */
    windowSize: { width: window.innerWidth, height: window.innerHeight}
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    radius: state => state.radius,
    centerX: state => state.centerX,
    centerY: state => state.centerY,
    scale: state => state.scale,
    windowSize: state => state.windowSize,
  },
  mutations: {
    /**
     * Met à jour la coordonée x du centre des cercles
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_CENTERX](state, payload) {
      state.centerX = payload
    },
    /**
     * Met à jour la coordonée y du centre des cercles
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_CENTERY](state, payload) {
      state.centerY = payload
    },
    /**
     * Définit le coefficient de taille pour le menu contextuel
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_CONTEXT_MENU_SIZE](state, payload) {
      state.contextMenuSize = payload
    },  
    /**
     * Met à jour le coefficient d'echelle pour la taille de la représentation
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_SCALE](state, payload) {
      state.scale = payload
    },
    /**
     * Définit si le menu de changement de temporalité depuis un cercle bien précis doit être affiché
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAYED_TEMPORALITY_MENU](state, payload){
      state.displayedTemporalityMenu = payload
    },
    /**
     * Met à jour les coordonnées du coin supérieur gauche du menu de changement de temporalité depuis un cercle bien précis
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_TEMPORALITY_MENU_POS](state, payload){
      state.temporalityMenuPos = payload
    },
    /**
     * 
     * Permet de mettre à jour les proportions de la fenêtre
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.UPDATE_WINDOW_SIZE](state){
      state.windowSize = { width: window.innerWidth, height: window.innerHeight}
    },
    /**
     * 
     * Permet de mettre à jour le rayon
     * @param {Number} payload
     * @method
     * @public
     */
    [mutationTypes.SET_RADIUS] (state, payload) {
      state.radius = payload
    },
  },
  actions: {
    /**
     * Permet de remettre à la valeur par défaut le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
  }
}