<template>
  <svg
    id="bf37b39e-d613-4b9f-b9cf-545d16a9c219"
    viewBox="0 0 40 32.5"
  >
    <g>
      <path
        class="b8c09088-cc9c-4fb8-9266-edad81a04b42"
        d="M26.3,18.52v7.55a2.14,2.14,0,0,1-2.14,2.14H7.49a2.15,2.15,0,0,1-2.14-2.14V8A2.16,2.16,0,0,1,7.49,5.83H19.17"
      />
      <path
        class="b8c09088-cc9c-4fb8-9266-edad81a04b42"
        d="M16.84,20.89,30.16,9.25a2.7,2.7,0,0,0,.25-3.8l0,0a2.69,2.69,0,0,0-3.79-.26L13.28,16.82l-2.2,5.52Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'EditIcon'
}
</script>

<style>
.b8c09088-cc9c-4fb8-9266-edad81a04b42 {
  fill: none;
  stroke: red;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 1.3px;
}
</style>