<template>
  <g id="repere-circle-container">
    <g
      id="repere-circle"
      ref="repere-circle"
    />
    <g ref="invisible-repere-circles" />
  </g>
</template>

<script>
import * as d3 from "d3";
import { mapActions, mapGetters } from "vuex";
import CircleUtility from '@/libraries/CircleUtility.js'
import introAnimation from '@/animation/intro.js'
import D3Animation from '@/config/D3Animation.js'
import utils from "@/libraries/utils.js"
import * as mutationTypes from '@/store/mutations-types.js'

/**
 * RepCircle : Repere Circle
 */

/**
 * Il s'agit de la portion de rayon à ajouter ou retrancher aux différents cercles de navigation invisible implémentant les différents fonctionnaités (navigation vers le précédent cercle / navigation précédent cercle séléctionné / navigation prochain cercle)
 * @type {Number}
 */
const sizeInvisibleRepCircle = 10
/**
 * Sauvegarde du rayon initial du cercle de navigation
 * @type {Number}
 */
let position = 0

/**
 * Objet svg representant le cercle de navigation invisible et plus large permettant de faciliter l'intéractivité
 * @type {String}
 */
let circleInvisible = null

/**
 * ???????
 * @type {Number}
 */
let diff = 0

export default {
  name: "RepereCircle",
  data: () => {
    return {
      /**
       * Rayon du cercle de navigation
       * @type {Number}
       */
      radius: 0
    };
  },
  computed: {
    ...mapGetters({
      mainRadius: "layout/radius",
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      scale: "layout/scale",
      color: "circle/repCircleColor",
      sections: "sections"
    }),
  },
  watch: {
    mainRadius() {
      this.radius = this.mainRadius * 0.97 * this.scale;
      position = this.radius;
    },
    radius() {
      d3.select(this.$refs['repere-circle'])
        .selectAll('path')
        .attr('d', d => CircleUtility.generateArcNavCircle(this.radius, d))
      circleInvisible.attr("r", (d) => this.radius + sizeInvisibleRepCircle * d)
    },
    color() {
      this.radius = position 
      this.draw()
    },
    sections() {
      this.draw()
    }
  },
  created() {
    introAnimation.setRefRepCircleComponent(this)
  },
  mounted() {
    this.radius = this.mainRadius * 0.97 * this.scale;
    position = this.radius;
    this.draw();
  },
  methods: {
    ...mapActions({
      moveToTheFirst: "circle/moveToTheFirst",
      moveToTheLast: "circle/moveToTheLast",
      history: "circle/history",
      sendEvent: "ws/sendEvent",
      nextCircle: "circle/nextCircle",
      previousCircle: "circle/previousCircle",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction s'occupe de l'affichage et de la mise à jour du dessin du cercle vert.
     * Elle se charge aussi de positionner les 3 cercle qui servent à l'interaction (.repere-invisible-circle) 
     * et à actualiser leurs position 
     * @method
     * @public
     */
    draw() {
      //to prevent redrawing those circle again and again
      if (d3.select(".repere-invisible-circle")._groups[0][0] == null) {
        circleInvisible = d3
          .select(this.$refs["invisible-repere-circles"])
          .selectAll(".repere-invisible-circle")
          .data([-1, 0, 1])
          .enter()
          .append("circle")
          .attr("class", "repere-invisible-circle");
      }

      d3.select(this.$refs['repere-circle'])
        .selectAll('path')
        .data(this.sections)
        .join('path')
        .attr('d', d => CircleUtility.generateArcNavCircle(this.radius, d))
        .attr("fill", "none")
        .attr("stroke", this.color)
        .attr("stroke-width", 3)
        .attr("stroke-linecap", "round")

      circleInvisible
        .attr("r", (d) => this.radius + sizeInvisibleRepCircle * d)
        .attr("x", this.centerX)
        .attr("y", this.centerY)
        .attr("fill", "none")
        .attr("stroke", "transparent")
        .attr("stroke-width", sizeInvisibleRepCircle)
        .on("mouseover", this.onMouseOver)
        .on("mouseleave", this.onMouseLeave)
        .on("click", (event, d) => this.handleInteraction(event, d))
        .on("mousedown touchstart", (event) => {
          this.sendPosition(event)
          circleInvisible
            .on("mouseover", null)
            .on("mouseleave", null)
          d3.select("#root-app").on("mousemove touchmove", (e) => {
            this.moveRepCircle(e)
          })
          d3.select('#root-app').on("mouseup touchend", (e) => {
            this.resetToDefault(e)
          })
          this.collaborativeEventTreated()
        }, {passive: true})
    },
    /**
     * Cette fonction se charge de faire correspondre les differentes interactions possible (aller sur le cercle de l'année d'avant, 
     * celui de l'année d'après et remonté l'historique des cercles précédemment selectionnés), avec le cercle qui à génerer l'event.  
     * @method
     * @public
     * @param {Event} event Evenement fournit par le listener
     * @param {Number} d Information du cercle sur lequel l'utilisatuer à intéragit
     */
    async handleInteraction(event, d) {
      this.sendEvent({ event })

      switch (d) {
      case -1:
        await this.nextCircle();
        break
      case 0:
        await this.history();
        break
      case 1:
        await this.previousCircle();
        break
      }
      utils.onCircleMoveAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    /**
     * cette fonction se charge d'attribuer les valeurs correct au cercle vert pour qu'il ait cet effet d'elastique quand on interagi avec elle. 
     * @method
     * @public
     */
    moveRepCircle(e) {
      this.sendPosition(e);
      let cursorDistance = 0;
      //ce if/else ne sert qu'à gerer tous les cas possibles dans notre cas: l'utilisation d'une tablette ou celle d'un cursor
      if (e.targetTouches) {
        cursorDistance = this.distance(
          e.targetTouches[0].clientX,
          e.targetTouches[0].clientY
        );
      } else {
        cursorDistance = this.distance(e.clientX, e.clientY);
      }

      /**
       * "diff" est la variable qui prend la difference entre la position au repos du cercle vert et la position reel du cercle. 
       * idealement au repos diff est egale à 0. "scale", n'est qu'une valeur en plus qui intervient quand il y'a un zoom sur la representation.
       */
      diff = cursorDistance / this.scale - position;

      /**
       * le resultat de la ligne 197 donne le rayon du cercle vert en implementant cette effect d'elastique dû au fait que "diff" grandit 
       * en suivant le tracé de la courbe arc tangente (https://fr.wikipedia.org/wiki/Arc_tangente). les parametres 100 et 0.005 nous permet 
       * de modeler la courbe pour qu'elle convienne a notre cas d'usage. "100" joue sur l'amplitude de la courbe, alors que "0.005" joue sur
       * pente de celle-ci   
       */
      //implementatiton of the easing on the repere circle (arc tangente behaviour)
      this.radius =
        position +
        100 * Math.atan(Math.abs(0.005 * diff)) * Math.sign(diff);
      this.collaborativeEventTreated()
    },
    /**
     * Permet d'envoyer la position du cercle vert aux autres membres d'une session collaborative
     * @method
     * @public
     * @param {event} event Evenement fournit au listener
     */
    sendPosition(event) {
      this.sendEvent({
        event: event,
        params: CircleUtility.eyePointRadial(
          event.clientX,
          event.clientY,
          this.centerX,
          this.centerY,
          this.mainRadius
        )
      })
    },
    /**
     * Permet de replacer le cercle de navigation à sa position par défaut
     * @method
     * @public
     * @param {event} e Evenement fournit au listener
     */
    resetToDefault(e) {
      this.sendEvent({ event: e })
      if (diff < 0 && Math.abs(diff) > 30) {
        this.moveToTheFirst();
      } else if (diff > 0 && Math.abs(diff) > 30) {
        this.moveToTheLast();
      }
      d3.select("#root-app").on("mousemove touchmove", null);
      d3.select('#root-app').on("mouseup touchend mouseleave", null);
      d3.select(this.$refs['repere-circle'])
        .selectAll('path')
        .transition()
        .duration(D3Animation.CIRCLE_NAVIGATION_RESET_POSITION)
        .attr('d', d => CircleUtility.generateArcNavCircle(position, d))
        .style("stroke-width", "3px")
        .style("opacity",0.7)

      d3.selectAll(".repere-invisible-circle")
        .transition()
        .duration(D3Animation.CIRCLE_NAVIGATION_RESET_POSITION)
        .attr("r", (d) => position + d * sizeInvisibleRepCircle)
        .on('end', () => {
          circleInvisible
            .on("mouseover", this.onMouseOver)
            .on("mouseleave", this.onMouseLeave)
          this.radius = position
        })

      diff = 0
      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appeler lorsque l'utilisateur survole le cercle de navigation. Le cercle apparaît alors en surbrillance.
     * @method
     * @public
     */
    onMouseOver(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`circle/${mutationTypes.SET_HOVERED_REP_CIRCLE}`, true)
      d3.select(this.$refs['repere-circle'])
        .selectAll('path')
        .transition()
        .duration(D3Animation.CIRCLE_NAVIGATION_HOVER)
        .style("stroke-width", "5px")
        .style("opacity", 1)

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur arrête de survoler le cercle de navigation. La surbrillance du cercle disparaît.
     * @method
     * @public
     * @param {event} event Evénement fournit au listener
     */
    onMouseLeave(event){
      this.sendEvent({ event: event })
      this.$store.commit(`circle/${mutationTypes.SET_HOVERED_REP_CIRCLE}`, false)
      d3.select(this.$refs['repere-circle'])
        .selectAll('path')
        .transition()
        .duration(D3Animation.CIRCLE_NAVIGATION_LEAVE)
        .style("stroke-width", "3px")
        .style("opacity",0.7)

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction permet de déterminer la distance entre une position x et y et le centre de la représentation
     * @method
     * @public
     * @param {Number} x Coordonnée x du point
     * @param {Number} y Coordonnée y du point
     */
    distance(x, y) {
      return Math.sqrt(
        Math.pow(this.centerX - x, 2) + Math.pow(this.centerY - y, 2)
      );
    },
  },
};
</script>