<template>
  <g>
    <path
      :id="idRefCircle"
      ref="ref-circle"
    />
    <path
      :id="idInvisibleRefCircle"
      ref="invisible-ref-circle"
    />
    <g :id="idEventsContainer" />
  </g>
</template>

<script>
import MixinRefCircle from '@/components/refCircle/MixinRefCircle.js'
import { mapActions, mapGetters } from 'vuex'
import * as d3 from 'd3'
import * as mutationTypes from '@/store/mutations-types.js'
import eventManager from '@/libraries/EventManager.js'

import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'
import Event from '@/libraries/Event.js'

import { useScoreChecker } from '@/components/refCircle/ScoreChecker.js'

export default {
  mixins: [MixinRefCircle],
  setup() {
    return useScoreChecker('associated-events-circle-event-container', 'associated-events-circle-events')
  },
  data: () => ({
    /**
     * Il s'agit de l'identifiant donné à l'element du dom du cercle des événements associés
     * @type {String}
     */
    idRefCircle: 'associated-events-circle',
    /**
     * Il s'agit de l'identifiant donnée à l'lement du dom du cercle des événements associés invisible permettant d'augmenter la zone de captation des événements de la souris
     * @type {String}
     */
    idInvisibleRefCircle: 'invisible-associated-events-circle',
    /**
     * Il s'agit de l'identifiant donnée au group contenant les cercles des événements du cercle des événements associés
     * @type {String}
     */
    idEventsContainer: 'associated-events-circle-event-container',
    /**
     * Il s'agit de l'identifiant donnée à chacun des cercles des événements du cercle des événements associés
     * @type {String}
     */
    classRefEvents: 'associated-events-circle-events'
  }),
  computed: {
    ...mapGetters({
      labelToDisplay: 'event/common/labelToDisplay',
      hoveredEvent: "event/common/hoveredEvent",
      matchScoreHoveredEvent: "refCircle/score/hoveredEvent"
    }),
    associatedEventsCircle() {
      return this.$store.state.refCircle.associatedEvents.associatedEventsCircle
    }
  },
  watch: {
    associatedEventsCircle() {
      if (this.associatedEventsCircle !== null) {
        this.points = [...this.associatedEventsCircle.points]
        this.events = eventManager.extractEventsFromCircle([this.associatedEventsCircle])
        this.draw()
        this.calcEventCircle()
        this.initEventInvisibleRefCircle()
      } else {
        d3.select(`#${this.idRefCircle}`).attr("d", "");
        d3.select(`#${this.idInvisibleRefCircle}`).attr("d", "");
        this.points = []
        this.events = []
        this.disableScoreEvents()
      }
    },
    hoveredEvent() {
      this.onHoveredEventChange(this.hoveredEvent, this.events)
    },
    matchScoreHoveredEvent() {
      this.onMatchScoreHoveredEventChange(this.matchScoreHoveredEvent, this.events)
    }
  },
  mounted() {
    this.initRefCircleEvents()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * Cette fonction permet l'initialisation des événements d'écoute lors du survol / arret de survol des événements du cercle des événements associés
     */
    initRefCircleEventsEvents() {
      const eventsSelection = d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)

      eventsSelection
        .data(this.events)
        .on("mouseover", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_REF_EVENT}`, d, { root: true })
          this.$store.commit(`event/common/${mutationTypes.PUSH_LABEL_TO_DISPLAY}`, d)
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`,[...this.labelToDisplay])
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.ASSOCIATED_EVENTS_CIRCLE_APPEAR)
            .style('stroke-opacity', 1)
            .style('fill', EyeColor.getFillColorEventCircle(0.8))
          Event.displayOtherLocationsEvent(eventsSelection, d)
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event, d) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.UPDATE_LABEL_TO_DISPLAY}`, this.labelToDisplay.filter((e) => e.id !== d.id))
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_REF_EVENT}`, null, { root: true })
          d3.select(event.currentTarget)
            .transition()
            .duration(D3Animation.ASSOCIATED_EVENTS_CIRCLE_DISAPPEAR)
            .style('stroke-opacity', 0)
            .style('fill', EyeColor.getFillColorEventCircle(0))
          Event.deleteParenthesisOtherLocations(eventsSelection, d)
          this.collaborativeEventTreated()
        })
        .on('contextmenu', (event, d) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.$store.commit(`event/common/${mutationTypes.TOOLTIPS_ADD_EVENTS}`, d)
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction permet l'initialisation d'événements d'écoute sur le cercle des événements associés
     */
    initRefCircleEvents() {
      d3.select(this.$refs['invisible-ref-circle'])
        .on('contextmenu', (event) => {
          this.sendEvent({ event: event })
          event.preventDefault()
          event.stopPropagation()
          this.$store.commit(`refCircle/associatedEvents/${mutationTypes.DISABLE_ASSOCIATED_EVENT_CIRCLE}`)
          this.collaborativeEventTreated()
        })
    }
  }
}
</script>

<style>
#associated-events-circle {
  fill: none;
  stroke: var(--color-primary-accent-opaque);
  stroke-width: 2px;
}

#invisible-associated-events-circle {
  fill: none;
  stroke: transparent;
  stroke-width: 20px;
}
</style>