/**
 * Définit les types d'erreurs globales pouvant se produire dans l'application
 */

export default {
  "SERVER": "SERVER",
  "TOO_MANY_CONCURRENT_USER": 'TOO_MANY_CONCURRENT_USER',
  "NOT_ENOUGHT_CREDIT": "NOT_ENOUGHT_CREDIT",
  "NOT_ALLOWED_TO_PERFORM_ACTION": "NOT_ALLOWED_TO_PERFORM_ACTION",
  "ENTITY_CONTEXT_NOT_ALLOWED": "ENTITY_CONTEXT_NOT_ALLOWED"
}