<template>
  <div>
    <p>
      Votre nombre de crédits est insuffisant pour cette action.
    </p>
    <p class="mt-4">
      Souhaitez-vous faire une demande de renouvellement ?
    </p>
    <p
      v-if="error.length > 0"
      class="mt-4"
    >
      {{ error }}
    </p>
    <div class="flex flex-col items-end">
      <div class="mt-10 w-40">
        <DesignButton
          :text="error.length > 0 ? 'Réessayer' : 'Renouveller'"
          @click="onRenewedCredits"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CreditRenew'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import EyeFetch from '@/libraries/EyeFetch'
import DesignButton from '@/components/DesignButton.vue'

const store = useStore()
const router = useRouter()
const error = ref('')
const errorMessage = 'Une erreur est survenue'

async function onRenewedCredits() {
  try {
    const response = await EyeFetch(store,
      `${process.env.VUE_APP_SERVER_BASE_URL}/user/credits/renew`,
      {
        method: "POST",
        credentials: 'include'
      }
    )

    if (response.ok) {
      error.value = ''
      alert('Votre demande a bien été prise en compte.')
      router.push({ name: 'Home' })
    } else {
      error.value = errorMessage
    }
  } catch (err) {
    error.value = errorMessage
  }
}

</script>