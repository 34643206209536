<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 32.5"
  >
    <g>
      <g>
        <line
          class="st6"
          x1="34.2"
          y1="4.2"
          x2="34.2"
          y2="10.1"
        />
        <line
          class="st6"
          x1="31.2"
          y1="7.2"
          x2="37.1"
          y2="7.2"
        />
      </g>
      <g>
        <circle
          class="st0"
          cx="16.3"
          cy="16.3"
          r="12.1"
        />
        <g>
          <line
            class="st0"
            x1="16.3"
            y1="1.7"
            x2="16.3"
            y2="9.8"
          />
          <line
            class="st0"
            x1="30.9"
            y1="16.3"
            x2="22.8"
            y2="16.3"
          />
          <line
            class="st0"
            x1="16.3"
            y1="30.9"
            x2="16.3"
            y2="22.8"
          />
          <line
            class="st0"
            x1="1.7"
            y1="16.3"
            x2="9.9"
            y2="16.3"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>

</script>

<style scoped>
.st6 {
  fill: none;
  stroke: #FF0000;
  stroke-width: 1.1339;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st0 {
  fill: none;
  stroke: #FF0000;
  stroke-width: 1.0406;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>