<template>
  <div>
    <ModalCollaborativeHostLeave />
    <ModalCollaborativePromotedPresenter />
    <ModalConfirmPresenterDelegation />
    <ModalCollaborativeError />
    <ModalCollaborativeCreateSession />
    <ModalCollaborativeJoinSession />
    <ModalChoseSection />
  </div>
</template>

<script>
import ModalCollaborativeHostLeave from '@/components/modal/collaborative/ModalCollaborativeHostLeave.vue'
import ModalCollaborativePromotedPresenter from '@/components/modal/collaborative/ModalCollaborativePromotedPresenter.vue'
import ModalConfirmPresenterDelegation from '@/components/modal/collaborative/ModalConfirmPresenterDelegation.vue'
import ModalCollaborativeError from '@/components/modal/collaborative/ModalCollaborativeError.vue'
import ModalCollaborativeCreateSession from '@/components/modal/collaborative/ModalCollaborativeCreateSession.vue'
import ModalCollaborativeJoinSession from '@/components/modal/collaborative/ModalCollaborativeJoinSession.vue'
import ModalChoseSection from '@/components/modal/ModalChoseSection.vue'


export default {
  components: {
    ModalCollaborativeHostLeave,
    ModalCollaborativePromotedPresenter,
    ModalConfirmPresenterDelegation,
    ModalCollaborativeError,
    ModalCollaborativeCreateSession,
    ModalCollaborativeJoinSession,
    ModalChoseSection
  }
}
</script>