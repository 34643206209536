
<template>
  <div
    id="container-drag"
    class="container-drag bg-indigo-500"
  >
    <div
      class="form"
      @dragend="handleUIState"
      @dragenter="handleUIState"
      @dragleave="handleUIState"
      @drop="handleUIState"
    >
      <uploadIcon v-show="UIState === 'idle'" />

      <span v-show="UIState === 'idle'">
        <input
          id="file"
          ref="file"
          type="file"
          name="file"
          accept=".csv, .tsv"
          class="inputfile"
          @change="(e) => saveFile(e, $refs['file'].files[0])"
        >
        <label for="file">
          Choisir un fichier
        </label> ou déposer un fichier
      </span>

      <span
        v-if="UIState === 'uploading'"
        class="pointer-events-none"
      >
        <em>Déposez le fichier pour l'ouvrir</em>
      </span>
      <div
        v-if="UIState === 'done'"
        class="done"
      >
        <span
          v-for="(file, key) in files"
          :key="key"
        >
          <strong>{{ file.name }}</strong>
        </span>
        <div class="button_container mt-4">
          <div class="mr-2">
            <DesignButton
              text="Supprimer"
              @clicked="removeFile"
            />
          </div>
          <div class="ml-2">
            <DesignButton
              text="Continuer"
              @clicked="sendFile"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadIcon from '@/assets/icons/uploadIcon.vue';
import DesignButton from "@/components/DesignButton.vue";
import { mapActions } from "vuex"
import EyeFetch from '@/libraries/EyeFetch'

export default {
  name: "DragAndDrop",
  components: {
    DesignButton,
    uploadIcon
  },
  emits: ["next"],
  data() {
    return {
      dragAndDropCapable: false,
      files: [],
      UIState: "idle"
    };
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    determineDragAndDropCapable() {
      var div = document.createElement("div");

      return (
        ("draggable" in div || ("ondragstart" in div && "ondrop" in div)) &&
        "FormData" in window &&
        "FileReader" in window
      );
    },
    saveFile(event, file) {
      this.sendEvent({
        event: event,
        params: {
          text: 'dataset',
          change: true
        }
      })
      if (event.isTrusted) {
        this.files.push(file);
      }
      this.UIState = "done"
      this.collaborativeEventTreated()
    },
    handleUIState(e) {
      e.preventDefault()
      e.stopPropagation()
      const drag_container = document.querySelector("#container-drag")

      if (e.type === "dragenter") {
        drag_container.classList.add("is-dragover")
        this.UIState = "uploading"
      } else if (e.type === 'drop') {
        for (let i = 0; i < e.dataTransfer.files.length; i++) {
          this.saveFile(e, e.dataTransfer.files[i])
        }
      } else {
        drag_container.classList.remove("is-dragover")
        this.UIState = "idle"
      }
    },
    async sendFile(event) {
      if (event.isTrusted) {
        var data = new FormData();
        data.append("file", this.files[0]);
        try {
          await EyeFetch(this.$store,
            `${process.env.VUE_APP_SERVER_BASE_URL}/universal/upload`,
            {
              method: "POST",
              credentials: 'include',
              headers: {
                'Content-type': 'multipart/form-data'
              },
              body: data
            }
          )
          this.sendEvent({event: event})
        } catch (e) {
          console.error(e)
        }
      }
      this.$emit("next")
      this.collaborativeEventTreated()
    },
    submitFiles() {
      let formData = new FormData();
      for (var i = 0; i < this.files.length; i++) {
        let file = this.files[i];
        formData.append("files[" + i + "]", file);
      }
    },
    removeFile() {
      this.files = []
      this.$refs['file'].value = ''
      this.UIState = "idle";
    },
  },
};
</script>

<style scoped>
.form {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  border: dashed 3px var(--c-circle-1-8);
  border-radius: 5px;
}
.done {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.button_container {
  display: flex;
  justify-content: center;
}
.container-drag {
  background: var(--color-shadow);
  flex-grow: 1;
  border-radius: 5px;
  padding: 1%;
  transition: 200ms;
}
.inputfile + label {
  font-weight: 700;
  color: var(--c-circle-1-1);
  display: inline-block;
  cursor: pointer;
}
.inputfile + label:hover {
  color: var(--color-shadow);
}
/* c'est juste pour des questions d'accessibilité qu'on n'a 0.1px et non 0*/
.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.is-dragover {
  background: var(--c-gray-2);
  transition: 300ms;
}
svg {
  margin: 0 auto 5% auto;
  transform: scale(2);
}
span {
  margin: 0 auto;
}

span, span > * {
  font-size: 2rem;
}

.button_container > div {
  width: 10rem;
}
</style>