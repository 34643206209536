<template>
  <g
    id="score-match-lines"
    ref="score-match-lines"
  />
</template>

<script>
import MixinLines from "@/components/event/MixinLines.js";
import eventManager from "@/libraries/EventManager.js";
import EventSelectionTypes from "@/enums/event_selection_types.js";
import * as mutationTypes from "@/store/mutations-types.js";
import _ from "lodash";
import { mapGetters } from "vuex";
import * as d3 from "d3";
import D3Animation from '@/config/D3Animation.js'

import * as EyeColor from '@/assets/color.js'
export default {
  name: "EventFiltering",
  mixins: [MixinLines],
  data: () => ({
    /**
     * Il s'agit de l'id qui sera utilisé pour le groupe contenant les lignes de filtrages
     * @type {String}
     */
    idGroupLines: "score-match-lines",
    idEventsToHighLight: [],
  }),
  computed: {
    ...mapGetters({
      hoveredRefEvent: "event/common/hoveredRefEvent",
      memorizedEvents: "event/common/memorizedEvents",
      selectedEvents: "event/common/selectedEvents",
      events: "event/common/events",
      hoveredEvent: "refCircle/score/hoveredEvent",
    }),
    isDisplayEvents() {
      return this.$store.state.event.common.displayEvents;
    },
  },
  watch: {
    hoveredEvent(newObj) {
      if (newObj) {
        this.showFilters(newObj);
        this.idEventsToHighLight = []
        Object.values(newObj).forEach((group) => {
          Object.values(group.sections).forEach((section) => {
            this.idEventsToHighLight.push(_.last(section.events).id)
          })
        })
        this.getEndLinesEvents(true)
          .style('stroke-opacity', 1)
          .style('fill', EyeColor.getFillColorEventCircle(0.8))
        this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, eventManager.events)
      } else {
        this.removeFiltering();

        this.changeStateEndEvent(false);
        this.getEndLinesEvents(false)
          .style('stroke-opacity', 0)
          .style('fill', EyeColor.getFillColorEventCircle(0))
        this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, eventManager.events)
        this.idEventsToHighLight = [];
      }
    },
    hoveredRefEvent() {
      if (this.hoveredRefEvent === null) {
        this.removeFiltering();

        this.changeStateEndEvent(false);
        this.getEndLinesEvents(false)
          .style('stroke-opacity', 0)
          .style('fill', EyeColor.getFillColorEventCircle(0))
        this.idEventsToHighLight = [];
      } else {
        const scoreMatchEvents = eventManager.getScoreMatchEvents(
          this.hoveredRefEvent
        );
        this.showFilters(scoreMatchEvents);
        this.idEventsToHighLight = []
        Object.values(scoreMatchEvents).map(group => {
          Object.values(group.sections).forEach((section) => {
            this.idEventsToHighLight.push(_.last(section.events).id)
          })
        });

        this.getEndLinesEvents(true)
          .style('stroke-opacity', 1)
          .style('fill', EyeColor.getFillColorEventCircle(0.8))
      }
    },
  },
  methods: {
    /**
     * Permet d'obtenir la selection des événements en fin de lignes dont le cercle doit être mis en surbrillance ou non
     * @method
     * @public
     * @param {Boolean} toHighlight Détermine si les événements en fin de ligne doivent-être mis en surbrillance ou non
     */
    getEndLinesEvents(toHighlight) {
      let selection = d3
        .selectAll(".event")
        .filter((d) => this.idEventsToHighLight.includes(d.id));

      if (!toHighlight) {
        selection = selection.filter((d) =>
          eventManager.isUnhighlightable(
            d,
            this.memorizedEvents,
            this.selectedEvents
          )
        );
      }

      selection = selection.transition().duration(D3Animation.EVENT_APPEAR_CIRCLE);

      return selection;
    },
    /**
     * Lorsque l'utilisateur arrête le filtrage sur un test du score, cette fonction permet de supprimer le type selection scoreMatch qui étaient appliqués sur les événements en fin de ligne de filtrage
     * @method
     * @public
     * @param {Boolean} toHighlight Indique si les événement en fin de ligne doivent être mis en surbrillance ou non
     */
    changeStateEndEvent(toHighlight) {
      if (!toHighlight) {
        const eventsEndLines = this.events.filter((event) =>
          this.idEventsToHighLight.includes(event.id)
        )

        eventsEndLines.forEach((event) => {
          this.$store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
            event,
            selectionType: EventSelectionTypes.SCOREMATCH,
          })
        })
      }
    },
  },
}
</script>