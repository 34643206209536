<template>
  <GenericModal
    :id="id"
    :title="title"
    :description="description"
    :buttons="buttons"
    theme="success"
    @close="onCancel"
  >
    <div
      id="modal-collaborative-join-session-container"
      class="m-b-10"
    >
      <div class="modal-collaborative-join-session-input-session m-b-10">
        <input
          ref="roomIdInput"
          v-model="inputRoomId"
          type="text"
          placeholder="id session"
        >
      </div>
      <p v-if="error.length > 0">
        {{ error }}
      </p>
      <p
        v-if="enableVisio"
        class="m-t-10"
      >
        Session de collaboration en visioconférence
      </p>
      <VisioChooseDevices
        v-if="enableVisio"
        ref="visio-devices-manager"
        class="m-t-10"
      />
    </div>
  </GenericModal>
</template>

<script>
import VisioChooseDevices from '@/components/visio/ChooseDevices.vue'
import GenericModal from '@/components/modal/GenericModal.vue'
import { mapGetters, mapActions } from 'vuex'
import * as d3 from 'd3'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  components: {
    GenericModal,
    VisioChooseDevices
  },
  data: () => ({
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Rejoindre une session',
    /**
     * Description de la modal
     * @type {String}
     */
    description: '',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-collaborative-join-session",
    /**
     * Identifiant de la session collaborative que l'utilisateur souhaite rejoindre
     * @type {String}
     */
    inputRoomId: '',
    /**
     * Détermine si les options permettant de choisir ses périphériques audio / video pour la visio sont activées
     * @type {Boolean}
     */
    enableVisio: false,
    /**
     * Permet de stocker l'erreur qui sera affiché sur la modal, lorsqu'une erreur s'est produite
     * @type {String}
     */
    error: ''
  }),
  computed: {
    ...mapGetters({
      isInCollaboration: "ws/isInCollaboration",
      roomId: "ws/roomId",
      stateModalSessionJoin: "ws/stateModalSessionJoin",
      wsSocket: "ws/wsSocket"
    }),
  },
  watch: {
    stateModalSessionJoin() {
      if (this.stateModalSessionJoin && !this.isInCollaboration) {
        const elem = document.getElementById(this.id)
        if (elem.open === false) {
          elem.showModal()
        }
      }
    },
    wsSocket() {
      this.setupWsEvent()
    }
  },
  mounted() {
    this.setButtons()
    this.setupWsEvent()
  },
  methods: {
    ...mapActions({
      joinRoom: "ws/joinRoom",
      leaveRoom: "ws/leaveRoom"
    }),
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Rejoindre',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: this.onConfirm
        },
        {
          label: 'Annuler',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onCancel
        }
      ]
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur appuie sur le bouton de confirmation de la modal. Elle permet de sauvegarder les périphériques audio / video choisit par l'utilisateur pour la visio et de rejoindre la session collaborative
     */
    async onConfirm() {
      const elem = document.getElementById(this.id)
      
      if (this.inputRoomId.length > 5) {
        if (this.isInCollaboration) {
          await this.leaveRoom()
        }
        if (this.enableVisio) {
          this.$refs['visio-devices-manager'].saveDevices()
          this.$refs['visio-devices-manager'].endPreview()
        }
        this.joinRoom(this.inputRoomId);
        this.enableVisio = false
        this.inputRoomId = ''
        this.$store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_JOIN}`, false);
      }
      elem.close()
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur le bouton annuler ou la croix de la modal. Elle annule la connexion à la session collaborative
     */
    onCancel() {
      const elem = document.getElementById(this.id)
      if (this.enableVisio) {
        this.$refs['visio-devices-manager'].endPreview()
      }
      elem.close()
      this.inputRoomId = ''
      this.enableVisio = false
      this.$store.commit(`ws/${mutationTypes.SET_STATE_MODAL_SESSION_JOIN}`, false);
    },
    setupWsEvent() {
      if (this.wsSocket === null) {
        return
      }

      d3.select(this.$refs['roomIdInput'])
        .on('input', () => {
          this.wsSocket.emit('collaborative:roomDetails', {
            roomId: this.inputRoomId
          })
        })

      this.wsSocket.on('collaborative:roomDetails', (roomData) => {
        if (!roomData) {
          this.error = 'Cette session n\'est pas disponible'
          this.enableVisio = false
          return
        }

        this.error = ''
        this.enableVisio = roomData.visio === true
      })
    }
  }
}
</script>

<style scoped>

#modal-collaborative-join-session-container {
  font-size: 1.1rem;
}

.modal-collaborative-join-session-input-session {
  display: flex;
  flex-direction: column;
}

</style>