import { score_33, score_36, score_35, score_37 } from './scores.js'
import { calcDESIR, calculFindrisc } from '@/score/diabete.js'
import { calc8, calc9 } from '@/score/welcoop.js'
import { defaultCalc } from '@/score/default.js'
import { calcStatsAssociated } from '@/score/statsAssociated.js'

/**
 * Cette fonction générique de calcul de score permet d'executer la bonne fonction de calcul en fonction de l'id du score
 * @param {EyeScoreChecker} patientData ScoreChecker du patient avec le score
 * @param {Object} patient Informations personnelles basiques du patient
 * @param {Number} num Identifiant du score
 * @returns {EyeScoreResult} Objet contenant les résultats du score
 */
export function calculate(patientData, patient, num, store) {
  if (num === null && store.state.refCircle.associatedEvents.srcEvent !== null) {
    return calcStatsAssociated(patientData, store)
  }
  
  switch (num) {
  case 9:
    return calc9()
  case 8:
    return calc8()
  case 7:
    return calculFindrisc(patientData, patient)
  case 6:
    return calcDESIR(patientData, patient)
  case 4:
    return calculate33(patientData, patient)
  case 3:
    return calculate35(patientData, patient)
  case 2:
    return calculate36(patientData, patient)
  case 1:
    return calculate37(patientData)
  default:
    return defaultCalc(patientData)
  }
}

/**
 * Cette fonction permet d'obtenir les résultats du score ChadsVasc appliqué au patient
 * @param {EyeScoreChecker} patientData ScoreChecker du patient avec le score
 * @param {Object} patient Informations personnelles basiques du patient
 * @returns {EyeScoreResult} Objet contenant les résultats du score
 */
function calculate35(patientData, patient) {
  let res = 0
  let mort = ''
  let recom = ''

  let risques = [0.78, 2.01, 3.71, 5.92, 9.27, 15.26, 19.74, 21.5, 22.38, 23.64]
  let conseil = ['pas d\'anticoagulants',
    'anticoagulants possible si homme',
    'anticoagulants si homme, possible si femme',
    'anticoagulants', 'anticoagulants',
    'anticoagulants', 'anticoagulants',
    'anticoagulants', 'anticoagulants',
    'anticoagulants']

  for (const property in patientData) {
    if (patientData[property]["display"]) {
      res += score_35[property].res
    }
  }
  mort = risques[res]
  recom = conseil[res]

  return {
    scoreAlert: `ChadsVasc: ${res} / Risque AVC:  ${mort} % / Conseil: ${recom}`,
    scoreTitle: 'ChadsVasc',
    scoreRes: res,
    scoreRecom: recom
  }
}

/**
 * Cette fonction permet d'obtenir les résultats du score "Facteur aggravants COV-19" appliqué au patient
 * @param {EyeScoreChecker} patientData ScoreChecker du patient avec le score
 * @returns {EyeScoreResult} Objet contenant les résultats du score
 */
function calculate37(patientData) {
  let res = 0
  let rsk = 0

  for (const property in patientData) {
    if (patientData[property]["display"])
      res += score_37[property].res
  }

  rsk = Math.ceil((res / 14) * 100)

  return {
    scoreAlert: `Facteur aggravants COV-19 ${rsk}%`,
    scoreTitle: 'Facteur aggravants COV-19',
    scoreRsk: rsk,
    scoreRes: res,
  }
}

/**
 * Cette fonction permet d'obtenir les résultats de l'EuroScore2 appliqué au patient
 * @param {EyeScoreChecker} patientData ScoreChecker du patient avec le score
 * @param {Object} patient Informations personnelles basiques du patient
 * @returns {EyeScoreResult} Objet contenant les résultats du score
 */
function calculate33(patientData, patient) {
  let res = 0
  let mortXi = 0
  let agePts = Math.floor((patient.age - 55) / 5)

  if (agePts < 0) agePts = 0
  if (agePts > 10) agePts = 10
  res = agePts

  let ageLog = patient.age - 58
  if (ageLog < 1) ageLog = 1
  mortXi = 0.0666354 * ageLog

  if (patient.sexe === 'F') {
    res += 1
    mortXi += 0.3304052
  }
  for (const property in patientData) {
    if (patientData[property]["display"]) {
      res += score_33[property].res
      mortXi += score_33[property].mortXi
    }
  }

  let mortExp = Math.exp(-4.789594 + mortXi)
  let mort = Math.floor(mortExp / (1 + mortExp) * 10000) / 100 + '%'

  return {
    scoreAlert: `EuroScore2: ${res} /Mortalité: ${mort}`,
    scoreTitle: 'EuroScore2',
    scoreRes: res,
    mort: mort
  }
  // format => `Facteur aggravants COV-19: ${rsk}%`,   
}

/**
 * Cette fonction permet d'obtenir les résultats du score HasBled appliqué au patient
 * @param {EyeScoreChecker} patientData ScoreChecker du patient avec le score
 * @param {Object} patient Informations personnelles basiques du patient
 * @returns {EyeScoreResult} Objet contenant les résultats du score
 */
function calculate36(patientData, patient) {
  let res = 0
  let risques = [1.9, 2.5, 5.3, 8.4, 10.4, 12.3, 12.3, 12.3, 12.3, 12.3]
  let rsk = null
  let recom = ''

  for (const property in patientData) {
    if (patientData[property]["display"])
      res += score_36[property].res
  }
  rsk = risques[res]

  if (rsk > 3) {
    recom = '«haut risque», prudence et surveillance si méd. anti-thrombotique'
  }

  return {
    scoreAlert: `HasBled: ${rsk} `,
    scoreTitle: 'HasBled',
    scoreRes: rsk,
    scoreRecom: recom ? `Conseil: ${recom}`: ''
  }
}