<template>
  <div class="merge-wrapper">
    <h1>Fusion catégories</h1>
    <ul>
      <li
        v-for="(section) in sections"
        :id="`merge-${section.id}`"
        :key="section.id"
        :section-index="section.id"
        class="merged"
        draggable="true"
        @dragstart="(e) => drag(e, section)"
        @drop="drop"
        @dragover="allowDrop"
      >
        {{ section.name }}
      </li>
    </ul>
  </div>
</template>

<script>
import * as mutationTypes from "@/store/mutations-types.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import {mapGetters, mapActions} from "vuex"

let draggingSection = null

export default {
  name: "MergeDate",
  props: {
    columns: {
      type: Array,
      default: null,
    },
  },
  emits: ["next"],
  data: () => ({
    sections: []
  }),
  computed:{
    ...mapGetters({
      asSection: "asSection",
      hierarchy: 'hierarchy'
    })
  },
  mounted() {
    this.sections = this.columns.map((column, i) => ({
      name: column,
      id: i,
      content: [{
        hierarchy: [{
          type: this.asSection,
          value: column
        }]
      }],
      updateSectionContent: {
        type: 'Universal'
      }
    }))
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      getDataRepresentation: 'circle/getDataRepresentation'
    }),
    allowDrop(event) {
      this.sendEvent({event: event})
      event.preventDefault();
      this.collaborativeEventTreated()
    },
    drag(event, section) {
      this.sendEvent({event: event})
      draggingSection = section
      this.collaborativeEventTreated()
    },
    drop(event) {
      event.preventDefault()
      this.sendEvent({event: event})
      const mergedIndex = event.target.getAttribute('section-index')

      if (mergedIndex == draggingSection.id) {
        this.collaborativeEventTreated()
        return
      }

      const section = this.sections.find(s => s.id == mergedIndex)
      section.content = [...section.content, ...draggingSection.content]
      section.name += `-${draggingSection.name}`

      this.sections = this.sections.filter(s => s.id !== draggingSection.id)
      this.collaborativeEventTreated()
    },
    async sendSections(event) {
      this.sendEvent({event: event})
      let data = this.sections
      let selection = {
        sections: [...data],
        hierarchy: this.hierarchy
      }
      this.$store.commit(mutationTypes.SET_HISTORY_SECTIONS, [
        selection,
      ])
      //Reset de l'index de l'historique des sections selectionnées par l'utilisateur
      this.$store.commit(mutationTypes.SET_INDEX_HISTORY_SECTIONS, 0)
      
      if (this.$route.name === 'EyeApp') {
        await this.getDataRepresentation({
          unitPerCircle: this.$store.state.circle.unitPerCircle,
          periodUnit: this.$store.state.circle.periodUnit,
          reloadDataTypes: reloadDataTypes.CATEGORISATION
        })
      } else {
        this.$router.push({ name: "EyeApp" })
      }
      this.collaborativeEventTreated()
    },
  },
};
</script>
<style scoped>
h1 {
  padding: 0 2rem;
}
ul {
  columns: 3;
}
li {
  padding: 5px;
  margin: 5px;
  list-style-type: none;
  background: var(--c-gray-soft-1);
}
</style>