<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./10.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Il est possible de ne faire apparaître seulement certaines catégories.</p>
    <p>Pour cela, un survol dans cette zone de l'interface permet de faire apparaître en bleu la portion de la catégorie survolée</p>
    <p>Ensuite, il suffit de sélectionner les catégories voulue, puis de valider en cliquant sur le bouton bleu central.</p>
  </div>
</template>
  
<script>
export default {
  name: "Component10Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>