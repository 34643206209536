<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 134.52 42.48"
  >
    <g>
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M52.58,11.8c-4.64,0-8.13,3.74-8.13,8.69v.07c0,5.07,3.56,8.76,8.46,8.76a8.27,8.27,0,0,0,6.2-2.81.17.17,0,0,0,0-.14c-.1-.25-.16-.4-.24-.58l-.06-.15a.16.16,0,0,0-.1-.08.12.12,0,0,0-.13.05,7.6,7.6,0,0,1-5.67,2.78c-4.15,0-7.26-3.15-7.45-7.5H60.23a.13.13,0,0,0,.14-.13v-.14a10,10,0,0,0-1.89-6A7.12,7.12,0,0,0,52.58,11.8ZM45.49,20c.23-4.29,3.11-7.27,7-7.27,4.23,0,6.6,3.63,6.79,7.27Z"
      />
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M16.68,11.8c-4.63,0-8.12,3.74-8.12,8.69v.07c0,5.07,3.56,8.76,8.46,8.76a8.27,8.27,0,0,0,6.2-2.81.14.14,0,0,0,0-.14c-.09-.25-.15-.39-.22-.57L23,25.64a.16.16,0,0,0-.1-.08.12.12,0,0,0-.13.05,7.6,7.6,0,0,1-5.67,2.78c-4.15,0-7.27-3.15-7.45-7.5H24.34a.13.13,0,0,0,.14-.13v-.14a10,10,0,0,0-1.89-6A7.13,7.13,0,0,0,16.68,11.8ZM9.6,20c.22-4.29,3.11-7.27,7-7.27,4.23,0,6.6,3.63,6.78,7.27Z"
      />
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M80.76,4.62h-.7a.14.14,0,0,0-.13.14v11.9a8,8,0,0,0-7.56-4.86,8.41,8.41,0,0,0-8.46,8.73v.06a8.41,8.41,0,0,0,8.46,8.73,8,8,0,0,0,7.56-4.87v4.34a.13.13,0,0,0,.13.13h.7a.13.13,0,0,0,.13-.13v-24A.14.14,0,0,0,80.76,4.62ZM80,20.53v.06a7.6,7.6,0,0,1-7.56,7.8,7.5,7.5,0,0,1-7.49-7.83v-.07a7.38,7.38,0,0,1,7.49-7.76A7.6,7.6,0,0,1,80,20.53Z"
      />
      <rect
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13" 
        x="86.39" 
        y="5.61" 
        width="1.3" 
        height="2.15" 
        rx="0.13"
      />
      <rect
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13" 
        x="86.55" 
        y="12.2" 
        width="0.97" 
        height="16.73" 
        rx="0.13"
      />
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M99.34,11.93a13,13,0,0,0-5.89,1.46.14.14,0,0,0-.06.18l.27.59a.1.1,0,0,0,.07.07.11.11,0,0,0,.11,0,12.27,12.27,0,0,1,5.47-1.37c4,0,6.15,2,6.15,5.72v1.2a19.52,19.52,0,0,0-6.12-1c-4.59,0-7.56,2.11-7.56,5.37v.07c0,3.48,3.3,5.07,6.36,5.07,3.74,0,6.31-1.87,7.32-3.79v3.26a.13.13,0,0,0,.14.13h.7a.13.13,0,0,0,.13-.13V18.55a6.54,6.54,0,0,0-1.67-4.77A7.4,7.4,0,0,0,99.34,11.93Zm6.12,8.82v2c0,3.47-3.78,5.62-7.28,5.62-4,0-5.4-2.25-5.4-4.18v-.06c0-3.25,3.41-4.41,6.59-4.41A17.18,17.18,0,0,1,105.46,20.75Z"
      />
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M127,12.2h-.7a.13.13,0,0,0-.14.13v3.79a8.29,8.29,0,0,0-7.35-4.32,8.17,8.17,0,0,0-8.36,7.94v.06a8.15,8.15,0,0,0,8.36,7.9,8.27,8.27,0,0,0,7.35-4.32v2.78c0,4.58-2.64,7.2-7.25,7.2a11.32,11.32,0,0,1-7-2.41.13.13,0,0,0-.18,0l-.44.56a.12.12,0,0,0,0,.1.12.12,0,0,0,0,.09,12.59,12.59,0,0,0,7.64,2.56,8.35,8.35,0,0,0,6-2.15,8,8,0,0,0,2.17-5.85v-14A.13.13,0,0,0,127,12.2Zm-.77,7.57v.06a7.39,7.39,0,0,1-14.75-.06V19.7a7.18,7.18,0,0,1,7.36-7A7.31,7.31,0,0,1,126.24,19.77Z"
      />
      <path
        class="a727f6e9-7147-4478-a8b6-f2d4725c9a13"
        d="M42.67,12.2h-.73a.14.14,0,0,0-.13.08l-7,15.47L27.31,12.27a.14.14,0,0,0-.12-.07h-.77a.13.13,0,0,0-.11.06.12.12,0,0,0,0,.13l8,16.4-3,6.52a.14.14,0,0,0,0,.11l.3.72a.14.14,0,0,0,.12.09h0a.13.13,0,0,0,.12-.08L42.79,12.39a.12.12,0,0,0,0-.13A.11.11,0,0,0,42.67,12.2Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "EyediagLogo"
}
</script>

<style scoped>
.a727f6e9-7147-4478-a8b6-f2d4725c9a13 {
  fill: var(--c-logo-eyediag);
}

</style>