import circlesConfig from '@/config/circle'
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import CircleUtility from '@/libraries/CircleUtility'

/**
 * Cette classe permet la gestion de l'animation de chargement lorsque des données sont en cours d'obtention depuis le serveur
 */

class LoadingAnimation {
  constructor() {
    /**
     * Détermine si les données sont en cours de transfert du serveur vers le client
     * @type {Boolean}
     */
    this.isLoadingData = false
    this.active = false
    this.resolve = null
  }

  /**
   * Setter de la variable isLoadingData
   * @param {Boolean} isLoadingData Détermine si les données sont en cours de transfert du serveur vers le client
   */
  setIsLoadingData(isLoadingData) {
    this.isLoadingData = isLoadingData
    if (this.resolve !== null && this.isLoadingData === false) {
      this.resolve()
      this.resolve = null
    }
  }

  /**
   * Permet l'exécution de l'animation de chargement
   * @param {Store} store Référence du store vuex de l'application
   */
  async waiting(store) {
    this.active = true

    const duration = D3Animation.ANIMATION_LOADING_BLINK
    const time = 80
    let promises = []
    let i = 0

    try { 
      if (store.state.circle.circles.length > 0) {
        for (i = 0; i < circlesConfig.length; i++) {
          if (!this.isLoadingData) {
            i = 0
            return
          }
    
          if (!CircleUtility.isVisibleCircle(i)) {
            continue
          }
    
          promises.push(
            d3.select('#displayCircle')
              .selectAll(".circle")
              .filter(c => c.circleData.configIndex === i)
              .transition()
              .duration(duration)
              .delay(i * time)
              .style('opacity', 0)
              .end()
          )
        }
    
        await Promise.all(promises)
    
        promises = []
    
        for (i--; i > 0; i--) {
          if (!this.isLoadingData) {
            return
          }
    
          if (!CircleUtility.isVisibleCircle(i)) {
            continue
          }
    
          promises.push(
            d3.select('#displayCircle')
              .selectAll(".circle")
              .filter(c => c.circleData.configIndex === i)
              .transition()
              .duration(duration)
              .delay((circlesConfig.length - i) * time)
              .style("opacity", (d) => circlesConfig[d.configIndexBounded].opacity)
              .end()
          )
        }

        await Promise.all(promises)

        if (this.isLoadingData) {
          await this.waiting(store)
        } else {
          await d3.select('#displayCircle')
            .selectAll(".circle")
            .filter(c => CircleUtility.isVisibleCircle(c.circleData.configIndex))
            .transition()
            .duration(duration)
            .style("opacity", (d) => circlesConfig[d.configIndexBounded].opacity)
            .end()
        }
      } else { //S'il n'y a pas de circle l'animation ne peux pas se faire
        if (this.isLoadingData) {
          await new Promise(resolve => {
            this.resolve = resolve
          })
        }
      }
    } catch {}
  }

  interruptAnimation() {
    if (this.active) {
      d3.select('#displayCircle')
        .selectAll(".circle")
        .interrupt()
    }
    this.active = false
  }
}

export default new LoadingAnimation()