<template>
  <ListCollapsableItem>
    <template #title>
      <p class="font-bold">
        UM
      </p>
    </template>
    <template #content>
      <div class="mt-2">
        <label class="title-checkbox-container">Activité</label>
        <div
          class="checkbox-container mt-2"
          @mousemove="moveCheckBox"
        >
          <div
            v-for="(activite, index) in listActivite"
            :key="index"
          >
            <input
              v-model="selectedActivite"
              type="checkbox"
              :value="activite"
              @click="onCheckbox"
            >
            <label>{{ activite }}</label>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <label class="title-checkbox-container">Discipline</label>
        <div
          class="checkbox-container mt-2"
          @mousemove="moveCheckBox"
        >
          <div
            v-for="(discipline, index) in listDiscipline"
            :key="index"
          >
            <input
              v-model="selectedDiscipline"
              type="checkbox"
              :value="discipline"
              @click="onCheckbox"
            >
            <label>{{ discipline }}</label>
          </div>
        </div>
      </div>
    </template>
  </ListCollapsableItem>
</template>

<script>
import ListCollapsableItem from '@/components/borderLists/CollapsableItem.vue'
import { mapActions } from 'vuex'
import EyeFetch from '@/libraries/EyeFetch'
import utils from '@/libraries/utils.js'

export default {
  name: 'FiltersPopulationalCode',
  components: {
    ListCollapsableItem
  },
  data: () => ({
    listActivite: [],
    listDiscipline: [],
    selectedDiscipline: [],
    selectedActivite: []
  }),
  mounted() {
    this.getListFilters()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      sendProperties: "ws/sendProperties",
    }),
    /**
     * @public
     * @async
     * @param {*} event 
     */
    async onCheckbox(event) {
      this.sendEvent({event: event})
      this.collaborativeEventTreated()
    },
    /**
     * @public
     */
    getFilters() {
      const filters = {}

      if (this.selectedActivite.length > 0) {
        filters.UFact = this.selectedActivite.join('~')
      }
      if (this.selectedDiscipline.length > 0) {
        filters.discipline = this.selectedDiscipline.join('~')
      }

      return filters
    },
    moveCheckBox(event) {
      utils.sendCheckScroll(event, this.$store)
    },
    /**
     * @public
     * @async
     */
    async getListFilters() {
      try {
        let response = await EyeFetch(this.$store,
          `${process.env.VUE_APP_SERVER_BASE_URL}/fhir/populational/filters/um`,
          {
            method: "GET",
            credentials: 'include'
          }
        )
        response = await response.json()
        this.listDiscipline = response.umDiscipline
        this.listActivite = response.umActivite
      } catch {
        this.listDiscipline = []
        this.listActivite = []
      }
    }
  }
}
</script>

<style scoped>
.checkbox-container {
  overflow: scroll;
  height: 150px;
}
.title-checkbox-container {
  color: var(--color-text);
  font-weight: bold;
}
</style>