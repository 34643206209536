<template>
  <ListBase
    ref="list-base"
    title="Racine"
  >
    <template #content>
      <div>
        <input
          v-model="hierarchy"
          placeholder="Racine"
        >
        <button @click="validateRacine">
          Valider
        </button>
      </div>
    </template>
  </ListBase>
</template>

<script>
import _ from 'lodash'
import { mapActions } from 'vuex'
import ListBase from '@/components/borderLists/ListBase.vue'
import * as mutationTypes from '@/store/mutations-types.js'
import * as hierarchy from '@/shared/enums/hierarchy.js'
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

export default {
  components: {
    ListBase
  },
  data: () => ({
    hierarchy: ''
  }),
  methods: {
    ...mapActions({
      'sendEvent': 'ws/sendEvent',
      'collaborativeEventTreated': 'ws/collaborativeEventTreated',
      'getDataRepresentation': 'circle/getDataRepresentation'
    }),
    /**
     * Permet la gestion de l'ouverture / fermeture de la liste
     * @param  {...any} params Paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    validateRacine() {
      this.$store.commit(mutationTypes.SET_HIERARCHY, {
        type: hierarchy.type.USER,
        id: this.hierarchy
      })
      this.getDataRepresentation({
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })
      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
    }
  }
}
</script>

<style scoped>
#choose-events-types-list-content {
  margin-inline: 5px;
}

h3 {
  display: flex;
  justify-content: center;
}

.validate-type-events {
  display: flex;
  flex-direction: column;
}

</style>