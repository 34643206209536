<template>
  <defs>
    <PieRadialGradient
      v-for="(pieTheme, index) in pieThemes"
      :key="index"
      :color="pieTheme.color"
      :neutral="pieTheme.neutral"
      :def-id="pieTheme.id"
    />
  </defs>
</template>

<script>
import PieRadialGradient from './PieRadialGradient.vue'

export default {
  name: 'EyeDefs',
  components: {
    PieRadialGradient
  },
  data: () => ({
    /**
     * Il s'agit de l'ensemble des thèmes pouvant s'appliquer à la pie
     * @type {EyePieTheme[]}
     */
    pieThemes: []
  }),
  mounted() {
    this.pieThemes = [
      {
        id: 'aquam-gradient',
        color: "var(--color-hover-1)",
        neutral: "var(--color-bg-0)"
      },
      {
        id: 'cyan-gradient',
        color: "var(--c-blue-cyan)",
        neutral: "var(--color-bg-0)"
      },
      {
        id: 'red-gradient',
        color: "var(--color-primary-accent-opaque)",
        neutral: "var(--color-bg-0)"
      },
      {
        id: 'orange-gradient',
        color: "var(--c-orange)",
        neutral: "var(--color-bg-0)"
      },
      {
        id: 'yellow-gradient',
        color: "var(--c-yellow)",
        neutral: "var(--color-bg-0)"
      },
    ]
  }
}

</script>