class CollaborativeUtils {
  static async onInput(store, event, text, radio, customCodeToExec = null) {
    store.dispatch('ws/sendEvent', {
      event: event,
      params: {
        text: text,
        change: radio === true
      }
    })

    if (customCodeToExec !== null) {
      await customCodeToExec()
    }
  
    store.dispatch('ws/collaborativeEventTreated')
  }
}

export default CollaborativeUtils