<template>
  <div class="tutorialDiv">
    <div class="box">
      <div class="utililtyButtons">
        <span
          style="cursor: pointer; color: grey"
          @click="changeComponent(-1)"
        >
          &#65124;
        </span>
        <span
          style="cursor: pointer; color: grey"
          @click="closeTutorial"
        >
          &#x2715;
        </span>
      </div>
      <component :is="currentComponent" />
      <div class="buttonContainer">
        <button
          class="button"
          @click="changeComponent(1)"
        >
          Suivant
        </button>
      </div>
      <span style="color: grey">{{ currIndex + 1 }}/{{ nbComponents }}</span>
    </div>
  </div>
</template>

<script>
import Component1  from '@/components/tutorial/decouverte/Component1.vue'
import Component2  from '@/components/tutorial/decouverte/Component2.vue'
import Component3  from '@/components/tutorial/decouverte/Component3.vue'
import Component4  from '@/components/tutorial/decouverte/Component4.vue'
import Component5  from '@/components/tutorial/decouverte/Component5.vue'

export default {
  name: "TutorialDecouverte",
  data() {
    return {
      currentComponent: Component1,
      currIndex: 0,
      allComponents: [Component1, Component2, Component3, Component4, Component5],
      nbComponents: 0
    };
  },
  created() {
    this.nbComponents = this.allComponents.length
  },
  methods: {
    changeComponent(itt) {
      this.currIndex += itt
      if (this.currIndex >= this.allComponents.length)
        this.currIndex = 0
      if (this.currIndex < 0)
        this.currIndex = 0
      this.currentComponent = this.allComponents[this.currIndex]
    },
    closeTutorial() {
      document.querySelector('.tutorialDiv').remove()
    }
  }
}
</script>

<style scoped>
.box {
  position: absolute;
  width: 90vw;
  height: 85vh;
  margin-left: 2.5vw;
  margin-top: 5vh;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  padding-top: 2.5vh;
  padding-bottom: 2.5vh;
  border: 1px solid #c0c0c0;
  background-color: rgba(128,128,128,.5);
  border-radius: 5px;
  backdrop-filter: blur(5px);
  font-family: var(--font-secondary-1);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
}

.button {
  align-items: center;
  background-color: #689acc;
  border: 0;
  border-radius: 10px;
  color: #201e1eea;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.button:hover, .button-18:focus { 
  background-color: #3e6596;
  color: #ffffff;
}

.button:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.utililtyButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1vh;
}
</style>