<template>
  <div v-if="patientData !== null">
    <h2>{{ patientData.name }} {{ patientData.lastname.toUpperCase() }}</h2>
    <div class="circle-container">
      <svg
        :width="circleDiameter"
        :height="circleDiameter"
        @transitionend="resetCircleSize"
      >
        <circle
          key="circleKey"
          cx="50%"
          cy="50%"
          :r="circleRadius"
          fill="none"
          stroke="var(--color-text)"
          stroke-width="2"
          :style="{ transition: `${circleTransition}, filter 0.5s ease-in-out`, filter: `blur(${blurStdDeviation}px)` }"
        />
      </svg>
    </div>

    <div>
      <p v-if="patientData.name">
        <strong>Prénom:</strong> {{ patientData.name }}
      </p>
      <p v-if="patientData.lastname">
        <strong>Nom:</strong> {{ patientData.lastname }}
      </p>
      <p v-if="patientData.gender">
        <strong>Sexe:</strong> {{ patientData.gender }}
      </p>
      <br>
      <p v-if="patientData.birthDate">
        <strong>Age:</strong>       {{ calculateAge(patientData.birthDate) }}
      </p>
      <p v-if="patientData.birthDate">
        <strong>Date de naissance:</strong> {{ patientData.birthDate }}
      </p>
      <br>

      <p v-if="patientData.height">
        <strong>Taille:</strong> {{ patientData.height }}
      </p>
      <p v-if="patientData.weight">
        <strong>Poids:</strong> {{ patientData.weight }}
      </p>
      <p v-if="imc">
        <strong>IMC:</strong> {{ imc[0] }} : {{ imc[1] }}
      </p> 
      <br>

      <small><strong>ID:</strong> {{ patientData.id }}</small>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    patientData: {
      type: [Object, null],
      required: true,
    },
  },

  data() {
    return {
      circleDiameter: 200,
      circleRadius: 60,
      circleTransition: 'none',
      blurStdDeviation: 0,
      circleKey: 0
    };
  },
  
  computed: {
    imc() {
      if (this.patientData.height && this.patientData.weight) {
        return this.calculerIMC(this.patientData.height, this.patientData.weight)
      }
      return null
    }
  },
  watch: {
    patientData() {
      this.triggerTransition()
    }
  },
  mounted() {
    // Apply initial styles to prevent initial transition issues
    const circleElement = this.$refs.circle
    if (circleElement) {
      circleElement.style.transition = 'filter 0.5s ease-in-out'
      circleElement.style.filter = 'blur(0)'
    }
  },
  methods: {
    triggerTransition() {
      this.circleTransition = 'r 0.5s ease-in-out'
      this.circleKey += 1
      this.$nextTick(() => {
        if (this.circleRadius < 90){
          this.circleRadius *= 1.1
        }
        this.blurStdDeviation = this.blurStdDeviation >= 1
          ? this.blurStdDeviation * 1.04
          : 1
      })
    },
    resetCircleSize() {
      this.circleRadius = 60
      this.blurStdDeviation = 0
      this.circleTransition = 'none'
    },
    
    calculateAge(birthdate) {
      const today = new Date();
      const birthDate = new Date(birthdate);
      
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDiff = today.getMonth() - birthDate.getMonth();
      
      if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      
      return age;
    },

    calculerIMC(taille, poids) {
      const imc = poids / ((taille / 100) ** 2)
      let roundedValue = Math.round(imc * 10) / 10
      roundedValue = roundedValue % 1 === 0 ? Math.round(roundedValue) : roundedValue.toFixed(1)
      let description
      if (roundedValue < 18.5) {
        description = 'Insuffisant'
      }
      else if (roundedValue >= 18.5 && roundedValue < 25) {
        description = 'Normal'
      }
      else if (roundedValue >= 25 && roundedValue < 30) {
        description = 'Surpoids'
      }
      else if (roundedValue >= 30 && roundedValue < 35) {
        description = 'Obesité classe I'
      }
      else if (roundedValue >= 35 && roundedValue < 40) {
        description = 'Obesité classe II'
      }
      else if (roundedValue >= 40) {
        description = 'Obesité classe III'
      }
      
      return [roundedValue, description]
    }
  }
}
</script>

<style scoped>
.circle-container {
  width: 100%;
  max-width: 400px;
  margin: 20px auto;
}

.circle-container svg circle {
  filter: blur(0);
  transition: filter 0.5s ease-in-out;
}

</style>
