<template>
  <div style="font-size: 2rem; color: white;">
    <img
      class="image"
      src="./17.png"
    >
    <p>Le 5ème permet d'accéder aux paramètres généraux de l'application.</p>
  </div>
</template>

<script>
export default {
  name: "Component17Tuto",
}
</script>
    
<style scoped>
p {
  padding-bottom: 10px;
}
.image {
  width: 100%;
  padding-bottom: 10px;
}
</style>