<template>
  <div>
    <a
      href="#"
      class="patient-info_name"
      @click="onShowConfig"
    >
      Mode Universel
    </a>
    <GenericModal
      :id="id"
      :title="title"
      :description="description"
      :buttons="buttons"
      theme="success"
      @close="onCancel"
    >
      <UniversalSteps
        ref="universal-steps"
        :import-file="false"
        :default-step-button="false"
        @change-step="onChangeStep"
      />
    </GenericModal>
  </div>
</template>

<script>
import GenericModal from '@/components/modal/GenericModal.vue'
import UniversalSteps from '@/components/universal/Steps.vue'
import { mapActions } from 'vuex'

export default {
  name: 'MainHudUniversal',
  components: {
    GenericModal,
    UniversalSteps
  },
  data: () => ({
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Configuration',
    /**
     * Description de la modal
     * @type {String}
     */
    description: '',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-universal-configuration"
  }),
  mounted() {
    this.setButtons()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
    }),
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Suivant',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: this.onConfirmConfigForm
        },
        {
          label: 'Annuler',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onCancel
        }
      ]
    },
    onShowConfig(event) {
      this.sendEvent({ event: event })
      const elem = document.getElementById(this.id)
      if (elem.open === false) {
        elem.showModal()
      }
      this.collaborativeEventTreated()
    },
    onCancel(event) {
      this.sendEvent({event: event})
      const elem = document.getElementById(this.id)
      elem.close()
      this.collaborativeEventTreated()
    },
    onChangeStep(step) {
      let onClick = null
      let label = null

      if (step === 'form') {
        label = 'Suivant'
        onClick = this.onConfirmConfigForm
      } else if (step === 'orderSeverity') {
        label = 'Suivant'
        onClick = this.onConfirmOrderedSeverity
      } else if (step === 'merge') {
        label = 'Valider'
        onClick = this.onConfirmMergedSections
      }

      this.buttons.shift()
      this.buttons.unshift({
        label: label,
        backgroundColorTheme: 'success',
        color: 'white',
        onClick: onClick
      })
    },
    async onConfirmConfigForm(event) {
      this.$refs['universal-steps'].onValidateConfig(event)
    },
    async onConfirmOrderedSeverity(event) {
      this.$refs['universal-steps'].onValidateOrderedSeverity(event)
    },
    async onConfirmMergedSections(event) {
      this.$refs['universal-steps'].onValidateMergedSections(event)
      const elem = document.getElementById(this.id)
      if (elem) {
        elem.close()
      }
    }
  }
}
</script>