<template>
  <g
    id="group-lines-report"
    ref="group-lines-report"
  /> 
</template>

<script>

import { mapGetters } from 'vuex'
import linesGenerator from '@/libraries/LinesGenerator.js'
import EventSelectionTypes from '@/enums/event_selection_types.js'
import * as mutationTypes from "@/store/mutations-types.js"
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'

export default {
  computed: {
    ...mapGetters({
      events: "event/common/events",
      linkedEvents: 'event/common/linkedEvents'
    })
  },
  watch: {
    linkedEvents(newLinkedEvents, oldLinkedEvents) {
      this.deleteLineReport(oldLinkedEvents)
      this.drawLineReport(newLinkedEvents)
    }
  },
  methods: {
    /**
     * Cette fonction permet l'affichage des lignes reliant les événements présent dans un même compte rendu
     * @method
     * @public
     * @param {EyeEvent} d Evénement pour lequel il faut relier tout les autres événement présent dans le même compte rendu
     */
    drawLineReport(linkedEvents) {
      if (linkedEvents.dest.length === 0) {
        return
      }

      const paths = linesGenerator.betweenReport(linkedEvents)

      linkedEvents.dest.forEach(event => {
        this.$store.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, {
          event: event,
          selectionType: EventSelectionTypes.REPORT
        })
      })
      this.$store.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, {
        event: linkedEvents.src,
        selectionType: EventSelectionTypes.REPORT
      })

      this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, this.events)

      d3.select(this.$refs["group-lines-report"])
        .selectAll(".report-trace")
        .data(paths)
        .join("path")
        .attr("d", (d) => d)
        .attr("class", "report-trace")
        .attr(
          "stroke-dasharray",
          (d, i, nodes) =>
            nodes[i].getTotalLength() + " " + nodes[i].getTotalLength()
        )
        .attr("stroke-dashoffset", (d, i, nodes) => nodes[i].getTotalLength())
        .transition()
        .duration(D3Animation.LINES_REPORT_APPEAR)
        .attr("stroke-dashoffset", 0);
    },
    /**
     * Cette fonction permet la suppression des lignes reliant les événements présent dans le même compte rendu
     * @method
     * @public
     * @param {EyeEvent} d Evénement pour lequel étaient réliés les autres événements présent dans le même compte rendu
     */
    deleteLineReport(linkedEvents) {
      if (linkedEvents.dest.length === 0) {
        return
      }

      d3.selectAll('.report-trace').remove()

      linkedEvents.dest.forEach(event => {
        this.$store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
          event,
          selectionType: EventSelectionTypes.REPORT
        })
      })
      this.$store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
        event: linkedEvents.src,
        selectionType: EventSelectionTypes.REPORT
      })
      this.$store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, this.events)
    }
  }
}
</script>

<style>

.report-trace {
  stroke: var(--c-report-trace);
  stroke-width: 2px;
  fill: none;
  opacity: 1;
  pointer-events: none;
}

</style>