import * as d3 from 'd3'
import CircleUtility from '@/libraries/CircleUtility'
import EventManager from '@/libraries/EventManager'
import { mapGetters } from 'vuex'
import utils from "@/libraries/utils.js"

import * as EyeColor from '@/assets/color.js'
import D3Animation from '@/config/D3Animation.js'

export default {
  data: () => ({
    /**
     * Rayon du cercle de référence
     * @type {Number}
     */
    radius: 0,
    /**
     * Liste des points constituant le cercle de référence
     * @type {EyeEvent[]}
     */
    points: [],
    /**
     * Liste des événements présent sur le cercle de référence
     * @type {EyeEvent[]}
     */
    events: [],
    /**
     * Il s'agit de l'identifiant donné à l'element du dom du cercle de référence
     * @type {String}
     */
    idRefCircle: 'ref-circle',
    /**
     * Il s'agit de l'identifiant donnée à l'lement du dom du cercle de référence invisible permettant d'augmenter la zone de captation des événements de la souris
     * @type {String}
     */
    idInvisibleRefCircle: 'invisible-ref-circle',
    /**
     * Il s'agit de l'identifiant donnée au group contenant les cercles des événements du cercle de référence
     * @type {String}
     */
    idEventsContainer: 'ref-circle-event-container',
    /**
     * Il s'agit de l'identifiant donnée à chacun des cercles des événements du cercle de référence
     * @type {String}
     */
    classRefEvents: 'ref-events'
  }),
  computed: {
    ...mapGetters({
      mainRadius: "layout/radius",
      centerX: "layout/centerX",
      centerY: "layout/centerY",
    }),
    /**
     * Coefficient par lequel est multiplié le rayon de référence pour déterminer le rayon du cercle des score
     * @type {Number}
     */
    coefRadiusScore() {
      return this.$store.state.refCircle.score.coefRadiusScore;
    },
  },
  watch: {
    mainRadius() {
      this.radius = this.mainRadius * this.coefRadiusScore;
    },
    radius() {
      if (this.points.length > 0) {
        this.draw()
        this.calcEventCircle()
      }
    },
    coefRadiusScore() {
      this.radius = this.mainRadius * this.coefRadiusScore;
    },
    events() {
      d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)
        .data(this.events)
        .join('circle')
        .attr('class', (d) => `${utils.getEventResourceTypeColor(d, this.$store)} event ${this.classRefEvents}`)
        .attr('r', (d) => d.r)
        .attr('cx', (d) => d.cx)
        .attr('cy', (d) => d.cy)
        .style('fill', EyeColor.getFillColorEventCircle(0))
      this.initRefCircleEventsEvents()
    },
  },
  mounted() {
    this.radius = this.mainRadius * this.coefRadiusScore
  },
  methods: {
    /**
     * Cette fonction permet la construction du path et l'affichage du cercle de référence sur la représentation
     */
    draw() {
      const pointsRadialFormat = this.points.map((point) => [
        point.angle,
        CircleUtility.getRadiusAtPoint(this.radius, point.severity),
      ]);
      const pathLineRadial = d3
        .lineRadial()
        .curve(d3.curveCardinalClosed.tension(0))(pointsRadialFormat);

      d3.select(`#${this.idRefCircle}`).attr("d", pathLineRadial)
      d3.select(`#${this.idInvisibleRefCircle}`).attr("d", pathLineRadial)
    },
    /**
     * Cette fonction permet l'extraction des événements depuis les points du cercle de référence et le calcul de la position des cercles d'événement pour chacun des événements du cercle de référence
     */
    calcEventCircle() {
      this.events = EventManager.calcEventPosition(this.events, null, this.radius)
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur survol le cercle de référence
     * @param {Event} event Il s'agit de l'événemet fournit par le listener
     */
    onMouseOverRefCircle(event){
      this.sendEvent({ event: event })
      d3.select(`#${this.idRefCircle}`)
        .transition()
        .duration(D3Animation.REFERENCE_CIRCLE_HOVER)
        .style("stroke-width", "5px")
        .style("opacity", 1)


      this.collaborativeEventTreated()
    },
    /**
     * Cette fonctino est appelée lorsque l'utilisateur arrête de survoler le cercle de référence
     * @param {Event} event Il s'agit de l'événement fournit par le listener 
     */
    onMouseLeaveRefCircle(event){
      this.sendEvent({ event: event })
      d3.select(`#${this.idRefCircle}`)
        .transition()
        .duration(D3Animation.REFERENCE_CIRCLE_LEAVE)
        .style("stroke-width", "3px")
        .style("opacity",0.7)
      

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction permet l'initialisation des listeners pour les actions possibles avec le cercle de référence
     */
    initEventInvisibleRefCircle() {
      d3.select(`#${this.idInvisibleRefCircle}`).on("mousedown touchstart", (event) => {
        this.sendEvent({
          event: event,
          params: CircleUtility.eyePointRadial(
            event.clientX,
            event.clientY,
            this.centerX,
            this.centerY,
            this.mainRadius
          )
        })
        this.disableScoreEvents()
        d3.select("#root-app")
          .on("mousemove touchmove", (e) => {
            this.sendEvent({
              event: e,
              params: CircleUtility.eyePointRadial(
                e.clientX,
                e.clientY,
                this.centerX,
                this.centerY,
                this.mainRadius
              )
            })
            let posX = 0
            let posY = 0

            if (e.targetTouches) {
              posX = e.targetTouches[0].pageX - this.centerX;
              posY = e.targetTouches[0].pageY - this.centerY;
            } else {
              posX = e.pageX - this.centerX;
              posY = e.pageY - this.centerY;
            }
            this.radius = Math.sqrt(Math.pow(posX, 2) + Math.pow(posY, 2));
            this.points = this.points.map((point) => {
              point.radius = CircleUtility.getRadiusAtPoint(
                this.radius,
                point.severity
              );
              return point;
            });
            this.collaborativeEventTreated()
          })
        d3.select('#root-app').on("mouseup touchend", (e) => {
          this.sendEvent({ event: e })
          d3.select("#root-app").on("mousemove touchmove", null);
          d3.select('#root-app').on("mouseup touchend", null);
          this.calcEventCircle();
          this.collaborativeEventTreated()
        });
        this.collaborativeEventTreated()
      }, {passive: true})
        .on("mouseover", this.onMouseOverRefCircle)
        .on("mouseleave", this.onMouseLeaveRefCircle)
    },
    initRefCircleEventsEvents() {},
    /**
     * Cette fonction permet de désactiver les actions sur les événements du cercle de référence lorsque l'utilisateur effectue un redimensionnement du cercle de référence
     */
    disableScoreEvents() {
      d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)
        .data(this.events)
        .transition()
        .duration(D3Animation.REFERENCE_CIRCLE_EVENTS_DISAPPEAR)
        .style('stroke-opacity', 0)
        .style('fill', EyeColor.getFillColorEventCircle(0))

      d3.select(`#${this.idEventsContainer}`)
        .selectAll(`.${this.classRefEvents}`)
        .on('mouseover', null)
        .on('mouseleave', null)
    },
  }
}