<template>
  <div style="font-size: 2rem; color: white;">
    <p>Lors de votre utilisation d'EyeDiag, vous allez rencontrer différents objets et symboles.</p>
    <p>Voici une légende des éléments principaux : </p>
    <p>
      <img
        class="image"
        src="./20-6.png"
      >
      <span>Les traits oranges permettent de mettre en évidence les événements liés par un même compte rendu médical.</span>
    </p>
    <p>
      <img
        class="image"
        src="./20-7.png"
      >
      <span>Les traits rouges représentent les liens entre les événements, ils sont activés lors de l'application d'un filtrage.</span>
    </p>
  </div>
</template>
    
<script>
export default {
  name: "Component21Tuto",
}
</script>
    
<style scoped>
p {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
video {
  padding-bottom: 10px;
}
img {
    width: 30%;
    height: 70%;
    padding-right: 1vw;
}
span {
    font-size: 15px;
}
</style>