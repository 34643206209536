<template>
  <g ref="group-symbols" />
</template>

<script>
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import { mapGetters } from 'vuex'

export default {
  name: 'EyeSymbols',
  computed: {
    ...mapGetters({
      symbols: 'event/common/symbols'
    })
  },
  watch: {
    symbols() {
      this.drawSymbols(this.symbols)
    }
  },
  methods: {
    /**
     * Cette fonction permet l'affichage des symboles
     * @method
     * @public
     * @param {EyeSymbol[]} symbols Tableau contenant les symboles devant être affichés sur la représentation
     */
    drawSymbols(symbols) {
      d3.selectAll('.symbol')
        .remove()

      d3.select(this.$refs["group-symbols"])
        .selectAll('.symbol')
        .data(symbols)
        .enter()
        .append("path")
        .attr("d", (d) => d.path)
        .attr("class", (d) => `${d.resourceTypeClass} symbol`)
        .attr("transform", (d) => `translate(${d.x},${d.y})`)
    },
    /**
     * Cette fonction permet la suppression de tout les symboles actuellement présent sur la représentation
     * @method
     * @public
     */
    removeSymbols() {
      const selection = d3
        .selectAll('.symbol')

      selection.interrupt()
      selection.transition()
        .duration(D3Animation.FILTERING_SYMBOLS_DISAPPEAR)
        .style("opacity", 0)
        .on('end', () => {
          selection.remove()
        })
    },
  }
}
</script>

<style>
.symbol {
  stroke: none;
}
</style>