<template>
  <div>
    <div class="flex flex-col border-0 border-dashed">
      <label
        class="mb-2"
      >
        Notes
      </label>
      <textarea
        v-model="note"
        class="grow"
        name="notes"
        rows="1"
        placeholder="Notes..."
        @input="(e) => onInputChange(e, note)"
      />
    </div>
    <div class="mt-2">
      <div class="flex flex-row">
        <ToggleSwitch
          v-model:status="recurrence.enable"
          :text="isEditingEventFromRecurrence ? 'Editer la récurrence' : 'Récurrence'"
        />
      </div>
      <div
        v-if="recurrence.enable"
        class="mt-4"
      >
        <div class="flex flex-row">
          <div class="flex flex-col grow basis-0 mr-1.5">
            <label>Début de période</label>
            <input
              v-model="recurrence.start"
              type="date"
              @change="(e) => onInputChange(e, recurrence.start)"
            >
          </div>
          <div class="flex flex-col grow basis-0 ml-1.5">
            <label>Fin de période</label>
            <input
              v-model="recurrence.end"
              type="date"
              @change="(e) => onInputChange(e, recurrence.end)"
            >
          </div>
        </div>
        <p
          v-for="(error, index) in errors.period"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
        <div class="mt-4">
          <div class="mb-4">
            <label>Fréquence</label>
          </div>
          <div class="flex flex-row">
            <p class="flex content-center flex-wrap">
              Tout les
            </p>
            <input
              v-model="recurrence.interval"
              class="mr-1.5 ml-1.5 w-14 border-0 border-b-2 border-solid"
              type="number"
              placeholder="2"
              min="1"
              @input="(e) => onInputChange(e, recurrence.interval)"
            >
            <select
              v-model="recurrence.intervalUnit"
              class="grow"
              @change="e => onInputChange(e, recurrence.intervalUnit, true)"
            >
              <option
                v-for="(intervalUnitOption, index) in recurrence.intervalUnitOptions"
                :key="index"
                :value="intervalUnitOption.value"
              >
                {{ intervalUnitOption.label }}
              </option>
            </select>
          </div>
          <p
            v-for="(error, index) in errors.interval"
            :key="index"
            class="error-modal"
          >
            {{ error }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
import { mapActions, mapGetters } from 'vuex'
import ToggleSwitch from '@/components/ToggleSwitch.vue'
import recurrenceIntervalUnit from '@/shared/config/recurrenceIntervalUnit.js'

export default {
  name: 'AddEventOptions',
  components: {
    ToggleSwitch
  },
  props: {
    isEditingEventFromRecurrence: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    note: "",
    recurrence: {
      enable: false,
      start: null,
      end: null,
      interval: 1,
      intervalUnit: "utcYear",
      intervalUnitOptions: recurrenceIntervalUnit,
      events: []
    },
    errors: {
      period: [],
      interval: []
    }
  }),
  computed: {
    ...mapGetters({
      allEvents: 'event/common/allEvents',
      editEvent: 'event/edit/editingEventToAdd'
    })
  },
  watch: {
    'recurrence.enable': {
      handler() {
        if (this.isEditingEventFromRecurrence && this.recurrence.enable === true) {
          this.resetFormFromRecurrence()
        }
      }
    }
  },
  mounted() {
    this.recurrence.start =
    this.recurrence.end = d3.timeFormat('%Y-%m-%d')(new Date())
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    checkForm() {
      this.errors = {
        period: [],
        interval: []
      }

      if (this.recurrence.end < this.recurrence.start) {
        this.errors.period.push('La période n\'est pas valide')
      }
      if (isNaN(this.recurrence.interval) || !this.recurrence.intervalUnitOptions.map(i => i.value).includes(this.recurrence.intervalUnit)) {
        this.errors.interval.push('La fréquence n\'est pas valide')
      }
    },
    resetForm() {
      this.note = "",
      this.recurrence.events = []
      this.recurrence.enable = false
      this.recurrence.start = null
      this.recurrence.end = null
      this.recurrence.interval = 1
      this.recurrence.intervalUnit = 'utcYear'
    },
    resetFormFromRecurrence() {
      this.recurrence.events = this.allEvents.filter(event => event.extension.recurrenceId === this.editEvent.extension.recurrenceId)
      this.recurrence.events.sort((a, b) => b.onsetDateTime - a.onsetDateTime)
      this.recurrence.enable = true
      this.recurrence.start = this.recurrence.events[0].onsetDateTime.slice(0, 10)
      this.recurrence.end = this.recurrence.events[this.recurrence.events.length - 1].onsetDateTime.slice(0, 10)
      this.recurrence.interval = this.editEvent.extension.recurrenceInterval
      this.recurrence.intervalUnit = this.editEvent.extension.recurrenceIntervalUnit
    },
    editThisEvent(event) {
      this.note = event.note,
      this.recurrence.enable = false
    },
    getOptions() {
      this.checkForm()

      for (const errors of Object.values(this.errors)) {
        if (errors.length > 0) {
          return null
        }
      }

      return {
        note: this.note,
        recurrence: {
          enable: this.recurrence.enable,
          start: this.recurrence.start,
          end: this.recurrence.end,
          interval: this.recurrence.interval,
          intervalUnit: this.recurrence.intervalUnit,
          events: this.recurrence.events
        }
      }
    },
    onInputChange(event, text, select = false) {
      if (event.isTrusted) {
        this.sendEvent({
          event: event,
          params: {
            text: text,
            change: select
          }
        })
      } else {
        this.sendEvent({event: event})
      }
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
label {
  margin-bottom: 1rem;
  font-size: 1.1rem;
}
</style>