import { createApp } from 'vue'
import router from './router/router.js'
import App from './App.vue'
import store from './store/index.js'
import VueCookies from 'vue-cookies'

import * as d3 from 'd3'
import './assets/tailwind.css'

d3.timeFormatDefaultLocale({
  "dateTime": "%A %e %B %Y à %X",
  "date": "%d/%m/%Y",
  "time": "%H:%M:%S",
  "periods": ["AM", "PM"],
  "days": ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
  "shortDays": ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
  "months": ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
  "shortMonths": ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."]
});

const app = createApp(App)

app.use(store)
app.use(router)
app.use(VueCookies)
app.mount('#app')