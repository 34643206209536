<template>
  <div>
    <div
      v-for="(event, index) in events"
      :key="index"
    >
      <ListItem
        :event="event"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendCircle'
}
</script>

<script setup>

import EventManager from '@/libraries/EventManager.js'
import ListItem from '@/components/event/ListItem.vue'
import { computed, defineEmits, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import _ from 'lodash'

const store = useStore()

const hoveredCircle = computed(() => store.getters['circle/hoveredCircle'])
const events = computed(() => {
  let result = []
  if (hoveredCircle.value !== null) {
    return EventManager.extractEventsFromCircle([hoveredCircle.value])
  }
  return result
})

onMounted(() => {
  if (hoveredCircle.value !== null) {
    emits('title', hoveredCircle.value.period.label)
  }
})

watch(hoveredCircle, () => {
  if (hoveredCircle.value !== null) {
    emits('title', hoveredCircle.value.period.label)
  }
})

const emits = defineEmits(['title'])

</script>