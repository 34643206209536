<template>
  <form @submit.prevent="validateForm">
    <p class="mb-4">
      Les identifiants de connexion seront automatiquement transmis au destinataire par e-mail.
    </p>
    <CommonForm
      ref="commonForm"
    />
    <div>
      <DesignButton
        id="create-patient-validate"
        text="Valider"
      />
    </div>
  </form>
</template>

<script>
export default {
  name: 'AccountCreationForm'
}
</script>

<script setup>
import CommonForm from '@/views/AccountCreation/Common.vue'
import DesignButton from '@/components/DesignButton.vue'
import { useCreateAccount } from '@/views/AccountCreation/useCreateAccount.js'

import { ref } from 'vue'

const errors = ref({})
const commonForm = ref(null)

const { createAccount } = useCreateAccount()

function checkForm() {
  errors.value = commonForm.value.checkForm()
}

async function validateForm(event) {
  checkForm()

  for (const err of Object.values(errors.value)) {
    if (err.length > 0) {
      return
    }
  }

  const data = commonForm.value.getDataForm()

  createAccount(event, data)
}

</script>

<style scoped>

</style>