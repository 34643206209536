<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./22.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Enfin, il est possible de retourner à la liste des patients à l'aide de l'option "Retour accueil" située dans la dernière icone du menu contextuel.</p>
  </div>
</template>
    
<script>
export default {
  name: "Component22Tuto",
}
</script>
    
<style scoped>
p {
  padding-bottom: 10px;
}
</style>