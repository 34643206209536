<template>
  <div style="font-size: 2rem; color: white;">
    Voici la représentation de votre patient.
  </div>
</template>

<script>
export default {
  name: "Component1Tuto",
}
</script>

<style scoped>
p {
    padding-bottom: 10px;
}
</style>