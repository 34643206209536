import * as d3 from 'd3'
import circlesConfig from '../config/circle.js'
import _ from 'lodash'
import EyeCardinalClosed from './EyeCardinalClosed.js'
// import EyeCardinal from './EyeCardinal.js'

/**
 * Class CircleUtility
 */

class CircleUtility {
  /**
   * Cette fonction permet de déterminer le rayon à un point présent sur un cercle en fonction de sa sévérité.
   * @param {Number} baseRadius Il s'agit du rayon base du cercle sur lequel le point doit être placé
   * @param {Number} severity Sévérité du point devant être pris en compte pour le calcul de son rayon
   * @returns {Number} Rayon auquel doit se trouver le point
   */
  static getRadiusAtPoint(baseRadius, severity) {

    // let severityToAdd = localStorage.getItem('radiusToAddSeverity')
    // severityToAdd = severityToAdd.replaceAll('$radiusCircle', `${baseRadius}`)
    // severityToAdd = eval(severityToAdd)
    const severityToAdd = baseRadius / 10

    const severitScale = d3.scaleLinear()
      .domain([0, 1])
      .range([baseRadius, baseRadius + severityToAdd])
    return severitScale(severity)
    //return baseRadius + Math.abs(severitScale(severity))
  }

  /**
   * Permet de déterminer le rayon d'un cercle
   * @param {Number} referenceRadius Rayon de référence
   * @param {Number} configIndex Index de configuration du cercle dont on souhaite déterminer le rayon
   * @returns {Number} Rayon du cercle
   */
  static getRadiusCircle(referenceRadius, configIndex) {
    if (configIndex < 0) {
      return referenceRadius * circlesConfig[0].coefRadius;
    } else if (configIndex >= circlesConfig.length) {
      return (
        referenceRadius * (_.last(circlesConfig).coefRadius +
          (configIndex - circlesConfig.length + 1) * 0.18)
      )
    } else {
      return referenceRadius * circlesConfig[configIndex].coefRadius;
    }
  }

  /**
   * Cette fonction permet de construire et obtenir le path SVG d'un cercle
   * @param {EyeCircle} circleData Il s'agit des données constituant le cercle
   * @param {Number} referenceRadius Rayon de référence
   * @param {Boolean} displayEvent Détermine si les événements du cercle doivent être affiché avec leur déformation (c'est à dire leur sévérité) ou sans déformation (sévérité de 0)
   * @returns {String} Path SVG du cercle
   */
  static getCirclePaths(circleData, referenceRadius, displayEvent) {

    const radiusCircle = CircleUtility.getRadiusCircle(
      referenceRadius,
      circleData.configIndex
    )
    const pointsRadialFormat = circleData.points.map((point) => [
      point.angle,
      CircleUtility.getRadiusAtPoint(
        radiusCircle,
        displayEvent ? point.severity : 0
      ),
    ])

    let paths = []

    d3.lineRadial().curve(
      EyeCardinalClosed
        .circleData(circleData)
        .radiusCircle(radiusCircle)
        .paths(paths)
        .configIndexBounded(this.getIndexConfig(circleData.configIndex))
    ) (pointsRadialFormat)

    return paths
  }

  /**
   * Cette fonction permet d'obtenir pour un cercle un index de configuration valide dans le tableau de configuration d'affichage des cercles
   * @param {Number} indexConfig Index de configuration du cercle
   * @returns {Number} Index de configuration valide pour le tableau des configurations d'affichage
   */
  static getIndexConfig(indexConfig) {
    if (indexConfig < 0) {
      return 0;
    } else if (indexConfig >= circlesConfig.length) {
      return circlesConfig.length - 1;
    } else {
      return indexConfig;
    }
  }

  /**
   * Cette fonction permet de retourner un tableau contenant un nombre de cercle concentriques parfaits fournit par l'utilisateur. L'utilité de cette fonction est de pouvoir afficher des cercles parfaits avec l'animation de chargement en attendant que l'utilisateur recoivent les informations depuis l'API
   * @param {Number} nbCircle Nombre de cercle désiré
   * @returns {EyeCircle[]} Tableau contenant les cercles de template
   */
  static getTemplateCircle(nbCircle) {
    const circles = []

    for (let i = 0; i < nbCircle; i++) {
      const points = []
      //Compteur qui s'incrément. Sa seule utilité est d'attribuer pour le moment idSection fictif à chaque point pour que la curve utilisé pour le tracé des cercles puisse faire les découpages des path du cercle par section
      let fakeIdSection = 0
      for (let angle = 0; angle < 360; angle += 30) {
        points.push({ angle: angle * (Math.PI / 180), severity: 0, peak: false, idSection: fakeIdSection})
        fakeIdSection++
      }
      circles.push({ points, configIndex: i })
    }
    return circles
  }

  /**
   * @param {Number} radius Rayon du cercle de navigation
   * @param {EyeSection} section Section pour laquelle l'arc de cercle doit être généré
   * @returns {String} path SVG de l'arc de cercle
   */
  static generateArcNavCircle(radius, section) {
    const path = new d3.path()
    path.arc(0, 0, radius, CircleUtility.degreesToRadians(section.angle + 1) - Math.PI / 2, CircleUtility.degreesToRadians(section.angle + section.size - 1) - Math.PI / 2)
    return path.toString()
  }

  /**
   * Permet de convertir des coordonées cartésiennes en coordonées polaire
   * @param {Number} x Coordonnée x du point
   * @param {Number} y Coordonnée y du point
   * @returns {Object[]} r: Number Rayon du point t: Number Angle en radian du point
   */
  static pointCartesian(x, y) {
    const r = Math.hypot(x, y)
    const t = Math.atan2(y, x) + Math.PI / 2

    return [r, t]
  }

  /**
   * Cette fonction permet de convertir un point avec des coordonnées cartésiennes par rapport au coin supérieur gauche de la fenêtre en coordonées polaire par rapport au centre d'eyediag. 
   * @param {Number} clientX Position x par rapport au coin supérieur gauche
   * @param {Number} clientY Position y par rapport au coin supérieur gauche
   * @param {Number} centerX Centre de la représentation sur l'axe des abscisses
   * @param {Number} centerY Centre de la représentation sur l'axe des ordonnées
   * @param {Number} referenceRadius Rayon de référence
   * @returns { Object } r: Number % du rayon de référence
   *  t: Number Angle en radian
   * Coordonnées Polaire par rapport au centre d'Eyediag
   */
  static eyePointRadial(clientX, clientY, centerX, centerY, referenceRadius) {
    const polarCoordinate = CircleUtility.pointCartesian(
      (clientX - centerX),
      (clientY - centerY)
    )

    return {
      r: polarCoordinate[0] / referenceRadius,
      t: polarCoordinate[1]
    }
  }

  /**
   * Cette fonction permet de convertir des coordonnées polaire par rapport au centre d'Eyediag en coordonnées cartésiennes par rapport au coin supérieur gauche de la fenêtre
   * @param {Number} r % du rayon de référence
   * @param {Number} t Angle en radian 
   * @param {Number} centerX Centre de la représentation sur l'axe des abscisses
   * @param {Number} centerY Centre de la représentation sur l'axe des ordonnées
   * @param {Number} referenceRadius Rayon de référence
   * @returns { Object } r: Number % du rayon de référence
   *  t: Number Angle en radian
   *  Coordonnées cartésiennes par rapport au coin supérieur gauche
   */
  static eyePointCartesian(r, t, centerX, centerY, referenceRadius) {
    r = r * referenceRadius
    const points = d3.pointRadial(t, r)

    return {
      x: points[0] + centerX,
      y: points[1] + centerY
    }
  }

  /**
   * Cette fonction permet de convertir un angle en degrés en radion
   * @param {Number} degrees Valeur de l'angle en degrés
   * @returns {Number} Valeur de l'angle en radian
   */
  static degreesToRadians(degrees) {
    return degrees * (Math.PI / 180);
  }

  /**
   * Permet de déterminer si un cercle est actuellement visible sur la représentation. La vérification se fait en fonction de son index dans le tableau de configuration d'affichage des cercles
   * @param {Number} configIndex Index de configuration du cercle dont sa visibilité doit être checké
   * @returns {Boolean} True si le cercle est visible. False si le cercle n'est pas visible
   */
  static isVisibleCircle(configIndex) {
    return circlesConfig[configIndex] && circlesConfig[configIndex].opacity !== 0
  }
}

export default CircleUtility