<template>
  <div
    ref="generic-wrapper"
    :class="`generic-wrapper ${containerClass}`"
  >
    <slot />
  </div>
</template>

<script>

import * as d3 from "d3";
import { mapActions, mapGetters } from 'vuex'
import CircleUtility from '@/libraries/CircleUtility.js'

export default {
  name: "MovingItem",
  props: {
    /**
     * Il s'agit d'une classe custom qui sera appliquée sur la div du composant
     * @type {String}
     */
    containerClass: {
      type: String,
      required: true
    },
    /**
     * Il s'agit d'une référence vers l'element racine du slot
     * @type {Object}
     */
    refSlot: {
      type: Object,
      required: false,
      default: null
    },
    /**
     * z-index qui sera appliqué sur la div du composant
     * @type {Number}
     */
    zIndex: {
      type: Number,
      required: true
    }
  },
  emits: ['myMouseDown', 'myMouseMove', 'myMouseUp'],
  data: () => ({
    /**
     * Coordonnée x de la div du composant
     * @type {Number}
     */
    posX: 0,
    /**
     * Coordonnée y de la div du composant
     * @type {Number}
     */
    posY: 0,
    options: {
      /**
       * Détermine si les actions effectuées sur le conteneur doivent être partagées lors d'une session collaborative
       * @type {Boolean}
       */
      share: false,
      /**
       * Détermine si le conteneur peut-être redimensionné
       * @type {Boolean}
       */
      resize: false
    },
    /**
     * Observer surveillant le redimensionnement du conteneur
     * @type {ResizeObserver}
     */
    resizeObserver: null,
    localZIndex: 1
  }),
  computed: {
    ...mapGetters({
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      referenceRadius: "layout/radius",
      windowSize: "layout/windowSize",
    })
  },
  watch: {
    windowSize() {
      if (this.posX + this.$refs['generic-wrapper'].offsetWidth > this.windowSize.width) {
        this.posX = this.windowSize.width - this.$refs['generic-wrapper'].offsetWidth;
      }
      if (
        this.posY + this.$refs['generic-wrapper'].offsetHeight >
        this.windowSize.height
      ) {
        this.posY = this.windowSize.height - this.$refs['generic-wrapper'].offsetHeight;
      }
      this.updateCoordinate(this.posX, this.posY)
    },
    zIndex() {
      this.setZIndex(this.zIndex)
    }
  },
  mounted() {
    this.setZIndex(this.zIndex)
  },
  beforeUnmount() {
    this.freeContainer()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      sendProperties: 'ws/sendProperties',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),

    /**
     * Cette fonction permet l'initialisation des coordonnées du conteneur
     * @method
     * @public
     * @param {Number} x Coordonée x du conteneur
     * @param {Number} y Coordonée y du conteneur
     */
    initialCoordinate(x, y) {
      this.updateCoordinate(x, y);
    },

    /**
     * Cette fonction permet l'initialisation de l'observer surveillant le redimensionnement de l'élément. Cet observer permet lors d'une collaboration d'envoyer les informations de redimensionnement aux autres participant de la session
     */
    initResizeObserver() {
      this.resizeObserver = new ResizeObserver((entries) => {
        if (!this.refSlot) {
          return
        }

        for (const entry of entries) {
          const params = { style: {}}
          if (entry.contentBoxSize) {
            // Firefox implements `contentBoxSize` as a single content rect, rather than an array
            const contentBoxSize = Array.isArray(entry.contentBoxSize) ? entry.contentBoxSize[0] : entry.contentBoxSize;

            params.style.height = `${contentBoxSize.blockSize}px`
            params.style.width = `${contentBoxSize.inlineSize}px`
          } else {
            params.style.height = `${entry.contentRect.height}px`
            params.style.width = `${entry.contentRect.width}px`
          }
          this.sendProperties({
            target: this.refSlot,
            params: params
          })
        }
      })

      this.resizeObserver.observe(this.$refs['generic-wrapper'])
    },

    /**
     * Cette fonction permet de mettre à jour les options / propriétés du conteneur
     * @method
     * @public
     * @param {
     *  [name: String]: Boolean
     * } newOptions Il s'agit des options qui seront appliqués / utilisés par le conteneur
     */
    setOptions(newOptions) {
      this.options = {...this.options, ...newOptions}

      if (this.options.resize) {
        this.initResizeObserver()
      }
    },

    /**
     * Cette fonction permet de mettre à jour les options / propriétés du conteneur
     * @method
     * @public
     * @param {Number} x Coordonée x du conteneur
     * @param {Number} y Coordonée y du conteneur
     * @param {Number} duration Durée de la transition de déplacement du conteneur
     */
    updateCoordinate(x, y, duration = 0) {
      this.posX = x;
      this.posY = y;
      d3.select(this.$refs["generic-wrapper"])
        .transition()
        .duration(duration)
        .style("left", `${x}px`)
        .style("top", `${y}px`)
    },

    /**
     * Cette fonction permet l'initialisation de tous les événements de placement du conteneur
     * @method
     * @public
     */
    moveItem() {
      d3.select(this.$refs["generic-wrapper"]).on("mousedown", (event) => { 
        if (event.buttons && event.buttons !== 1) {
          return
        }

        if (
          this.options.resize &&
          event.clientX > this.posX + this.$refs['generic-wrapper'].offsetWidth - 25 &&
          event.clientY > this.posY + this.$refs['generic-wrapper'].offsetHeight - 25
        ) {
          return
        }
        this.sendEvent({event: event})
        this.$emit('myMouseDown')

        const offsetx = event.clientX - this.posX;
        const offsety = event.clientY - this.posY;
        d3.selectAll(`.${this.containerClass}`).style("z-index", this.localZIndex);
        d3.select(this.$refs['generic-wrapper']).style("z-index", this.localZIndex + 1);

        d3.select('#root-app').on("mouseup mouseleave", (e) => {
          this.shareEvent(e)
          d3.select("#root-app").on("mousemove", null)
          this.$emit('myMouseUp')
          if (this.options.share) {
            this.collaborativeEventTreated()
          }
        });
        d3.select("#root-app").on("mousemove", async (e) => {
          if (e.isTrusted) {
            this.posX = e.clientX - offsetx;
            this.posY = e.clientY - offsety;
          } else {
            this.posX = e.clientX
            this.posY = e.clientY
          }
          this.shareMoveEvent(this.posX, this.posY, e)
          //print avec un isTrusted bizarre quand meme que les events marche
          //pas en sexectutant après un par un, alors que ça marche pour le cercle vert
          //Test avec des points d'arrets pour l'histoire de la recursivité
          this.updateCoordinate(this.posX, this.posY);
          await this.$emit('myMouseMove')
          if (this.options.share) {
            this.collaborativeEventTreated()
          }
        });
        if (this.options.share) {
          this.collaborativeEventTreated()
        }
      });
    },

    /**
     * Cette fonction permet le partage d'événement sans paramètre en session collaborative
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     */
    shareEvent(event) {
      if (this.options.share) {
        this.sendEvent({ event: event })
      }
    },

    /**
     * Cette fonction permet le partage d'événement en session collaborative avec en paramètre la position polaire de l'événement dans le repère Eyediag
     * @method
     * @public
     * @param {event} event Il s'agit de l'événement fournit par le listener
     */
    shareMoveEvent(x, y, event) {
      if (this.options.share) {
        this.sendEvent({
          event: event,
          params: CircleUtility.eyePointRadial(
            x,
            y,
            this.centerX,
            this.centerY,
            this.referenceRadius
          ),
        })
      }
    },

    /**
     * Cette fonction permet le partage d'événement en session collaborative avec en paramètre la position polaire de l'événement dans le repère Eyediag
     * @method
     * @public
     * @return {
     *  x: Number,
     *  y: Number
     * } Retourne les coordonnées x et y représentant la position du conteneur
     */
    getCoordinates() {
      return {
        x: this.posX,
        y: this.posY
      }
    },
    /**
     * Permet d'arrêter l'observation de l'observer. Utile avant le démontage du composant
     */
    freeContainer() {
      if (this.options.resize) {
        this.resizeObserver.disconnect()
      }
    },
    /**
     * Permet de mettre à jour la valeur de z-index utilisé par le composant
     * @param {Number} zIndex nouvelle valeur du z-index
     */
    setZIndex(zIndex) {
      this.localZIndex = zIndex
      d3.select(this.$refs['generic-wrapper']).style('z-index', this.localZIndex)
    }
  },
};
</script>

<style>
.generic-wrapper {
  position: absolute;
  display: flex;
}
</style>