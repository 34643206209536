import CircleUtility from "./CircleUtility"
import * as d3 from 'd3'

/**
 * Il s'agit de l'implémentation d'une courbe personnalisée utilisée pour l'affichage des lignes entre les événements liés par un même compte rendu
 * https://github.com/d3/d3-shape/blob/v3.2.0/README.md#custom-curves
 */

class EyeCurve {
  constructor(context) {
    this._context = context
  }

  areaStart() { console.error("areaStart eyeCurve not implemented") }

  areaEnd() { console.error("areaEnd eyeCurve not implemented") }

  lineStart() {
    this._point = 0;
  }

  lineEnd() {
  }

  point(x, y) {
    x = +x, y = +y;
    switch (this._point) {
    case 0:
      this._point = 1;
      this._point1 = {x, y}
      this._context.moveTo(x, y)
      break
    case 1:
      this._point = 2;
    default:
      this._point2 = {x, y}
      this._ctrlPoint = {
        x: this._point1.x,
        y: this._point2.y
      }

      const middlePoint = {
        x: (this._point1.x + this._point2.x) / 2,
        y: (this._point1.y + this._point2.y) / 2
      }

      const polar = CircleUtility.pointCartesian(this._point1.x - middlePoint.x, this._point1.y - middlePoint.y)
      const tmpCtrlPoint = d3.pointRadial(polar[1] + Math.PI / 2, polar[0] / 3)

      this._ctrlPoint = {
        x: tmpCtrlPoint[0] + middlePoint.x,
        y: tmpCtrlPoint[1] + middlePoint.y
      }

      this._context.quadraticCurveTo(this._ctrlPoint.x, this._ctrlPoint.y, this._point2.x, this._point2.y)
      this._point1 = this._point2
      break
    }
  }
}
  
export default (context) => new EyeCurve(context)