<template>
  <baseTutorial
    :curr-index="currIndex"
    :nb-components="nbComponents"
    @change-component="changeComponent"
  >
    <component :is="currentComponent" />
  </baseTutorial>
</template>

<script>
import Component1  from '@/components/tutorial/priseEnMain/Component1.vue'
import Component2  from '@/components/tutorial/priseEnMain/Component2.vue'
import Component3  from '@/components/tutorial/priseEnMain/Component3.vue'
import Component4  from '@/components/tutorial/priseEnMain/Component4.vue'
import Component5  from '@/components/tutorial/priseEnMain/Component5.vue'
import Component6  from '@/components/tutorial/priseEnMain/Component6.vue'
import Component7  from '@/components/tutorial/priseEnMain/Component7.vue'
import Component8  from '@/components/tutorial/priseEnMain/Component8.vue'
import Component9  from '@/components/tutorial/priseEnMain/Component9.vue'
import Component10 from '@/components/tutorial/priseEnMain/Component10.vue'
import Component11 from '@/components/tutorial/priseEnMain/Component11.vue'
import Component12 from '@/components/tutorial/priseEnMain/Component12.vue'
import Component13 from '@/components/tutorial/priseEnMain/Component13.vue'
import Component14 from '@/components/tutorial/priseEnMain/Component14.vue'
import Component15 from '@/components/tutorial/priseEnMain/Component15.vue'
import Component16 from '@/components/tutorial/priseEnMain/Component16.vue'
import Component17 from '@/components/tutorial/priseEnMain/Component17.vue'
import Component18 from '@/components/tutorial/priseEnMain/Component18.vue'
import Component19 from '@/components/tutorial/priseEnMain/Component19.vue'
import Component20 from '@/components/tutorial/priseEnMain/Component20.vue'
import Component21 from '@/components/tutorial/priseEnMain/Component21.vue'
import Component22 from '@/components/tutorial/priseEnMain/Component22.vue'
import baseTutorial from '@/components/tutorial/baseTutorial.vue'

export default {
  name: "StartUpTutorialName",
  components: {
    Component1,
    Component2,
    Component3,
    Component4,
    Component5,
    Component6,
    Component7,
    Component8,
    Component9,
    Component10,
    Component11,
    Component12,
    Component13,
    Component14,
    Component15,
    Component16,
    Component17,
    Component18,
    Component19,
    Component20,
    Component21,
    Component22,
    baseTutorial
  },
  data() {
    return {
      currentComponent: 'Component1',
      currIndex: 0,
      allComponents: ['Component1', 'Component2', 'Component3', 'Component4', 'Component5', 'Component6', 'Component7', 'Component8', 'Component9', 'Component10', 'Component11', 'Component12', 'Component13', 'Component14', 'Component15', 'Component16', 'Component17', 'Component18', 'Component19', 'Component20', 'Component21', 'Component22'],
      nbComponents: 0
    };
  },
  created() {
    this.nbComponents = this.allComponents.length
  },
  methods: {
    changeComponent(itt) {
      this.currIndex += itt
      if (this.currIndex >= this.allComponents.length) {
        this.currIndex = 0
      }
      if (this.currIndex < 0) {
        this.currIndex = 0
      }
      this.currentComponent = this.allComponents[this.currIndex]
    }
  }
}
</script>