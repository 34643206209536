<template>
  <div class="mx-4">
    <ContentPatientEvent
      v-if="hoveredEvent"
      :tooltip-data="hoveredEvent"
    />
  </div>
</template>

<script>
export default {
  name: 'LegendEvent'
}
</script>

<script setup>

import { useStore } from 'vuex'
import { computed, defineEmits, onMounted } from 'vue'
import ContentPatientEvent from '@/components/tooltip/ContentPatientEvent.vue'

const store = useStore()

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])

onMounted(() => {
  emits('title', 'Evénement')
})

const emits = defineEmits(['title'])
</script>