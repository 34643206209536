import { useStore } from 'vuex'
import { computed } from 'vue'
import utils from "@/libraries/utils"

/**
 * @function
 * @returns {Object}    name,sex,birthdate,age
 */

export function userParserPatientData() {
  const store = useStore()

  const patientData = computed(() => store.getters['patient/patientData'])

  /**
   * Il s'agit de l'identité (nom, prénom) du patient
   * @type {String}
  */
  const name = computed(() => {
    return !patientData.value ? '' : `${patientData.value.lastname}, ${patientData.value.name}`
  })
  /**
   * Il s'agit du genre du patient
   * @type {String}
   */
  const sex = computed(() => {
    return !patientData.value ? '' : `${patientData.value.gender}`
  })
  /**
   * Il s'agit de la date de naissance du patient
   * @type {String}
   */
  const birthdate = computed(() => {
    return !patientData.value ? '' : `${patientData.value.birthDate}`
  })
  /**
   * Age du patient
   * @type {Number}
   */
  const age = computed(() => {
    return !patientData.value ? '' : utils.calculateAge(new Date(birthdate.value))
  })

  return {
    name,
    sex,
    birthdate,
    age
  }
}