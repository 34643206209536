import TopListSort from '@/enums/borderList/topListSort.js'
import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => ({
  sortTop: TopListSort.SIZE
})

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    sortTop: state => state.sortTop
  },
  mutations: {
    [mutationTypes.UPDATE_SORT_TOP_LIST] (state, payload) {
      state.sortTop = payload
    }
  },
  actions: {
    /**
     * Permet de remettre à la valeur par défaut le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
  }
}