<template>
  <g
    id="invisible-circles-container"
    ref="invisible-circles-container"
  />
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import CircleUtility from "@/libraries/CircleUtility.js"
import categorisationAnimation from '@/animation/categorisation.js'
import temporalityCircleAnimation from '@/animation/temporalityCircle.js'
import temporality from '@/animation/temporality.js'
import introAnimation from '@/animation/intro.js'
import D3Animation from '@/config/D3Animation.js'
import Circles from '@/libraries/Circles.js'
import * as d3 from 'd3'

/**
 * Il s'agit des données des cercles
 * @type {EyeCircle[]}
 */
let circlesData = null;
/**
 * Indique si les événement sur les cercles (piques) doivent être affiché (sinon sévérité 0)
 * @type {Boolean}
 */
const displayEvent = true;
/**
 * Cet objet contient les références des différents watchers. Le stockage de ces références permet au moment voulu de pouvoir stopper les watchers
 * @type Object {
 *    [name: String]: null|StopHandle
 * }
 */
let watchers = {
  hide: null,
  circles: null,
  theme: null,
  radius: null,
};

/**
 * Ce composant s'occupe de l'affichage et la gestion des différents actions possibles sur les cercles d'Eyediag
 * @displayName Composant Circles
 */
export default {
  name: "CirclesContainer",
  computed: {
    ...mapGetters({
      radius: "layout/radius",
      circles: "circle/circles",
      theme: "circle/theme",
      hide: "circle/hide",
      centerX: "layout/centerX",
      centerY: "layout/centerY",
    }),
  },
  created() {
    if (localStorage.getItem("theme"))
      this.$store.dispatch("circle/changeTheme", localStorage.getItem("theme"));
    categorisationAnimation.setRefCircleComponent(this);
    temporalityCircleAnimation.setRefCircleComponent(this);
    temporality.setRefCircleComponent(this);
    introAnimation.setRefCircleComponent(this);
    this.initWatchers();
  },
  async mounted() {
    try {
      circlesData = CircleUtility.getTemplateCircle(10)
      await this.refreshDisplayCircle({ interactions: false, animationTime: 0 })
      d3.select('#displayCircle')
        .selectAll(".circle")
        .style("opacity", 0);
    } catch {}
  },
  methods: {
    ...mapActions({
      changeMainCircle: "circle/changeMainCircle",
      onCircleStopMove: "circle/onCircleStopMove",
      findPreviousSelected: "circle/findPreviousSelected"
    }),

    /**
     *  Permet l'initialisation des watchers sur certaines variables pour réagir lors de la modifications de ces variables
     * @method
     * @public
     */
    initWatchers() {
      circlesData = this.circles;
      watchers.hide = this.$watch('hide', async () => {
        circlesData = this.circles;

        await this.refreshDisplayCircle({
          interactions: true,
          animationTime: 0,
        });
        this.onCircleStopMove();
      });

      watchers.circles = this.$watch("circles", async () => {
        circlesData = this.circles;

        try {
          await this.refreshDisplayCircle({
            interactions: false,
            animationTime: D3Animation.CIRCLES_MOVE_CIRCLES,
          });
          this.onCircleStopMove();
          await this.refreshDisplayCircle({
            interactions: true,
            animationTime: D3Animation.CIRCLES_MOVE_CIRCLES,
          });
        } catch {}
      });

      watchers.theme = this.$watch("theme", () => {
        this.refreshDisplayCircle({
          interactions: true,
          animationTime: D3Animation.CIRCLES_CHANGE_COLOR_THEME,
        });
      });

      watchers.radius = this.$watch("radius", async () => {
        await this.refreshDisplayCircle({
          interactions: true,
          animationTime: 0,
        });
        this.onCircleStopMove();
      });
    },

    /**
     * Permet d'arrêter l'écoute de tous les watchers du composant
     * @method
     * @public
     */
    removeWatcher() {
      Object.values(watchers).map((watcher) => {
        if (watcher !== null) {
          watcher(); //unwatch
          watcher = null;
        }
      });
    },

    /**
     * Permet de rafraichir l'affichage des cercles à l'écran. La fonction met à jour à la fois les cercles visibles avec le style css qui leur est associé mais également les cercles invisbles servant à élargir la zone de capture des événements avec le pointeur.
     * @method
     * @public
     * @param {
     *  interactions: Boolean,
     *  animationTime: Number
     * } Object Objet d'options permettant de configurer la manière doit-être rafraichit les cercles. En autorisant l'utilisateur à effectuer des actions sur les cercles et/ou en rafraichissant les cercles avec l'animation avec une durée customisable
     */
    async refreshDisplayCircle({interactions, animationTime}) {
      let sectionsPath = circlesData.map((circle) => CircleUtility.getCirclePaths(circle, this.radius, displayEvent))
      sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

      return Circles.displayCircles({
        paths: sectionsPath,
        interactions: interactions,
        animationTime: animationTime,
      })
    },
  },
};
</script>

<style>
.circle {
  fill: none;
}

.invisible-custom-circle {
  fill: none;
  stroke: transparent;
  stroke-width: 8px;
}
</style>