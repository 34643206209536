<template>
  <div
    ref="context-menu-container"
    class="context-menu-container"
    :style="`z-index: ${zIndex}`"
  >
    <ul class="menu-list">
      <div
        v-for="(item, index) in items"
        :key="item.id"
      >
        <li
          v-if="item.icon"
          class="menu-item-icon"
          :index="index"
        >
          <svg
            :height="30 * contextMenuScale()"
            :width="40 * contextMenuScale()"
          >
            <component
              :is="item.icon"
              class="menu-icon"
              :transform="`translate(${20 * contextMenuScale()}, ${
                15 * contextMenuScale()
              }) scale(${0.35 * contextMenuScale()})`"
            />
          </svg>
        </li>
        <li
          v-else
          class="menu-item-text"
          :class="
            item.selected ? 'menu-item-color-selected' : 'menu-item-color'
          "
          :style="fontSize"
          :index="index"
        >
          <slot
            name="croix"
            :item="item"
          >
            {{ item.label }}
          </slot>
        </li>
      </div>
    </ul>
  </div>
  <ContextMenu
    v-if="
      indexHoveredItem &&
        items[indexHoveredItem] &&
        items[indexHoveredItem].children
    "
    ref="submenu"
    :items="items[indexHoveredItem].children"
    :x="posXSubMenu"
    :y="posYSubMenu"
    :z-index="zIndex"
    @loaded="onSubMenuLoaded"
  />
</template>

<script>
import Logo0 from "../assets/icons/logo0.vue";
import HistoryIcon from "../assets/icons/history.vue";
import Logo2 from "../assets/icons/logo2.vue";
import Logo3 from "../assets/icons/logo3.vue";
import Logo4 from "../assets/icons/logo4.vue";
import Logo5 from "../assets/icons/logo5.vue";
import { mapActions, mapGetters } from "vuex";

import menuTypes from "../enums/menu_types.js";
import * as d3 from "d3";
import D3Animation from "@/config/D3Animation.js";
import utils from "@/libraries/utils"

let timeoutID = null
/**
 * Ce composant permet l'affichage de menu contextuel
 * @displayName Composant ContextMenu
 */
export default {
  name: "ContextMenu",
  components: {
    Logo0,
    HistoryIcon,
    Logo2,
    Logo3,
    Logo4,
    Logo5,
  },
  props: {
    /**
     * Tableau contenant les informations d'un item de la liste
     * @type {EyeContextMenuItem[]}
     */
    items: {
      type: Array,
      required: true,
    },

    /**
     * Coordonnée x du coin supérieur gauche du menu
     * @type {Number}
     */
    x: {
      type: Number,
      required: true,
    },

    /**
     * Coordonnée y du coin supérieur gauche du menu
     * @type {Number}
     */
    y: {
      type: Number,
      required: true,
    },
    /**
     * Valeur définissant le z-index du menu contextuel
     * @type {Number}
     */
    zIndex: {
      type: Number,
      required: true,
    },
  },
  emits: ["close", "loaded"],
  data: () => ({
    /**
     * Index de l'item en train d'être survolé par l'utilisateur
     * @type {Number}
     */
    indexHoveredItem: null,

    /**
     * Coordonnée x du coin supérieur gauche du menu
     * @type {Number}
     */
    posX: 0,

    /**
     * Coordonnée y du coin supérieur gauche du menu
     * @type {Number}
     */
    posY: 0,

    /**
     * Coordonnée x du coin supérieur gauche du sous-menu
     * @type {Number}
     */
    posXSubMenu: 0,
    /**
     * Coordonnée y du coin supérieur gauche du sous-menu
     * @type {Number}
     */
    posYSubMenu: 0,
  }),
  computed: {
    ...mapGetters({
      radioRemPx: "layout/radioRemPx",
      isLoadingRepresentation: 'isLoadingRepresentation'
    }),

    /**
     * Taille de la police utilisé pour le menu
     * @type {String}
     */
    fontSize() {
      return {
        "font-size": 1.2 * this.contextMenuScale() + "rem",
      };
    },
  },
  watch: {
    x: {
      handler() {
        this.drawMenu();
      },
    },
    y: {
      handler() {
        this.drawMenu();
      },
    },
    items: {
      handler() {
        this.indexHoveredItem = null;
        this.drawMenu(); // permet d'afficher le menu conetextuel
        this.linkD3(); // permet de lier le menu à des actions over ecttt
        this.$emit('loaded')
      },
      flush: "post",
    },
    indexHoveredItem(newIndex, oldIndex) {
      if (oldIndex !== null && newIndex !== null) {
        const previousHovered = d3
          .select(this.$refs["context-menu-container"])
          .select(`li[index="${oldIndex}"]`);

        if (this.items[oldIndex].selected === false) {
          previousHovered
            .transition()
            .duration(D3Animation.CONTEXT_MENU_LEAVE_PREVIOUS_HOVERED_ITEM)
            .style("color", "var(--c-gray-1)");
        }
        if (this.items[oldIndex].type === menuTypes.ICON) {
          previousHovered
            .select("g")
            .transition()
            .duration(D3Animation.CONTEXT_MENU_LEAVE_PREVIOUS_HOVERED_ICON)
            .style("opacity", 0.7)
            .attr(
              "transform",
              `translate(${20 * this.contextMenuScale()},${
                15 * this.contextMenuScale()
              }) scale(${0.35 * this.contextMenuScale()})`
            );
        }
      }
    },
    // Eviter que le menu dépasse de l'écran lorsque sa taille est augmentée
    // Ne marche plus car contextMenuScale n'est plus un computed
    contextMenuScale: {
      handler() {
        this.drawMenu();
      },
      flush: "post",
    },
  },
  mounted() {
    this.listenBodyEvent();
    this.drawMenu();
    this.linkD3();
    this.$emit('loaded')
  },
  beforeUnmount() {
    if (timeoutID) {
      clearTimeout(timeoutID)
      timeoutID = null
    }
    const rootApp = document.getElementById('root-app')
    rootApp.removeEventListener("click", this.emitCloseEvent)
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
  
    onSubMenuLoaded() {
      this.posYSubMenu = this.posY + this.heightLine() * +this.indexHoveredItem;
      this.posXSubMenu = this.posX + this.widthContainer() + 10;

      if (this.$refs.submenu) {
        if (this.posXSubMenu + this.$refs.submenu.widthContainer() > window.innerWidth) {
          this.posXSubMenu = this.posX - this.$refs.submenu.widthContainer() - 10
        }
      }
    },
    /**
     * Cette fonction retourne la largeur du menu
     * @method
     * @public
     */
    widthContainer() {
      return this.$refs["context-menu-container"].offsetWidth;
    },

    /**
     * Cette fonction retourne la hauteur du menu
     * @method
     * @public
     */
    heightContainer() {
      return this.$refs["context-menu-container"].offsetHeight;
    },

    /**
     * Cette fonction retourne la hauteur d'un item du menu
     * @method
     * @public
     */
    heightLine() {
      return (
        this.$refs["context-menu-container"].offsetHeight / this.items.length
      );
    },

    /**
     * retourne le % d'augmentation ou rétrécissement de la taille par rapport à la taille de base
     * @method
     * @public
     */
    contextMenuScale() {
      let documentFontSize = document.documentElement.style.fontSize;
      documentFontSize = +documentFontSize.slice(0, -1);

      return documentFontSize / 62.5;
    },

    /**
     * Cette fonction permet de lier les items du menu avec D3 pour la gestion des événements
     * @method
     * @public
     */
    linkD3() {
      d3.select(this.$refs["context-menu-container"])
        .selectAll("li")
        .data(this.items)
        .on("mouseover", (event) => {
          if (event.isTrusted) {
            //sauvegarde temporaire pour eviter que currentTarget soit null dans onOverItem, c'est un comportement spécial (https://developer.mozilla.org/en-US/docs/Web/API/Event/currentTarget)
            const currentTarget = event.currentTarget
            timeoutID = setTimeout(this.onOverItem, 50, event, currentTarget)
          } else {
            this.onOverItem(event, event.currentTarget)
          }
        })
        .on("mouseleave", (event) => {
          if (timeoutID) {
            clearTimeout(timeoutID)
            timeoutID = null
          }
        })
        .on("click", async (event) => {
          this.sendEvent({
            event: event,
          });
          if (this.items[this.indexHoveredItem].click) {
            await this.items[this.indexHoveredItem].click()
          }
          //Si une animation globale sur les cercles est en cours
          if (this.isLoadingRepresentation) {
            utils.onGlobalAnimationEnd(this.$store, () => {
              this.collaborativeEventTreated()
            })
          } else {
            this.collaborativeEventTreated()
          }
        });
    },
    /**
     * Gestion du survol des items du menu contextuel
     * @method
     * @param {*} event 
     * @param {*} currentTarget 
     */
    onOverItem(event, currentTarget) {
      this.sendEvent({
        event: event,
        currentTarget: currentTarget
      })
      this.indexHoveredItem = d3.select(currentTarget).attr("index");
      d3.select(currentTarget)
        .transition()
        .duration(D3Animation.CONTEXT_MENU_HOVERED_ITEM)
        .style("color", "var(--color-text)")

      if (this.items[this.indexHoveredItem].type === menuTypes.ICON) {
        d3.select(currentTarget)
          .select("g")
          .transition()
          .duration(D3Animation.CONTEXT_MENU_HOVERED_ICON)
          .style("opacity", 1)
          .attr(
            "transform",
            `translate(${20 * this.contextMenuScale()},${
              15 * this.contextMenuScale()
            }) scale(${0.4 * this.contextMenuScale()})`
          )
      }

      this.collaborativeEventTreated()
    },
    /**
     * Cette fonction permet placer le menu à la bonne position
     * @method
     * @public
     */
    drawMenu() {
      this.posX = this.x;
      this.posY = this.y;
      if (this.posX + this.widthContainer() > window.innerWidth) {
        this.posX = window.innerWidth - this.widthContainer();
      }
      if (this.posY + this.heightContainer() > window.innerHeight) {
        this.posY = window.innerHeight - this.heightContainer();
      }
      d3.select(this.$refs["context-menu-container"])
        .style("left", `${this.posX}px`)
        .style("top", `${this.posY}px`)
        .transition()
        .duration(D3Animation.CONTEXT_MENU_DRAW_MENU)
        .style("opacity", 1);
    },
    /**
     * Cette fonction permet d'initialiser un événement d'écoute au click sur le body de la page
     * @method
     * @public
     */
    listenBodyEvent() {
      const rootApp = document.getElementById('root-app')
      rootApp.addEventListener("click", this.emitCloseEvent);
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur effectue un clique sur le body. Elle permet d'envoyer un signal au composant parent indiquant que le menu contextuel doit se fermer
     * @param {Event} event Evénement fournit par le listener
     */
    async emitCloseEvent(event) {
      this.sendEvent({ event: event })
      await this.$emit("close")
      this.collaborativeEventTreated()
    },
  },
};
</script>

<style scoped>
.context-menu-container {
  position: fixed;
  background-color: var(--color-bg-1);
  opacity: 0.85;
  border: solid;
  border-color: var(--color-border);
  border-width: 0.13rem;
  border-radius: 1.1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  width: max-content;
}

.menu-list {
  list-style: none;
  padding: 0;
}

.menu-item-text {
  cursor: pointer;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-inline: 2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.menu-item-color {
  color: var(--c-gray-1);
}
.menu-item-color-selected {
  color: var(--color-text);
}

.menu-item-icon {
  cursor: pointer;
}

.menu-icon {
  opacity: 0.7;
  font-size: 1.9rem;
}
</style>