<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 218.93 42.48"
  >
    <g>
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M110.14,11.76a8.39,8.39,0,0,0-6.25,2.75,9.89,9.89,0,0,0-2.49,6.76A9.71,9.71,0,0,0,104,28a8.89,8.89,0,0,0,6.5,2.74A9.26,9.26,0,0,0,117,28.4a.62.62,0,0,0,.16-.69l-.54-1.35a.66.66,0,0,0-.44-.37.64.64,0,0,0-.55.15,7.06,7.06,0,0,1-5,2,6.08,6.08,0,0,1-6.09-5.62H118a.61.61,0,0,0,.61-.59c0-.2,0-.37,0-.51a10.88,10.88,0,0,0-2-6.58A7.82,7.82,0,0,0,110.14,11.76ZM104.46,20c.47-3.44,2.76-5.68,5.62-5.69a5,5,0,0,1,4,1.82A7.59,7.59,0,0,1,115.59,20Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M149,11.76a8.4,8.4,0,0,0-6.26,2.75,9.94,9.94,0,0,0-2.49,6.76,9.7,9.7,0,0,0,2.6,6.77,8.89,8.89,0,0,0,6.5,2.74,9.25,9.25,0,0,0,6.55-2.46.63.63,0,0,0,.16-.69l-.55-1.35a.63.63,0,0,0-.44-.37.61.61,0,0,0-.55.15,7.06,7.06,0,0,1-5.1,2.07,6.1,6.1,0,0,1-6.1-5.62h13.56a.62.62,0,0,0,.62-.59v-.51a10.94,10.94,0,0,0-2-6.58A7.83,7.83,0,0,0,149,11.76ZM143.35,20c.47-3.44,2.75-5.68,5.61-5.69a5,5,0,0,1,4,1.82A7.59,7.59,0,0,1,154.47,20Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M187.91,11.76a8.41,8.41,0,0,0-6.26,2.75,9.93,9.93,0,0,0-2.48,6.76A9.71,9.71,0,0,0,181.76,28a8.89,8.89,0,0,0,6.5,2.74,9.22,9.22,0,0,0,6.48-2.4.62.62,0,0,0,.16-.69l-.55-1.34a.59.59,0,0,0-.43-.37.61.61,0,0,0-.56.15,7,7,0,0,1-5,2,6.1,6.1,0,0,1-6.1-5.62H195.8a.61.61,0,0,0,.61-.59c0-.2,0-.37,0-.51a10.88,10.88,0,0,0-2-6.58A7.82,7.82,0,0,0,187.91,11.76ZM182.23,20c.47-3.44,2.76-5.68,5.61-5.69a5,5,0,0,1,4,1.82A7.59,7.59,0,0,1,193.36,20Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M98.84,12.14H97a.62.62,0,0,0-.57.39L90.6,26.71,84.85,12.53a.62.62,0,0,0-.57-.39H81.87a.61.61,0,0,0-.57.39L75.51,26.71,69.76,12.53a.61.61,0,0,0-.57-.39h-2a.64.64,0,0,0-.52.28.63.63,0,0,0,0,.58L74,30.13a.63.63,0,0,0,.57.37h1.76a.61.61,0,0,0,.57-.37L83,16l6.08,14.14a.61.61,0,0,0,.56.37h1.76a.61.61,0,0,0,.57-.37L99.41,13a.63.63,0,0,0,0-.58A.64.64,0,0,0,98.84,12.14Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M137.73,12.14h-1.89a.61.61,0,0,0-.57.39l-5.79,14.18-5.75-14.18a.61.61,0,0,0-.57-.39h-2a.62.62,0,0,0-.51.28.6.6,0,0,0,0,.58L128,30.13a.63.63,0,0,0,.57.37h1.76a.62.62,0,0,0,.57-.37L138.29,13a.58.58,0,0,0,0-.58A.61.61,0,0,0,137.73,12.14Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M210.54,22a13.53,13.53,0,0,0-3.8-2.11h0a16.36,16.36,0,0,1-3.17-1.58,3,3,0,0,1-.83-.85,1.53,1.53,0,0,1-.19-1,2.18,2.18,0,0,1,1-1.68,3.87,3.87,0,0,1,2.51-.48,9.39,9.39,0,0,1,4,1.49.62.62,0,0,0,.93-.69l-.8-2a.61.61,0,0,0-.33-.39,10.4,10.4,0,0,0-3.45-1,7,7,0,0,0-4.61,1,4.91,4.91,0,0,0-2.2,3.66,4.17,4.17,0,0,0,1.5,3.79,14.22,14.22,0,0,0,4,2.12h0a14.1,14.1,0,0,1,2.94,1.43,3.08,3.08,0,0,1,.87.91,1.81,1.81,0,0,1,.22,1.1A2.43,2.43,0,0,1,208,27.64a4.2,4.2,0,0,1-2.69.52,9.7,9.7,0,0,1-4.8-2,.63.63,0,0,0-.72,0,.61.61,0,0,0-.24.68l.88,2.25a.61.61,0,0,0,.3.37,10.77,10.77,0,0,0,4.17,1.28,7.7,7.7,0,0,0,4.57-.84,5.09,5.09,0,0,0,2.55-4A4.35,4.35,0,0,0,210.54,22Z"
      />
      <path
        class="fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9"
        d="M176.61,12.14h-1.89a.62.62,0,0,0-.57.39l-5.78,14.18-5.75-14.18a.62.62,0,0,0-.57-.39h-2a.64.64,0,0,0-.52.28.63.63,0,0,0,0,.58l7.41,17.23-2.22,5.45v.47l.67,1.55a.62.62,0,0,0,.56.37.61.61,0,0,0,.57-.37L177.18,13a.63.63,0,0,0-.05-.58A.64.64,0,0,0,176.61,12.14Z"
      />
      <path
        class="e081d1d6-9605-4dd7-ad22-0bf1dda05819"
        d="M15,14.8a.62.62,0,0,0-.57-.19A.64.64,0,0,0,14,15l-.72,1.61a.63.63,0,0,0,.13.69l11,11.18L8.73,28.14a.62.62,0,0,0-.6.38l-.79,1.84a.67.67,0,0,0,0,.6.66.66,0,0,0,.53.28H27a.63.63,0,0,0,.55-.35,4.9,4.9,0,0,1-.07-.67A5.4,5.4,0,0,1,28,27.94Z"
      />
      <path
        class="e081d1d6-9605-4dd7-ad22-0bf1dda05819"
        d="M32.08,7a.63.63,0,0,0-.28-.53.62.62,0,0,0-.59,0l-1.33.59a.65.65,0,0,0-.38.59l.24,15.68L18.62,12.25a.65.65,0,0,0-.7-.13l-1.62.71a.63.63,0,0,0-.19,1L28.87,26.63a5.43,5.43,0,0,1,3.33-1.81Z"
      />
      <path
        class="e081d1d6-9605-4dd7-ad22-0bf1dda05819"
        d="M37.12,26.66,50,13.67a.61.61,0,0,0,.18-.57.62.62,0,0,0-.37-.46L48.31,12a.63.63,0,0,0-.69.14l-11,11.16L36.7,7.59A.63.63,0,0,0,36.31,7l-1.55-.63a.64.64,0,0,0-.6.06.62.62,0,0,0-.27.53l0,17.86A5.47,5.47,0,0,1,37.12,26.66Z"
      />
      <path
        class="e081d1d6-9605-4dd7-ad22-0bf1dda05819"
        d="M58.71,30.36,58,28.58a.66.66,0,0,0-.6-.38l-15.67.32,11-11.17a.63.63,0,0,0,.13-.69L52,14.82a.63.63,0,0,0-1-.18L38.09,28.27a3,3,0,0,1,.1.32h-.42l-4.45.12a.87.87,0,0,0-.44.2l-1.19,1.26a.63.63,0,0,0,.46,1.06h26a.64.64,0,0,0,.53-.28A.61.61,0,0,0,58.71,30.36Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "EyediagLogo"
}
</script>

<style scoped>

.fb4c8c30-39fd-4ebf-be7b-c90afb89dbd9 {
  fill: var(--c-logo-weveyes);
}

.e081d1d6-9605-4dd7-ad22-0bf1dda05819 {
  fill: #00b6e7;
}

</style>