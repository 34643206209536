<template>
  <div style="font-size: 2rem; color: white;">
    <p>Chaque cercle correspond à une temporalité.</p>
    <p>Ici 1 cercle = 1 an.</p>
    <p>Cette temporalité est modifiable depuis le menu contextuel.</p>
  </div>
</template>

<script>
export default {
  name: "Component2Tuto",
}
</script>

<style scoped>
p {
    padding-bottom: 10px;
}
</style>