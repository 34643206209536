import * as mutationTypes from '../mutations-types.js'

const getDefaultState = () => {
  return {
    lockedPie: null,
    lockedPieToUnlock: null,
    lockedEvent: null,
    configSaveButton: null,
    hoveredSaveButton: false,
    savingFieldEvent: null,
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    lockedPie: state => state.lockedPie,
    lockedPieToUnlock: state => state.lockedPieToUnlock,
    lockedEvent: state => state.lockedEvent,
    isLocked: state => state.lockedPie !== null || state.lockedEvent !== null,
    configSaveButton: state => state.configSaveButton,
    isHoveredSaveButton: state => state.hoveredSaveButton,
    savingFieldEvent: state => state.savingFieldEvent
  },
  mutations: {
    [mutationTypes.SET_LOCKED_PIE_TO_UNLOCK] (state, payload) {
      state.lockedPieToUnlock = payload
    },
    [mutationTypes.SET_LOCKED_PIE] (state, payload) {
      state.lockedPie = payload
    },
    [mutationTypes.SET_LOCKED_EVENT] (state, payload) {
      state.lockedEvent = payload
    },
    [mutationTypes.SET_CONFIG_BOX_ARROW] (state, payload) {
      state.configSaveButton = payload
    },
    [mutationTypes.SET_HOVERED_SAVE_BUTTON] (state, payload) {
      state.hoveredSaveButton = payload
    },
    [mutationTypes.SET_SAVING_FIELDS_EVENT] (state, payload) {
      state.savingFieldEvent = payload
    }
  },
  actions: {
    /**
     * Permet de remettre à la valeur par défaut le state du module
     * @method
     * @public
     */
    resetState(context) {
      Object.assign(context.state, getDefaultState())
    },
    blurTooltipField() {
      document.activeElement.setAttribute('code-blur', true)
      document.activeElement.blur()
    }
  }
}