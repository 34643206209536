export default [
  {
    label: "Ans",
    value: "utcYear"
  },
  {
    label: "Mois",
    value: "utcMonth"
  },
  {
    label: "Semaines",
    value: "utcMonday"
  },
  {
    label: "Jours",
    value: "utcDay"
  }
]