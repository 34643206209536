<template>
  <GenericModal
    :id="id"
    :title="title"
    :description="description"
    :buttons="buttons"
    theme="success"
    @close="onCancel"
  />
</template>

<script>
import GenericModal from '@/components/modal/GenericModal.vue'
import { mapGetters } from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'

export default {
  components: {
    GenericModal
  },
  data: () => ({
    /**
     * Il s'agit des informations sur la personne souhaitant prendre le contrôle de la session collaborative
     * @type {Object}
     */
    callerData: null,
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-collaborative-presenter-delegation"
  }),
  computed: {
    ...mapGetters({
      wsSocket: "ws/wsSocket",
    }),
    /**
     * Titre de la modal
     * @type {String}
     */
    title() {
      return 'Prise de contrôle'
    },
    /**
     * Description de la modal
     * @type {String}
     */
    description() {
      return this.callerData
        ? `${this.callerData.name} ${this.callerData.familyName} souhaite prendre le contrôle de la session`
        : ''
    }
  },
  watch: {
    wsSocket() {
      this.setupWsEvent()
    }
  },
  mounted() {
    this.setButtons()
    this.setupWsEvent()
  },
  unmounted() {
    if (this.wsSocket !== null) {
      this.wsSocket.off('collaborative:askToBePresenter', this.callback)
    }
  },
  methods: {
    /**
     * Cette fonction est appelé lorsque l'utilisateur est présentateur et qu'un autre participant de la session collaborative souhaite prendre le contrôle de la session
     * @param {Object} param0 
     */
    callback({caller}) {
      this.callerData = caller
      const elem = document.getElementById(this.id)
      if (elem.open === false) {
        elem.showModal()
      }
    },
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Valider',
          backgroundColorTheme: 'success',
          color: 'white',
          onClick: this.onConfirm
        },
        {
          label: 'Refuser',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onCancel
        }
      ]
    },
    /**
     * Cette fonction est appelé lorsque l'utilsateur appuie sur le bouton 'valider', elle permet d'autoriser la prise de contrôle à la personne qui l'a demandé
     * @param {Event} event Il s'agit de l'événement fournit par le listener
     */
    onConfirm(event) {
      event.preventDefault();
      this.$store.commit(`pie/${mutationTypes.RESET_SELECTED_SECTION}`)
      const elem = document.getElementById(this.id)
      elem.close()
      if (this.callerData) {
        this.wsSocket.emit('collaborative:changePresenter', {
          roomId: this.$store.state.ws.roomId,
          idSocket: this.callerData.idSocket
        })
      }
      this.callerData = null
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur clique sur le bouton 'refuser' ou sur le croix. Cette action ignore la prise de contrôle demandé par un autre utilisateur de la session collaborative
     * @param {Event} event Il s'agit de l'événement fournit par le listener
     */
    onCancel(event) {
      event.preventDefault()
      const elem = document.getElementById(this.id)
      elem.close()
      this.callerData = null
    },
    setupWsEvent() {
      if (this.wsSocket === null) {
        return
      }

      this.wsSocket.on('collaborative:askToBePresenter', this.callback)
    }
  },
}
</script>