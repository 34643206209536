import menuTypes from '@/enums/menu_types.js'

/**
 * Lorsque l'utilisateur selectionne une fonctionnalité du menu contextuel, cette fonction permet l'initialisation du on/off sur l'icon de la section dans laquelle se trouve la fonctionnalité
 * @param {Objet} context Context dans lequel la fonction est appelée
 * @param {EyeContextMenuItem[]} contextMenuConfig Il s'agit du tableau parent decrivant le menu contextuel / contenant l'ensemble des fonctionnalités du menu contextuel
 */
export function manageSelectOption(context, contextMenuConfig) {
  const idSection = context.id.split('-')
  if (idSection.length > 0) {
    // "+ number" mean casting an interger to a string value. Il ne faut pas que dans le cas d'1 seule hierarchie executer ce bout de code, car cela va modifier l'objet de la fonctionnalité et non pas l'objet des "icones"
    if (context.on && context.off && context !== contextMenuConfig[+idSection[0]]) {
      contextMenuConfig[+idSection[0]].state = context.selected === false ? 'on' : 'off'
      contextMenuConfig[+idSection[0]].selected = !context.selected
      contextMenuConfig[+idSection[0]].on = context.on
      contextMenuConfig[+idSection[0]].off = context.off
    }
  }
}

/**
 * fonction pour modifier la seleciton dans un menu contextuel
 * @method
 * @public
 * @param {*} menuConfig
 * @param {*} newLabelId
 * @param {*} oldLabelId   
 */
export function modifySelection(menuConfig, newLabelId, oldLabelId) {
  for (const item of menuConfig.items) {
    if (item.labelId === newLabelId) {
      item.selected = true
    } else if (item.labelId === oldLabelId) {
      item.selected = false
    }
  }
}

/**
 * Lors d'un clique sur une fonctionnalité du menu contextuel ou sur une icon, cette fonction permet l'execution de la fonctionnalité "on/off"
 * @param {Objet} context Context dans lequel la fonction est appelée
 */
export async function onOff(contextMenuConfig, context) {
  const isOnOption = (context.type === menuTypes.ICON && context.state === 'on')
    || (context.type !== menuTypes.ICON && context.selected === true)

  if (isOnOption === true) {
    await context.off()
  } else {
    await context.on()
  }

  if (context.type === menuTypes.ICON && context.selected !== undefined) {
    context.state = context.state === 'on' ? 'off' : 'on'
  }
}