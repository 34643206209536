<template>
  <div style="font-size: 2rem; color: white;">
    <img
      class="image"
      src="./16.png"
    >
    <p>Le 4ème permet d'appliquer des scores dont le but est de comparer les cercles patients avec un cercle de référence.</p>
  </div>
</template>

<script>
export default {
  name: "Component16Tuto",
}
</script>
    
<style scoped>
p {
  padding-bottom: 10px;
}
.image {
  width: 100%;
  padding-bottom: 10px;
}
</style>