/**
 * class Proportion
 */

class Propotion {
  constructor() {
    /**
     * Cet objet contient les pourcentages de marge présent tout autour de la représentation
     * @type {Object}
     */
    this.baseMargin = {
      top: 0.15,
      right: 0.15,
      bottom: 0.15,
      left: 0.15
    }
    /**
     * Cet objet contient les valeurs de marges supplémentaires aux marges de bases devant être présente autour de la représentation
     * @type {Object}
     */
    this.margin = {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0
    }
    /**
     * Largeur disponible pour l'affichage de la représentation
     * @type {Number}
     */
    this.width = window.innerWidth - window.innerWidth * (this.baseMargin.left + this.baseMargin.right) //- this.margin.left - this.margin.right
    /**
     * Hauteur disponible pour l'affichage de la représentation
     * @type {Number}
     */
    this.height = window.innerHeight - window.innerHeight * (this.baseMargin.top + this.baseMargin.bottom) //- this.margin.top - this.margin.bottom
  }

  /**
   * Permet de mettre à jour l'objet des marges supplémentaires
   * @param {Object} margin Objet contenant les valeurs des marges supplémentaires aux marges de bases
   */
  addMargin(margin) {
    this.margin = margin
  }

  /**
   * Permet de calculer le rayon de référence utilisé par l'application
   * @returns {Number} Rayon de référence
   */
  getRadiusSize() {
    return Math.min(this.width / 2, this.height / 2)
  }

  /**
   * Permet d'obtenir les données permettant d'appliquer un "transform" sur le group contenant la représentation
   * @returns {Object} x: Number Centre de la représentation sur l'axe x;
   *  y: Number Centre de la représentation sur l'axe y;
   *  k: Number ratio à l'aquelle la représentation doit être zoomée
   */
  getTransformRepresentation() {
    return {
      x: this.margin.left + (window.innerWidth / 2) - this.margin.right,
      y: this.margin.top + (window.innerHeight / 2) - this.margin.bottom,
      k: 1
    }
  }
  
  /**
   * Permet de mettre à jour les proportions occupés par la représentation
   */
  updateProportion() {
    this.width = window.innerWidth - window.innerWidth * (this.baseMargin.left + this.baseMargin.right) //- this.margin.left - this.margin.right
    this.height = window.innerHeight - window.innerHeight * (this.baseMargin.top + this.baseMargin.bottom) //- this.margin.top - this.margin.bottom
  }
}

export default new Propotion()