<template>
  <div style="font-size: 2rem; color: white;">
    <p>Chaque événement est représenté par une déformation sur le cercle.</p>
    <p>Plus la déformation est intense, plus la sévérité de l'évènement est élevée.</p>
  </div>
</template>
  
<script>
export default {
  name: "Component6Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
</style>