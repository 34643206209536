export default {
  STANDARD: 1,
  ADMIN: 2,
  PHARMACIEN: 3,
  GENERALISTE: 4,
  QOR: 5,
  ROBOT: 6,
  TUTO: 7,
  RECORDABLE: 8,
  UNIVERSEL: 9,
  DISCLAIMER: 10,
  CREATE_ACCOUNT: 11,
  CREATE_ACCOUNT_WITH_CREDIT: 12,
  TMP_ACCOUNT: 13
}