<template>
  <ListCollapsableItem>
    <template #title>
      <p class="font-bold">
        Patient
      </p>
    </template>
    <template #content>
      <div class="flex flex-col mt-3">
        <div class="flex grow items-center">
          <label 
            for="dateFrom"
            class="mr-1 date-label"
          >De</label>
          <input 
            id="dateFrom" 
            v-model="dateFromInput"
            type="date" 
            class="grow mt-1"
            @input="e => formatDate(e, dateFromInput, 'dateFrom')" 
          >
          <br>
        </div>
        <div class="flex grow items-center">
          <label 
            for="dateTo"
            class="mr-1 date-label" 
          >
            À
          </label>
          <input 
            id="dateTo" 
            v-model="dateToInput"
            type="date"
            class="grow mt-1"
            @input="e => formatDate(e, dateToInput, 'dateTo')" 
          >
        </div>
      </div>
      <div class="flex flex-col mt-3">
        <label>Genre</label>
        <div class="filtre-button-group">
          <div
            v-for="elem in genderOptions"
            :key="elem.label"
            class="mr-1"
          >
            <input
              :id="'gender-' + elem.value"
              v-model="gender"
              class="filtre-button"
              type="radio"
              name="gender"
              :value="elem.value"
              @change="e => onInput(e, gender, true)"
            >
            <label
              :for="'gender-' + elem.value"
              class="filtre-button-label"
            >
              {{ elem.label }}
            </label>
          </div>
        </div>
      </div>
      <div class="flex grow flex-col mt-2">
        <label>Age</label>
        <div class="flex grow mt-1 ml-4">
          <div class="flex items-center mr-2">
            <label 
              class="mr-1"
              for="min-age"
            >
              Min
            </label>
            <input
              id="min-age"
              v-model="minAge"
              type="number"
              min="0"
              width="20px"
              placeholder="60"
              style="width: 30px;"
              @input="e => onInput(e, minAge)"
            >
          </div>
          <div class="flex items-center">
            <label 
              class="mr-1"
              for="max-age"
            >
              Max
            </label>
            <input
              id="max-age"
              v-model="maxAge"
              type="number"
              min="0"
              placeholder="70"
              style="width: 30px;"
              @input="e => onInput(e, maxAge)"
            >
          </div>
        </div>
      </div>
      <div class="flex flex-col grow mt-3">
        <label>Localisation</label>
        <div class="flex flex-col grow ml-4 mt-1">
          <input
            v-model="codepstl"
            class="mt-1"
            placeholder="Code postal (France)"
            @input="e => onInput(e, codepstl)"
          >
  
          <input
            id="input-pays"
            v-model="pays"
            class="mt-1"
            placeholder="Pays (hors France)"
            type="text"
            @input="filterSuggestPays"
          >
          <ul v-if="paysSubstring.length > 0">
            <li
              v-for="suggestion in filteredSuggestPays"
              :key="suggestion"
              @click="e => selectSuggestion(e, suggestion)"
            >
              {{ suggestion }}
            </li>
          </ul>
        </div>
      </div>
    </template>
  </ListCollapsableItem>
</template>

<script>
import ListCollapsableItem from '@/components/borderLists/CollapsableItem.vue'
import { mapActions } from 'vuex'
import {list_pays} from './list_pays'
export default {
  name: 'FiltersPopulationalPatient',
  components: {
    ListCollapsableItem
  },
  data: () => ({
    dateFrom: '00000000',
    dateFromInput: '',
    dateTo: '00000000',
    dateToInput: '',

    pays:'',
    codepstl: '',
    gender: '',
    minAge: null,
    maxAge: null,
    genderOptions: [
      {
        label: 'Tous',
        value: ''
      },
      {
        label: 'Femme',
        value: 'F'
      },
      {
        label: 'Homme',
        value: 'M',
      },

    ],
    list_pays,
    filteredSuggestPays: [],
  }),
  computed: {
    age() {
      const min = parseInt(this.minAge) > 200 ? 200 : parseInt(this.minAge) 
      const max = parseInt(this.maxAge) > 200 ? 200 : parseInt(this.maxAge)
      if ((min && min !== NaN) && (max && max !== NaN)) {
        return min <= max ? `${min}-${max}` : `${max}-${min}`;
      }
      else if (min && min != NaN) {
        return `${min}-999`
      }
      else if (max && max != NaN) {
        return `0-${max}`
      }
      return undefined;
    },
    paysSubstring() {
      const lastSemicolonIndex = this.pays.lastIndexOf(';');
      return lastSemicolonIndex !== -1 ? this.pays.substring(lastSemicolonIndex + 1) : this.pays;
    },

  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * @public
     */
    getFilters() {
      const filters = {}
      const codepst = this.codepstl.replace(/[^a-zA-Z0-9]/g, '~').replace("~~", '~')
      const pays = this.pays.replace(";", '~')
      if (codepst.length > 0) {
        filters.codepostal = codepst
      }
      if (pays.length > 0) {
        filters.filtre_pays = pays
      }

      if (this.gender.length > 0) {
        filters.filtre_gender = this.gender
      }
      filters.filtre_age = this.age && this.age.length > 0 ? this.age : undefined
      filters.dates = this.dateFrom + this.dateTo
      return filters
    },
    /**
     * @public
     * @param {event}
     * @param {String} text
     * @param {Boolean} isRadio
     */
    onInput(e, text, isRadio = false) {
      e.preventDefault()
      e.stopPropagation()
      this.sendEvent({
        event: e,
        params: {
          text: text,
          change: isRadio === true
        }
      })
      this.collaborativeEventTreated()
    },
    /**
     * @public
     * @param{event}
     */
    onSelect(e) {
      e.preventDefault()
      e.stopPropagation()
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value,
          change: true
        }
      })
      this.collaborativeEventTreated()
    },
    filterSuggestPays(event) {
      this.sendEvent({
        event: event,
        params: {
          text: this.pays
        }
      })
      this.filteredSuggestPays = this.list_pays.filter((suggestion) => {
        return suggestion.toLowerCase().includes(this.paysSubstring.trim().toLowerCase())
      })
      this.collaborativeEventTreated()
    },
    selectSuggestion(event, suggestion) {
      this.sendEvent({event: event})
      const lastSemicolonIndex = this.pays.lastIndexOf(';');
      const paysSubstring = lastSemicolonIndex !== -1 ? this.pays.substring(0, lastSemicolonIndex) : ''
      this.pays = paysSubstring.length > 0 ? paysSubstring + ';' + suggestion + ';': suggestion + ';'
      this.filteredSuggestPays = []
      this.collaborativeEventTreated()
    },
    formatDate(event, date, nameFormattedField) {
      this.sendEvent({
        event: event,
        params: {
          text: date
        }
      })
      this[nameFormattedField] = date.replaceAll('-', '')
      this.collaborativeEventTreated()
    }
  },
}
</script>

<style scoped>
.filtre-button-group {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.filtre-button {
  display: none;
}

.filtre-button-label {
  border: 1px solid var(--color-border);
  padding: 2px 4px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--color-text) ;
  font-weight: 100;
  font-size: 1.2rem;
  font-family: var(--font-secondary-1);
  background-color: var(--color-bg);
}

.selected {
  background-color: var(--color-border) !important;
  font-weight: bold  !important;
  
}

.filtre-button:checked + .filtre-button-label {
  background-color: var(--color-border) !important;
  font-weight: bold  !important;
}

.date-label {
  width: 2rem;
}

</style>