import familyHistory from "@/store/modules/refCircle/familyHistory.js"
import score from "@/store/modules/refCircle/score.js"
import associatedEvents from "@/store/modules/refCircle/associatedEvents.js"

export default {
  namespaced: true,
  modules: {
    familyHistory: familyHistory,
    score: score,
    associatedEvents: associatedEvents
  },
  getters: {},
  mutations: {},
  actions: {
    /**
     * Permet de reset par défaut le state de l'application. Cette fonction est utilise avant chaque accès à un nouveau patient pour remttre à 0 tout ce qui a pu être effectué sur l'application
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    async resetState(context) {
      const promises = []
      const modulesToReset = ['familyHistory', 'score', 'associatedEvents']
      
      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`refCircle/${module}/resetState`, null, {root: true}))
      })

      return Promise.all(promises) 
    },
  }
}