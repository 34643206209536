<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./8.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Un clic droit sur un évènement permet l'ouverture d'une tooltip permettant de consulter d'avantage d'informations.</p>
    <p>Celle-ci est redimensionnable et déplaçable sur la page.</p>
  </div>
</template>

<script>
export default {
  name: "Component8Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>