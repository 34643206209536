<template>
  <MovingItem
    ref="tooltipContainer"
    container-class="tooltip-generic-container"
    :ref-slot="refTooltip"
    :z-index="1"
    @my-mouse-move="onMouseMoveContainer"
  >
    <div
      ref="tooltip"
      class="tooltip tooltip-style"
    >
      <div class="flex items-center justify-end">
        <div
          v-if="isPatientEvent"
          class="flex"
          @click="onEditEvent"
        >
          <span class="tooltip-settings cursor-pointer">
            <EditIcon
              width="20px"
              height="20px"
            />
          </span>
        </div>
        <div class="flex">
          <button
            ref="tooltipExit"
            data-test="tooltip-exitButton"
            class="tooltip-exit-button"
          >
            <!-- &#9747; -->
            &#10005;
          </button>
        </div>
      </div>
      <ContentNurseEntry
        v-if="isInNurseEntry"
        :tooltip-data="tooltipData"
        class="tooltip-body"
      />
      <ContentPatientEvent
        v-else-if="isPatientEvent || isScoreEvent"
        :tooltip-data="tooltipData"
        class="tooltip-body"
        @focus-cohort="focusOnCohort"
      />
      <ContentNbEventPerSection
        v-else
        :tooltip-data="tooltipData"
        class="tooltip-body"
        @focus-cohort="focusOnCohort"
      />
    </div>
  </MovingItem>
</template>

<script>
import CircleUtility from "@/libraries/CircleUtility.js";
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "@/store/mutations-types";
import * as d3 from "d3";
import * as eyediagMode from "@/shared/enums/eyediagMode.js";
import ContentPatientEvent from "@/components/tooltip/ContentPatientEvent.vue";
import ContentNbEventPerSection from "@/components/tooltip/ContentNbEventPerSection.vue";
import ContentNurseEntry from "@/components/tooltip/ContentNurseEntry.vue"
import D3Animation from "@/config/D3Animation.js";
import MovingItem from "@/components/MovingItem.vue";
import utils from "@/libraries/utils.js";
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import EditIcon from "@/assets/icons/edit.vue"

export default {
  name: "MyTooltip",
  components: {
    ContentPatientEvent,
    ContentNbEventPerSection,
    ContentNurseEntry,
    MovingItem,
    EditIcon
  },
  props: {
    /**
     * Données de l'événement dont le détail doit être affiché une tooltip
     * @type {EyeEvent}
     */
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  data: () => ({
    /**
     * Il s'agit d'une référence vers la div englobant l'ensemble du composant tooltip
     * @type {Div}
     */
    refTooltip: null
  }),
  computed: {
    ...mapGetters({
      centerX: "layout/centerX",
      centerY: "layout/centerY",
      referenceRadius: "layout/radius",
      hoveredEvent: "event/common/hoveredEvent",
      orderTooltips: "event/common/orderTooltips",
      eyediagMode: "eyediagMode",
      events: "event/common/events",
      isInNurseEntry: "isInNurseEntry"
    }),
    /**
     * Détermine si Eyediag est en mode dossier patient ou en mode universel
     * @type {Boolean}
     */
    isPatientEvent() {
      return this.eyediagMode.type === eyediagMode.mode.PATIENT || this.eyediagMode.type === eyediagMode.mode.UNIVERSAL;
    },
    isScoreEvent() {
      return typeof this.tooltipData.id === 'string' && this.tooltipData.id.includes('SCORE-')
    },
    /**
     * Détermine si les événements doivent être affichés
     * @type {Boolean}
     */
    isDisplayEvents() {
      return this.$store.state.event.common.displayEvents;
    },
    /**
     * Les données d'une tooltip constitue une copie d'un object event. Cette variable contient la source de cette copie
     * @type {EyeEvent}
     */
    srcEyeEvent() {
      const src = this.events.find(e => e.id === this.tooltipData.id)

      return src || null
    }
  },
  watch: {
    hoveredEvent(event) {
      const isEventTooltip =
        event !== null && this.tooltipData.code === event.code;

      if (isEventTooltip) {
        this.crossChangeState(
          false,
          true,
          D3Animation.TOOLTIP_EXIT_BUTTON_BORDER_COLOR_APPEAR
        );
      } else {
        this.crossChangeState(
          false,
          false,
          D3Animation.TOOLTIP_EXIT_BUTTON_BORDER_COLOR_DISAPPEAR
        );
      }
    },
    orderTooltips: {
      handler() {
        const posX = this.tooltipData.cx;
        const posY = this.tooltipData.cy;
        this.$refs.tooltipContainer.setZIndex(1)

        this.$refs.tooltipContainer.updateCoordinate(posX, posY)
      },
    }
  },
  mounted() {
    this.initializeCoordinate();
    this.initializeTooltips();
    this.hideAndShowCross();
    this.refTooltip = this.$refs.tooltip
  },
  beforeUnmount() {
    this.$refs.tooltipContainer.freeContainer()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      sendProperties: "ws/sendProperties",
      getPatientData: "patient/getPatientData",
      collaborativeEventTreated: "ws/collaborativeEventTreated"
    }),
    /**
     * Cette fonction permet l'intialisation de la position de la tooltip et des fonctions permettant les fonctionnalités de déplacement de la tooltip
     */
    initializeCoordinate() {
      const eyeCartesian = CircleUtility.eyePointCartesian(
        this.tooltipData.tooltipRadius,
        this.tooltipData.tooltipAngle,
        this.centerX,
        this.centerY,
        this.referenceRadius
      );

      //Initialisation des non classés à {x: 0, y: 0}
      const posX = eyeCartesian.x || 0;
      const posY = eyeCartesian.y || 0;

      this.$refs.tooltipContainer.initialCoordinate(posX, posY);
      this.$refs.tooltipContainer.moveItem();
      this.$refs.tooltipContainer.setOptions({
        share: true,
        resize: true,
      });
    },
    /**
     * Cette fonction permet l'initialisation des actions sur la tooltip
     */
    initializeTooltips() {
      d3.select(this.$refs.tooltip)
        .on("scroll", () => {
          this.sendProperties({
            target: this.$refs.tooltip,
            params: {
              scrollTop: this.$refs.tooltip.scrollTop,
            },
          });
        })
        .on("mouseover", (event) => {
          if (!this.isDisplayEvents) {
            return
          }

          this.sendEvent({ event: event })
          this.$store.commit(
            `event/common/${mutationTypes.SET_HOVERED_EVENT}`,
            this.srcEyeEvent,
            { root: true }
          );
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event) => {
          this.sendEvent({ event: event })
          this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, {
            root: true,
          });
          this.collaborativeEventTreated()
        })
        .on("contextmenu", (e) => {
          this.tooltipExit(e)
          e.preventDefault();
          e.stopPropagation();
        });
    },
    /**
     * Cette fonction fonction permet l'initialisation des actions sur le boutons croix permettant de fermer la tooltip
     */
    hideAndShowCross() {
      d3.select(this.$refs.tooltipExit)
        .on("click", this.tooltipExit)
        .on("mouseover", (event) => {
          this.sendEvent({ event: event })
          this.crossChangeState(
            true,
            true,
            D3Animation.TOOLTIP_EXIT_BUTTON_CROSS_APPEAR
          )
          this.collaborativeEventTreated()
        })
        .on("mouseleave", (event) => {
          this.sendEvent({ event: event })
          this.crossChangeState(
            false,
            true,
            D3Animation.TOOLTIP_EXIT_BUTTON_CROSS_DISAPPEAR
          )
          this.collaborativeEventTreated()
        })
    },
    /**
     * Cette fonction permet de modifier l'état du bouton permettant de fermer la tooltip. La fonction gère à la fois l'apparition / disparition de la croix lorsque l'utilisateur positionne son pointeur sur le bouton mais également la modification de couleur du contour du bouton pour indiquer que cette tooltip est associée à l'événement que l'utilisateur est en train de survoler
     * @param {Boolean} isOver Indique si le bouton est actuellement survolé par le pointeur de l'utilisateur
     * @param {Boolean} isMouseOnTooltip Indique si le pointeur de l'utilisateur se trouve sur la tooltip
     * @param {*} animationTime Définit le temps de l'animation pour le changement d'état du bouton
     */
    crossChangeState(isOver, isMouseOnTooltip, animationTime) {
      d3.select(this.$refs.tooltipExit)
        .transition()
        .duration(animationTime)
        .tween("style['border-color']", (d, i, nodes) => {
          const resourceTypeClass = `.${utils.getEventResourceTypeColor(this.tooltipData, this.$store)}`
          const resourceTypeColor = utils.getCssPropertyOf(resourceTypeClass, 'stroke')

          let base = nodes[i].style["border-color"] || "var(--c-gray-1)"
          let next = !isMouseOnTooltip
            ? "var(--c-gray-1)"
            : resourceTypeColor
          const interpolator = utils.getRGBInterpolatorCssVar(base, next)

          return (t) => {
            nodes[i].style["border-color"] = t === 1 ? next : interpolator(t)
          };
        })
        .tween("style.color", (d, i, nodes) => {
          let base = nodes[i].style.color
          let next = isOver ? "var(--c-gray-1)" : "var(--color-bg-1)"
          const interpolator = utils.getRGBInterpolatorCssVar(base, next)

          return (t) => {
            nodes[i].style.color = t === 1 ? next : interpolator(t)
          }
        })
    },
    /**
     * Cette fonction est appelée lorsque l'utilsateur est en train de déplacer la tooltip sur la fenêtre. La fonction permet de mettre à jour les coordonnées polaires par rapport au centre d'eyediag dans le store
     */
    onMouseMoveContainer() {
      const coordinates = this.$refs.tooltipContainer.getCoordinates();
      const eyePolar = CircleUtility.eyePointRadial(
        coordinates.x,
        coordinates.y,
        this.centerX,
        this.centerY,
        this.referenceRadius
      );
      this.$store.commit(
        `event/common/${mutationTypes.UPDATE_TOOLTIP_POLAR_COORDINATE}`,
        {
          ...eyePolar,
          ...{ id: this.tooltipData.id },
        }
      );
    },
    /**
     * Cette fonction est appelée lorsque l'utilisateur utilise l'une des fonctionnalités permettant la fermeture de la tooltip. La fonction permet la fermeture de la tooltip
     * @param {Event} event Evenement fournit par le listener
     */
    tooltipExit(event) {
      if (event !== null) {
        this.sendEvent({ event: event });
      }

      this.$store.commit(`event/common/${mutationTypes.SET_HOVERED_EVENT}`, null, {
        root: true,
      })
      this.$store.commit(
        `event/common/${mutationTypes.TOOLTIPS_REMOVE_EVENTS}`,
        this.tooltipData.id
      )

      if (event !== null) {
        this.collaborativeEventTreated()
      }
    },
    async focusOnCohort(event, {focusContent, focusParams}) {
      this.sendEvent({ event: event })
      event.preventDefault()
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })

      this.$store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
        dp: true
      })
      this.$store.commit(`${mutationTypes.UPDATE_LABEL_FILTERS}`, 'Diagnostic principal')

      this.$store.commit(`${mutationTypes.ADD_PREVIOUS_MODE}`, this.eyediagMode)
      this.$store.commit(mutationTypes.SET_EYEDIAG_MODE, {
        type: eyediagMode.mode.PATIENT,
        options: {
          cohortBase: true,
          cohortFocusContent: focusContent,
          cohortFocusParams: focusParams
        }
      })
      await this.$store.dispatch('circle/getDataRepresentation', {
        unitPerCircle: this.$store.state.circle.unitPerCircle,
        periodUnit: this.$store.state.circle.periodUnit,
        reloadDataTypes: reloadDataTypes.CATEGORISATION
      })
      await this.getPatientData()
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    onEditEvent(event) {
      this.sendEvent({event: event})
      this.$store.commit(mutationTypes.IS_EDIT_MODE, true)
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_EVENT}`, this.tooltipData)
      this.tooltipExit(null)
      this.collaborativeEventTreated()
    }
  },
};
</script>

<style>
.tooltip-exit-button {
  color: var(--color-bg-1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 15px;
  width: 15px;
  background: none;
  border-radius: 10px;
  border: solid 1px var(--c-gray-1);
  user-select: none;
}

.tooltip-style {
  background-color: var(--color-bg-1);
  border: solid;
  border-color: var(--color-border);
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
}

.tooltip {
  display: flex;
  flex-direction: column;
  width: var(--tooltip-event-default-width);
  height: var(--tooltip-event-default-height);
  min-width: 230px;
  min-height: 150px;
  resize: both;
  cursor: grab;
  overflow-y: hidden;
}

.tooltip button {
  align-self: flex-end;
}
.tooltips_cross {
  position: absolute;
  top: 0px;
  left: 0px;
}

.annexes {
  color: var(--c-gray-1);
  font-size: 1.3rem;
}

.tooltip-body {
  display: flex;
  flex-direction: column;
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 0px;
  margin-left: 14px;
  flex: 1;
  /* max-height: 400px; */
  padding-right: 10px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: scroll;
}
.tooltip_title {
  font-weight: 600;
  font-size: 1.4rem;
  color: var(--color-text);
  overflow-wrap: anywhere;
}

.tooltip_date {
  font-size: 1.3rem;
  color: var(--color-text);
}

.tooltip-note {
  font-size: 1.2rem;
  margin-top: 10px;
  color: var(--c-gray-1);
  overflow-wrap: anywhere;
}

.tooltip-event-note-container {
  margin-top: 10px;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

.tooltip-event-note-container::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}

.bold {
  font-weight: bold;
}

.tooltip-settings {
  display: flex;
  margin-right: 7px;
}

</style>