<template>
  <g ref="icon" />
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Logo3Icon",
  data: () => ({
    logoswidth: "3px",
    logopacity: 1,
  }),
  mounted() {
    const logo3 = d3.select(this.$refs["icon"]);

    logo3
      .append("circle")
      .attr("r", "33.29")
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("stroke-width", this.logoswidth);

    logo3
      .append("polyline")
      .attr(
        "points",
        "40 73.29 40 51.06 31.92 37.71 7.31 33.69 31.92 37.71 18.99 16.32 31.92 37.71 31.92 12.28 31.92 37.71 40 51.06 58.43 12.28 48.23 33.74 71.14 28.22 48.23 33.74"
      )
      .attr("transform", "translate(-40,-40)")
      .attr("fill", "none")
      .attr("stroke-width", this.logoswidth);

    logo3
      .style("stroke-width", "20")
      .style("stroke", "red")
      .style("fill", "white")
      .style("stroke-opacity", 1)
      .attr("opacity", 0.6);
  },
};
</script>