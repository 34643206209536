import * as d3 from 'd3'

/**
 * Permet d'obtenir le path d'une section de la pie lorsque cette section est survolé
 * @method
 * @public
 * @param {EyePie} d Informations de la section de la pie
 * @param {Number} radius Rayon externe de la pie
 */
function arcEnd(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius)
    .innerRadius(radius / 4)
    .outerRadius((5 * radius) / 4)

  return arc(d)
}

/**
 * Permet d'obtenir le path d'une section de la pie lorsque cette section n'est pas survolé
 * @method
 * @public
 * @param {EyePie} d Informations de la section de la pie
 */
function arcStart(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius)
    .innerRadius(radius / 4)
    .outerRadius((3 * radius) / 4)

  return arc(d)
}

/**
* Permet d'obtenir le path d'une section de la pie lorsque cette section est selectionné par l'utilisateur
* @method
* @public
* @param {EyePie} d Informations de la section de la pie
* @param {Number} radius Rayon externe de la pie
*/
function arcClick(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius)
    .innerRadius(radius / 4)
    .outerRadius(radius)

  return arc(d)
}

/**
 * Permet d'obtenir le path d'une section étendue de la pie lorsque cette section est survolé
 * @method
 * @public
 * @param {EyePie} d Informations de la section de la pie
 * @param {Number} radius Rayon externe de la pie
 */
function arcEndExtension(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius)
    .innerRadius(radius / 4)
    .outerRadius((5 * radius) / 4)

  return arc(d)
}

/**
 * Permet d'obtenir le path d'une section étendue de la pie lorsque cette section n'est pas survolé
 * @method
 * @public
 * @param {EyePie} d Informations de la section de la pie
 */
function arcStartExtension(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius / 2)
    .innerRadius(radius)
    .outerRadius(radius + (radius / 3))

  return arc(d)
}

/**
* Permet d'obtenir le path d'une section étendue de la pie lorsque cette section est selectionné par l'utilisateur
* @method
* @public
* @param {EyePie} d Informations de la section de la pie
* @param {Number} radius Rayon externe de la pie
*/
function arcClickExtension(d, radius) {
  const arc = d3
    .arc()
    .padRadius(radius)
    .innerRadius(radius / 4)
    .outerRadius(radius)

  return arc(d)
}

/**
 * Permet d'obtenir la couleur que doit avoir la section de la pie
 * @method
 * @public
 * @param {EyePie} d Informations de la section de la pie
 * @returns {String} couleur devant s'appliquer sur la section de la pie
 */
function getColor(d) {
  if (d.data.selectedByPie) { //La section est selectionné par l'utilisateur
    return 'url(#aquam-gradient)'
  } else {
    return "transparent";
  }
}

async function sparklePie() {
  const selectionPieSection = d3.select('#group-pie')
    .selectAll('.pie-quarter')
    .filter(section => section.data.selectedByPie)

  try {
    d3.select("#pie-origin")
      .transition('pie-origin-sparkle')
      .duration(1000)
      .style('fill-opacity', 0.8)

    await selectionPieSection
      .transition('pie-sparkle')
      .duration(1000)
      .style('fill-opacity', 0.8)
      .end()

    d3.select("#pie-origin")
      .transition('pie-origin-unsparkle')
      .duration(1000)
      .style('fill-opacity', 0.3)

    await selectionPieSection
      .transition('pie-unsparkle')
      .duration(1000)
      .style('fill-opacity', 0.3)
      .end()

    if (selectionPieSection.data().length > 0) {
      sparklePie()
    }
  } catch {}
}

export {
  arcEnd,
  arcStart,
  arcClick,
  arcEndExtension,
  arcStartExtension,
  arcClickExtension,
  getColor,
  sparklePie
}