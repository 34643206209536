<template>
  <div>
    <div
      v-for="(item, index) in chronology"
      :key="index"
      class="mb-2"
    >
      <div
        v-if="index !== 0"
        class="link-box"
        :class="{
          'solid-link': item.previousJustBehind,
          'dash-link': !item.previousJustBehind
        }"
      />
      <div class="flex flex-row items-center">
        <div class="point-container">
          <span class="point" />
        </div>
        <div class="ml-2">
          <p class="legend-rep-circle-content-date">
            {{ item.label }}
          </p>
          <p class="mt-1">
            {{ `${item.size} événement${item.size > 1 ? 's' : ''}` }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>

import { useStore } from 'vuex'
import { computed, defineEmits, onMounted } from 'vue'

const store = useStore()

const circles = computed(() => store.getters['circle/circles'])
const chronology = computed(() => {
  const result = []

  let previousJustBehind = true
  for (let i = circles.value.length - 1; i > 0; i--) {
    const circle = circles.value[i]
    const events = circle.points.filter(p => p.peak === true)
    if (events.length > 0) {
      result.push({label: circle.period.label, size: events.length, previousJustBehind})
      previousJustBehind = true
    } else {
      previousJustBehind = false
    }
  }
  return result
})

onMounted(() => {
  emits('title', 'Parcours patient')
})

const emits = defineEmits(['title'])

</script>

<style scoped>

.link-box {
  margin-left: 8px;
  /* margin-top: 5px; */
  /* margin-bottom: 5px; */
  height: 15px;
  border-left-color: var(--color-primary-accent-opaque);
  border-left-width: 1px;
}

.dash-link {
  border-left-style: dashed;
}

.solid-link {
  border-left-style: solid;
}

.point {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  fill: none;
  border: 1px solid var(--color-primary-accent-opaque);
  display: block;
}

.point-container {
  flex-basis: 22px;
  flex-shrink: 0;
}

.legend-rep-circle-content-date {
  font-family: var(--font-secondary-2);
  font-weight: 500;
  letter-spacing: 0.03em;
}

</style>