<template>
  <div v-if="recordData !== null">
    <h2>{{ recordData.Name }} {{ recordData.Surname.toUpperCase() }}</h2>
    <div> 
      <p v-if="recordData.Id">
        <strong>Id:</strong>{{ recordData.Id }}
      </p>
      <p v-if="recordData.IdUser">
        <strong>IdUser:</strong>{{ recordData.IdUser }}
      </p>
      <p v-if="recordData.IdRoom">
        <strong>IdRoom:</strong>{{ recordData.IdRoom }}
      </p>
      <br>
      <p v-if="recordData.Start">
        <strong>Début session:</strong>{{ recordData.Start }}
      </p>
      <p v-if="recordData.End">
        <strong>Fin session:</strong>{{ recordData.End }}
      </p>
      <br>

      <p v-if="recordData.Name">
        <strong>Prénom:</strong>{{ recordData.Name }}
      </p>
      <p v-if="recordData.Surname">
        <strong>Nom:</strong>{{ recordData.Surname }}
      </p>
      <p v-if="recordData.Email">
        <strong>Email:</strong>{{ recordData.Email }}
      </p> 
      <br>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    recordData: {
      type: [Object, null],
      required: true,
    }
  }
}
</script>
