/**
 * Ce fichier contient des données nécessaire aux calculs de chacun des scores
 */

/**
 * Données pour le calcul du score ChadsVasc
 */
export const score_35 = {
  ic: { res: 1 },
  ta: { res: 1 },
  age75: { res: 1 },
  diabete: { res: 1 },
  atcvc: { res: 1 },
  atcmv: { res: 1 },
  age6475: { res: 1 },
  femme: { res: 1 }
}

/**
 * Données pour le calcul du score Facteur aggravants COV-19
 */
export const score_37 = {
  hypert: { res: 1 },
  obes: { res: 1 },
  avc: { res: 1 },
  infarct: { res: 1 },
  insufrp: { res: 1 },
  insufrn: { res: 1 },
  insufc: { res: 1 },
  cirr: { res: 1 },
  hepat: { res: 1 },
  diab: { res: 1 },
  chimo: { res: 1 },
  defimun: { res: 1 },
  tumma: { res: 1 },
  leucm: { res: 1 },
}

/**
 * Données pour le calcul du score HasBled
 */
export const score_36 = {
  hypert: { res: 1 },
  renal: { res: 1 },
  hepat: { res: 1 },
  avc: { res: 1 },
  hemo: { res: 1 },
  inr: { res: 1 },
  age65: { res: 1 },
  alcool: { res: 1 },
  medocatt: { res: 1 },
}

/**
 * Données pour le calcul de l'EuroScore2
 */
export const score_33 = {
  bpco: { res: 1, mortXi: 0.4931341 },
  artPeri: { res: 2, mortXi: 0.6558917 },
  neuro: { res: 2, mortXi: 0.841626 },
  chirCar: { res: 3, mortXi: 1.002625 },
  creat: { res: 2, mortXi: 0.6521653 },
  endo: { res: 3, mortXi: 1.101265 },
  preop: { res: 3, mortXi: 0.9058132 },
  angor: { res: 2, mortXi: 0.5677075 },
  ejec3050: { res: 1, mortXi: 0.4191643 },
  ejec30: { res: 3, mortXi: 1.094443 },
  infac: { res: 2, mortXi: 0.5460218 },
  paps: { res: 2, mortXi: 0.7676924 },
  urg: { res: 2, mortXi: 0.7127953 },
  chirCardAssoc: { res: 2, mortXi: 0.5420364 },
  chirAorte: { res: 3, mortXi: 1.159787 },
  repSept: { res: 4, mortXi: 1.462009 }
}