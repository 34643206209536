<template>
  <GenericModal
    :id="id"
    :title="title"
    :description="description"
    :buttons="buttons"
    theme="error"
    @close="onConfirm"
  />
</template>

<script>
import GenericModal from '@/components/modal/GenericModal.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    GenericModal
  },
  data: () => ({
    /**
     * Titre de la modal
     * @type {String}
     */
    title: 'Erreur',
    /**
     * Description de la modal
     * @type {String}
     */
    description: 'L\'hôte à mis fin à la session de collaboration.',
    /**
     * Tableau décrivant les boutons présent sur la modal
     * @type {EyeGenericModalButton[]}
     */
    buttons: [],
    /**
     * Identifiant de la modal générique
     * @type {String}
     */
    id: "modal-collaborative-error"
  }),
  computed: {
    ...mapGetters({
      wsSocket: "ws/wsSocket",
    })
  },
  watch: {
    wsSocket() {
      this.setupWsEvent()
    }
  },
  mounted() {
    this.setButtons()
    this.setupWsEvent()
  },
  methods: {
    /**
     * Cette fonction permet de définir les boutons qui seront présent sur la modal
     */
    setButtons() {
      this.buttons = [
        {
          label: 'Ok',
          backgroundColorTheme: 'error',
          color: 'white',
          onClick: this.onConfirm
        },
      ]
    },
    /**
     * Cette fonction est appelé lorsque l'utilisateur appuie sur le bouton de confirmation de la modal ou la croix de la modal. Elle permet de fermer la tooltip
     */
    onConfirm(event) {
      event.preventDefault();
      const elem = document.getElementById(this.id)
      elem.close()
    },
    setupWsEvent() {
      if (this.wsSocket === null) {
        return
      }

      this.wsSocket.on('collaborative:error', (data) => {
        const elem = document.getElementById(this.id)
        if (elem.open) {
          return
        }
        
        if (data.type === 'sessionNotFound') {
          this.description = `La session ${data.roomId} n'est pas disponible`
        } else {
          this.description = 'Une erreur est survenue'
        }
        elem.showModal()
      })
    }
  },
}
</script>