<template>
  <svg
    width="15"
    height="15"
  >
    <line
      x1="0"
      y1="0"
      x2="15"
      y2="15"
      stroke="var(--color-text)"
      stroke-width="2"
    />
    <line
      x1="15"
      y1="0"
      x2="0"
      y2="15"
      stroke="var(--color-text)"
      stroke-width="2"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconCross'
}
</script>