<template>
  <div style="font-size: 2rem; color: white;">
    <img
      class="image"
      src="./5.png"
    >
    <p>En haut à gauche sont indiquée les informations générales du patient.</p>
  </div>
</template>
  
<script>
export default {
  name: "Component5Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
.image {
  width: 100%;
  padding-bottom: 10px;
}
</style>