import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => {
  return {
    testName: null,
    idxScreenshot: 1
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    testName: state => state.testName,
    idxScreenshot: state => state.idxScreenshot
  },
  mutations: {
    [mutationTypes.E2E_SET_TEST_NAME](state, payload) {
      state.testName = payload
    },
    [mutationTypes.E2E_SET_IDX_SCREENSHOT](state, payload) {
      state.idxScreenshot = payload
    }
  }
}