<template>
  <div>
    <div
      id="clear-selected-events-action-container"
      ref="clear-selected-events-action-container"
      height="15"
    >
      <Transition name="arrow-options">
        <IconCross
          v-if="onDeleteMode"
          id="clear-selected-events-action"
          ref="clear-selected-events-action"
          class="clear-selected-events-action"
          @click.once="(e)=>clearSelect(e)"
        />
      </Transition>
    </div>
    <ToggleSwitch
      id="toggle"
      ref="toggleSwitch"
      v-model:status="onDeleteModeTmp"
      class="toggle-switch"
      text=""
      @on-off="handleOnOff"
    />
  </div>
</template>

<script>
import ToggleSwitch from "../../ToggleSwitch.vue";
import {mapActions, mapGetters} from 'vuex'
import * as mutationTypes from '@/store/mutations-types.js'
import IconCross from "@/assets/icons/cross.vue"

export default {
  name: 'ListePatientViewOption',
  components: {
    ToggleSwitch,
    IconCross
  },
  data: ()=>({
    onDeleteModeTmp: null
  }),
  computed: {
    ...mapGetters({
      onDeleteMode: "view/onDeleteMode",
      patientViews: 'view/patientViews',
    })
  },
  created(){
    this.onDeleteModeTmp =  this.onDeleteMode
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteViews: 'view/deleteViews',
      getAllViews: 'view/getAllViews'
    }),
    handleOnOff() {
      this.$store.commit(`view/${mutationTypes.SET_DELETE_MODE}`, this.onDeleteModeTmp)
    },
    async clearSelect(event){
      if (event.isTrusted) {
        let patientViewsSelected = Object.values(this.patientViews).filter(view => view.selected).map(view => view.Id)
        await this.deleteViews(patientViewsSelected)
      }  
      this.sendEvent({event:event})
      this.$refs.toggleSwitch.toggle(null)
      this.getAllViews()
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style>
.toggle-switch{
  height: 30px;
  width: 20px;
}

.toggle-switch input:checked+.slider {
  background-color: rgba(255, 0, 0, 0.5);
}

.toggle-switch input:checked+.slider::before {
    -webkit-transform: translateX(8px);
    -ms-transform: translateX(8px);
    transform: translateX(8px);
}

.toggle-switch .slider:before{
  height: 8px;
  width: 8px;
  background-color: white;
}

.slider {
    background-color: transparent;
}

.toggle-switch .slider{
  border-color: white;
  border: 1px solid;
  background-color: transparent;
  width: 22px;
  height: 12px;
}

.toggle-switch .slider.round:before{
  background-color: transparent;
  border: 1px solid;
  bottom: 1px;
  background-color: var(--color-bg-0)
}

#toggle{
  padding-left: 1px;
  margin-right: 9px;
}

#toggle .switch{
  margin: 0px;
  height: 14px;
}

#clear-selected-events-action-container{
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
}
</style>

<style scoped>
.arrow-options-enter-from, .arrow-options-leave-to {
  transform: translate(50px);
  opacity : 0;
}

.arrow-options-enter-to, .arrow-options-leave-from {
  transform: translate(0px);
}

.arrow-options-enter-active, .arrow-options-leave-active {
  transition: all 0.5s ease;
  }
</style>