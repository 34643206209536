<template>
  <g ref="icon" />
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Logo2Icon",
  data: () => ({
    logoswidth: "3px",
    logopacity: 1,
  }),
  mounted() {
    const logo2 = d3.select(this.$refs["icon"]);

    logo2
      .append("circle")
      .attr("r", "33.29")
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("stroke-width", "0px");
    logo2
      .append("path")
      .attr("d", "M10.72,64.25a51.94,51.94,0,0,0,58.56,0L58.61,12.37H21.39Z")
      .attr("transform", "translate(-40,-40)")
      .attr("fill", "none")
      .attr("stroke-width", this.logoswidth);

    logo2
      .style("stroke-width", "20")
      .style("stroke", "red")
      .style("fill", "white")
      .style("stroke-opacity", 1)
      .attr("opacity", 0.6);
  },
};
</script>