import * as d3 from 'd3'
import circlesConfig from '../config/circle.js'
import CircleUtility from './CircleUtility.js'
import * as mutationTypes from '@/store/mutations-types.js'
import EventSelectionTypes from '@/enums/event_selection_types.js'
import utils from '@/libraries/utils.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import Filters from '@/shared/libraries/filters.js'
import * as eventsTypes from '@/shared/enums/eventsTypes.js'

/**
 * Il s'agit d'une duplication de la courbe Cardinal d3js, mais permettant en plus d'obtenir les points de contrôle utilisé pour le tracé de la courbe de Béziers. La courbe sert à générer les tracés des cercles d'Eyediag
 * https://github.com/d3/d3-shape/blob/v3.2.0/README.md#custom-curves
 */

class EventManager {
  constructor() {
    /**
     * Référence du store vuex de l'application
     * @type {Store}
     */
    this.refStore = null
    /**
     * Définit si l'option permettant de mettre en surbrillance tout les événements du cercle principal est activée
     * @type {Boolean}
     */
    this.displayAllActiveCircleEvent = false
    /**
     * Il s'agit des filtres devant s'appliquer sur les événements
     * @type {EyeEventFilterOptions}
     */
    this.filters = {}
    /**
     * Liste des événements présent sur les cercles visible par l'utilisateur
     * @type {EyeEvent[]}
     */
    this.events = []
    /**
     * Ensemble des événements présent sur tout les cercles
     * @type {EyeEvent[]}
     */
    this.allEvents = []
    /**
     * Contient la liste des événements matchant avec le filtrage choisi par l'utilisateur
     * @type {EyeEventFiltering}
     */
    this.filteredEvents = []
    /**
     * Classement des événements par code en fonction de leur nombre d'occurence (classement du plus présent au moins présent)
     * @type {Map<String, Number>}
     */
    this.antecedentRank = null
    /**
     * Il s'agit de la liste des rayons devant être attribués aux cercles des événements en fonction de la sévérité de l'événement
     * @type {Object}
     */
    this.severityRadius = {
      0: 9,
      1: 9,
      2: 11,
      3: 13,
      4: 16,
    }
  }

  /**
   * Retourne la liste des événements présent sur les cercles visible par l'utilisateur
   * @returns {EyeEvent[]} Liste des événements présent sur les cercles visible par l'utilisateur
   */
  getEvents() {
    return this.events
  }

  /**
   * Permet d'obtenir le filtrage d'événement matchant avec un événement du score
   * @param {EyeEvent} scoreEvent Il s'agit de l'événement du score sur lequel le filtrage doit s'appliquer
   * @returns {EyeEventFiltering} Filtrage des événements matchant avec l'événement du score
   */
  getScoreMatchEvents(scoreEvent) {
    const scoreMatchEvents = []

    this.events.forEach(event => {
      if (scoreEvent.code.includes(event.code)) {
        scoreMatchEvents.push(event)
      }
    })
    return this.prepareFilteringSelection(scoreMatchEvents, EventSelectionTypes.SCOREMATCH)
  }

  /**
   * Détermine si un événement peut ne plus être mis en surbrillance
   * @param {EyeEvent} event Il s'agit de l'événement testé par la fonction
   * @returns {Boolean} Indique si l'événement peut ne plus être mis en surbrillance
   */
  isUnhighlightable(event) {
    if (this.refStore.getters['event/common/isDisplayedMemorizedEvents']) {
      const memorizedEvents = this.refStore.getters['event/common/memorizedEvents']
      const isNotMemorized = memorizedEvents.find(e => e.id === event.id) === undefined
      return isNotMemorized
    } else {
      const selectedEvents = this.refStore.getters['event/common/selectedEvents']
      const isNotSelected = selectedEvents.find(e => e.id === event.id) === undefined
      return isNotSelected
    }
  }

  /**
   * Permet de mettre à jour le classement des événements par code en fonction de leur nombre d'occurence
   * @param {Map<String, Number>} antecedentRank Classement à jour
   */
  setAntecedentRank(antecedentRank) {
    this.antecedentRank = antecedentRank
  }

  filterByDP() {
    if (this.filters.dp) {
      if (this.refStore.state.mode.type !== eyediagMode.mode.POPULATIONAL) {
        this.allEvents.forEach(event => {
          if (event.extension.typeDiagnostique && event.extension.typeDiagnostique.value === eventsTypes.pmsiTypeDiag.DP) {
            this.filteredEvents.push(event)
          }
        })
      } else {
        for (const event of this.allEvents) {
          for (const rank of event.ranks) {
            for (const e of rank.events) {
              if (e.extension.typeDiagnostique && e.extension.typeDiagnostique.value === eventsTypes.pmsiTypeDiag.DP) {
                this.filteredEvents.push(event)
              }
            }
          }
        }
      }
    }
  }

  filterByText() {
    if (this.filters.text) {
      if (this.refStore.state.mode.type === eyediagMode.mode.POPULATIONAL) {
        for (const event of this.allEvents) {
          for (const rank of event.ranks) {
            for (const e of rank.events) {
              for (let extension of Object.values(e.extension)) {
                extension += ''
                extension = extension.toLowerCase()
        
                if (extension.includes(this.filters.text.toLowerCase())) {
                  this.filteredEvents.push(event)
                }
              }
            }
          }
        }
      } else {
        Filters.filterByText(this.allEvents, this.filters, this.filteredEvents)
      }
    }
  }

  filterByDiscipline() {
    if (this.filters.pmsi && this.filters.pmsi.discipline) {
      if (this.refStore.state.mode.type === eyediagMode.mode.POPULATIONAL) {
        for (const event of this.allEvents) {
          for (const rank of event.ranks) {
            for (const e of rank.events) {
              if (this.filters.pmsi.discipline.includes(e.extension.discipline)) {
                this.filteredEvents.push(event)
              }
            }
          }
        }
      } else {
        for (const event of this.allEvents) {
          if (this.filters.pmsi.discipline.includes(event.extension.discipline)) {
            this.filteredEvents.push(event)
          }
        }
      }
    }
  }

  filterByActivite() {
    if (this.filters.pmsi && this.filters.pmsi.activite) {
      if (this.refStore.state.mode.type === eyediagMode.mode.POPULATIONAL) {
        for (const event of this.allEvents) {
          for (const rank of event.ranks) {
            for (const e of rank.events) {
              if (this.filters.pmsi.activite.includes(e.extension.activite)) {
                this.filteredEvents.push(event)
              }
            }
          }
        }
      } else {
        for (const event of this.allEvents) {
          if (this.filters.pmsi.activite.includes(event.extension.activite)) {
            this.filteredEvents.push(event)
          }
        }
      }
    }
  }

  /**
   * Permet de mettre en surbrillance les événements du cercle principal / active lorsque cette option est activée
   */
  showAllEventsMainCircle() {
    if (this.displayAllActiveCircleEvent) {
      const eventsMainCircle = this.events.filter(event => event.circleParent.active === true)
      eventsMainCircle.forEach(event => {
        this.refStore.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, { event, selectionType: EventSelectionTypes.MAIN_CIRCLE })
      })
    }
  }

  /**
   * Cette fonction permet le calcul des coordonnées des cercles des événements permettant de les mettre en surbrillance
   * @param {EyeEvent[]} events Liste des événements pour lesquels leur rayon de mise en surbrillance doit être calculé
   * @param {Number} referenceRadius Rayon de référence
   * @param {Number | null} radiusParent Si ce paramètre est spécifié, il s'agit du rayon pris en compte pour le positionnement des cercles des événements (utile pour les événements des cercles de score / antécédents familiaux). Si aucune n'est valeur n'est fournis, le rayon du cercle auquel appartient l'événement sera utilisé
   * @returns {EyeEvent[]} Retourne la liste des événements enrichit par les coordonnées cartésiens de leur cercle de mise en surbrillance
   */
  calcEventPosition(events, referenceRadius, radiusParent = null) {
    events = events.map(event => {
      let radiusParentCircle = radiusParent
      if (radiusParentCircle === null) {
        radiusParentCircle = referenceRadius * circlesConfig[event.circleParent.configIndex].coefRadius
      }

      let radiusFromCircle = null
      
      if (event.dataEntry === true) {
        radiusFromCircle = CircleUtility.getRadiusAtPoint(radiusParentCircle, 0.25)
      } else {
        radiusFromCircle = CircleUtility.getRadiusAtPoint(radiusParentCircle, event.severity)
      }

      const point = d3.pointRadial(event.angle, radiusFromCircle)

      event.radiusEvent = radiusFromCircle
      event.cx = point[0]
      event.cy = point[1]
      return event
    })
    return events
  }

  /**
   * Cette fonction permet d'extraire les événements composant les cercles fournit en paramètres
   * @param {EyeCircle[]} circles Cercles contenant les événements que l'on souhaite extraire dans une liste à part
   * @returns {EyeEvent[]} Liste des événements
   */
  extractEventsFromCircle(circles) {
    let events = circles.map(circle => {
      const events = circle.points.filter(point => point.peak === true)
      return events.map(point => ({
        ...point,
        ...{
          //Math.round, car pour un pic de score comprenant plusieurs événs, une moyenne des sévérités des events est faite
          r: (point.severity > 0 && point.severity < 1)
            ? this.severityRadius[1]
            : this.severityRadius[Math.round(point.severity)],
          circleParent: circle,
          selectionTypes: [],
          displayLabel: false
        }
      }))
    })

    if (events.length > 0) {
      events = events.reduce((prev, current) => prev.concat(current))
    }

    return events
  }

  /**
   * Cette fonction permet de remettre en surbrillance les événements qui l'étaient déjà avant un rafraichissement des données depuis le serveur
   * @param {EyeEvent[]} previousSelected Liste des événements précédement séléctionné
   */
  selectPreviouslySelected(previousSelected) {
    this.events = this.events.map(event => {
      const findEvent = previousSelected.find(previous => utils.uniqEvent(event, previous))

      if (!findEvent) { //if findEvent not null 
        return event
      } else if (findEvent.selectionTypes.length === 1 && findEvent.selectionTypes.includes(EventSelectionTypes.MAIN_CIRCLE)) {
        this.refStore.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
          event: findEvent,
          selectionType: EventSelectionTypes.MAIN_CIRCLE
        })
        return event
      } else {
        event.selected = true
        event.selectionTypes = findEvent.selectionTypes
        event.displayLabel = findEvent.displayLabel
        return event
      }
    })
  }

  /**
   * La fonction permet d'obtenir l'objet décrivant le filtrage qui doit être présent sur la représentation. C'est cet objet qui permet la construction des lignes et le placement des symbols sur la représentation
   * @param {EyeEvent[]} filteredEvents Liste des événements matchant avec le filtrage
   * @param {EyeEnumSelectionType} selectionType Type de selection à l'origine du filtrage (Filtrage ? Match de score ? etc)
   * @returns {EyeEventFiltering} Il s'agit de l'objet décrivant le filtrage devant être affiché sur la représentation 
   */
  prepareFilteringSelection(filteredEvents, selectionType) {
    //TO-DO trouver un nom mieux ?
    const backup = _.groupBy(filteredEvents, (d) => d.code)
    filteredEvents = _.uniqBy(filteredEvents, 'id');
    const firstDisplayedCircle = _.head(this.refStore.getters['circle/displayedCircles'])
    const lastDisplayedCircle = _.last(this.refStore.getters['circle/displayedCircles'])
    filteredEvents = _.groupBy(filteredEvents, (d) => d.code)

    for (let [code, events] of Object.entries(filteredEvents)) {
      let lastEvent = _.last(events)
      const firstEvent = _.head(events)
      let resourceType = 'Condition'

      /* Si les événement répondant à un code sont relié à une extrémité de la representation
          aucun trait ne traverse la portion de la figure actuellement représentée
      */
      if (firstEvent.circleParent.id > lastDisplayedCircle.id
        || lastEvent.circleParent.id < firstDisplayedCircle.id) {
        delete filteredEvents[code]
        continue
      }
      resourceType = events[0].resourceType
      // event présent sur la représentation
      events = events.filter(event => event.circleParent.id >= firstDisplayedCircle.id && event.circleParent.id <= lastDisplayedCircle.id)

      // Récupérer l'instance de l'event depuis events
      events = events.map(event => this.events.filter(e => e.id === event.id && e.parentSection === event.parentSection))

      // concaténer les sous array
      events = _.reduce(events, (sum, d) => [...sum, ...d], [])

      //Regroupement par section
      events = _.groupBy(events, (d) => d.parentSection)
      let allEvents = _.groupBy(backup[code], d => d.parentSection)

      for (let [idSection, eventsSection] of Object.entries(events)) {
        lastEvent = _.last(eventsSection)
        if (eventsSection.length > 0 && lastEvent.id === _.last(allEvents[idSection]).id) {
          this.refStore.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, { event: lastEvent, selectionType })
        }
        //angle chelou si pas d'event ?
        events[idSection] = {
          internalProgression: eventsSection.length === 0 || eventsSection[0].id !== allEvents[idSection][0].id,
          externalProgression: eventsSection.length === 0 || lastEvent.id !== _.last(allEvents[idSection]).id,
          events: eventsSection,
          angle: eventsSection[0].angle,
          resourceType
        }
      }
      filteredEvents[code].sections = events
    }
    return filteredEvents
  }

  /**
   * Cette fonction permet de mettre à jour la liste des événements après un rafraichissement des données en appellant le serveur
   * @param {Store} refStore Référence du store vuex
   * @returns {Object} events: EyeEvent[] Liste des événements présent sur les cercles visible par l'utilisateur
   *  filteredEvents: EyeEventFiltering Contient la liste des événements matchant avec le filtrage choisi par l'utilisateur
   * 
   * }}
   */
  updateEvents(refStore) {
    const circles = refStore.getters['circle/circles']
    const filters = refStore.state.event.common.eventFilters
    const displayAllActiveCircleEvent = refStore.state.event.common.displayAllActiveCircleEvent
    const memorizedEvents = refStore.state.event.common.memorizedEvents
    const previousSelected = refStore.state.event.common.selectedEvents
    const referenceRadius = refStore.state.layout.radius

    this.refStore = refStore
    this.displayAllActiveCircleEvent = displayAllActiveCircleEvent
    this.filters = filters
    this.filteredEvents = []

    const displayedCircles = circles.filter(circle => CircleUtility.isVisibleCircle(circle.configIndex))
    this.events = this.extractEventsFromCircle(displayedCircles)
    this.allEvents = this.extractEventsFromCircle(circles)


    Filters.filterBySeverity(this.allEvents, this.filters, this.filteredEvents)
    Filters.filterByAntecedent(this.allEvents, this.filters, this.filteredEvents, this.antecedentRank)
    Filters.filterByLast(this.events, this.filters, this.filteredEvents)
    Filters.filterByCodes(this.allEvents, this.filters, this.filteredEvents)
    Filters.filterBySection(this.allEvents, this.filters, this.filteredEvents)
    this.filterByText()
    // this.filterByDiscipline()
    // this.filterByActivite()
    this.filterByDP()

    this.filteredEvents = this.prepareFilteringSelection(this.filteredEvents, EventSelectionTypes.FILTERING)

    if (this.refStore.getters['event/common/isDisplayedMemorizedEvents']) {
      this.selectPreviouslySelected(memorizedEvents)
    }
    
    this.selectPreviouslySelected(previousSelected)
    this.showAllEventsMainCircle()
    this.events = this.calcEventPosition(this.events, referenceRadius, null)
    
    //Mise à jour de l'instance des objets EyeEvent[] stockés dans divers tableau
    for (let i = 0; i < memorizedEvents.length; i++) {
      memorizedEvents[i] = this.events.find(e => e.id === memorizedEvents[i].id) ?? memorizedEvents[i]
    }
    for (let i = 0; i < this.refStore.state.event.common.tooltips.length; i++) {
      this.refStore.state.event.common.tooltips[i] = this.events.find(e => e.id === this.refStore.state.event.common.tooltips[i].id) ?? this.refStore.state.event.common.tooltips[i]
    }
    //FIN Mise à jour

    return {
      events: this.events,
      filteredEvents: this.filteredEvents
    }
  }
}

export default new EventManager()