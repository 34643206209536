<template>
  <div>
    <div class="field-block">
      <label>
        <span class="block">Nom</span>
        <input
          v-model="lastName"
          type="text"
          class="text-input"
          @input="(e) => onInput(e, lastName, false)"
        >
        <p
          v-for="(error, index) in errors.lastName"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
    <div class="field-block">
      <label>
        <span class="block">Prénom</span>
        <input
          v-model="firstName"
          type="text"
          class="text-input"
          @input="(e) => onInput(e, firstName, false)"
        >
        <p
          v-for="(error, index) in errors.firstName"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
    <div class="field-block">
      <label>
        <span class="block">Email</span>
        <input
          v-model="email"
          type="email"
          class="text-input"
          @input="(e) => onInput(e, email, false)"
        >
        <p
          v-for="(error, index) in errors.email"
          :key="index"
          class="error-modal"
        >
          {{ error }}
        </p>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountCreationCommonForm'
}
</script>

<script setup>
import { ref } from 'vue'
import { useStore } from 'vuex'
import CollaborativeUtils from '@/libraries/collaborative/utils.js'

const store = useStore()

const lastName = ref("")
const firstName = ref("")
const email = ref("")
const errors = ref({})

function checkForm() {
  const requiredField = 'Ce champ est obligatoire'
  errors.value = {
    lastName: [],
    firstName: [],
    email: []
  }

  if (lastName.value === "") {
    errors.value.lastName.push(requiredField)
  }
  if (firstName.value === "") {
    errors.value.firstName.push(requiredField)
  }
  if (email.value === "") {
    errors.value.email.push(requiredField)
  }

  return errors.value
}

function getDataForm() {
  return {
    lastName: lastName.value,
    firstName: firstName.value,
    email: email.value
  }
}

const onInput = (...params) => CollaborativeUtils.onInput(...[store, ...params])

defineExpose({
  checkForm: checkForm,
  getDataForm: getDataForm
})

</script>

<style scoped>

</style>