<template>
  <div style="font-size: 2rem; color: white;">
    <p>Lors de votre utilisation d'EyeDiag, vous allez rencontrer différents objets et symboles.</p>
    <p>Voici une légende des éléments principaux : </p>
    <p>
      <img
        class="image"
        src="./20-1.png"
      >
      <span>Un cercle rouge représente un diagnostic.</span>
    </p>
    <p>
      <img
        class="image"
        src="./20-2.png"
      >
      <span>Les plus petits cercles correspondent à des évènements au code similaire.</span>
    </p>
    <p>
      <img
        class="image"
        src="./20-3.png"
      >
      <span>Un cercle avec des parenthèses signifie qu'il s'agit du même événement mais qu'il est également classé dans cette autre catégorie où il apparaît</span>
    </p>
    <p>
      <img
        class="image"
        src="./20-4.png"
      >
      <span>Un cercle bleu représente un acte médical</span>
    </p>
    <p>
      <img
        class="image"
        src="./20-5.png"
      >
      <span>Une croix rouge signifie qu'il existe un évenement au code similaire, situé sur un cercle non visible actuellement</span>
    </p>
  </div>
</template>
    
<script>
export default {
  name: "Component20Tuto",
}
</script>
    
<style scoped>
p {
  padding-bottom: 10px;
  display: flex;
  align-items: center;
}
video {
  padding-bottom: 10px;
}
img {
    width: 30%;
    height: 70%;
    padding-right: 1vw;
}
span {
    font-size: 15px;
}
</style>