<template>
  <ListBase
    ref="list-base"
    title="Top 10"
  >
    <template #content>
      <ul class="list-borderList">
        <li
          v-for="(event, indexItem) in listTopEvents"
          :key="indexItem"
          class="events-podium"
        >
          {{ event.size }} - {{ event.code }} - {{ event.label }}
        </li>
      </ul>
    </template>
  </ListBase>
</template>

<script>
import ListBase from '@/components/borderLists/ListBase.vue'
import utils from '@/libraries/utils'
import { mapGetters, mapActions  } from 'vuex'
import EventManager from '@/libraries/EventManager.js'
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import * as eyediagMode from '@/shared/enums/eyediagMode.js'
import * as mutationTypes from '@/store/mutations-types.js'
import TopListSort from '@/enums/borderList/topListSort.js'
import _ from 'lodash'

export default {
  name: 'ListTop10',
  components: {
    ListBase
  },
  computed: {
    ...mapGetters({
      allEvents: 'event/common/allEvents',
      eventsPodium: 'eventsPodium',
      currentDisplayedBorderList: 'borderList/currentDisplayedBorderList',
      eyediagMode: 'eyediagMode',
      sortTop: 'borderList/topElem/sortTop'
    }),
    listTopEvents() {
      if (this.sortTop === TopListSort.SIZE) {
        return this.eventsPodium
      } else if (this.sortTop === TopListSort.SEVERITY) {
        return this.calcPodiumBySeverity()
      }
      return []
    }
  },
  watch: {
    listTopEvents() {
      if (this.currentDisplayedBorderList === borderListTypes.LIST_TOP_10) {
        this.setFiltering()
      }
    },
    currentDisplayedBorderList() {
      if (this.currentDisplayedBorderList === borderListTypes.LIST_TOP_10) {
        this.setFiltering()
      }
    }
  },
  methods: {
    ...mapActions({
      generateEvents: 'event/common/generateEvents'
    }),
    /**
     * Cette fonction est appelé pour initier le déplacement de la liste / panel (ouverture et fermeture de celui-ci)
     * @param  {...any} params Liste des paramètres fournit à la fonction
     */
    movePanel(...params) {
      this.$refs['list-base'].movePanel(...params)
    },
    setFiltering() {
      const codes = this.listTopEvents.map(e => e.code)
      let events = []

      if (this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && this.eyediagMode.options.mergedBy === eyediagMode.populationalOptions.mergedBy.SECTION) {
        events = utils.searchEventsFromRanks(EventManager.allEvents, (e) => codes.includes(e.code))
      } else {
        events = EventManager.allEvents.filter(e => codes.includes(e.code))
      }

      this.$store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, {
        codes: events
      }, { root: true })
      this.generateEvents()
    },
    calcPodiumBySeverity() {
      const resultCodes = []
      const srcEvents = [...this.allEvents]
      srcEvents.sort((a, b) => b.severity - a.severity)

      for (const event of srcEvents) {
        if (resultCodes.length >= 10) {
          break
        }
        if (resultCodes.includes(event.code) === false) {
          resultCodes.push(event.code)
        }
      }

      const groupedEvents = _.groupBy(srcEvents, d => d.code)
      return resultCodes.reduce(
        (acc, code) => { 
          let size = groupedEvents[code].length
          //Contrôle que l'on est en populationnel et que l'on à bien des événements de type populationnel. Ca peut ne pas être le cas, si les données ne sont pas encore à jour
          if (this.eyediagMode.type === eyediagMode.mode.POPULATIONAL && groupedEvents[code][0].ranks !== undefined) {
            size = groupedEvents[code].reduce((result, event) => result + event.ranks[0].size, 0)
          }

          acc.push({
            size: size,
            code: code,
            label: groupedEvents[code][0].defaultLabel
          })
          return acc
        },
        [],
      )
    }
  }
}
</script>

<style scoped>
.events-podium {
  color: var(--color-text);
  opacity: 1;
  padding-bottom: 10px;
  font-size: 1.2rem;
}
</style>