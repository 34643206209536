import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => {
  return {
    notifications: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    notifications: state => state.notifications
  },
  mutations: {
    [mutationTypes.ADD_NOTIFICATION] (state, notification) {
      state.notifications.push(notification)
      setTimeout(() => this.commit(`notification/${mutationTypes.REMOVE_NOTIFICATION}`, notification.id), notification.timeout)
    },
    [mutationTypes.REMOVE_NOTIFICATION] (state, notificationId) {
      state.notifications = state.notifications.filter(notification => notification.id !== notificationId)
    }
  }
}