<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 284.42 39.83"
  >
    <g>
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M166.58,10.67c-4.63,0-8.13,3.74-8.13,8.69v.07c0,5.07,3.56,8.76,8.46,8.76a8.25,8.25,0,0,0,6.2-2.81.12.12,0,0,0,0-.14c-.09-.24-.15-.38-.22-.55l-.07-.18a.15.15,0,0,0-.11-.08.13.13,0,0,0-.12,0A7.61,7.61,0,0,1,167,27.26c-4.16,0-7.27-3.15-7.46-7.5h14.75a.13.13,0,0,0,.13-.13v-.14a10,10,0,0,0-1.88-6A7.14,7.14,0,0,0,166.58,10.67Zm-7.08,8.2c.22-4.29,3.1-7.27,7.05-7.27,4.23,0,6.6,3.63,6.78,7.27Z"
      />
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M202.47,10.67c-4.63,0-8.12,3.74-8.12,8.69v.07c0,5.07,3.55,8.76,8.45,8.76A8.28,8.28,0,0,0,209,25.38a.14.14,0,0,0,0-.14c-.09-.25-.15-.39-.22-.56l-.07-.17a.16.16,0,0,0-.1-.08.14.14,0,0,0-.13,0,7.59,7.59,0,0,1-5.67,2.79c-4.15,0-7.27-3.15-7.45-7.5h14.74a.13.13,0,0,0,.13-.13v-.14a10,10,0,0,0-1.88-6A7.14,7.14,0,0,0,202.47,10.67Zm-7.08,8.2c.22-4.29,3.1-7.27,7.05-7.27,4.23,0,6.6,3.63,6.78,7.27Z"
      />
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M230.66,3.49H230a.14.14,0,0,0-.14.14v11.9a8,8,0,0,0-7.55-4.86,8.4,8.4,0,0,0-8.46,8.73v.06a8.4,8.4,0,0,0,8.46,8.73,8,8,0,0,0,7.55-4.87v4.34a.13.13,0,0,0,.14.13h.7a.13.13,0,0,0,.13-.13v-24A.14.14,0,0,0,230.66,3.49Zm-.81,15.91v.06a7.59,7.59,0,0,1-7.55,7.8,7.5,7.5,0,0,1-7.49-7.83v-.07a7.38,7.38,0,0,1,7.49-7.76A7.59,7.59,0,0,1,229.85,19.4Z"
      />
      <rect
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        x="236.28"
        y="4.48"
        width="1.3"
        height="2.15"
        rx="0.13"
      />
      <rect
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        x="236.45"
        y="11.06"
        width="0.97"
        height="16.73"
        rx="0.13"
      />
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M249.24,10.8a13,13,0,0,0-5.89,1.46.14.14,0,0,0-.06.18l.26.59a.18.18,0,0,0,.08.07.12.12,0,0,0,.1,0,12.27,12.27,0,0,1,5.47-1.37c4,0,6.16,2,6.16,5.72v1.2a19.52,19.52,0,0,0-6.12-1c-4.6,0-7.56,2.11-7.56,5.37v.07c0,3.48,3.29,5.07,6.36,5.07,3.73,0,6.3-1.87,7.32-3.79v3.26a.13.13,0,0,0,.14.13h.69a.13.13,0,0,0,.14-.13V17.42a6.54,6.54,0,0,0-1.67-4.77A7.42,7.42,0,0,0,249.24,10.8Zm6.12,8.82v2c0,3.48-3.78,5.63-7.29,5.63-4,0-5.39-2.25-5.39-4.18V23c0-3.25,3.4-4.41,6.59-4.41A17.18,17.18,0,0,1,255.36,19.62Z"
      />
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M276.9,11.06h-.7a.14.14,0,0,0-.13.14V15a8.3,8.3,0,0,0-7.36-4.32,8.17,8.17,0,0,0-8.36,7.94v.06a8.15,8.15,0,0,0,8.36,7.9,8.28,8.28,0,0,0,7.36-4.32V25c0,4.58-2.64,7.21-7.26,7.21a11.29,11.29,0,0,1-7-2.41.14.14,0,0,0-.19,0l-.43.56a.15.15,0,0,0,0,.1.18.18,0,0,0,.06.09,12.54,12.54,0,0,0,7.63,2.56A8.38,8.38,0,0,0,274.87,31,8,8,0,0,0,277,25.16v-14A.14.14,0,0,0,276.9,11.06Zm-.76,7.58v.06a7.39,7.39,0,0,1-14.75-.06v-.07a7.18,7.18,0,0,1,7.36-7A7.32,7.32,0,0,1,276.14,18.64Z"
      />
      <path
        class="f387e980-31b2-4f1f-83fe-f2c1b168a2af"
        d="M192.57,11.06h-.74a.15.15,0,0,0-.12.08l-7,15.48-7.46-15.48a.17.17,0,0,0-.13-.08h-.76a.15.15,0,0,0-.12.07.15.15,0,0,0,0,.13l8,16.4-3,6.52a.11.11,0,0,0,0,.11l.29.72a.16.16,0,0,0,.13.09.15.15,0,0,0,.12-.08l10.92-23.76a.15.15,0,0,0,0-.13A.15.15,0,0,0,192.57,11.06Z"
      />
    </g>
    <g>
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M48.42,10.33h0A8.21,8.21,0,0,0,42.26,13a9.79,9.79,0,0,0-2.45,6.65,9.55,9.55,0,0,0,2.55,6.65A8.67,8.67,0,0,0,48.73,29h0a9.07,9.07,0,0,0,6.31-2.34.44.44,0,0,0,.12-.5l-.54-1.34a.46.46,0,0,0-.32-.27.43.43,0,0,0-.4.11,7.17,7.17,0,0,1-5.13,2,6.26,6.26,0,0,1-6.27-5.76.18.18,0,0,1,0-.13.18.18,0,0,1,.13-.06H56.27a.45.45,0,0,0,.45-.43c0-.18,0-.33,0-.46v0a10.67,10.67,0,0,0-2-6.47A7.67,7.67,0,0,0,48.42,10.33ZM54,18.54a.14.14,0,0,1-.12.06H42.71a.18.18,0,0,1-.13-.06.23.23,0,0,1,0-.14c.48-3.48,2.8-5.82,5.78-5.83a5.08,5.08,0,0,1,4.15,1.89A7.64,7.64,0,0,1,54,18.41.15.15,0,0,1,54,18.54Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M86.17,10.33h0A8.21,8.21,0,0,0,80,13a9.79,9.79,0,0,0-2.45,6.65,9.59,9.59,0,0,0,2.55,6.66A8.7,8.7,0,0,0,86.48,29h0a9.07,9.07,0,0,0,6.4-2.42A.43.43,0,0,0,93,26.1l-.54-1.36a.47.47,0,0,0-.32-.26.45.45,0,0,0-.4.11,7.19,7.19,0,0,1-5.22,2.1,6.26,6.26,0,0,1-6.27-5.76.15.15,0,0,1,0-.13.14.14,0,0,1,.12-.06H94a.45.45,0,0,0,.45-.43c0-.2,0-.36,0-.5a10.7,10.7,0,0,0-2-6.47A7.7,7.7,0,0,0,86.17,10.33Zm5.54,8.21a.14.14,0,0,1-.12.06H80.46a.18.18,0,0,1-.13-.06.23.23,0,0,1,0-.14c.48-3.48,2.8-5.82,5.78-5.83a5.08,5.08,0,0,1,4.15,1.89,7.64,7.64,0,0,1,1.54,3.95A.15.15,0,0,1,91.71,18.54Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M123.64,10.33h0A8.19,8.19,0,0,0,117.48,13,9.77,9.77,0,0,0,115,19.67a9.55,9.55,0,0,0,2.55,6.65A8.67,8.67,0,0,0,124,29h0a9,9,0,0,0,6.33-2.36.44.44,0,0,0,.12-.5l-.55-1.34a.47.47,0,0,0-.31-.27.44.44,0,0,0-.4.11,7.15,7.15,0,0,1-5.15,2,6.25,6.25,0,0,1-6.27-5.76.18.18,0,0,1,0-.13.16.16,0,0,1,.13-.06h13.56a.45.45,0,0,0,.45-.43c0-.2,0-.36,0-.5a10.71,10.71,0,0,0-2-6.47A7.67,7.67,0,0,0,123.64,10.33Zm5.54,8.21a.16.16,0,0,1-.13.06H117.93a.16.16,0,0,1-.13-.06.22.22,0,0,1,0-.13c.48-3.49,2.8-5.83,5.78-5.84a5.09,5.09,0,0,1,4.15,1.89,7.67,7.67,0,0,1,1.53,3.95A.15.15,0,0,1,129.18,18.54Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M37.66,10.71H35.77a.46.46,0,0,0-.42.28L29.57,25.17a.19.19,0,0,1-.16.11.16.16,0,0,1-.15-.11L23.51,11a.46.46,0,0,0-.42-.28H20.68a.44.44,0,0,0-.41.28L14.49,25.17a.17.17,0,0,1-.32,0L8.42,11A.44.44,0,0,0,8,10.71h-2a.45.45,0,0,0-.37.2.42.42,0,0,0,0,.42L13,28.46a.43.43,0,0,0,.41.27h1.77a.43.43,0,0,0,.4-.27l6.11-14.14a.17.17,0,0,1,.32,0l6.08,14.14a.43.43,0,0,0,.41.27h1.76a.43.43,0,0,0,.41-.27l7.4-17.13a.45.45,0,0,0-.41-.62Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M75.41,10.71H73.52a.44.44,0,0,0-.41.28L67.32,25.17a.18.18,0,0,1-.16.11.16.16,0,0,1-.15-.11L61.26,11a.46.46,0,0,0-.42-.28h-2a.45.45,0,0,0-.41.62l7.37,17.13a.43.43,0,0,0,.41.27H68a.43.43,0,0,0,.41-.27l7.4-17.13a.45.45,0,0,0-.41-.62Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M145.82,20.65a13.71,13.71,0,0,0-3.7-2.17h0A16.61,16.61,0,0,1,139,16.81a3.12,3.12,0,0,1-.85-.92,1.69,1.69,0,0,1-.19-1.06A2.33,2.33,0,0,1,139.07,13a4.12,4.12,0,0,1,2.63-.44,9.41,9.41,0,0,1,4,1.61.45.45,0,0,0,.51,0,.44.44,0,0,0,.18-.48l-.75-2a.45.45,0,0,0-.24-.31,10.16,10.16,0,0,0-3.37-1,6.81,6.81,0,0,0-4.52.85,4.72,4.72,0,0,0-2.2,3.49,4,4,0,0,0,1.36,3.67,14.38,14.38,0,0,0,3.9,2.19,14,14,0,0,1,2.95,1.52,3.09,3.09,0,0,1,.89,1,1.86,1.86,0,0,1,.22,1.2,2.61,2.61,0,0,1-1.25,2,4.33,4.33,0,0,1-2.81.48,9.89,9.89,0,0,1-4.84-2.13.48.48,0,0,0-.28-.1.46.46,0,0,0-.43.57l.83,2.25a.47.47,0,0,0,.22.29,10.63,10.63,0,0,0,4.08,1.35,7.54,7.54,0,0,0,4.47-.72,4.89,4.89,0,0,0,2.56-3.83,4.17,4.17,0,0,0-1.38-3.74Z"
      />
      <path
        class="b5864672-a1fc-49c6-8ea9-e2bc12b264f3"
        d="M113.16,10.71h-1.89a.44.44,0,0,0-.41.28l-5.79,14.18a.17.17,0,0,1-.15.11.18.18,0,0,1-.16-.11L99,11a.45.45,0,0,0-.42-.28h-2a.48.48,0,0,0-.38.2.45.45,0,0,0,0,.42l7.4,17.23a.15.15,0,0,1,0,.13l-2.2,5.42v.4l.65,1.52a.45.45,0,0,0,.41.27.43.43,0,0,0,.41-.27l10.67-24.7a.45.45,0,0,0-.41-.62Z"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "EyediagLogo"
}
</script>

<style scoped>
.f387e980-31b2-4f1f-83fe-f2c1b168a2af {
  fill: var(--c-logo-weveyes);
}

.b5864672-a1fc-49c6-8ea9-e2bc12b264f3 {
  fill: var(--c-logo-eyediag);
}
</style>