<template>
  <Collapse
    :content-margin-right="5"
    :content-padding-bottom="10"
  >
    <template #title>
      <div class="list-collapsable-item-title">
        <slot name="title" />
      </div>
    </template>
    <template #content>
      <slot name="content" />
    </template>
  </Collapse>
</template>

<script>
import Collapse from '@/components/Collapse.vue'

export default {
  name: 'CollapsableItem',
  components: {
    Collapse
  }
}
</script>

<style scoped>
.list-collapsable-item-title {
  font-size: 1.15rem
}
</style>