<template>
  <div>
    <Transition name="border-list-options">
      <component
        :is="componentsOptionsToDisplay"
        v-if="isDisplayedList && componentsOptionsToDisplay !== null"
      />
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'BorderListsOptions'
}
</script>

<script setup>
import { computed, ref, watch, markRaw } from 'vue'
import { useStore } from 'vuex'
import ListTopElemOptions from '@/components/borderLists/ListTop10/Options.vue'
import ListTimeFilterOptions from '@/components/borderLists/ListTimeFilter/Options.vue'
import ListSelectedEventOptions from '@/components/borderLists/ListSelectedEvent/Options.vue'
import ListNurseEntryOptions from '@/components/borderLists/ListNurseEntry/Options.vue'
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import ListPatientViewsOptions from '@/components/borderLists/ListPatientViews/Options.vue'

const store = useStore()

const optionsTable = [
  {
    idList: borderListTypes.LIST_TIME_FILTER,
    componentOptions: markRaw(ListTimeFilterOptions)
  },
  {
    idList: borderListTypes.LIST_MEMORIZED_EVENTS,
    componentOptions: markRaw(ListSelectedEventOptions)
  },
  {
    idList: borderListTypes.LIST_NURSE_ENTRY,
    componentOptions: markRaw(ListNurseEntryOptions)
  },
  {
    idList: borderListTypes.LIST_TOP_10,
    componentOptions: markRaw(ListTopElemOptions)
  },
  {
    idList: borderListTypes.LIST_PATIENT_VIEWS,
    componentOptions: markRaw(ListPatientViewsOptions)
  }
]

const isDisplayedList = computed(() => store.getters['borderList/isDisplayedList'])
const currentDisplayedBorderList = computed(() => store.getters['borderList/currentDisplayedBorderList'])
const componentsOptionsToDisplay = ref(null)

findBorderListOptions()

watch(currentDisplayedBorderList, () => {
  findBorderListOptions()
})

function findBorderListOptions() {
  for (const optionTable of optionsTable) {
    if (currentDisplayedBorderList.value === optionTable.idList) {
      componentsOptionsToDisplay.value = optionTable.componentOptions
      return
    }
  }
  componentsOptionsToDisplay.value = null
}
</script>

<style scoped>

.border-list-options-enter-from, .border-list-options-leave-to {
  transform: translate(50px);
  opacity : 0;
}

.border-list-options-enter-to, .border-list-options-leave-from {
  transform: translate(0px);
}

.border-list-options-enter-active, .border-list-options-leave-active {
  transition: all 0.5s ease;
}
</style>