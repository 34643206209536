<template>
  <MovingItem
    v-if="isEditMode"
    ref="movingContainer"
    container-class="add-event-modal-generic-container"
    :ref-slot="refDialog"
    :z-index="1"
  >
    <div
      ref="modal"
      class="modal_custom"
      @mousedown="onMouseDownModal"
    >
      <div class="flex flex-col h-full">
        <div class="flex flex-col grow min-h-0">
          <div
            class="flex flex-row justify-between"
          >
            <h3>{{ sectionName }}</h3>
            <CrossButton
              @close="exitModal"
            />
          </div>
          <MainInformations
            ref="eventMainInformation"
            class="grow min-h-0 overflow-scroll"
            @event-section="onEventSection"
          />
          <Options
            ref="eventOptions"
            class="mt-4"
            :is-editing-event-from-recurrence="isEditingEventFromRecurrence"
          />
        </div>
        <div class="grid grid-cols-2 mt-5">
          <DesignButton
            v-if="isEditing"
            :class="{
              'mr-2': isEditing
            }"
            text="Supprimer"
            @click="onDeleteEvent"
          />
          <DesignButton
            class="col-start-2"
            :class="{
              'ml-2': isEditing
            }"
            text="Enregistrer"
            @click="onSaveEvent"
          />
        </div>
      </div>
    </div>
  </MovingItem>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import * as mutationTypes from "../../../store/mutations-types.js";
import * as d3 from 'd3'

import MainInformations from "./MainInformations.vue"
import Options from "./Options.vue"
import borderListTypes from '@/enums/borderList/borderListTypes.js'
import CrossButton from "@/components/Button.vue"
import eventEditingTypes from "@/shared/enums/eventEditingTypes.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'
import utils from "@/libraries/utils.js"
import EyeFetch from "@/libraries/EyeFetch.js"
import DesignButton from '@/components/DesignButton.vue'
import MovingItem from "@/components/MovingItem.vue"

let idSelectedSection = null

export default {
  name: "ModalDialog",
  components: {
    MainInformations,
    Options,
    CrossButton,
    DesignButton,
    MovingItem
  },
  data: () => {
    return {
      sectionName: "",
      idCounter: 0,
      isEditing: false,
      refDialog: null
    };
  },
  computed: {
    ...mapGetters({
      pieSelectedSections: "pie/pieSelectedSections",
      isEditMode: 'isEditMode',
      editEvent: 'event/edit/editingEventToAdd',
      editingType: 'event/edit/editingType',
      unitPerCircle: 'circle/unitPerCircle',
      periodUnit: 'circle/periodUnit',
      isInPatient: 'isInPatient',
      isInNurseEntry: 'isInNurseEntry',
      folderId: 'patient/folderId'
    }),
    isEditingEventFromRecurrence() {
      return this.editingType === eventEditingTypes.ALREADY_EXIST && this.editEvent.extension.recurrenceId !== undefined
    }
  },
  watch: {
    isEditMode: {
      handler() {
        this.isEditing = false
        if (this.isEditMode === true) {
          this.$refs.eventMainInformation.resetForm()
          this.$refs.eventOptions.resetForm()
          this.idCounter = 0
          this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, borderListTypes.LIST_EVENTS_TO_ADD)
          this.initMovingObject()
        } else {
          this.exitModal(null)
        }
      },
      flush: 'post'
    },
    editEvent: {
      handler() {
        if (this.editEvent !== null) {
          this.$refs.eventMainInformation.editThisEvent(this.editEvent)
          this.$refs.eventOptions.editThisEvent(this.editEvent)
          this.isEditing = true
        }
      },
      flush: 'post'
    }
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated',
      deleteEvent: 'event/edit/deleteEvent',
      deleteUserCache: 'deleteUserCache',
      getDataRepresentation: 'circle/getDataRepresentation'
    }),
    onMouseDownModal(event) {
      if (['div', 'form', 'label'].includes(event.target.localName) === false) {
        event.stopPropagation()
      }
    },
    initMovingObject() {
      this.refDialog = this.$refs.modal
      const rect = this.$refs.modal.getBoundingClientRect()

      const x = (window.innerWidth / 2) - (rect.width / 2) - 150 //Le 150 c'est une valeur à l'oeil pour le rendu, rien de scientifique là dedans
      const y = (window.innerHeight / 2) - (rect.height / 2)

      this.$refs.movingContainer.initialCoordinate(x, y)
      this.$refs.movingContainer.moveItem()
      this.$refs.movingContainer.setOptions({
        share: true,
        resize: true,
      })
    },
    async onSaveEvent(event) {
      const mainData = this.$refs.eventMainInformation.getMainInformation()
      const options = this.$refs.eventOptions.getOptions()
      let newEvents = []

      if (mainData === null || options === null) {
        return
      }

      const eventCommonParams = {
        code: mainData.selectedEventCode,
        severity: +mainData.severity,
        defaultLabel: mainData.eventLabel,
        idSection: idSelectedSection,
        resourceType: mainData.resourceType,
        fakedResourceType: mainData.selectedTypeEvent,
        note: options.note || ''
      }

      const recurrenceEnabled = options?.recurrence?.enable === true
      if (recurrenceEnabled === true) {
        //Permet d'arrondir la date, exemple pour une année arrondi au 1janvier de l'année, pour un mois arrondi au premier du premier, pour la semaine le lundi etc. Ca permet de prendre en compte l'année / semaine / mois courant dans la génération des date de récurrence. cf doc d3-time
        const startDate = d3[options.recurrence.intervalUnit].floor(new Date(options.recurrence.start))
        const endDate = new Date(options.recurrence.end)
        //On rajoute 24h pour faire en sorte que la date de fin soit bien prise en compte pour la génération des dates de récurrence. Car la borne stop est excluante. Donc pour le 01/01/2020 par exemmple l'année 2020 ne serait pas présente dans les dates de récurrence. cf doc d3-time
        endDate.setHours(endDate.getHours() + 24)

        const dates = d3[options.recurrence.intervalUnit].range(
          startDate,
          endDate,
          options.recurrence.interval
        )

        newEvents = dates.map(date => ({
          ...eventCommonParams,
          ...{
            id: `TMP-${this.idCounter++}`,
            date: date.toISOString()
          }
        }))
      } else {
        newEvents = [{
          ...eventCommonParams,
          ...{
            id: this.editingType === eventEditingTypes.ALREADY_EXIST ? this.editEvent.id : `TMP-${this.idCounter++}`,
            date: new Date(mainData.eventDate).toISOString(),
          }
        }]
      }

      this.$refs.eventMainInformation.resetForm()
      this.$refs.eventOptions.resetForm()

      if (event.isTrusted) {
        const recurrenceObj = {
          enable: recurrenceEnabled
        }

        if (recurrenceEnabled) {
          recurrenceObj.interval = options.recurrence.interval
          recurrenceObj.intervalUnit = options.recurrence.intervalUnit
        }
        if (recurrenceEnabled && this.isEditingEventFromRecurrence) {
          recurrenceObj.previousRecurrenceId = this.editEvent.extension.recurrenceId
        }

        await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/patient/event`, {
          method: 'POST',
          credentials: 'include',
          body: JSON.stringify({
            recurrence: recurrenceObj,
            events: newEvents,
            folderId: this.folderId
          })
        })
      }
      this.sendEvent({event})

      await this.deleteUserCache()

      if (this.editingType === eventEditingTypes.ALREADY_EXIST) {
        this.exitModal(null)
      }

      this.getDataRepresentation({
        unitPerCircle: this.unitPerCircle,
        periodUnit: this.periodUnit,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })

      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    async onDeleteEvent(event) {
      if (event.isTrusted) {
        const options = this.$refs.eventOptions.getOptions()
        let idsToDelete = []

        if (this.isEditingEventFromRecurrence && options?.recurrence?.enable === true) {
          idsToDelete = options.recurrence.events.map(e => e.id)
        } else {
          idsToDelete = [this.editEvent.id]
        }
        await this.deleteEvent({
          idEvents: idsToDelete
        })
      }
      this.sendEvent({event})
      await this.deleteUserCache()
      await this.getDataRepresentation({
        unitPerCircle: this.unitPerCircle,
        periodUnit: this.periodUnit,
        reloadDataTypes: reloadDataTypes.TEMPORALITY
      })
      this.exitModal(null)
      utils.onGlobalAnimationEnd(this.$store, () => {
        this.collaborativeEventTreated()
      })
    },
    exitModal(event) {
      if (event) {
        this.sendEvent({ event })
      }

      this.$store.commit(`borderList/${mutationTypes.SET_CURRENT_DISPLAYED_BORDER_LIST}`, null)
      this.$store.commit(`${mutationTypes.IS_EDIT_MODE}`, false)
      this.$store.commit(`event/edit/${mutationTypes.SET_EDITING_TYPE}`, null)
      if (event) {
        this.collaborativeEventTreated()
      }
    },
    onEventSection(section) {
      idSelectedSection = section.id
      this.sectionName = section.name
    }
  },
}
</script>

<style>
.modal_custom {
  flex-direction: column;
  background-color: var(--color-bg-1);
  border: solid;
  border-width: 1px;
  border-radius: 5px;
  width: 50vw;
  height: 50vh;
  min-width: 30vw;
  min-height: 50vh;
  resize: both;
  overflow: auto;
  color: var(--color-text);
  padding: 12px;
}

.input_wrapper {
  margin: 10px 0px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
}
.modal_form {
  display: flex;
  flex-direction: column;
}

/* .modal_custom button:hover {
  background: rgb(86, 97, 109);
  transition-duration: 500ms;
} */
.modal_custom button:nth-child(1) {
  margin-right: 2px;
}
.modal_custom button:nth-child(2) {
  margin-left: 2px;
}
.button_wrapper {
  display: flex;
  margin-top: 20px;
}
textarea {
  margin-bottom: 15px;
  resize: vertical;
}

dialog::backdrop {
  background: linear-gradient(90deg, navy,rgb(1, 1, 47) );
  opacity: 0.1;
}

#suggestions-modal {
  margin-top: 20px;
  margin-inline: 5px;
}

.suggestion {
  cursor: pointer;
}

.error-modal {
  color: red;
}

/* .insert-event-recurrence-choose-dates { */
input:invalid {
  border: red solid 1px;
}

</style>