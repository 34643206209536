<template>
  <g ref="icon" />
</template>

<script>
import * as d3 from "d3";

export default {
  name: "Logo5Icon",
  data: () => ({
    logoswidth: "3px",
    logopacity: 1,
  }),
  mounted() {
    const logo5 = d3.select(this.$refs["icon"]);

    logo5
      .append("circle")
      .attr("r", "11.34")
      .attr("fill", "white")
      .attr("fill-opacity", 0)
      .attr("stroke-width", "2px");

    logo5
      .style("stroke-width", "20")
      .style("stroke", "red")
      .style("fill", "white")
      .style("stroke-opacity", 1)
      .attr("opacity", 0.6);
  },
};
</script>