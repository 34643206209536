export function defaultCalc(patientData) {
  let nbElem = 0
  let nbValidatedEvent = 0

  for (const scoreElem of Object.values(patientData)) {
    if (scoreElem.display) {
      nbValidatedEvent++
    }
    nbElem++
  }

  return {
    scoreAlert: `Correspondance: ${parseFloat(nbValidatedEvent / nbElem * 100).toFixed(2)}%`,
    scoreTitle: '',
    scoreRecom: ''
  }
}