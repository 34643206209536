import * as mutationTypes from '@/store/mutations-types.js'

const getDefaultState = () => {
  return {
    /**
     * Cette variable contient les informations des sections de la pie actuellement survolées par l'utilisateur. Plusieurs sections peuvent être survolé en même temps par l'utilisateur dans le cas de sections liées (Ex: Traitements en entrée de l'information diabétique)
     * @type {Object[]}
     */
    pieHoveredSections: [],
    /**
     * Cette variable contient les informations des sections de la pie actuellement selectionnées par l'utilisateur.
     * @type {Object[]}
     */
    pieSelectedSections: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    pieHoveredSections: state => state.pieHoveredSections,
    pieSelectedSections: state => state.pieSelectedSections
  },
  mutations: {
    /**
     * Permet d'ajouter une ou plusieurs sections actuellement survolé par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.ADD_HOVERED_SECTION](state, payload) {
      const toAdd = []

      for (const section of payload) {
        const result = state.pieHoveredSections.find(s => s.id === section.id)
        if (result === undefined) {
          toAdd.push(section)
        }
      }
      state.pieHoveredSections = [...state.pieHoveredSections, ...toAdd]
    },
    /**
     * Permet de supprimer une ou pluseurs sections que l'utilisateur survolé précédement
     * @method
     * @public
     */
    [mutationTypes.REMOVE_HOVERED_SECTION](state, payload) {
      state.pieHoveredSections = state.pieHoveredSections.filter(section => {
        const result = payload.find(s => s.id === section.id)
        return result === undefined
      })
    },
    /**
     * Permet de remettre à zero le tableau contenant la liste des sections survolées par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.RESET_HOVERED_SECTION](state) {
      state.pieHoveredSections = []
    },
    /**
     * Permet d'ajouter une ou plusieurs sections selectionné par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.ADD_SELECTED_SECTION](state, payload) {
      const toAdd = []

      for (const section of payload) {
        const result = state.pieSelectedSections.find(s => s.id === section.id)
        if (result === undefined) {
          toAdd.push(section)
        }
      }
      state.pieSelectedSections = [...state.pieSelectedSections, ...toAdd]
    },
    /**
     * Permet de supprimer une ou pluseurs sections que l'utilisateur a selectionné précédement
     * @method
     * @public
     */
    [mutationTypes.REMOVE_SELECTED_SECTION](state, payload) {
      state.pieSelectedSections = state.pieSelectedSections.filter(section => {
        const result = payload.find(s => s.id === section.id)
        return result === undefined
      })
    },
    /**
     * Permet de remettre à zero le tableau contenant la liste des sections selectionné par l'utilisateur
     * @method
     * @public
     */
    [mutationTypes.RESET_SELECTED_SECTION](state, payload) {
      state.pieSelectedSections = []
    },
  },
  actions: {}
}