<template>
  <div class="outer-container">
    <div class="container-form">
      <div class="inner-container">
        <div class="part right">
          <div class="unit">
            <label>Orientation du contenu</label>
            <select
              ref="typeContent"
              v-model="typeContent"
              @change="onChangeTypeContent"
            >
              <option
                v-for="(choice, index) in typesContent"
                :key="index"
                :value="choice.value"
              >
                {{ choice.label }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Catégories</label>
            <select
              ref="asSection"
              v-model="asSection"
              @input="setCategory"
            >
              <option
                v-for="section in sections"
                :key="section"
              >
                {{ section }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Libellé</label>
            <select
              ref="defaultLabel"
              v-model="defaultLabel"
              @input="setDefaultLabel"
            >
              <option
                v-for="section in sections"
                :key="section"
              >
                {{ section }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Code</label>
            <select
              ref="asCode"
              v-model="asCode"
              @input="setCode"
            >
              <option
                v-for="section in sections"
                :key="section"
              >
                {{ section }}
              </option>
              <option>aucun</option>
            </select>
          </div>
        </div>
        <div class="part left">
          <div class="unit">
            <label>Sévérité</label>
            <select
              ref="asSeverity"
              v-model="asSeverity"
              @input="setSeverity"
            >
              <option
                v-for="section in sections"
                :key="section"
              >
                {{ section }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Type de sévérité</label>
            <select
              ref="typeSeverity"
              v-model="typeSeverity"
              @input="setTypeSeverity"
            >
              <option
                v-for="sev in typesSeverity"
                :key="sev.value"
                :value="sev.value"
              >
                {{ sev.label }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Date</label>
            <select
              ref="date"
              v-model="date"
              @input="setDate"
            >
              <option
                v-for="section in sections"
                :key="section"
              >
                {{ section }}
              </option>
            </select>
          </div>
          <div class="unit">
            <label>Format de date</label>
            <select
              ref="dateFormat"
              @input="setDateFormat"
            >
              <option
                v-for="(value, key) in dateFormats"
                :key="key"
                :value="value"
              >
                {{ key }}
              </option>
            </select>
          </div>
          <div class="unit" />
        </div>
      </div>
      <div
        v-if="isCustom"
        class="unit extend"
      >
        <label for="format">{{
          sampleLine[date] ? sampleLine[date] : "aucune sélection"
        }}</label>
        <p class="preview">
          {{ dateFormat ? dateFormat : "Format de date" }}
        </p>
        <p
          v-for="(token, index) in tokens"
          :key="index"
        >
          Definir <em> {{ token.token }}</em> :
          <select
            id="format"
            ref="dateFormatToken"
            v-model="token.formatter"
            @change="createCustomDate"
          >
            <option
              v-for="(option, i) in optionsDateFormat"
              :key="i"
              :value="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </p>
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <div>
        <div class="mt-2 flex flex-row">
          <div class="container-design-button mr-2">
            <label
              for="UniversalImportConfigfile"
            >
              <DesignButton
                text="Importer"
                class="design-button hidden"
                @click="onClickImport"
              />
              <input
                id="UniversalImportConfigfile"
                ref="UniversalImportConfigfile"
                type="file"
                name="file"
                accept=".json"
                class="inputfile hidden"
                @change="readConfigFromFile($refs['UniversalImportConfigfile'].files[0])"
              >
            </label>
          </div>
          <div class="container-design-button ml-2">
            <DesignButton
              text="Exporter"
              class="design-button"
              @clicked="onExportConfig"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DesignButton from "@/components/DesignButton.vue"
import utils from '@/libraries/utils.js'
import { mapActions } from 'vuex'
import EyeFetch from "@/libraries/EyeFetch"
import * as mutationTypes from "@/store/mutations-types.js"

export default {
  name: "FormImport",
  components: {
    DesignButton,
  },
  data() {
    return {
      dateFormats: {
        "MM/dd/yy HH:mm": "%m/%d/%Y %H:%M",
        "dd/MM/yyyy HH:mm": "%d/%m/%Y %H:%M",
        "yyyy-MM-dd HH:mm:ssX": "%Y-%m-%d %H:%M",
        yyyy: "%Y",
        custom: "custom",
      },
      typeContent: 'horizontal',
      typesContent: [
        { label: 'Horizontal', value: 'horizontal'},
        { label: 'Vertical', value: 'vertical'}
      ],
      typeSeverity: 'numeric',
      typesSeverity: [
        { label: 'Numerique', value: 'numeric'},
        { label: 'Alphanumérique', value: 'alphanumeric'}
      ],
      optionsDateFormat: [
        { label: 'jour', value: 'day', d3Flag: '%d' },
        { label: 'mois', value: 'month', d3Flag: '%m' },
        { label: 'année', value: 'year', d3Flag: '%Y' },
        { label: 'heure', value: 'hour', d3Flag: '%H' },
        { label: 'minute', value: 'minute', d3Flag: '%M' },
        { label: 'seconde', value: 'second', d3Flag: '%s' },
        { label: 'ignorer', value: 'ignore', d3Flag: '' }
      ],
      isCustom: false,
      date: null,
      asSeverity: null,
      asSection: null,
      dateFormat: "",
      defaultLabel: null,
      asCode: null,
      tokens: [],
      sampleLine: null
    };
  },
  computed: {
    sections() {
      return this.sampleLine ? Object.keys(this.sampleLine) : []
    }
  },
  watch: {
    sections() {
      if (this.sections.length > 0) {
        this.$store.commit(mutationTypes.SET_COLUMNS_FILE, this.sections)
      }
    }
  },
  async mounted() {
    await this.getSampleLine()
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    setCategory(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.asSection = e.target.value
      this.collaborativeEventTreated()
    },
    setSeverity(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.asSeverity = e.target.value
      this.collaborativeEventTreated()
    },
    setTypeSeverity(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.typeSeverity = e.target.value
      this.collaborativeEventTreated()
    },
    setDate(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.date = e.target.value

      if (this.isCustom) {
        this.createTokensCustomDate()
      }
      this.collaborativeEventTreated()
    },
    setDefaultLabel(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.defaultLabel = e.target.value
      this.collaborativeEventTreated()
    },
    setCode(e) {
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })
      this.asCode = e.target.value
      this.collaborativeEventTreated()
    },
    setDateFormat(e) {
      e.preventDefault()
      e.stopPropagation()
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value
        }
      })

      if (e.target.value === "custom") {
        this.isCustom = true;
        this.dateFormat = this.sampleLine[this.date];
        this.createTokensCustomDate()
        this.collaborativeEventTreated()
        return;
      }

      this.tokens = []
      this.isCustom = false;
      this.dateFormat = e.target.value
      this.collaborativeEventTreated()
    },
    createTokensCustomDate() {
      const tokens = this.sampleLine[this.date] + "";
      this.tokens = tokens.split(/[\s*,:\/+T-]/).map(t => ({
        token: t,
        formatter: 'ignore'
      }))
    },
    createCustomDate(event) {
      this.sendEvent({
        event: event,
        params: {
          text: event.target.value
        }
      })
      this.dateFormat = this.sampleLine[this.date];
      for (const token of this.tokens) {
        const formatter = this.optionsDateFormat.find(o => o.value === token.formatter)

        this.dateFormat = String(this.dateFormat).replace(
          token.token,
          formatter.d3Flag
        )
      }
      this.collaborativeEventTreated()
    },
    async readConfigFromFile(file) {
      const fileReader = new FileReader()
      fileReader.readAsText(file)

      let result = await new Promise((resolve, reject) => {
        fileReader.onload = () => resolve(fileReader.result)
        fileReader.onerror = (error) => reject(error)
      })
      result = JSON.parse(result)
      //La valeur est set de cette manière là puis un event est dispatch plus bas pour forcer un envoi d'événement en collaboratif
      this.$refs.typeContent.value = result.typeContent || 'horizontal'
      const typeContentEvent = new Event('change', { 'bubbles': true })
      typeContentEvent.eyeFirstLineAlreadyReloaded = true
      this.$refs.typeContent.dispatchEvent(typeContentEvent)
      await this.getSampleLine()

      this.$refs.asSection.value = result.asSection
      this.$refs.asSeverity.value = result.severity
      this.$refs.date.value = result.date
      this.$refs.dateFormat.value = (result.tokens === undefined) ? result.dateFormat : 'custom'
      this.$refs.defaultLabel.value = result.defaultLabel
      this.$refs.asCode.value = result.code
      this.$refs.typeSeverity.value = result.typeSeverity

      this.$refs.asSection.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.asSeverity.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.date.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.defaultLabel.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.asCode.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.typeSeverity.dispatchEvent(new Event('input', { 'bubbles': true }))
      this.$refs.dateFormat.dispatchEvent(new Event('input', { 'bubbles': true }))

      await this.$nextTick()
      if (result.tokens !== undefined && result.tokens.length === this.tokens.length) {
        for (const [i, dateFormatToken] of this.$refs.dateFormatToken.entries()) {
          dateFormatToken.value = result.tokens[i].formatter
          dateFormatToken.dispatchEvent(new Event('change', { 'bubbles': true }))
        }
      }
    },
    getConfig() {
      const config = {
        asSection: this.asSection,
        severity: this.asSeverity,
        date: this.date,
        dateFormat: this.dateFormat,
        defaultLabel: this.defaultLabel,
        code: this.asCode,
        typeContent: this.typeContent,
        typeSeverity: this.typeSeverity
      }

      if (this.isCustom) {
        config.tokens = this.tokens
      }
      return config
    },
    onExportConfig() {
      const config = this.getConfig()
      utils.downloadObjectAsJson(config, 'config')
    },
    async getSampleLine() {
      try {
        const searchParams = new URLSearchParams()
        searchParams.append("typeContent", this.typeContent)

        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/universal/headers?${searchParams}`, {
          method: 'GET',
          credentials: 'include'
        })
        this.sampleLine = await response.json()
      } catch (e) {
        console.error(e)
      }
    },
    onClickImport() {
      this.$refs.UniversalImportConfigfile.click()
    },
    async onChangeTypeContent(event) {
      this.sendEvent({
        event: event,
        params: {
          text: event.target.value,
          change: true
        }
      })
      this.typeContent = event.target.value
      if (event.eyeFirstLineAlreadyReloaded !== true) {
        await this.getSampleLine()
      }
      this.collaborativeEventTreated()
    }
  },
};
</script>

<style scoped>
.part {
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  justify-content: space-evenly;
  /* background: var(--color-shadow); */
}
.right {
  border-radius: 5px 0 0 5px;
}
.left {
  border-radius: 0 5px 5px 0;
}
.container-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: auto;
  background: var(--c-gray-soft-1);
  border-radius: 5px;
  transition: 200ms;
}
.inner-container {
  display: flex;
  height: 100%;
  padding-bottom: 2%;
  padding-top: 2%;
}
.unit {
  display: flex;
  flex-direction: column;
  padding-inline: 2%;
}
.unit > * {
  margin: 5px;
}
label {
  font-weight: 600;
}
.extend > label {
  text-align: center;
  font-style: italic;
  font-weight: 100;
  font-size: 1.5rem;
}
.preview {
  opacity: 0.8;
  font-size: 1rem;
  text-align: center;
}
extend > p {
  text-align: left;
}
.outer-container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.design-button {
  align-self: end;
}
</style>