<template>
  <svg
    width="15" 
    height="15"
  >
    <polyline
      points="0 2.5 2.5 0 12.5 0 15 2.5 15 12.5 12.5 15 2.5 15 0 12.5 0 2.5"
      stroke="var(--color-text)"
      stroke-width="1"
      stroke-linecap="round"
      fill="none"
      stroke-linejoin="round" 
    />
    <line
      x1="2.5"
      x2="12.5"
      y1="5"
      y2="5"
      stroke="var(--color-text)"
      stroke-linecap="round"
      stroke-width="1" 
    />
    <line
      x1="2.5"
      x2="12.5"
      y1="10"
      y2="10"
      stroke="var(--color-text)"
      stroke-linecap="round"
      stroke-width="1" 
    />
  </svg>
</template>

<script>
export default {
  name: 'IconMenuList'
}
</script>