<template>
  <div>
    <div
      v-for="(f, index) in folder.children"
      :key="index"
      class="mb-4"
    >
      <Collapse>
        <template #title>
          <p class="home-folder-name">
            {{ f.label }}
          </p>
        </template>
        <template #content>
          <SubMenuList
            :folder="f"
            :menu-item-selected="menuItemSelected"
            @update-patient-id="update_patient_id"
            @on-record="onRecord"
          />
        </template>
      </Collapse>
    </div>
    <div
      v-if="typePatientContext === 5 && isNaN(folder.name) === false"
      class="collaborative-patient cursor-pointer"
      @click="(e) => onPatientGroupCohorte(e)"
    >
      <span>Cohorte</span>
    </div>
    <PatientsList
      :sub-menu-items="folder.patients"
      :menu-item-selected="menuItemSelected"
      @update-patient-id="(e, patient) => update_patient_id(e, patient, folder)"
      @on-record="onRecord"
    />
    <div
      v-if="menuItemSelected === 'Patients' && typePatientContext === 4"
      class="collaborative-patient cursor-pointer"
      @click="onCreatePatient"
    >
      <span>+ Créer un patient</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubMenuList'
}
</script>

<script setup>
import { defineProps, defineEmits } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import Collapse from '@/components/Collapse.vue'
import PatientsList from '@/views/Home/PatientsList.vue'
import { computed } from 'vue'
import * as mutationTypes from '@/store/mutations-types.js'
import dataSourceComposition from '@/shared/enums/dataSourceComposition.js'

const store = useStore()
const router = useRouter()

const props = defineProps({
  folder: {
    type: Object,
    required: true
  },
  menuItemSelected: {
    type: String,
    required: true
  }
})

const typePatientContext = computed(() => store.getters['user/typePatientContext'])
const emits = defineEmits(['update-patient-id', 'on-record'])

function update_patient_id(e, patient, folder) {
  emits('update-patient-id', e, patient, folder)
}

function onRecord(record) {
  emits('on-record', record)
}

function onCreatePatient(event) {
  store.dispatch('ws/sendEvent', {event: event})
  store.commit(`patient/${mutationTypes.SET_FOLDER_ID}`, props.folder.id)
  router.push({ name: "PatientCreation"})
  store.dispatch('ws/collaborativeEventTreated')
}

async function onPatientGroupCohorte(event) {
  store.dispatch('ws/sendEvent', {event: event})
  store.commit(`patient/${mutationTypes.SET_FOLDER_ID}`, props.folder.id)
  store.commit(`patient/${mutationTypes.UPDATE_DATA_SOURCE_COMPOSITION}`, dataSourceComposition.COHORT)
  store.commit(`patient/${mutationTypes.SET_ID_PATIENT}`, props.folder.patients[0].id)
  router.push({ name: "EyeApp" })
  store.dispatch('ws/collaborativeEventTreated')
}

</script>

<style scoped>
.home-folder-name {
  font-size: 1.4rem;
}
</style>