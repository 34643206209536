export default {
  'LOYALTY_CHECKER': {
    name: 'LOYALTY_CHECKER',
    // color: '#00C9FF', //cyan 0.3
    // color: 'red', //rouge 0.3
    // color: '#FF0074', //rouge rose 0.3
    // color: '#4600FF', //bleu 0.3
    // color: '#FF4600', //orange 0.5
    color: 'magenta',
    opacity: 0.45
  },
  'BIO_NORM': {
    name: 'BIO_NORM',
    color: 'red',
    opacity: 1
  }
}