<template>
  <button
    :class="color"
    @click="emitCloseEvent"
  >
    &#10005;
  </button>
</template>

<script>
export default {
  name: "ButtonClose",
  props: {
    color: {
      type: String,
      default: "d3_gray",
    },
  },
  emits: ["close"],
  methods: {
    /**
     * Cette fonction est appelé lorsque l'utilisateur clique sur le bouton croix du composant. La fonction envoi un signal au composant parent pour l'avertir de l'action de l'utilisateur
     * @param {Event} event Evénement fournit par le listener
     */
    emitCloseEvent(event) {
      this.$emit("close", event);
    },
  },
};
</script>

<style scoped>

button {
  display: inline-bock;
  background-color: transparent;
  border: none;
  font-size: 1.5rem;
  padding: 0px;
}

</style>