<template>
  <div class="imgContainer">
    <img
      ref="myImg"
      src="./Eyediag.svg"
    >
    <p>Bienvenue dans Eyediag...</p>
  </div>
</template>

<script>
export default {
  name: "Component1TutoDecouverte",
  mounted() {
	  const lines = document.querySelectorAll('p')
	  this.$refs.myImg.style.height = `${70 - lines.length * 10}vh`
  }
}
</script>

<style scoped>
p {
	height: 10vh;
}

.imgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height:  70vh;
  font-size: 2rem;
  color: white;
}
</style>