function calc8() {
  return {
    scoreAlert: `Alertes`,
    scoreTitle: 'Alertes',
    scoreRes: 0,
  }
}

function calc9() {
  return {
    scoreAlert: 'Suggestion de mission',
    scoreTitle: `Alerte`,
    scoreRes: 0,
  }
}

export {
  calc8,
  calc9
}