import ErrorManager from "./ErrorManager.js"
import cookies from 'vue-cookies'

let isTokenRefreshing = false
let requestQueue = []

async function execQueueRequest(request) {
  try {
    const response = await fetch(request.url, request.params)
    request.resolve(response)
  } catch (err) {
    request.reject(err)
  }
}

async function refreshTokenSet() {
  try {
    isTokenRefreshing = true
    const response = await fetch(`${process.env.VUE_APP_SERVER_BASE_URL}/refreshToken/`, {
      method: 'GET',
      credentials: 'include',
    })

    isTokenRefreshing = false
    //Forcement une 401 si ça rentre dans le if
    if (!response.ok) {
      ErrorManager.requestError(response, null)
      return
    }

    for (const request of requestQueue) {
      execQueueRequest(request)
    }
  } catch (err) {
    isTokenRefreshing = false
    const test = {
      "userinfo":
        {
          "address": {"essential": true},
        }
    }
    window.location.href =`${process.env.VUE_APP_SERVER_BASE_URL}/oidc/auth?client_id=${process.env.VUE_APP_EYEDIAG_CLIENT_ID}&redirect_uri=${process.env.VUE_APP_FRONT_BASE_URL}/login/&response_type=code&scope=profile+email+openid+offline_access+phone&state=321&claims=${JSON.stringify(test)}`
  }
  requestQueue = []
}

function checkValidityAccessToken() {
  let isRefreshTokenEnabled = cookies.get('is_refresh_token_enabled')
  let expiration = cookies.get('expiration_access_token')

  const currentDate = new Date()
  const expirationDate = expiration ? new Date(expiration) : new Date()

  //si la variable vaut autre chose très bizarre, il vaut mieux faire expirer le token
  if (["false", "true"].includes(isRefreshTokenEnabled) === false) {
    return false
  }
  if (isRefreshTokenEnabled == "false" && expirationDate < currentDate) {
    return false
  }
  if (isRefreshTokenEnabled == "true" && expirationDate - currentDate < 5*60*1000) {
    return false
  }
  return true
}

/**
 * @async
 * @function
 * @param {*} store 
 * @param {*} url 
 * @param {*} params 
 * @returns {promise}
 */
async function EyeFetch(store, url, params = {}) {
  if (isTokenRefreshing === false && params.eyeTokenRequest !== true && checkValidityAccessToken() === false) {
    refreshTokenSet()
  }

  const defaultHeader = {
    'Eye-Collab-Room-Id': store.getters['ws/roomId'],
  }

  if (params.headers === undefined || params.headers['Content-type'] === undefined) {
    defaultHeader['Content-type'] = 'application/json'
  } else if (params.headers['Content-type'] === 'multipart/form-data') {
    delete params.headers['Content-type']
  }

  params.signal = store.getters['reqAbortController'].signal,
  params.headers = {
    ...defaultHeader,
    ...params.headers
  }

  if (isTokenRefreshing) {
    return new Promise((resolve, reject) => {
      requestQueue.push({
        url: url,
        params: params,
        resolve: resolve,
        reject: reject
      })
    })
  } else {
    return fetch(url, params)
  }
}

export default EyeFetch