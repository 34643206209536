<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./9.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Il est possible de mémoriser un ou plusieurs évènements en effectuant un clique gauche dessus.</p>
    <p>Ils seront ensuite répertoriés dans le menu accessible grâce à la flèche en bas à droite de l'application.</p>
  </div>
</template>
  
<script>
export default {
  name: "Component9Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>