<template>
  <radialGradient
    :id="defId"
    gradientUnits="userSpaceOnUse"
    cx="0"
    cy="0"
    :r="gradientRadius"
  >
    <stop
      offset="0%"
      :stop-color="neutral"
    />
    <stop
      offset="18%"
      :stop-color="neutral"
    />
    <stop
      offset="27%"
      :stop-color="color"
    />
    <stop
      offset="70%"
      :stop-color="color"
    />
    <stop
      offset="100%"
      :stop-color="neutral"
    />
  </radialGradient>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  props: {
    /**
     * Il s'agit de la couleur principale du gradient
     * @type {String}
     */
    color: {
      type: String,
      required: true
    },
    /**
     * Il s'agit de la couleur servant à faire le fondu avec la couleur d'arrière plan de l'application
     * @type {String}
     */
    neutral: {
      type: String,
      required: true
    },
    /**
     * Il s'agit de l'identifiant de la def
     * @type {String}
     */
    defId: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters({
      mainRadius: "layout/radius",
    }),
    /**
     * Rayon du gradient
     * @type {Number}
     */
    gradientRadius() {
      return (5 * this.mainRadius) / 4;
    }
  }
};
</script>