<template>
  <div class="selected-event">
    <div
      class="event-circle-container" 
      :style="`flex-basis: ${sizeEventCircleContainer}px`"
    >
      <span
        :class="{
          [getEventResourceTypeColor(event)]: true,
          'dot': true,
          'dot-empty': hoveredEvent === null || event.id !== hoveredEvent.id,
        }"
        :style="`width: ${sizeCircle}px; height: ${sizeCircle}px`"
      />
    </div>

    <div class="list-event-content">
      <slot name="description">
        <p class="selected-event-text">
          {{ event.defaultLabel }}
        </p>
      </slot>
    </div>
  </div>
</template>

<script setup>

import { defineProps, computed } from 'vue'
import { useStore } from 'vuex'
import utils from '@/libraries/utils.js'
import eventManager from '@/libraries/EventManager'

const store = useStore()
const props = defineProps({
  event: {
    type: Object,
    required: true
  },
  circlePercentReduce: {
    type: Number,
    required: false,
    default: 0
  }
})

const hoveredEvent = computed(() => store.getters['event/common/hoveredEvent'])
const reduction = computed(() => props.circlePercentReduce / 100)

const sizeCircle = computed(() => {
  const maxSize = props.event.r * 2
  return maxSize - maxSize * reduction.value
})
const sizeEventCircleContainer = computed(() => {
  const maxSize = 5 + eventManager.severityRadius[4] * 2
  return maxSize - maxSize * reduction.value
})

const getEventResourceTypeColor = (event) => utils.getEventResourceTypeColor(event, store)

</script>

<style scoped>
.dot {
  border-radius: 50%;
  border-style: solid;
  border-width: 1.5px;
  display: inline-block;
}

.dot-empty {
  background-color: transparent;
}

.event-circle-container {
  display: flex;
  justify-content: right;
  flex-shrink: 0;
}

.selected-event-text {
  font-size: 1.2rem;
  text-align: justify-all;
}

.list-event-content {
  padding: 0px;
  margin-left: 10px;
  flex-grow: 1;
}

.selected-event {
  padding-bottom: 10px;
  display: flex;
}
</style>