<template>
  <div class="tutorialDiv">
    <div class="box">
      <div class="utililtyButtons">
        <span
          style="cursor: pointer; color: grey"
          @click="changeComponent(-1)"
        >
          &#65124;
        </span>
        <span
          style="cursor: pointer; color: grey"
          @click="closeTutorial" 
        >
          &#x2715;
        </span>
      </div>
      <slot />
      <div class="buttonContainer">
        <button
          class="button"
          @click="changeComponent(1)"
        >
          Suivant
        </button>
      </div>
      <div class="footer">
        <span style="color: grey">{{ currIndex + 1 }}/{{ nbComponents }}</span>
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip" />
            <span class="icon-line line-long" />
            <div class="icon-circle" />
            <div class="icon-fix" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseTutorial',
  props: {
    currIndex: {
      type: Number,
      required: true
    },
    nbComponents: {
      type: Number,
      required: true
    }
  },
  emits: ['changeComponent'],
  methods: {
    changeComponent(itt) {
      this.$emit('changeComponent', itt)
    },
    closeTutorial() {
      document.querySelector('.tutorialDiv').remove()
    },
    resetAnimation() {
      const check = document.querySelector('.success-checkmark')
      check.classList.remove('success-checkmark')
      check.offsetWidth
      check.classList.add('success-checkmark')
    }
  }
}
</script>

<style scoped>
.box {
  position: absolute;
  width: 20vw;
  top: 5vh;
  right: 3vw;
  border: 1px solid #c0c0c0;
  background-color: rgba(128,128,128,.5);
  border-radius: 5px;
  padding: 20px;
  backdrop-filter: blur(5px);
  font-family: var(--font-secondary-1);
}

.buttonContainer {
  display: flex;
  justify-content: center;
  padding-top: 3vh;
}

.button {
  align-items: center;
  background-color: #689acc;
  border: 0;
  border-radius: 10px;
  color: #201e1eea;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  min-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.button:hover, .button-18:focus { 
  background-color: #3e6596;
  color: #ffffff;
}

.button:active {
  background: #09223b;
  color: rgb(255, 255, 255, .7);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.utililtyButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1vh;
}

.success-checkmark .check-icon {
  width: 20px;
  height: 20px;
  position: relative;
  border-radius: 50%;
  box-sizing: content-box;
  border: 1px solid #4caf50;
}
.success-checkmark .check-icon::before {
  top: 0.750px;
  left: -0.5px;
  width: 7.5px;
  transform-origin: 100% 50%;
  border-radius: 25px 0 0 25px;
}
.success-checkmark .check-icon::after {
  top: 0;
  left: 7.5px;
  width: 15px;
  transform-origin: 0 50%;
  border-radius: 0 25px 25px 0;
  animation: rotate-circle 4.25s ease-in;
}
.success-checkmark .check-icon::before, .success-checkmark .check-icon::after {
  content: '';
  height: 25px;
  position: absolute;
  transform: rotate(-45deg);
}
.success-checkmark .check-icon .icon-line {
  height: 1.25px;
  background-color: #4caf50;
  display: block;
  border-radius: 0.25px;
  position: absolute;
  z-index: 10;
}
.success-checkmark .check-icon .icon-line.line-tip {
  top: 11.5px;
  left: 3.5px;
  width: 6.25px;
  transform: rotate(45deg);
  animation: icon-line-tip 0.75s;
}
.success-checkmark .check-icon .icon-line.line-long {
  top: 9.5px;
  right: 2px;
  width: 11.75px;
  transform: rotate(-45deg);
  animation: icon-line-long 0.75s;
}
.success-checkmark .check-icon .icon-circle {
  top: -1px;
  left: -1px;
  z-index: 10;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  box-sizing: content-box;
  border: 1px solid rgba(76, 175, 80, .5);
}
.success-checkmark .check-icon .icon-fix {
  top: 2px;
  width: 1.25px;
  left: 6.5px;
  z-index: 1;
  height: 21.25px;
  position: absolute;
  transform: rotate(-45deg);
}
@keyframes rotate-circle {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}
@keyframes icon-line-tip {
  0% {
    width: 0;
    left: 0.25px;
    top: 4.75px;
  }
  54% {
    width: 0;
    left: 0.25px;
    top: 4.75px;
  }
  70% {
    width: 12.5px;
    left: -2px;
    top: 9.25px;
  }
  84% {
    width: 4.25px;
    left: 5.25px;
    top: 12px;
  }
  100% {
    width: 6.5px;
    left: 3.5px;
    top: 11.25px;
  }
}
@keyframes icon-line-long {
  0% {
    width: 0;
    right: 11.5px;
    top: 13.5px;
  }
  65% {
    width: 0;
    right: 11.5px;
    top: 13.5px;
  }
  84% {
    width: 13.75px;
    right: 0px;
    top: 8.75px;
  }
  100% {
    width: 11.75px;
    right: 2px;
    top: 9.5px;
  }
}

</style>