<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./19.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Ici, Eyediag peut vous suggérer certains filtres et paramètres.</p>
    <p>Un clic droit sur cette zone permet d'accéder à l'ensemble des suggestions ainsi que vos filtres sauvegardés.</p>
  </div>
</template>
    
<script>
export default {
  name: "Component19Tuto",
}
</script>

<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>