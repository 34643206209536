<template>
  <div>
    <div
      v-for="(choice, idx) in choices"
      :key="idx"
      class="severity-choice-container p-1 mb-3"
      draggable="true"
      @dragstart="(e) => onDragStart(e, choice)"
      @drop="(e) => onDrop(e, choice)"
      @dragover="onDragOver"
    >
      <p>{{ choice }}</p>
    </div>
  </div>
</template>

<script setup>
import { useStore } from 'vuex'
import { ref, defineProps, defineExpose } from 'vue'
import EyeFetch from "@/libraries/EyeFetch"

const store = useStore()
const props = defineProps({
  severityChoices: {
    type: Array,
    required: true
  }
})

const choices = ref([...props.severityChoices])
let draggingId = null

function onDragStart(event, choiceId) {
  draggingId = choiceId
}

function onDrop(event, choiceId) {
  const fromIndex = choices.value.findIndex(e => e === draggingId)
  const toIndex = choices.value.findIndex(e => e === choiceId)
  moveChoice(choices.value, fromIndex, toIndex)
}

function onDragOver(event) {
  event.preventDefault()
}

function moveChoice(arr, fromIndex, toIndex) {
  var element = arr[fromIndex]
  arr.splice(fromIndex, 1)
  arr.splice(toIndex, 0, element)
}

async function sendOrderedSeverity() {
  try {
    const body = {
      severityOrder: choices.value
    }
    const searchParams = new URLSearchParams()
    searchParams.append("typeContent", this.typeContent)

    await EyeFetch(store, `${process.env.VUE_APP_SERVER_BASE_URL}/universal/order-severity`, {
      method: 'POST',
      credentials: 'include',
      body: JSON.stringify(body)
    })
  } catch (e) {
    console.error(e)
  }
}

defineExpose({
  sendOrderedSeverity: sendOrderedSeverity
})

</script>

<style scoped>

.severity-choice-container {
  background-color: var(--c-gray-soft-1);
}

</style>