<template>
  <div>
    <div
      v-for="(groupEvents, code) in groupedEvents"
      :key="code"
    >
      <ListItem
        :event="groupEvents[0]"
      >
        <template #description>
          <p class="legend-pie-content-date">
            {{ getDateLabel(groupEvents) }}
          </p>
          <p class="mt-1">
            {{ groupEvents[0].defaultLabel }}
          </p>
        </template>
      </ListItem>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendPie'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { ref, computed, watch, onMounted, onBeforeUnmount, defineEmits } from 'vue'
import ListItem from '@/components/event/ListItem.vue'
// import EventSelectionTypes from '@/enums/event_selection_types.js'
import * as mutationTypes from '@/store/mutations-types.js'
import _ from 'lodash'

const store = useStore()

let events = []
const groupedEvents = ref({})
const displayedEvents = computed(() => store.getters['event/common/events'])
const allEvents = computed(() => store.getters['event/common/allEvents'])
const pieHoveredSections = computed(() => store.getters['pie/pieHoveredSections'])
const eventFilters = computed(() => store.getters['event/common/eventFilters'])

watch(pieHoveredSections, () => {
  setLegendEvents()
})

onMounted(() => {
  setLegendEvents()
})

onBeforeUnmount(() => {
  deleteOldHighlight()
  events = []
  groupedEvents.value = {}
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
})

const emits = defineEmits(['title'])

function setLegendEvents() {
  deleteOldHighlight()
  let idPie = null
  if (pieHoveredSections.value.length > 0) {
    const hoveredPie = pieHoveredSections.value[pieHoveredSections.value.length - 1]
    idPie = hoveredPie.id
    emits('title', hoveredPie.name)
    events = allEvents.value.filter(event => event.parentSection === idPie)
    groupedEvents.value = _.groupBy(events, (d) => d.code)
  }
  highlight(idPie)
  store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
}

// function setLegendEvents() {
//   deleteOldHighlight(events.value)
//   if (pieHoveredSections.value.length > 0) {
//     const idPie = pieHoveredSections.value[pieHoveredSections.value.length - 1].id
//     events.value = displayedEvents.value.filter(event => event.circleParent.active && event.parentSection === idPie)
//   }
//   highlight(events.value)
//   store.commit(`event/common/${mutationTypes.UPDATE_DISPLAYED_EVENTS}`, displayedEvents.value)
//   return []
// }

function deleteOldHighlight() {
  delete eventFilters.value.sections
  store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, eventFilters.value)
  store.dispatch(`event/common/generateEvents`)
}

function highlight(idPie) {
  if (idPie === null) {
    return
  }

  eventFilters.value.sections = [idPie]
  store.commit(`event/common/${mutationTypes.SET_EVENT_FILTERS}`, eventFilters.value)
  store.dispatch(`event/common/generateEvents`)
}

function getDateLabel(srcEvents) {
  if (srcEvents.length === 0) {
    return ''
  }
  const firstEvent = srcEvents[0]
  const lastEvent = srcEvents[srcEvents.length - 1]
  let label = srcEvents[0].onsetDateTime.slice(0, 4)
  
  if (srcEvents.length > 1 && firstEvent.onsetDateTime.slice(0, 4) !== lastEvent.onsetDateTime.slice(0, 4)) {
    label += `-${lastEvent.onsetDateTime.slice(0, 4)}`
  }
  return label
}

// function deleteOldHighlight(src) {
//   for (const event of src) {
//     store.commit(`event/common/${mutationTypes.REMOVE_SELECTED_EVENT}`, {
//       event,
//       selectionType: EventSelectionTypes.LEGEND
//     })
//   }
// }

// function highlight(src) {
//   for (const event of src) {
//     store.commit(`event/common/${mutationTypes.ADD_SELECTED_EVENT}`, {
//       event: event,
//       selectionType: EventSelectionTypes.LEGEND
//     })
//   }
// }

</script>

<style>
.legend-pie-content-date {
  font-family: var(--font-secondary-2);
  font-weight: 500;
  letter-spacing: 0.03em;
}
</style>