<template>
  <div style="font-size: 2rem; color: white;">
    <video
      src="./7.webm"
      style="width: 100%;"
      autoplay
      loop
    />
    <p>Survoler le sommet d'une déformation avec la souris permet de faire apparaitre l'intitulé de l'évènement.</p>
    <p>Les autres événements possédant le même code sont mis en évidence par le biais de cercle de plus petite taille.</p>
  </div>
</template>
  
<script>
export default {
  name: "Component7Tuto",
}
</script>
  
<style scoped>
p {
  padding-bottom: 10px;
}
video {
  padding-bottom: 10px;
}
</style>