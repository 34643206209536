import CircleUtility from "@/libraries/CircleUtility.js"

/**
 * Class CollaborativeEventDispatcher
 */

class CollaborativeEventDispatcher {
  constructor() {
    this.refStore = null
    this.eventQueue = []
    this.askState = false
    this.downEvent = null
    this.timeout = null
    this.observer = null
    this.resolve = null
  }

  resetEventQueue() {
    this.eventQueue.length = 0
  }

  addElementEventQueue(event, refStore) {
    if (this.askState) {
      this.eventQueue.push(event)
      return
    }

    const nbElement = this.eventQueue.length

    this.eventQueue.push(event)
    if (nbElement === 0) {
      this.treatNextQueueEvent(refStore)
    }
  }

  shiftQueueEvent() {
    this.eventQueue.shift()
  }

  async treatNextQueueEvent(refStore) {
    if (this.askState === false && this.eventQueue.length > 0) {
      while (this.eventQueue.length > 0) {
        const event = this.eventQueue[0]
        const test = document.querySelector(event.selector)
        const promise = new Promise(resolve => {
          this.resolve = resolve
        })
        if (event.selector !== 'window' && test === null) {
          this.timeout = setTimeout(() => {
            this.observer.disconnect()
            this.observer = null
            document.body.dispatchEvent(new Event('click'))
            this.askState = true
            this.eventQueue.forEach(e => {
              this.manageDownEvent(e)
            })
            this.eventQueue.length = 0
            refStore.getters.reqAbortController.abort()
            refStore.commit('SET_ABORT_CONTROLLER', new AbortController())
            refStore.dispatch('ws/askPresenterState')
            clearTimeout(this.timeout)
          }, 5000)
          this.waitForElement(event.selector, () => {
            this.dispatchEvent(event, refStore)
          })
        } else {
          this.dispatchEvent(event, refStore)
        }
        await promise
        this.resolve = null
      }
    }
  }

  waitForElement(selector, callback) {
    // Initialize a mutation observer
    this.observer = new MutationObserver(() => {
      // Query for the element
      var element = document.querySelector(selector)
      if (element) {
        clearTimeout(this.timeout)
        this.observer.disconnect()
        this.observer = null
        callback()
        // Once the element has been found, we can stop observing for mutations
        return
      }
    })

    // Start observing the document with the configured parameters
    this.observer.observe(document.body, { childList: true, subtree: true })
  }

  manageDownEvent(event) {
    if (event.eventType === 'mousedown') {
      this.downEvent = event
    }
    if (event.eventType === 'mouseup') {
      this.downEvent = null
    }
  }

  /**
   * Cette fonction permet le traitement d'un événement fournit par le présentateur lors d'une session collaborative. En fonction du type d'événement, cette fonction va transmettre l'événement à la fonction chargé d'appliquer ce type d'événement au dom du spectateur
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {Store} refStore Il s'agit d'une référence du store vuex de l'application
   */
  async dispatchEvent(event, refStore) {
    this.refStore = refStore
    let target = (event.selector === 'window')
      ? window
      : document.querySelector(event.selector)
    
    if (!target) {
      return
    }
    
    this.manageDownEvent(event)

    switch (event.eventType) {
    case 'change':
      this.inputChange(event, target)
      break
    case 'input':
      this.inputChange(event, target)
      break
    case 'properties':
      this.setProperties(event.params, target)
      this.refStore.dispatch('ws/collaborativeEventTreated')
      break
    case 'wheel':
      this.wheel(event, target)
      break
    case 'resize':
      this.windowResize()
      break
    case 'checkScroll':
      this.checkScroll(event, target)
      this.refStore.dispatch('ws/collaborativeEventTreated')
      break
    case 'keydown':
    case 'keyup':
      this.manageKeyboard(event, target)
      break
    case 'custom':
      this.manageCustomEvent(event, target)
      break
    default:
      this.defaultManagement(event, target)
    }
  }

  /**
   * Cette fonction permet l'obtention d'options de base pour le constructeur MouseEvent
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @returns {Object} Objet contenant les options de base pour le constructeur MouseEvent
   */
  getBaseParamsMouseEvent(event) {
    const eventPosition = CircleUtility.eyePointCartesian(
      event.params.r,
      event.params.t,
      this.refStore.getters['layout/centerX'],
      this.refStore.getters['layout/centerY'],
      this.refStore.getters['layout/radius']
    )

    return {
      view: window,
      bubbles: true,
      cancelable: true,
      clientX: eventPosition.x,
      clientY: eventPosition.y,
      button: 0
    }
  }

  /**
   * Cette fonction permet la modifications de propriétés sur un élément du dom
   * @param {Object} properties Il s'agit des propriétés devant être altérées sur l'element du dom
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  setProperties(properties, target) {
    // Parcours récursif des propriétés devant être modifiées
    for (const [property, value] of Object.entries(properties)) {
      if (_.isPlainObject(value)) {
        this.setProperties(value, target[property])
      } else {
        target[property] = value
      }
    }
  }

  /**
   * Cette fonction permet l'emission d'événement de molette de souris / trackpad
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  wheel(event, target) {
    const eventToDispatch = new WheelEvent(event.eventType, {
      ...this.getBaseParamsMouseEvent(event),
      ...{
        deltaX: event.params.deltaX,
        deltaY: event.params.deltaY,
        deltaZ: event.params.deltaZ,
        deltaMode: event.params.deltaMode,
        ctrlKey: event.params.ctrlKey
      }
    })

    target.dispatchEvent(eventToDispatch);
    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }

  /**
   * Cette fonction permet l'emission d'événement d'entrée de texte sur un input
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  inputChange(event, target) {
    let eventToDispatch = null

    if (target.type !== 'file') {
      target.value = event.params.text
    }

    eventToDispatch = new Event('input', { 'bubbles': true })

    if (event.params.change) {
      //Dans le cas d'un change, on exécute quand même un événement input car pour les input de type text, vuejs à besoin de recevoir un event input pour mettre à jour le v-model
      target.dispatchEvent(eventToDispatch)
      eventToDispatch = new Event('change', { 'bubbles': true })
    }

    target.dispatchEvent(eventToDispatch)

    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }

  /**
   * Cette fonction permet l'emission d'événement custom
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  manageCustomEvent(event, target) {
    let eventToDispatch = new CustomEvent(event.customType, {detail: event.params})

    target.dispatchEvent(eventToDispatch)

    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }

  /**
   * Cette fonction permet l'emission d'événement de souris
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  async defaultManagement(event, target) {
    let eventToDispatch = null
    
    if (event.params) {
      eventToDispatch = new MouseEvent(event.eventType, this.getBaseParamsMouseEvent(event))
    } else {
      eventToDispatch = new MouseEvent(event.eventType, {
        bubbles: true,
        cancelable: true
      })
    }

    target.dispatchEvent(eventToDispatch)

    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }

  /**
   * Cette fonction permet l'emission d'événement du clavier
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  async manageKeyboard(event, target) {
    let eventToDispatch = null
    eventToDispatch = new KeyboardEvent(event.eventType, {
      key: event.params.key
    })

    target.dispatchEvent(eventToDispatch)

    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }

  /**
   * Cette fonction permet d'altérer la propriété scrollTop de la cible si la zone survolée par la souris du présentateur sur la cible n'est pas visible chez le spectateur
   * @param {EyeCollaborativeEvent} event Il s'agit de l'événement transmis par le présentateur de la session collaborative
   * @param {DOMElem} target Il s'agit de l'element du dom sur lequel l'événement doit s'appliquer
   */
  checkScroll(event, target) {
    const rect = target.getBoundingClientRect()

    if (event.params.offsetY > rect.height + target.scrollTop) {
      target.scrollTop = event.params.offsetY - rect.height
    }
    else if (event.params.offsetY < target.scrollTop) {
      target.scrollTop = event.params.offsetY
    }
  }

  /**
   * Cette fonction permet d'emettre un événement de redimensionnement de la fenêtre
   */
  windowResize() {
    const eventToDispatch = new Event('resize')
    window.dispatchEvent(eventToDispatch)

    if (!eventToDispatch.eyediag || eventToDispatch.eyediag.treated !== true) {
      this.refStore.dispatch('ws/collaborativeEventTreated')
    }
  }
}

export default new CollaborativeEventDispatcher()