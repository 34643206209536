/**
 * Définit les types d'événements disponible dans l'application et les sous catégorie pouvant servir de filtrages à l'intérieur de chaque type
 */
export const allergy = {
  'ALL': 'ALL'
}

export const condition = {
  'ALL': 'ALL'  
}

export const procedure = {
  'ALL': 'ALL',
  'DIAGNOSIS': 'DIAGNOSIS',
  'THERAPEUTIC': 'THERAPEUTIC'
}

export const immunization = {
  'ALL': 'ALL'
}

export const pmsiTypeDiag = {
  'DP': 'Principal',
  'SIGNIFICATIF': 'Significatif',
  'DOCUMENTAIRE': 'Documentaire',
  'RELIE': 'Relie'
}