import CircleUtility from "@/libraries/CircleUtility"
import circlesConfig from '@/config/circle'
import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import * as mutationTypes from '@/store/mutations-types.js'
import Circles from "@/libraries/Circles.js"
import loadingAnimation from '@/animation/loading.js'

/**
 * Cette classe permet la gestion de l'animation de démarrage de la représentation
 */

class IntroAnimation {
  constructor() {
    /**
     * Il s'agit de la référence de l'instance du composant Circles
     * @type {ComponentCircles}
     */
    this.refCircleComponent = null
    /**
     * Il s'agit de la référence de l'instance du composant RepCircle
     * @type {ComponentRepCircle}
     */
    this.refRepCircleComponent = null
    /**
     * Permet de déterminer si l'animation est active
     * @type {Boolean}
     */
    this.active = false
    /**
     * Détermine si les données ont finit d'être transmise du serveur vers le client
     * @type {Boolean}
     */
    this.dataAvailable = false
    /**
     * Permet de stocker la référence de la fonction permettant de revoquer le watcher qui surveille les modifications sur les cercles
     * @type {Function}
     */
    this.watchEnd = null
  }

  /**
   * Setter de la variable refCircleComponent
   * @param {ComponentCircles} refCircleComponent Référence de l'instance du composant Circles
   */
  setRefCircleComponent(refCircleComponent) {
    this.refCircleComponent = refCircleComponent
  }

  /**
   * Setter de la variable refRepCircleComponent
   * @param {ComponentRepCircle} refRepCircleComponent Référence de l'instance du composant RepCircle
   */
  setRefRepCircleComponent(refRepCircleComponent) {
    this.refRepCircleComponent = refRepCircleComponent
  }

  /**
   * Permet d'intialiser l'animation
   * @param {Store} store Référence du store vuex
   */
  async startAnimation(store) {
    if (!this.active) {
      this.refCircleComponent.removeWatcher()
      this.active = true;
      this.watchEnd = store.watch(() => store.getters['circle/circles'], () => {
        loadingAnimation.interruptAnimation()
        this.dataAvailable = true
      })
      if (!this.dataAvailable) {
        await loadingAnimation.waiting(store)
      }
      this.endLoadingAnimation(store)
    }
  }

  /**
   * Une fois les données obtenus depuis le serveur, c'est cette fonction se charge d'exécuter l'animation "goutter d'eau"
   * @param {Store} store Référence du store vuex
   */
  async endLoadingAnimation(store) {
    if (this.dataAvailable) {
      d3.selectAll('.repere-invisible-circle')
        .on("mouseover", null)
        .on("mouseleave", null)

      let sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], true))
      sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

      try {
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: false,
          animationTime: 0,
          transition: (selection) => selection
            .attr("class", "circle")
            .attr("d", (d) => d.path)
            .style("fill", "none")
            .style("stroke", (d) => circlesConfig[d.configIndexBounded].color)
            .style("stroke-width", (d) => circlesConfig[d.configIndexBounded].stroke)
            .style("opacity", 0)
        })
  
        d3.select("#repere-circle")
          .selectAll('path')
          .transition()
          .duration(D3Animation.ANIMATION_INTRO_NAVIGATION_CIRCLE_SHRINK)
          .style("opacity", 0.7)
          .attr('d', d => CircleUtility.generateArcNavCircle(store.state.layout.radius * 0.90 * store.state.layout.scale, d))
          .transition()
          .duration(D3Animation.ANIMATION_INTRO_NAVIGATION_CIRCLE_GROW)
          .attr('d', d => CircleUtility.generateArcNavCircle(store.state.layout.radius * 0.97 * store.state.layout.scale, d))
          .on('end', () => {
            d3.selectAll('.repere-invisible-circle')
              .on("mouseover", this.refRepCircleComponent.onMouseOver)
              .on("mouseleave", this.refRepCircleComponent.onMouseLeave)
          })
  
        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: false,
          animationTime: 0,
          transition: (selection) => {
            Circles.currentTransition = selection
              .filter((d) => d.circleData.configIndex === 2)
              .attr("transform", "scale(1.5)")
              .style("opacity", (d) => circlesConfig[d.configIndexBounded + 1].opacity)
              .style("stroke-width", (d) => circlesConfig[d.configIndexBounded + 1].stroke)
              .style("stroke", (d) => circlesConfig[d.configIndexBounded + 1].color)
              .transition()
              .duration(D3Animation.ANIMATION_INTRO_SMALLEST_CIRCLE_SHRINK)
              .attr("transform", "scale(0.80)")
              .style("opacity", (d) => circlesConfig[d.configIndexBounded].opacity)
              .style("stroke-width", (d) => circlesConfig[d.configIndexBounded].stroke)
              .style("stroke", (d) => circlesConfig[d.configIndexBounded].color)
              .transition()
              .duration(D3Animation.ANIMATION_INTRO_SMALLEST_CIRCLE_GROW)
              .attr("transform", "scale(1)")
          }
        })

        await Circles.displayCircles({
          paths: sectionsPath,
          interactions: false,
          animationTime: 0,
          transition: (selection) => {
            Circles.currentTransition = selection
              .filter((d) => CircleUtility.isVisibleCircle(d.circleData.configIndex) && d.circleData.configIndex !== 2)
              .attr("transform", "scale(0.95)")
              .style("stroke", (d) => (d.colorDoubleLine) ? d.colorDoubleLine.color : circlesConfig[d.configIndexBounded].color)
              .style("stroke-width", (d) => circlesConfig[d.configIndexBounded].stroke)
              .transition()
              .duration(D3Animation.ANIMATION_INTRO_OTHERS_CIRCLES_GROW)
              .delay((d) => 80 * d.configIndexBounded)
              .attr("transform", "scale(1)")
              .style("opacity", (d) => (d.colorDoubleLine) ? d.colorDoubleLine.opacity : circlesConfig[d.configIndexBounded].opacity)
          }
        })
      } catch(err) {
        console.error(err)
      }

      this.closeAnimation(store)
    }
  }

  closeAnimation(store) {
    this.active = false
    this.dataAvailable = false

    let sectionsPath = store.getters['circle/circles'].map((circle) => CircleUtility.getCirclePaths(circle, store.getters['layout/radius'], true))
    sectionsPath = _.reduce(sectionsPath, (sum, d) => [...sum, ...d], [])

    if (this.watchEnd !== null) {
      this.watchEnd()
      this.watchEnd = null
    }

    d3.select("#repere-circle")
      .selectAll('path')
      .attr('d', d => CircleUtility.generateArcNavCircle(store.state.layout.radius * 0.97 * store.state.layout.scale, d))
      .style("opacity", 0.7)

    d3.selectAll('.repere-invisible-circle')
      .on("mouseover", this.refRepCircleComponent.onMouseOver)
      .on("mouseleave", this.refRepCircleComponent.onMouseLeave)

    if (sectionsPath.length > 0) { //Si pas ce if, le fetch pour récupérer les données des cerces ne se résout jamais lorsque l'on fait très rapidement bouton retour du navigateur + selectionner un patient
      Circles.displayCircles({
        paths: sectionsPath,
        interactions: true,
        animationTime: 0,
      })
    }
    this.refCircleComponent.initWatchers()
    store.dispatch('circle/onCircleStopMove', null, {root: true})
    store.commit(mutationTypes.SET_LOADING_REPRESENTATION, false, { root: true })
  }

  interruptAnimation(store) {
    if (this.active) {
      Circles.interruptAnimation()
      d3.select("#repere-circle").selectAll('path').interrupt()
      this.closeAnimation(store)
    }
  }
}

export default new IntroAnimation()