<template>
  <div>
    <div class="tooltip-event-header">
      <p class="tooltip_title">
        {{ 'Classement' }}
      </p>
    </div>
    <div
      ref="tooltip-event-note-container"
      class="tooltip-event-note-container"
    >
      <a
        href="#"
        class="show-associated"
        @click="(e) => showAssociatedEventsCircle(e, tooltipData.ranks.length > 0 ? tooltipData.ranks[0] : {})"
      >
        Stats Associées
      </a>
      <div
        v-for="(ranking, index) in tooltipData.ranks"
        :key="index"
        class="tooltip-note"
      >
        <Collapse>
          <template #title>
            <p>
              {{ `${ranking.size} - ${ranking.code} - ${ranking.label}` }}
            </p>
          </template>
          <template #content>
            <Collapse>
              <template #title>
                <p>{{ linksId[ranking.code].rum.length }} - RUM</p>
              </template>
              <template #content>
                <div
                  v-for="(rumData, i) in linksId[ranking.code].rum"
                  :key="i"
                >
                  <a
                    href="#"
                    class="show-associated"
                    @click="(e) => focusRUM(e, rumData.RSS, rumData.RUM)"
                    @mouseover="(e) => onOverIdRUM(e, rumData.RSS, rumData.RUM)"
                    @mouseleave="(e) => onLeaveIdRUM(e)"
                  >
                    {{ rumData.RUM }} / {{ rumData.RSS }}
                  </a>
                  <br>
                </div>
              </template>
            </Collapse>
            <Collapse>
              <template #title>
                <p>{{ linksId[ranking.code].rss.length }} - RSS</p>
              </template>
              <template #content>
                <div
                  v-for="(rss, i) in linksId[ranking.code].rss"
                  :key="i"
                >
                  <a
                    href="#"
                    class="show-associated"
                    @mouseover="(e) => onOverIdRSS(e, rss)"
                    @mouseleave="(e) => onLeaveIdRSS(e)"
                    @click="(e) => focusRSS(e, rss)"
                  >
                    {{ rss }}
                  </a>
                  <br>
                </div>
              </template>
            </Collapse>
          </template>
        </Collapse>
      </div>
      <Collapse class="tooltip-note">
        <template #title>
          <p>Tableau</p>
        </template>
        <template #content>
          <TableEvents :tooltip-data="tooltipData" />
        </template>
      </Collapse>
      <Collapse class="tooltip-note">
        <template #title>
          <p>Tableau Global</p>
        </template>
        <template #content>
          <TableEvents />
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script>
import utils from '@/libraries/utils.js'
import * as d3 from 'd3'
import Collapse from '../Collapse.vue'
import { mapGetters, mapActions } from 'vuex'
import * as mutationTypes from "@/store/mutations-types.js"
import * as eyediagMode from "@/shared/enums/eyediagMode.js"
import _ from 'lodash'
import * as eventsTypes from "@/shared/enums/eventsTypes.js"
import TableEvents from "./Table.vue"

export default {
  name: 'TooltipContentNbEventPerSection',
  components: {
    Collapse,
    TableEvents
  },
  props: {
    /**
     * Contient les informations de l'événement dont le détail doit être affiché en tooltip
     * @type {EyeEvent}
     */
    tooltipData: {
      type: Object,
      required: true,
    }
  },
  emits: ['focusCohort'],
  data: () => ({
    linksId: {}
  }),
  computed: {
    ...mapGetters({
      events: "event/common/events",
    })
  },
  mounted() {
    this.getLinksId()
    this.initScrollEmitter()
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    async showAssociatedEventsCircle(event, eyediagEvent) {
      this.sendEvent({ event: event })
      event.preventDefault()
      this.$store.commit(`refCircle/score/${mutationTypes.DISABLE_SCORE}`, null)
      const promise = this.$store.dispatch('refCircle/associatedEvents/getAssociatedEvents', eyediagEvent, {root: true})
      this.$store.commit(`refCircle/associatedEvents/${mutationTypes.UPDATE_SRC_EVENT_ASSOCIATED}`, eyediagEvent)

      await promise
      this.collaborativeEventTreated()
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur de la tooltip puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['tooltip-event-note-container'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    },
    getLinksId() {
      this.tooltipData.ranks.forEach(rank => {
        const rums = []

        for (const event of rank.events) {
          if (event.extension.RUM) {
            for (const rum of event.extension.RUM.value) {
              rums.push({RSS: event.extension.RSS.value, RUM: rum})
            }
          }
        }

        if (rums.length > 0) {
          this.linksId[rank.code] = {
            rum: _.uniqWith(rums, (a, b) => a.RSS === b.RSS && a.RUM === b.RUM),
            rss: _.uniqBy(rank.events, d => d.extension.RSS.value).map(event => event.extension.RSS.value)
          }
        } else {
          this.linksId[rank.code] = { rum: [], rss: [] }
        }
      })
    },
    focusRUM(event, idRss, idRum) {
      this.$emit('focusCohort', event, {
        focusContent: eyediagMode.populationalOptions.focusContent.RUM,
        focusParams: {
          RSS: idRss,
          idRum: idRum,
        }
      })
    },
    focusRSS(event, rss) {
      this.$emit('focusCohort', event, {
        focusContent: eyediagMode.populationalOptions.focusContent.RSS,
        focusParams: {
          RSS: rss
        }
      })
    },
    onOverIdRUM(event, idRss, idRum) {
      this.sendEvent({ event: event })
      const src = this.events.find(e => e.id === this.tooltipData.id)
      let dest = this.events.filter(e => utils.differentEventOrManyLocation(this.tooltipData, e))
      dest = utils.searchEventsFromRanks(dest, (e) => e.extension.RSS.value === idRss && e.extension.RUM.value.includes(idRum))

      if (src) {
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
          src: src,
          dest: dest
        })
      }
      this.collaborativeEventTreated()
    },
    onLeaveIdRUM(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    },
    onOverIdRSS(event, idRss) {
      this.sendEvent({ event: event })
      const eventsRSS = utils.searchEventsFromRanks(this.events, (e) => e.extension.RSS.value === idRss)
      const dpEvent = utils.findEventFromRanks(eventsRSS, (e) => e.extension.typeDiagnostique?.value === eventsTypes.pmsiTypeDiag.DP)
      if (dpEvent) {
        this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
          src: dpEvent,
          dest: eventsRSS.filter(e => utils.differentEventOrManyLocation(dpEvent, e))
        })
      }
      this.collaborativeEventTreated()
    },
    onLeaveIdRSS(event) {
      this.sendEvent({ event: event })
      this.$store.commit(`event/common/${mutationTypes.UPDATE_LINKED_EVENTS}`, {
        src: {},
        dest: []
      })
      this.collaborativeEventTreated()
    }
  }
}

</script>

<style scoped>
.show-associated {
  color: var(--c-gray-1);
  font-size: 1.3rem;
}

</style>