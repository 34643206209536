export const list_pays = [
  "ALBANIE",
  "ALLEMAGNE",
  "ANDORRE",
  "ARUBA",
  "AUTRICHE",
  "BELGIQUE",
  "BIELORUSSIE",
  "BOSNIE-HERZEGOVINE",
  "BOUVET (ILE)",
  "BULGARIE",
  "CROATIE",
  "DANEMARK",
  "ESPAGNE",
  "ESTONIE",
  "MACEDOINE DU NORD",
  "FEROE (ILES)",
  "FINLANDE",
  "GIBRALTAR",
  "GRECE",
  "GUERNESEY",
  "HONGRIE",
  "IRLANDE, ou EIRE",
  "ISLANDE",
  "ITALIE",
  "JERSEY",
  "KOSOVO",
  "LETTONIE",
  "LIECHTENSTEIN",
  "LITUANIE",
  "LUXEMBOURG",
  "MALTE",
  "MAN (ILE)",
  "MOLDAVIE",
  "MONACO",
  "MONTENEGRO",
  "NORVEGE",
  "PAYS-BAS",
  "POLOGNE",
  "PORTUGAL",
  "REPUBLIQUE DEMOCRATIQUE ALLEMANDE",
  "REPUBLIQUE FEDERALE D'ALLEMAGNE",
  "ROUMANIE",
  "ROYAUME-UNI",
  "RUSSIE",
  "SAINT-MARIN",
  "SERBIE",
  "SLOVAQUIE",
  "SLOVENIE",
  "SUEDE",
  "SUISSE",
  "SVALBARD et ILE JAN MAYEN",
  "TCHECOSLOVAQUIE",
  "TCHEQUIE",
  "TURQUIE D'EUROPE",
  "UKRAINE",
  "VATICAN, ou SAINT-SIEGE",
  "AFGHANISTAN",
  "ARABIE SAOUDITE",
  "ARMENIE",
  "AZERBAIDJAN",
  "BAHREIN",
  "BANGLADESH",
  "BHOUTAN",
  "BIRMANIE",
  "BRUNEI",
  "CAMBODGE",
  "CHINE",
  "CHYPRE",
  "COREE",
  "COREE (REPUBLIQUE DE)",
  "COREE (REPUBLIQUE POPULAIRE DEMOCRATIQUE DE)",
  "EMIRATS ARABES UNIS",
  "ETATS MALAIS NON FEDERES",
  "GEORGIE",
  "GOA",
  "HONG-KONG",
  "INDE",
  "INDONESIE",
  "IRAN",
  "IRAQ",
  "ISRAEL",
  "JAPON",
  "JORDANIE",
  "KAMTCHATKA",
  "KAZAKHSTAN",
  "KIRGHIZISTAN",
  "KOWEIT",
  "LAOS",
  "LIBAN",
  "MACAO",
  "MALAISIE",
  "MALDIVES",
  "MANDCHOURIE",
  "MONGOLIE",
  "NEPAL",
  "OMAN",
  "OUZBEKISTAN",
  "PAKISTAN",
  "PALESTINE (Etat de)",
  "PHILIPPINES",
  "POSSESSIONS BRITANNIQUES AU PROCHE-ORIENT",
  "QATAR",
  "SIBERIE",
  "SINGAPOUR",
  "SRI LANKA",
  "SYRIE",
  "TADJIKISTAN",
  "TAIWAN",
  "THAILANDE",
  "TIMOR ORIENTAL",
  "TURKESTAN RUSSE",
  "TURKMENISTAN",
  "TURQUIE",
  "VIET NAM",
  "VIET NAM DU NORD",
  "VIET NAM DU SUD",
  "YEMEN",
  "YEMEN DEMOCRATIQUE",
  "YEMEN (REPUBLIQUE ARABE DU)",
  "ACORES, MADERE",
  "AFRIQUE DU SUD",
  "ALGERIE",
  "ANGOLA",
  "BENIN",
  "BOTSWANA",
  "BURKINA",
  "BURUNDI",
  "CAMEROUN",
  "CAMEROUN ET TOGO",
  "CANARIES (ILES)",
  "CAP-VERT",
  "CENTRAFRICAINE (REPUBLIQUE)",
  "COMORES",
  "CONGO",
  "CONGO (REPUBLIQUE DEMOCRATIQUE)",
  "COTE D'IVOIRE",
  "DJIBOUTI",
  "EGYPTE",
  "ERYTHREE",
  "ESWATINI",
  "ETHIOPIE",
  "GABON",
  "GAMBIE",
  "GHANA",
  "GUINEE",
  "GUINEE EQUATORIALE",
  "GUINEE-BISSAU",
  "ILES PORTUGAISES DE L'OCEAN INDIEN",
  "KENYA",
  "LESOTHO",
  "LIBERIA",
  "LIBYE",
  "MADAGASCAR",
  "MALAWI",
  "MALI",
  "MAROC",
  "MAURICE",
  "MAURITANIE",
  "MOZAMBIQUE",
  "NAMIBIE",
  "NIGER",
  "NIGERIA",
  "OCEAN INDIEN (TERRITOIRE BRITANNIQUE DE L')",
  "OUGANDA",
  "PRESIDES",
  "PROVINCES ESPAGNOLES D'AFRIQUE",
  "RWANDA",
  "SAHARA OCCIDENTAL",
  "SAINTE HELENE, ASCENSION ET TRISTAN DA CUNHA",
  "SAO TOME-ET-PRINCIPE",
  "SENEGAL",
  "SEYCHELLES",
  "SIERRA LEONE",
  "SOMALIE",
  "SOUDAN",
  "SOUDAN ANGLO-EGYPTIEN, KENYA, OUGANDA",
  "SOUDAN DU SUD",
  "TANGER",
  "TANZANIE",
  "TCHAD",
  "TOGO",
  "TUNISIE",
  "ZAMBIE",
  "ZANZIBAR",
  "ZIMBABWE",
  "ALASKA",
  "ANGUILLA",
  "ANTIGUA-ET-BARBUDA",
  "ANTILLES NEERLANDAISES",
  "ARGENTINE",
  "BAHAMAS",
  "BARBADE",
  "BELIZE",
  "BERMUDES",
  "BOLIVIE",
  "BONAIRE, SAINT EUSTACHE ET SABA",
  "BRESIL",
  "CAIMANES (ILES)",
  "CANADA",
  "CHILI",
  "COLOMBIE",
  "COSTA RICA",
  "CUBA",
  "CURAÇAO",
  "DOMINICAINE (REPUBLIQUE)",
  "DOMINIQUE",
  "EL SALVADOR",
  "EQUATEUR",
  "ETATS-UNIS",
  "GEORGIE DU SUD ET LES ILES SANDWICH DU SUD",
  "GRENADE",
  "GROENLAND",
  "GUATEMALA",
  "GUYANA",
  "HAITI",
  "HONDURAS",
  "JAMAIQUE",
  "LABRADOR",
  "MALOUINES, OU FALKLAND (ILES)",
  "MEXIQUE",
  "MONTSERRAT",
  "NICARAGUA",
  "PANAMA",
  "PARAGUAY",
  "PEROU",
  "PORTO RICO",
  "SAINT-CHRISTOPHE-ET-NIEVES",
  "SAINTE-LUCIE",
  "SAINT-MARTIN (PARTIE NEERLANDAISE)",
  "SAINT-VINCENT-ET-LES GRENADINES",
  "SURINAME",
  "TERR. DES ETATS-UNIS D'AMERIQUE EN AMERIQUE",
  "TERR. DU ROYAUME-UNI DANS L'ATLANTIQUE SUD",
  "TERRE-NEUVE",
  "TERRITOIRES DU ROYAUME-UNI AUX ANTILLES",
  "TRINITE-ET-TOBAGO",
  "TURKS ET CAIQUES (ILES)",
  "URUGUAY",
  "VENEZUELA",
  "VIERGES BRITANNIQUES (ILES)",
  "VIERGES DES ETATS-UNIS (ILES)",
  "AUSTRALIE",
  "CHRISTMAS (ILE)",
  "COCOS ou KEELING (ILES)",
  "COOK (ILES)",
  "FIDJI",
  "GUAM",
  "HAWAII (ILES)",
  "HEARD ET MACDONALD (ILES)",
  "KIRIBATI",
  "MARIANNES DU NORD (ILES)",
  "MARSHALL (ILES)",
  "MICRONESIE (ETATS FEDERES DE)",
  "NAURU",
  "NIUE",
  "NORFOLK (ILE)",
  "NOUVELLE-ZELANDE",
  "PALAOS (ILES)",
  "PAPOUASIE-NOUVELLE-GUINEE",
  "PITCAIRN (ILE)",
  "SALOMON (ILES)",
  "SAMOA AMERICAINES",
  "SAMOA OCCIDENTALES",
  "TERR. DES ETATS-UNIS D'AMERIQUE EN OCEANIE",
  "TOKELAU",
  "TONGA",
  "TUVALU",
  "VANUATU"
]