import diabete from "@/store/modules/medical/diabete.js"

export default {
  namespaced: true,
  modules: {
    diabete: diabete
  },
  getters: {},
  mutations: {},
  actions: {
    /**
     * Permet de reset par défaut le state de l'application. Cette fonction est utilise avant chaque accès à un nouveau patient pour remttre à 0 tout ce qui a pu être effectué sur l'application
     * @method
     * @public
     * @param {Context} context Contexte dans lequelle est appelé l'action vuex
     */
    async resetState(context) {
      const promises = []
      const modulesToReset = ['diabete']
      
      modulesToReset.forEach(module => {
        promises.push(context.dispatch(`medical/${module}/resetState`, null, {root: true}))
      })

      return Promise.all(promises) 
    }
  }
}