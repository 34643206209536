<template>
  <div style="font-size: 2rem; color: white;">
    <p>Une catégorie peut comporter des sous-catégories.</p>
    <p>Pour y accéder, il faut sélectionner la catégorie, puis valider avec le bouton bleu comme à l'étape précédente. La représentation se réarrange avec une vision propre et détaillée pour cette catégorie</p>
  </div>
</template>

<script>
export default {
  name: "Component11Tuto",
}
</script>

<style scoped>
p {
  padding-bottom: 10px;
}
</style>