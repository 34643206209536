<template>
  <baseTutoTile
    :id-patient="5"
    :id-tuto="3"
    :number-steps="numberSteps"
    :custom-handler="true"
    @launch="showDecouverteMenu"
  >
    <template #title>
      <h1>Découverte</h1>
    </template>
    <template #description>
      <p>- Qu'est-ce que EyeDiag ?</p>
      <p>- Pourquoi utiliser EyeDiag ?</p>
    </template>
    <template #backgroundImage>
      <img
        src="./decouverte.png"
        style="height: 35vh;"
      >
    </template>
  </baseTutoTile>
</template>

<script>
import baseTutoTile from '@/components/tutorial/viewComponents/baseTutoTile.vue'

export default {
  name: "Component2ViewTuto",
  components: {
    baseTutoTile
  },
  data: () => ({
    numberSteps: require.context(`@/components/tutorial/decouverte`, false, /\.(vue)$/).keys().length
  }),
  methods: {
    async showDecouverteMenu() {
      await this.$router.push("/TutorialDecouverte");
    }
  }
}
</script>

<style scoped>
.tutorielCase {
    background-color: rgb(255, 255, 255);
    border-radius: 5px;
    padding: 2vh;
    width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.tutorielLine {
    padding-bottom: 0vh;
}

.frontDisplay {
    position: relative;
    z-index: 2;
}

.backDisplay {
    position: absolute;
    z-index: 1;
}
.buttonContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.button {
    align-items: center;
    background-color: #689acc;
    border: 0;
    border-radius: 10px;
    color: #201e1eea;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    min-height: 40px;
    padding-left: 20px;
    padding-right: 20px;
    transition: background-color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, box-shadow 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s, color 0.167s cubic-bezier(0.4, 0, 0.2, 1) 0s;
}

.button:hover, .button-18:focus { 
    background-color: #3e6596;
    color: #ffffff;
}

.button:active {
    background: #09223b;
    color: rgb(255, 255, 255, .7);
}
</style>