<template>
  <div>
    <div
      id="LegendOption"
      @click="onClickLegendOption"
    >
      <LegendIcon
        width="30px"
        height="30px"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'LegendModeOption'
}
</script>

<script setup>
import { useStore } from 'vuex'
import LegendIcon from '@/assets/icons/legend.vue'
import BorderListTypes from '@/enums/borderList/borderListTypes'

const store = useStore()

async function onClickLegendOption(event) {
  store.dispatch('ws/sendEvent', {event: event})
  const listToEnabled = store.getters['borderList/isDisplayedList'] === false
    || store.getters['borderList/currentDisplayedBorderList'] !== BorderListTypes.LIST_LEGEND

  await store.dispatch('event/common/controlEvent',{isEnabled: listToEnabled, typeEventslist: BorderListTypes.LIST_LEGEND})
  store.dispatch('ws/collaborativeEventTreated')
}
</script>

<style scoped>
#LegendOption {
  cursor: pointer;
  width: 30px;
  height: 30px;
}
</style>