<template>
  <div>
    <div
      class="flex flex-row items-center cursor-pointer"
      @click="onClickHeader"
    >
      <div
        v-if="button"
        class="basis-0"
      >
        <span
          v-if="open"
          class="collapse-icon"
        >
          &#9660;
        </span>
        <span
          v-else
          class="collapse-icon"
        >
          &#9654;
        </span>
      </div>
  
      <div class="basis-0 grow">
        <slot
          name="title"
          :open="open"
        />
      </div>
    </div>
    <div
      v-if="open"
      :style="`margin-left: ${contentMarginRight}px;padding-bottom: ${contentPaddingBottom}px`"
    >
      <slot name="content" />
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'EyeCollapse',
  props: {
    button: {
      type: Boolean,
      default: true
    },
    contentMarginRight: {
      type: Number,
      default: 25
    },
    contentPaddingBottom: {
      type: Number,
      default: 0
    }
  },
  data: () => ({
    open: false
  }),
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    onClickHeader(event) {
      this.sendEvent({
        event: event
      })
      this.open = !this.open
      this.collaborativeEventTreated()
    }
  }
}
</script>

<style scoped>
.collapse-icon {
  width: 20px;
  display: block;
}
</style>