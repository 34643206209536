<template>
  <baseTutorial
    :curr-index="currIndex"
    :nb-components="nbComponents"
    @change-component="changeComponent"
  >
    <component :is="currentComponent" />
  </baseTutorial>
</template>

<script>
import Component1  from '@/components/tutorial/score/Component1.vue'
import Component2  from '@/components/tutorial/score/Component2.vue'
import Component3  from '@/components/tutorial/score/Component3.vue'
import Component4  from '@/components/tutorial/score/Component4.vue'
import Component5  from '@/components/tutorial/score/Component5.vue'
import Component6  from '@/components/tutorial/score/Component6.vue'
import baseTutorial from '@/components/tutorial/baseTutorial.vue'

export default {
  name: "ScoreTutorialName",
  components: {
    Component1,
    Component2,
    Component3,
    Component4,
    Component5,
    Component6,
    baseTutorial
  },
  data() {
    return {
      currentComponent: 'Component1',
      currIndex: 0,
      allComponents: ['Component1', 'Component2', 'Component3', 'Component4', 'Component5', 'Component6'],
      nbComponents: 0
    };
  },
  created() {
    this.nbComponents = this.allComponents.length
  },
  methods: {
    changeComponent(itt) {
      this.currIndex += itt
      if (this.currIndex >= this.allComponents.length)
        this.currIndex = 0
      if (this.currIndex < 0)
        this.currIndex = 0
      this.currentComponent = this.allComponents[this.currIndex]
    }
  }
}
</script>