<template>
  <div class="flex grow">
    <DragAndDrop
      v-if="ui[0].value"
      @next="handleUI('form')"
    />
    <div
      v-if="ui[1].value"
      class="flex grow flex-col"
    >
      <EyeForm
        ref="universal-config-form"
      />
      <div
        v-if="defaultStepButton"
        class="flex justify-end"
      >
        <div class="container-design-button">
          <DesignButton
            text="Valider"
            @clicked="onValidateConfig"
          />
        </div>
      </div>
    </div>
    <div
      v-if="ui[2].value"
      class="flex grow flex-col"
    >
      <OrderAlphaSeverity
        ref="universal-order-severity"
        :severity-choices="severityChoices"
      />
      <div
        v-if="defaultStepButton"
        class="flex justify-end"
      >
        <div class="container-design-button">
          <DesignButton
            text="Valider"
            @clicked="onValidateOrderedSeverity"
          />
        </div>
      </div>
    </div>
    <div
      v-if="ui[3].value"
      class="flex grow flex-col"
    >
      <Merge
        ref="universal-merge-form"
        :columns="columns"
      />
      <div
        v-if="defaultStepButton"
        class="flex justify-end"
      >
        <div class="container-design-button">
          <DesignButton
            text="Valider"
            @clicked="onValidateMergedSections"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/universal/DragAndDrop.vue"
import EyeForm from "@/components/universal/Form.vue"
import Merge from "@/components/universal/Merge.vue"
import OrderAlphaSeverity from '@/components/universal/OrderAlphaSeverity.vue'
import {mapGetters, mapActions} from "vuex"
import EyeFetch from "@/libraries/EyeFetch"
import DesignButton from "@/components/DesignButton.vue"
import Universal from "@/libraries/Universal.js"
import * as mutationTypes from "@/store/mutations-types.js"

export default {
  name: "UniversalSteps",
  components: {
    DragAndDrop,
    EyeForm,
    Merge,
    DesignButton,
    OrderAlphaSeverity
  },
  props: {
    importFile: {
      type: Boolean,
      default: true
    },
    defaultStepButton: {
      type: Boolean,
      default: true
    }
  },
  emits: ['changeStep'],
  data() {
    return {
      severityChoices: [],
      columns: null,
      ui: [
        { name: "drag", value: false },
        { name: "form", value: false },
        { name: "orderSeverity", value: false},
        { name: "merge", value: false }
      ],
    };
  },
  computed: {
    ...mapGetters({
      asSection: "asSection"
    })
  },
  mounted() {
    const idx = this.importFile === true ? 0 : 1
    this.ui[idx].value = true
  },
  methods: {
    ...mapActions({
      sendEvent: "ws/sendEvent",
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    async getSections() {
      try {
        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/universal/raw-sections`, {
          method: 'GET',
          credentials: 'include'
        })
        this.columns = await response.json()
        this.columns.sort()
      } catch (e) {
        console.error(e)
      }
    },
    async getSeverityChoices() {
      try {
        let response = await EyeFetch(this.$store, `${process.env.VUE_APP_SERVER_BASE_URL}/universal/alpha-severity`, {
          method: 'GET',
          credentials: 'include'
        })
        response = await response.json()
        this.severityChoices = response.severityChoices
      } catch (e) {
        console.error(e)
      }
    },
    async handleUI(actionName) {
      if (actionName === 'merge') {
        await this.getSections()
      } else if (actionName === 'orderSeverity') {
        await this.getSeverityChoices()
      }

      for (const item of this.ui) {
        item.value = item.name === actionName
      }
      this.$emit('changeStep', actionName)
    },
    async onValidateConfig(event) {
      const config = this.$refs['universal-config-form'].getConfig()
      this.$store.commit(mutationTypes.SET_AS_SECTION, config.asSection)

      if (event.isTrusted) {
        await Universal.sendConfig(this.$store, config, {
          makeRequest: true
        })
      }

      this.sendEvent({ event: event })

      this.handleUI(config.typeSeverity === 'numeric' ? 'merge' : 'orderSeverity')
      this.collaborativeEventTreated()
    },
    onValidateMergedSections(event) {
      this.$refs['universal-merge-form'].sendSections(event)
      this.handleUI('form')
    },
    async onValidateOrderedSeverity(event) {
      await this.$refs['universal-order-severity'].sendOrderedSeverity(event)
      this.handleUI('merge')
    }
  },
}
</script>

<style scoped>
.column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  flex-wrap: wrap;
}
.row {
  display: flex;
  flex-direction: row;
}
ul {
  background: white;
  list-style-type: none;
}
li {
  outline: rgb(127, 83, 83) solid 4px;
  margin: 5px;
}
</style>