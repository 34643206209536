<template>
  <ListCollapsableItem>
    <template #title>
      <p class="font-bold">
        Filtrage par code
      </p>
    </template>
    <template #content>
      <div class="flex flex-col mt-3">
        <label>Type de recherche</label> 
        <div class="flex flex-row mt-3">
          <div
            v-for="(searchOperatorOption, index) in searchOperatorOptions"
            :key="index"
            class="mr-1"
          >
            <input
              :id="searchOperatorOption.id"
              v-model="searchOperator"
              class="filtre-button"
              type="radio"
              :value="searchOperatorOption.value"
              @change="e => onInput(e, searchOperator, true)"
            >
            <label
              :for="searchOperatorOption.id"
              class="filtre-button-label"
            >
              {{ searchOperatorOption.label }}
            </label>
          </div>
        </div>

        <div
          v-if="searchOperator != ''"
          class="mt-3"
        >
          <label>Type de diagnostiques</label>
          <div class="flex items-center mt-1">
            <button
              v-for="option in domain.filter(item => item.ordre != 0)"
              :key="option.value"
              class="mr-1"
              :class="{ selected: isSelected(option), 'filtre-button-label': true }"
              @click="(e) => selectOption(e, option)"
            >
              {{ option.label_court }}
            </button>
          </div>

          <div class="ml-4 mt-2">
            <div 
              v-for="option in selectedDomain.sort((a, b) => a.ordre - b.ordre)"
              :key="option.value"
              class="flex grow flex-col mt-2"
            >
              <label>{{ option.label }}</label>
              <input
                v-model="search[option.value]"
                class="flex grow flex-col mt-1"
                placeholder="I10;J44.9"
                @input="e => onInput(e, search[option.value])"
              >
            </div>
          </div>
        </div>

        <div
          v-else
          class="flex flex-col grow mt-3"
        >
          <label>{{ domain[0].label }}</label>
          <input
            v-model="search.all"
            placeholder="I10;J44.9"
            class="mt-1"
            @input="e => onInput(e, search.all)"
          >
        </div>
      </div>

      <div class="flex flex-col mt-3">
        <label>Résultats</label>
        <select
          v-model="selectedTypeResult"
          class="mt-1"
          @input="onSelect"
        >
          <option
            v-for="(elem, index) in typeResult"
            :key="index"
            :value="elem.value"
          >
            {{ elem.label }}
          </option>
        </select>
      </div>

      <!-- PAS IMPLEMENTE DANS TTT -->
      <!-- <div class="flex flex-col mt-6">
        <label>Codes Retours</label>
        <input
          v-model="codes_retours"
          @input="onInput"
        >
      </div> -->

      <!-- <div class="flex flex-col mt-6">
        <label>Evenements liés au code</label>
        <input
          v-model="testCodeCohort"
          placeholder="code"
          @input="onInputCodeCohort"
        >
      </div> -->
    </template>
  </ListCollapsableItem>
</template>

<script>
import ListCollapsableItem from '@/components/borderLists/CollapsableItem.vue'
import { mapActions } from 'vuex'

function clean_str(str) {
  return str.length != "" ? str.toUpperCase().replace(/[\s,\n-]+/g, ';') : undefined
}

function formatCodesRetours(input) {
  const numericSubstrings = input.match(/\d+/g) || [];

  const formattedSubstrings = numericSubstrings.map((substring) => {
    const length = Math.max(Math.min(substring.length, 3), 3);
    return substring.padStart(length, '0').substring(0, 3);
  });

  return formattedSubstrings.join('');
}

/**
 * code Filter
 */
export default {
  name: 'FiltersPopulationalCode',
  components: {
    ListCollapsableItem
  },
  /**
   * @slot data
   */
  data: () => ({
    searchOperator: '',
    searchOperatorOptions: [
      {
        id: 'searchOperatorTous',
        value: '',
        label: 'Tous'
      },
      {
        id: 'searchOperatorOr',
        value: 'OR',
        label: 'Ou'
      },
      {
        id: 'searchOperatorAnd',
        value: 'AND',
        label: 'Et'
      }
    ],
    codes_retours: '',
    search: { all: "", dp: "", rel: "", DS: "", doc: "" },

    selectedDomain: [],

    selectedTypeResult: 'strict',
    typeResult: [
      {
        label: 'Recherche uniquement',
        value: 'strict'
      },
      {
        label: 'RUM associé',
        value: 'rum'
      },
      {
        label: 'RSS associé',
        value: 'rss'
      }
    ],
    domain: [
      {
        label: 'Diagnostiques',
        label_court: 'Tous',
        value: 'all',
        ordre: 0
      },
      {
        label: 'Diagnostiques principaux',
        label_court: 'DP',
        value: 'dp',
        ordre: 1
      },
      {
        label: 'Diagnostiques reliés',
        label_court: 'DR',
        value: 'rel',
        ordre: 2
      },
      {
        label: 'Diagnostiques significatifs',
        label_court: 'signif.',
        value: 'DS',
        ordre: 3
      },
      {
        label: 'Diagnostiques documentaires', 
        label_court: 'doc.',
        value: 'doc',
        ordre: 4
      }
    ]
  }),
  computed: {
    hasSeparators() {
      const conditions = [" ", ",", ";", "-"];
      return conditions.some(condition => this.search.includes(condition));
    },
  },
  methods: {
    ...mapActions({
      sendEvent: 'ws/sendEvent',
      collaborativeEventTreated: 'ws/collaborativeEventTreated'
    }),
    /**
     * @public
     */
    getFilters() {
      const filters = {}
      if (this.selectedTypeResult === 'rss') {
        filters.parRSS = 'true'
      }
      if (this.selectedTypeResult === 'strict') {
        filters.filtre_diag_strict = 'true'
      }

      this.searchOperator == "OR" ? filters.or_diag = "True" : null

      const domain_val = this.selectedDomain.map(x=>x.value)
      if (this.searchOperator == '' && this.search.all != '') {
        filters.filtre_diag_ALL = clean_str(this.search.all)
      }
      if (this.searchOperator != '' && domain_val.includes('dp')  && this.search.dp  != '') {
        filters.filtre_diag_ppl = clean_str(this.search.dp)
      }
      if (this.searchOperator != '' &&  domain_val.includes('rel') && this.search.rel != '') {
        filters.filtre_diag_2r = clean_str(this.search.rel)
      }
      if (this.searchOperator != '' &&  domain_val.includes('DS')  && this.search.DS  != '') {
        filters.filtre_diag_sign = clean_str(this.search.DS)
      }
      if (this.searchOperator != '' && domain_val.includes('doc') && this.search.doc != '') {
        filters.filtre_diag_doc = clean_str(this.search.doc)
      }
      if (this.codes_retours.length > 0) {
        filters.codes_retours = formatCodesRetours(this.codes_retours)
      }

      return filters
    },
    /**
     * @public
     * @param {event} e
     * @param {String} text
     * @param {Boolean} isRadio
     */
    onInput(e, text, isRadio = false) {
      e.preventDefault()
      e.stopPropagation()
      this.sendEvent({
        event: e,
        params: {
          text: text,
          change: isRadio === true
        }
      })
      this.collaborativeEventTreated()
    },
    /**
     * @public
     * @param{event}
     */
    onSelect(e) {
      e.preventDefault()
      e.stopPropagation()
      this.sendEvent({
        event: e,
        params: {
          text: e.target.value,
          change: true
        }
      })
      this.collaborativeEventTreated()
    },
    selectOption(event, option) {
      this.sendEvent({event: event})
      if (option.value === 'all') {
        this.selectedDomain = [option];
      } else {
        if (this.selectedDomain.map(x=>x.value).includes('all')) {
          this.selectedDomain = [option];
        } 
        else {
          if (this.selectedDomain.includes(option)) {
            this.selectedDomain = this.selectedDomain.filter((item) => item !== option);
          } else {
            this.selectedDomain = this.selectedDomain.concat(option);
          }
        }
      }
      this.collaborativeEventTreated()
    },
    isSelected(option) {
      return this.selectedDomain.includes(option);
    },
  },
}
</script>

<style scoped>
.filtre-button-group {
  display: flex;
  align-items: center;
  margin: 10px 0;
}

.filtre-button {
  display: none;
}

.filtre-button-label {
  border: 1px solid var(--color-border);
  padding: 2px 4px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: var(--color-text) ;
  font-weight: 100;
  font-size: 1.2rem;
  font-family: var(--font-secondary-1);
  background-color: var(--color-bg-0);
}

.selected {
  background-color: var(--color-border) !important;
  font-weight: bold  !important;
}

.filtre-button:checked + .filtre-button-label {
  background-color: var(--color-border) !important;
  font-weight: bold  !important;

}
</style>