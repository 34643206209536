<template>
  <div>
    <Transition name="boxArrow">
      <BoxArrowIcon
        v-if="configSaveButton !== null"
        id="nurse-entry-save-button"
        class="save-button cursor-pointer"
        :style="`top: ${configSaveButton.top}px`"
        @mouseover="onMouseHoverSaveButton"
        @mouseleave="onMouseLeaveSaveButton"
        @click="onClickSaveButton"
      />
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'ListNurseEntryOptions'
}
</script>

<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'
import BoxArrowIcon from '@/assets/icons/boxArrow.vue'
import * as mutationTypes from '@/store/mutations-types.js'

const store = useStore()

const configSaveButton = computed(() => store.getters['formEntry/configSaveButton'])

function onMouseHoverSaveButton(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`formEntry/${mutationTypes.SET_HOVERED_SAVE_BUTTON}`, true)
  store.dispatch('ws/collaborativeEventTreated')
}

function onMouseLeaveSaveButton(event) {
  store.dispatch('ws/sendEvent', { event: event })
  store.commit(`formEntry/${mutationTypes.SET_HOVERED_SAVE_BUTTON}`, false)
  store.dispatch('ws/collaborativeEventTreated')
}

function onClickSaveButton(event) {
  //sendEvent inutile, la fonction onSaveData du composant TooltipForm posséde son propre sendEvent et collaborativeEventTreated qui vont utiliser l'event fournit avec le commit juste en dessous
  store.commit(`formEntry/${mutationTypes.SET_SAVING_FIELDS_EVENT}`, event)
}
</script>

<style scoped>
.save-button {
  position: absolute;
  right: 5px;
  fill: red;
  transform: rotate(180deg);
  width: 25px;
  height: 25px;
  transition: top 0.5s ease;
  padding: 2px;
  background-color: var(--color-bg-0);
}

.boxArrow-enter-from, .boxArrow-leave-to {
  opacity: 0;
}

.boxArrow-enter-to, .boxArrow-leave-from {
  opacity: 1;
}

.boxArrow-enter-active, .boxArrow-leave-active {
  transition: all 0.3s ease;
}
</style>