<template>
  <div
    ref="cursor"
    class="cursor"
  />
</template>

<script>
import * as d3 from "d3";

/**
 * Ce composant permet l'affichage du curseur du présentateur d'une session collaborative
 * @displayName Composant CollaborativeViewerLayer
 */

export default {
  name: "MyCursor",
  computed: {
    /**
     * Contient les coordonnées x et y du curseur du présentateur lors d'une session collaborative
     * @type {EyeCoordinate}
     */
    cursorPosition() {
      return this.$store.state.layout.cursorPosition
    }
  },
  watch: {
    cursorPosition() {
      d3.select(this.$refs.cursor)
        .style('top', `${this.cursorPosition.y - 7}px`)
        .style('left', `${this.cursorPosition.x - 7}px`)
    }
  }
};
</script>

<style>
.cursor {
  position: absolute;
  border-radius: 20px;
  top: 120px;
  left: 100px;
  background-color: var(--c-orange);
  width: 15px;
  height: 15px;
  z-index: 10;
}
</style>