<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 32.5"
  >
    <g>
      <path
        class="st3"
        d="M27.6,20.5V7.5c0-2.2-1.8-3.9-3.9-3.9H7.5c-2.2,0-3.9,1.8-3.9,3.9V25c0,2.2,1.8,3.9,3.9,3.9h11.7L27.6,20.5z"
      />
      <g>
        <g>
          <path
            class="st11"
            d="M27.6,20.6h-4.5c-2.2,0-3.9,1.8-3.9,3.9V29"
          />
          <path
            class="st11"
            d="M23.7,3.6H7.5c-2.2,0-3.9,1.8-3.9,3.9v17.6c0,2.2,1.8,3.9,3.9,3.9h11.7v-4.5c0-2.2,1.8-3.9,3.9-3.9h4.5V7.5C27.6,5.4,25.8,3.6,23.7,3.6z"
          />
        </g>
        <line
          class="st11"
          x1="19.2"
          y1="29"
          x2="27.6"
          y2="20.6"
        />
      </g>
      <g>
        <line
          class="st12"
          x1="34.2"
          y1="4.2"
          x2="34.2"
          y2="10.1"
        />
        <line
          class="st12"
          x1="31.2"
          y1="7.2"
          x2="37.1"
          y2="7.2"
        />
      </g>
    </g>
  </svg>
</template>

<script>

</script>

<style scoped>
.st3 {
  fill: var(--color-bg-0);
  opacity: 0.7;
}

.st11 {
  fill: none;
  stroke: #ff8100;
  stroke-width: 0.9921;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}

.st12 {
  fill: none;
  stroke: #ff8100;
  stroke-width: 1.1339;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
}
</style>