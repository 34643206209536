import { io } from "socket.io-client";
import CssSelector from '../../libraries/CssSelector.js'
import CircleUtility from "@/libraries/CircleUtility.js"
import * as mutationTypes from '../mutations-types.js'
import collaborativeEventDispatcher from "@/libraries/collaborative/CollaborativeEventDispatcher.js";
import * as d3 from 'd3'
import utils from "@/libraries/utils.js"
import reloadDataTypes from '@/shared/enums/reload_data_types.js'

export default {
  namespaced: true,
  state: {
    /** Position de la souris du présentateur en mode collaboratif
     * @type {EyeCoordinates}
     */
    cursorPosition: {x: 10, y: 10},
    /** Permet de déterminer si l'utilisateur est en mode collaboratif
     * @type {Boolean}
     */
    inCollaboration: false,
    /** En mode collaboratif si la valeur de viewer est à true c'est qu'il s'agit d'un spectateur
     * @type {Boolean}
     */
    viewer: false,
    /** Indique si le curseur du présentateur doit être affiché
     * @type {Boolean}
     */
    showCursor: false,
    /** socket de l'utilisateur (objet de socket.io)
     * @type {Socket}
     */
    socket: null,
    /** Identifiant de la session collaborative dans laquelle l'utilisateur se trouve
     * @type {String}
     */
    roomId:"",
    /**
     * Tableau contenant la liste des participants à la session collaborative
     * @type {EyeCollabParticipant[]}
     */
    roomParticipants: [],
    /** Détermine l'état d'affichage des modal lié au mode collaboratif
     * @type {Object} Boolean
     */
    modalState: {
      sessionCreation: false, //Modal création de session collaborative
      sessionJoin: false //Modal pour joindre une session collaborative
    },
    visio: {
      /** Détermine si la visio est active
       * @type {Boolean}
       */
      enable: false,
      /** Contient les objets des périphériques qui seront utilisées pour la visio 
       * @type {
       *  MediaDeviceInfo
       * }
       */
      devices: {
        audio: null,
        video: null
      }
    }
  },
  getters: {
    isInCollaboration: state => state.inCollaboration,
    isViewer: state => state.viewer,
    roomId: state => state.roomId,
    stateModalSessionJoin: state => state.modalState.sessionJoin,
    stateModalSessionCreation: state => state.modalState.sessionCreation,
    wsSocket: state => state.socket,
    visioConfig: state => state.visio,
    roomParticipants: state => state.roomParticipants
  },
  mutations: {
    /**
     * Permet de définir l'identifiant de la session collaborative
     * @param {String} payload Identifiant de la session collaborative
     * @method
     * @public
     */
    [mutationTypes.UPDATE_ROOM_ID](state, payload){
      state.roomId = payload
    },
    /**
     * Permet de définir si l'utilisateur est en session collaborative
     * @param {Boolean} payload Booléen déterminant si l'utilisateur est en session collaborative
     * @method
     * @public
     */
    [mutationTypes.UPDATE_COLLABORATION_STATUS](state, payload){
      state.inCollaboration = payload
    },
    /**
     * Permet de définir si la modal permettant de rejoindre une session doit-être ouverte ou non
     * @param {Boolean} payload Booléen déterminant l'état d'ouverture de la modal 
     * @method
     * @public
     */
    [mutationTypes.SET_STATE_MODAL_SESSION_JOIN](state, payload){
      state.modalState.sessionJoin = payload
    },
    /**
     * Permet de définir en session collaborative si le curseur du présentateur doit être affiché
     * @param {Boolean} payload Booléen déterminant l'état d'affichage du curseur 
     * @method
     * @public
     */
    [mutationTypes.SET_DISPLAY_CURSOR] (state, payload) {
      state.showCursor = payload
    },
    /**
     * Permet de définir si la modal permettant de créer une session collaborative doit être affiché
     * @param {Boolean} payload Booléen déterminant l'état d'ouverture de la modal
     * @method
     * @public
     */
    [mutationTypes.SET_STATE_MODAL_SESSION_CREATION] (state, payload) {
      state.modalState.sessionCreation = payload
    },
    /**
     * Permet de définir le périphérique audio qui sera utilisé pour la visio
     * @param {MediaDeviceInfo} payload Objet décrivant la périphérique audio
     * @method
     * @public
     */
    [mutationTypes.SET_VISIO_AUDIO_DEVICE] (state, payload) {
      state.visio.devices.audio = payload
    },
    /**
     * Permet de définir le périphérique video qui sera utilisé pour la visio
     * @param {MediaDeviceInfo} payload Objet décrivant la périphérique video
     * @method
     * @public
     */
    [mutationTypes.SET_VISIO_VIDEO_DEVICE] (state, payload) {
      state.visio.devices.video = payload
    },
    /**
     * Permet de définir l'état de la visio
     * @param {Boolean} payload Booléen indiquant si la visio doit être activé ou non
     * @method
     * @public
     */
    [mutationTypes.SET_VISIO_STATE] (state, payload) {
      state.visio.enable = payload
    },
    /**
     * Permet de définir les participants présents dans la session collaborative
     * @param {EyeCollabParticipant[]} payload Liste des participants à la session collaborative
     * @method
     * @public
     */
    [mutationTypes.SET_ROOM_PARTICIPANTS] (state, payload) {
      state.roomParticipants = payload
    }
  },
  actions: {
    /**
     * Permet d'initialiser la liaison websocket via socketIo avec le serveur
     * @method
     * @public
     */
    initWs(context) {
      const socket = io(process.env.VUE_APP_SERVER_BASE_URL.split('/api')[0], {
        path: '/api/socket.io/',
        transports: [ "websocket" ]
      })

      let wsConnectError = (error) => {
        const notification = {
          id: Date.now(),
          type: 'error',
          message: 'La connexion aux fonctionnalités collaboratives a échouée. Les options de collaboration ne sont pas disponibles.',
          timeout: 2* 60 * 1000 // 2 minutes
        }
        context.commit(`notification/${mutationTypes.ADD_NOTIFICATION}`, notification, { root: true })
        //appeler la fonction qu'une fois, car erreur de connexion en boucle sinon
        socket.off('connect_error', wsConnectError)
        socket.off('connect_timeout', wsConnectError)
        socket.off('error', wsConnectError)
      }

      socket.on('connect_error', wsConnectError)
      socket.on('connect_timeout', wsConnectError)
      socket.on('error', wsConnectError)

      //other socket.on could be in components
      socket.on('collaborative:exit', ({amICreator}) => {
        context.state.inCollaboration = false
        context.state.viewer = false
        context.commit(mutationTypes.SET_DISPLAY_CURSOR, false)
        if (amICreator === false) {
          context.dispatch('record/record/startRecording', null, {root: true})
        }
      })
      socket.on('collaborative:viewer', ({roomId}) => {
        context.commit(mutationTypes.SET_DISPLAY_CURSOR, true)
        context.commit(mutationTypes.UPDATE_COLLABORATION_STATUS, true)
        context.commit(mutationTypes.UPDATE_ROOM_ID, roomId)
        context.state.viewer = true
        d3.select(window).on("mousemove", null)
        //Réactualisation des assemblages avec ceux du nouveau présentateur
        context.dispatch('assembly/getListUserAssemblies', null, { root: true })
        collaborativeEventDispatcher.resetEventQueue()
      })
      socket.on('collaborative:presenter', () => {
        context.commit(mutationTypes.SET_DISPLAY_CURSOR, false)
        context.state.viewer = false
        d3.select(window).on("mousemove", (event) => {
          const svgContainer = document.getElementById('representation-svg')
          if (svgContainer && svgContainer.contains(event.target)) {
            context.dispatch("sendPresenterCursor", {
              cursorPosition: CircleUtility.eyePointRadial(
                event.clientX,
                event.clientY,
                context.rootState.layout.centerX,
                context.rootState.layout.centerY,
                context.rootState.layout.radius
              )
            })
          } else {
            context.dispatch("sendPresenterCursor", {
              selector: CssSelector.DOMPresentationUtils.cssPath(event.target),
              offsetX: event.offsetX,
              offsetY: event.offsetY
            })
          }
        })
        //Réactualisation des assemblages avec ceux du nouveau présentateur
        context.dispatch('assembly/getListUserAssemblies', null, { root: true })
      })
      socket.on('collaborative:movePresenterCursor', (presenterPosition) => {
        context.dispatch('onCollaborativeMovePresenterCursor', presenterPosition)
      })
      socket.on('collaborative:askState', async (data) => {
        context.dispatch('sendPresenterState', data)
      })
      socket.on('collaborative:event', (event) => {
        context.dispatch('onCollaborativeEvent', event)
      })
      socket.on('collaborative:stateTransfer', (data) => {
        context.dispatch('onPresenterStateTransfer', data)
      })
      socket.on("collaborative:updateRoomListParticipant", (data) => {
        context.commit(mutationTypes.SET_ROOM_PARTICIPANTS, data.participants)
      })
      socket.on("authentified", () => {
        context.dispatch('record/record/startRecording', null, {root: true})
      })
      socket.on('debug:joinRoom', () => {
        context.dispatch('record/record/stopRecording', null, { root: true })
      })
      context.state.socket = socket
    },
    onCollaborativeMovePresenterCursor(context, presenterPosition) {
      if (presenterPosition.cursorPosition) {
        context.rootState.layout.cursorPosition = CircleUtility.eyePointCartesian(
          presenterPosition.cursorPosition.r,
          presenterPosition.cursorPosition.t,
          context.rootState.layout.centerX,
          context.rootState.layout.centerY,
          context.rootState.layout.radius
        )
      } else {
        const element = document.querySelector(presenterPosition.selector)
        if (element) {
          const rectElement = element.getBoundingClientRect()
          context.rootState.layout.cursorPosition = {
            x: rectElement.left + presenterPosition.offsetX,
            y: rectElement.top + presenterPosition.offsetY
          }
        }
      }
      context.dispatch('sendPresenterCursor', presenterPosition)
    },
    onCollaborativeEvent(context, event) {
      if (context.state.showCursor === true) {
        collaborativeEventDispatcher.addElementEventQueue(event, this)
      }
    },
    async onPresenterStateTransfer(context, {store, reload}) {
      await context.dispatch('resetState', null, {root: true})
      await context.dispatch('stateInitCollab', {store, reload}, {root: true})
      await context.dispatch('event/common/generateEvents', null, {root: true})
      if (collaborativeEventDispatcher.downEvent) {
        collaborativeEventDispatcher.eventQueue.unshift(collaborativeEventDispatcher.downEvent)
      }
      utils.onGlobalAnimationEnd(this, () => {
        collaborativeEventDispatcher.askState = false
        collaborativeEventDispatcher.treatNextQueueEvent(this)
      })
    },
    /**
     * Permet d'avertir le serveur que l'on souhaite rejoindre une session collaborative
     * @param {String} roomId Identifiant de la session collaborative que l'utilisateur souhaite rejoindre
     * @method
     * @public
     */
    joinRoom(context, roomId) {
      context.dispatch('record/record/stopRecording', null, { root: true })
      collaborativeEventDispatcher.askState = true
      context.state.socket.emit('collaborative:join', {roomId})
    },
    /**
     * Permet d'avertir le serveur que l'on souhaite quitter la session collaborative en cours
     * @method
     * @public
     */
    leaveRoom(context) {
      const roomCreatorData = context.getters.roomParticipants.find(participant => participant.isCreator)

      context.state.socket.emit('collaborative:leave', {roomId: context.state.roomId})
      context.commit(mutationTypes.UPDATE_COLLABORATION_STATUS, false)
      context.commit(mutationTypes.SET_DISPLAY_CURSOR, false)
      context.commit(mutationTypes.UPDATE_ROOM_ID, "")

      //Lorsqu'un non créateur d'une session collaborative quitte la session, il faut relancer l'enregistrement de ses actions
      if (roomCreatorData.id !== context.rootGetters['user/userData'].sub) {
        context.dispatch('record/record/startRecording', null, {root: true})
      }
    },
    /**
     * Permet d'avertir le serveur que l'on souhaite prendre la main (devenir présentateur) de la session collaborative en cours
     * @method
     * @public
     */
    async presenter(context, {force, creatorData}) {
      if (force) {
        context.state.socket.emit('collaborative:changePresenter', {
          roomId: context.state.roomId,
          idSocket: creatorData.idSocket
        })
      } else {
        context.state.socket.emit('collaborative:askToBePresenter', {roomId: context.state.roomId})
      }
    },
    /**
     * Permet de transmettre au serveur les coordonnées du curseur du présentateur
     * @param {EyePolarCoordinates} cursorPosition Position du curseur du présentateur 
     * @method
     * @public
     */
    async sendPresenterCursor(context, presenterPosition) {
      presenterPosition.collaborativeEventType = 'cursorPosition'
      if (context.rootGetters["record/record/isRecording"] === true) {
        presenterPosition.ms = await context.dispatch('record/record/getElapsedMs', null, {root: true})
        context.commit(`record/record/${mutationTypes.ADD_ELEM_QUEUE_RECORD_EVENTS}`, presenterPosition, {root: true})
      }
      if (context.state.inCollaboration && context.state.viewer === false) {
        context.state.socket.emit('collaborative:movePresenterCursor', {roomId: context.state.roomId, presenterPosition, record: context.rootState.record.record.record})
      }
    },
    //TEMPORAIRE TESTS CLIENTS
    /**
     * Permet de stopper la visio pour tout les participants. Cela reste pour le moment une fonctionnalité pour les tests
     * @method
     * @public
     */
    stopVisio(context) {
      context.state.socket.emit('collaborative:stopVisio', {
        roomId: context.state.roomId
      })
    },
    //TEMPORAIRE
    /**
     * Permet l'envoi des événements effectués sur l'application aux autres membres de la session collaborative
     * @param {Object} event: Event,       Evenement devant être envoyé aux autres participants
     *  params: Objet       Paramètres complémentaires devant être envoyés avec l'événement
     *  window: Boolean     Détermine si l'évenement doit s'appliquer sur l'objet window
     * @method
     * @public
     */
    async sendEvent(context, {event, params, window, currentTarget}) {
      event.eyediag = { treated: true }
      
      const isSendingForCollaboration = context.state.inCollaboration && context.state.viewer === false
      const isSendingForRecording = context.rootGetters["record/record/isRecording"] === true 

      if (isSendingForRecording || isSendingForCollaboration) {
        let selector = null

        if (!window) {
          currentTarget = currentTarget || event.currentTarget
          if (currentTarget === null) {
            selector = CssSelector.DOMPresentationUtils.cssPath(event.target)
          } else {
            selector = CssSelector.DOMPresentationUtils.cssPath(currentTarget)
          }
  
          if (selector === '') {
            selector = CssSelector.DOMPresentationUtils.cssPath(event.target)
          }
        }

        const eventObj = {
          selector: (window) ? 'window' : selector,
          eventType: event.type,
          customType: event.customType,
          collaborativeEventType: 'event'
        }

        if (params) {
          eventObj.params = params
        }

        if (isSendingForCollaboration) {
          context.state.socket.emit('collaborative:event', {roomId: context.state.roomId, eventObj, record: context.rootState.record.record.record})
        }
        
        if (isSendingForRecording) {
          eventObj.ms = await context.dispatch('record/record/getElapsedMs', null, {root: true})
          context.commit(`record/record/${mutationTypes.ADD_ELEM_QUEUE_RECORD_EVENTS}`, eventObj, {root: true})
        }
      }
    },
    /**
     * Permet de transmettre aux autres membres d'une session collaborative la modification de propriétés sur un élément
     * @param { Object } target: Objet,       Cible sur laquelle les propriétés doivent s'appliquer
     *  params: Objet        Contient les proprités devant être modifiées sur la cible
     * @method
     * @public
     */
    async sendProperties(context, {target, params}) {
      const isSendingForCollaboration = context.state.inCollaboration && context.state.viewer === false
      const isSendingForRecording = context.rootGetters["record/record/isRecording"] === true 

      if (isSendingForRecording || isSendingForCollaboration) {
        let selector = CssSelector.DOMPresentationUtils.cssPath(target)

        const eventObj = {
          selector: selector,
          eventType: 'properties',
          collaborativeEventType: 'properties',
          params: params,
        }

        if (isSendingForCollaboration) {
          context.state.socket.emit('collaborative:event', {roomId: context.state.roomId, eventObj})
        }

        if (isSendingForRecording) {
          eventObj.ms = await context.dispatch('record/record/getElapsedMs', null, {root: true})
          context.commit(`record/record/${mutationTypes.ADD_ELEM_QUEUE_RECORD_EVENTS}`, eventObj, {root: true})
        }
      }
    },
    async collaborativeEventTreated(context) {
      if ((context.rootState.record.replay.replay) || (context.state.inCollaboration && context.state.viewer === true)) {
        collaborativeEventDispatcher.shiftQueueEvent()
        if (collaborativeEventDispatcher.resolve !== null) {
          collaborativeEventDispatcher.resolve()
        }
        if (context.rootState.record.replay.replay === true) {
          context.dispatch('record/replay/dispatchRecordEvent', null, {root: true})
        }
      }
    },
    /**
     * Permet de transmettre au serveur la demande de création d'une session collaborative
     * @method
     * @public
     */
    createRoom(context, { isRecordEvent }) {
      context.state.socket.emit("collaborative:createRoom", {
        visio: context.state.visio.enable,
        isRecordEvent: isRecordEvent,
        testName: context.rootState.e2eTesting.testName
      })

      const onRoomId = (event) => {
        if (event.isRecordEvent === true) {
          context.commit(`record/record/${mutationTypes.SET_RECORD_ROOM_ID}`, event.roomId, {root: true})
          context.dispatch('sendPresenterState', { idSocket: null, reload: reloadDataTypes.TEMPORALITY, isRecordEvent: true })
        } else {
          context.commit(mutationTypes.UPDATE_ROOM_ID, event.roomId)
        }
        context.state.socket.off("collaborative:roomId", onRoomId)
      }

      context.state.socket.on("collaborative:roomId", onRoomId)

      if (isRecordEvent === false) {
        context.commit(mutationTypes.UPDATE_COLLABORATION_STATUS, true)
      }
    },
    askPresenterState(context) {
      if (context.rootState.record.replay.replay === false) {
        context.state.socket.emit('collaborative:askState', {
          roomId: context.state.roomId
        })
      }
    },
    async sendPresenterState(context, data) {
      const store = await context.dispatch('getState', null, {root: true})
      context.state.socket.emit('collaborative:presenterStateTransfer', {
        clientSocketId: data.idSocket,
        store,
        reload: data.reload,
        isRecordEvent: data.isRecordEvent,
        roomId: data.isRecordEvent === true ? context.rootState.record.record.recordRoomId : context.state.roomId,
        elapsedSeconds: context.rootState.record.record.secondsElapsed
      })
    },
    changeTabVisibility(context, {visible}) {
      if (context.state.inCollaboration) {
        context.state.socket.emit('collaborative:changeTabVisibility', {
          visible: visible,
          roomId: context.state.roomId
        })
      }
    }
  }
}