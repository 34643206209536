<template>
  <div>
    <h1 class="patient-info_name">
      {{ name }}, {{ sex }}
    </h1>
    <p class="patient-birthdate">
      {{ birthdate }}, {{ age }} ans
    </p>
  </div>
</template>

<script setup>
import { userParserPatientData } from '@/components/hud/main/patientParser.js'

const {
  name,
  sex,
  birthdate,
  age
} = userParserPatientData()
</script>

<script>
export default {
  name: 'MainHudPatient',
}
</script>