<template>
  <div
    ref="base-border-list"
    class="base-border-list float-right top-0 right-0 bottom-0 w-0 border select-none"
  >
    <div
      ref="resize"
      class="resize-border-list absolute h-screen cursor-ew-resize" 
      @mousedown="resizeEvent"
    />
    <div class="style-borderList-info">
      <h3
        v-if="title !== null"
        class="m-b-20 text-center"
      >
        {{ title }}
      </h3>
      <slot name="content" />
    </div>
  </div>
</template>

<script>

import * as d3 from 'd3'
import D3Animation from '@/config/D3Animation.js'
import * as mutationTypes from "@/store/mutations-types"
import utils from '@/libraries/utils.js'

export default {
  name: 'ListBase',
  props: {
    title: {
      type: [String, null],
      required: false,
      default: null
    }
  },
  data: () => ({
    //position
    mPos: 0
  }),
  mounted() {
    this.initScrollEmitter()
  },
  methods: {
    /**
    * Fonction permettant de lancer la redimenssion de la fenêtre avec l'appelde la fonction resize de la borderlist à droite
    * @method
    * @public
    * @param {event} e
    */
    resizeEvent(e) {
      this.mPos = e.x

      const mouseMoveCallback = (event) => {
        this.resize(event)
      };

      window.addEventListener("mousemove", mouseMoveCallback)
      window.addEventListener("mouseup", function mouseUpCallback(event) {
        window.removeEventListener("mousemove", mouseMoveCallback)
        window.removeEventListener("mouseup", mouseUpCallback)
      })
    },
    /**
    * Permet de changer la taille des listes avec une limite entre 200 et 700px
    * @method
    * @public
    * @param {event} e
    */
    resize(e){
      const resizeElement = this.$refs["resize"];
      const parentResize = resizeElement.parentNode;
      const dx = this.mPos - e.x;
      const nbPixel = parseInt(getComputedStyle(parentResize, '').width) + dx
      if(nbPixel > 199 && nbPixel < 700){
        this.mPos = e.x;
        parentResize.style.width = nbPixel + "px";
      }
    },
    /**
     * Permet de gérer l'ouverture et la fermeture du conteneur permettant de selectionner les éléments présent dans l'assemblage que l'on souhaite sauvegarder
     * @method
     * @public
     * @param {Boolean} isDisplayed Détermine si la liste doit être affiché
     */
    async movePanel(isDisplayed) {
      const panelTransition = d3.select(this.$refs["base-border-list"])
        .transition()
        .duration(D3Animation.BORDER_LIST_DISPLAY)
        .style('width', isDisplayed ? '200px' : '0px')

      const elements = this.$refs["base-border-list"]
      
      this.$store.commit(`borderList/${mutationTypes.UPDATE_DISPLAYED_LIST}`, isDisplayed)

      try {
        if (isDisplayed) {
          elements.classList.add("visible")
          await panelTransition.end()
        } else {
          await panelTransition.end()
          elements.classList.remove("visible")
        }
      } catch {} //Click rapide d'un point à un autre du carroussel, la promesse lors du click sur le premier cercle fail. Il n'y a rien de spécial à faire lorsque la promesse d3js fail
    },
    /**
     * Permet de contrôler la position de la souris à l'intérieur du conteneur de filtrages des événements par temps puis de l'envoyer aux autres participants de la session collaborative afin d'engendrer un scroll sur leur affichage si la zone où se trouve la souris du présentateur n'est pas visible
     * @method
     * @public
     */
    initScrollEmitter() {
      d3.select(this.$refs['base-border-list'])
        .on('mousemove', (event) => {
          utils.sendCheckScroll(event, this.$store)
        })
    }
  }
}
</script>

<style scoped>
.base-border-list {
  display: none;
  background-color: var(--color-bg-1);
  overflow-y: scroll;
  overflow-x: hidden;
  color: var(--color-text);
}
.base-border-list.visible {
  display: block; /* Afficher les éléments visibles */
  height: calc(100vh - 50px);
}
.resize-border-list {
  border-left: solid var(--color-border);
  border-left-width: 1px;
  z-index: 7;
  width: 6px
}
.style-borderList-info{
  margin-inline: 15px;
  margin-top: 20px;
}
</style>